import React, {Component} from 'react'
import './index.scss'
import {message, Table} from 'antd'
import {Link, withRouter} from 'dva/router';
import {money100} from "../../utils/formatCheck"
import scfBusinessApi from "../../request/api/scfBusinessApi"
import {getCreditListApi, getQuotaStatusApi} from '../../request/api/quota'
import TicketStatusEnum from '../businessTicketIssue/component/ticketStatusEnum'
import RenderData from "../businessTicketIssue/component/renderData";
import {connect} from "dva/index";
import scfBaseApi from "../../request/api/scfBaseApi";
import {getStorage} from "../../utils/tools";

@withRouter

@connect((store) => ({
	store: store.bussimessTicket,
	loading: store.loading
}))
class Home extends Component {
	state = {
		companyID: "",
		companyName: "",
		data: "",
		orgType: '',
		count: "",

		tableData: [],
		date: '',
		creditStateList: []
	};

	componentDidMount() {
		this.setState({
			companyID: getStorage("companyID"),
			companyName: getStorage("companyName"),
		});
		this.getCompanyInfo()
		this.getQuotaStatus()
		this.getCreditList()
		this.getPublishStatics()
	}

	getPublishStatics = () => {
		scfBusinessApi.publishStatisticsApi().then(res => {
			this.setState({
				date: res.data
			})
		}).catch(err => {
			message.error(err.message);
		})
	}

	getQuotaStatus = () => {
		getQuotaStatusApi({dicType: 'ticketStatusCd'}).then(res => {
			if (res.code == '2000') {
				this.setState({
					creditStateList: res.data
				})
			} else {
				message.info(res.message)
			}
		}).catch(err => {
			message.error(err.message);
		})
	}
	getCreditList = () => {
		let params = {
			pageNum: 1,
			pageSize: 10,
		}
		getCreditListApi(params).then(res => {
			if (res.code == '2000') {
				this.setState({
					tableData: res.data.list
				})
			} else {
				message.info(res.message)
			}
		}).catch(err => {
			message.error(err.message);
		})
	}

	getCompanyInfo = () => {
		scfBaseApi.queryDetailApi({id: getStorage("companyID")}).then(res => {
			this.setState({
				orgType: res.data.orgType
			})
		}).catch(err => {
			message.error(err.message);
		})
	}
	goOpen = () => {
		this.props.history.push("/admin/businessTicketIssue/evaluation")
	}
	addBusiness = () => {
		this.props.history.push("/admin/businessTicketIssue/issueShanjian")
	}
	goToCompanyDetail = () => {
		const {companyID, orgType} = this.state
		this.props.history.push(`/admin/clientManage/clientInfo/view?orgId=${companyID}&orgType=${orgType}&status=0`)
	}

	render() {
		const {companyName, tableData, date} = this.state;
		const columns = [
			{
				title: "票据号码",
				dataIndex: "ticketNo",
				width: 240,
				fixed: 'left',
				sorter: (a, b) => {
					if (!a.ticketNo) {
						a.ticketNo = ""
					} else if (!b.ticketNo) {
						b.ticketNo = ""
					}
					return a.ticketNo - b.ticketNo
				},
			},
			{
				title: "票据金额（元）",
				render: (record) => {
					return <span>{money100(record.ticketAmount)}</span>
				},
				sorter: (a, b) => {
					if (!a.ticketAmount) {
						a.ticketAmount = ""
					} else if (!b.ticketAmount) {
						b.ticketAmount = ""
					}
					return a.ticketAmount - b.ticketAmount
				},
			},
			{
				title: "出票日期",
				dataIndex: "ticketDate",
				sorter: (a, b) => {
					let aTime = new Date(a.ticketDate).getTime();
					let bTime = new Date(b.ticketDate).getTime();
					return aTime - bTime;
				},
			},
			{
				title: "汇票到期日期",
				dataIndex: "ticketExpireDate",
				sorter: (a, b) => {
					let aTime = new Date(a.ticketExpireDate).getTime();
					let bTime = new Date(b.ticketExpireDate).getTime();
					return aTime - bTime;
				},
			},
			{
				title: "票据状态",
				dataIndex: "ticketStatusCd",
				sorter: (a, b) => {
					if (!a.ticketStatusCd) {
						a.ticketStatusCd = ""
					} else if (!b.ticketStatusCd) {
						b.ticketStatusCd = ""
					}
					return a.ticketStatusCd.localeCompare(b.ticketStatusCd)
				},
				render: (record) => {
					return <TicketStatusEnum data={record} creditStateList={this.state.creditStateList}/>
				},
			},
			{
				title: "出票人名称",
				dataIndex: "drawerName",
				sorter: (a, b) => {
					if (!a.drawerName) {
						a.drawerName = ""
					} else if (!b.drawerName) {
						b.drawerName = ""
					}
					return a.drawerName.localeCompare(b.drawerName)
				},
			},
			{
				title: "出票账号",
				dataIndex: "drawerAccountNo",
				sorter: (a, b) => {
					if (!a.drawerAccountNo) {
						a.drawerAccountNo = ""
					} else if (!b.drawerAccountNo) {
						b.drawerAccountNo = ""
					}
					return a.drawerAccountNo - b.drawerAccountNo
				},

			},
			{
				title: "出票开户行",
				dataIndex: "drawerBank",
				sorter: (a, b) => {
					if (!a.drawerBank) {
						a.drawerBank = ""
					} else if (!b.drawerBank) {
						b.drawerBank = ""
					}
					return a.drawerBank.localeCompare(b.drawerBank)
				},
			},
			{
				title: "收款人名称",
				dataIndex: "payeeName",
				sorter: (a, b) => {
					if (!a.payeeName) {
						a.payeeName = ""
					} else if (!b.payeeName) {
						b.payeeName = ""
					}
					return a.payeeName.localeCompare(b.payeeName)
				},
			},
			{
				title: "收款账号",
				dataIndex: "payeeAccountNo",
				sorter: (a, b) => {
					if (!a.payeeAccountNo) {
						a.payeeAccountNo = ""
					} else if (!b.payeeAccountNo) {
						b.payeeAccountNo = ""
					}
					return a.payeeAccountNo - b.payeeAccountNo
				},
			},
			{
				title: "收款开户行",
				dataIndex: "payeeBank",
				sorter: (a, b) => {
					if (!a.payeeBank) {
						a.payeeBank = ""
					} else if (!b.payeeBank) {
						b.payeeBank = ""
					}
					return a.payeeBank.localeCompare(b.payeeBank)
				},
			},
			{
				title: "承兑人名称",
				dataIndex: "acceptorName",
				sorter: (a, b) => {
					if (!a.acceptorName) {
						a.acceptorName = ""
					} else if (!b.acceptorName) {
						b.acceptorName = ""
					}
					return a.acceptorName.localeCompare(b.acceptorName)
				},
			},
			{
				title: "承兑人账号",
				dataIndex: "acceptorAccountNo",
				sorter: (a, b) => {
					if (!a.acceptorAccountNo) {
						a.acceptorAccountNo = ""
					} else if (!b.acceptorAccountNo) {
						b.acceptorAccountNo = ""
					}
					return a.acceptorAccountNo - b.acceptorAccountNo
				},
			},
			{
				title: "承兑人开户行",
				dataIndex: "acceptorBank",
				sorter: (a, b) => {
					if (!a.acceptorBank) {
						a.acceptorBank = ""
					} else if (!b.acceptorBank) {
						b.acceptorBank = ""
					}
					return a.acceptorBank.localeCompare(b.acceptorBank)
				},
			},
			{
				title: "操作",
				width: "280px",
				fixed: 'right',
				render: (record) => {
					return <RenderData data={record}/>;
				},
			},
		];

		return (
			<div>
				<div style={{background: "#eee", padding: "5px 15px"}}>
					<span className="fontSize20px">欢迎使用我们的服务</span>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<span className="companyName-box">{companyName}</span>
					<span onClick={this.goToCompanyDetail} className='detail-span'>详细 >> </span>
				</div>
				<div className="case">
					<div className="content">
						<div className='content-flex'>
							<ul>
								{date ?
									<li className="fontSize30px" title={money100(date.totalCount)}>{money100(date.totalCount)}</li> :
									<li className="fontSize30px">0</li>}
								<li>累计发行总笔数</li>
							</ul>
							<ul>
								{date ?
									<li className="fontSize30px" title={money100(date.totalAmount)}>{money100(date.totalAmount)}</li> :
									<li className="fontSize30px">0</li>}
								<li>累计发行总金额(元)</li>
							</ul>
							<ul>
								{date ? <li className="fontSize30px"
								            title={money100(date.unexpiredAmount)}>{money100(date.unexpiredAmount)}</li> :
									<li className="fontSize30px">0</li>}
								<li>未解付金额(元)</li>
							</ul>
							<ul>
								{date ? <li className="fontSize30px"
								            title={money100(date.expiredAmount)}>{money100(date.expiredAmount)}</li> :
									<li className="fontSize30px">0</li>}
								<li>已解付金额(元)</li>
							</ul>
						</div>
					</div>
				</div>
				<div>
					<div className='marginBottom-flex'>
						<h2>近期发行</h2>
						<div style={{textAlign: 'right'}}>
							<Link to="/admin/businessTicketIssue/issueShanjian" style={{color: 'blue'}}>查看更多</Link>
						</div>
					</div>
					<Table
						bordered
						columns={columns}
						rowKey={(record, index) => record.id}
						dataSource={tableData}
						scroll={{x: 2000}}
						pagination={false}
					/>
				</div>
			</div>
		);
	}
}

export default Home
import React, {Component} from 'react';
import {Button, Form, Input, message, Spin, Modal, Table} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";

const FormItem = Form.Item;

class AddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            visible: true,
            orgName: "",
            cardNo: "",
            selectedRowKeys: []

        }
    }

    componentDidMount() {
        this.getCompanyList();
    }

    //获取企业列表
    getCompanyList = () => {
        let {orgId, businessRole,relationBusinessRole} = this.props;
        let {pageNum, pageSize, orgName, cardNo} = this.state;
        let params = {
            orgId,
            businessRole:relationBusinessRole,
            relationBusinessRole:businessRole,
            orgName,
            cardNo,
            pageNum,
            pageSize,
        };
        scfBaseApi.orgrelationQueryApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getCompanyList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getCompanyList();
        })
    }
    //选择的table条数改变
    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    orgName: values.orgName,
                    cardNo: values.cardNo,
                }, () => {
                    this.getCompanyList();
                })
            }
        })
    }
    handleOk = () => {
        let {orgId,businessRole,relationBusinessRole} = this.props;
        let {selectedRowKeys} = this.state;
        let params = {orgId, ids:selectedRowKeys,businessRole:relationBusinessRole,relationBusinessRole:businessRole};
        scfBaseApi.orgrelationAddApi(params).then(res => {
            message.success(res.message);
            this.setState({
                isShowAdd: false,
            });
            this.props.operationOk();
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });


    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount, selectedRowKeys, visible} = this.state;
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        let columns = [
            {
                title: '客户名称',
                dataIndex: 'orgName',
                key: 'orgName',
                sorter: (a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                }
            },
            {
                title: '证件类型',
                dataIndex: 'idTypeCdDesc',
                key: 'idTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.idTypeName){
                        a.idTypeName=""
                    }else if(!b.idTypeName){
                        b.idTypeName=""
                    }
                    return a.idTypeName.localeCompare(b.idTypeName)
                }
            },
            {
                title: '证件号码',
                dataIndex: 'cardNo',
                key: 'cardNo',
                sorter: (a,b)=>{
                    if(!a.cardNo){
                        a.cardNo=""
                    }else if(!b.cardNo){
                        b.cardNo=""
                    }
                    return a.cardNo-b.cardNo
                }
            }
        ];

        return (
            <Modal
                title="新增"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
                width={1000}
                className="add-modal-wrapper"
            >
                <Form layout="inline" onSubmit={this.handleSubmit} className="flex-between" style={{marginBottom: 20}}>
                    <FormItem label="客户名称">
                        {
                            getFieldDecorator('orgName', {
                                rules: []
                            })(<Input style={{width: 250}}/>)
                        }
                    </FormItem>
                    <FormItem label="证件号码">
                        {
                            getFieldDecorator('cardNo', {
                                rules: []
                            })(<Input placeholder='请输入证件号码' style={{width: 250}}/>)
                        }
                    </FormItem>
                    <FormItem>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </FormItem>
                </Form>
                <Table
                    className="freezing-deposit-table"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount>10?pagination:false}
                />
            </Modal>
        );
    }
}

export default Form.create()(AddModal);
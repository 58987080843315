import React, { Component } from 'react';
import { connect } from 'dva';
import DragTableComp from './dragTableComp';
import scfBaseApi from '@/request/api/scfBaseApi';
import { Form, Row, Col, Select, Spin, message } from 'antd';

const { Option } = Select;
const signConfigAddApi = scfBaseApi['signConfigAddApi'];

@Form.create()
@connect((store) => ({ store: store.signingConfiguration, loading: store.loading }))
class AddComp extends Component {

    componentDidMount () {
        this.props.dispatch({ type: 'signingConfiguration/updateReducers', payload: { key: 'enableData', val: [] }});
        this.props.dispatch({type: 'signingConfiguration/getProjectList'});
    }

    handleAddCredit = (data) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                let newData = [];
                data.map((item) => newData.push({ signOrder: item.signOrder, contractTemplateId: item.contractTemplateId }));
                values.configDetails = newData;
                signConfigAddApi(values).then(res => {
                    if(res.code === '0') message.success(res.message);
                    this.props.history.push('/admin/signingConfiguration/list');
                }).catch(err => message.error(err.message))
            }
        })
    }

    handleSelectChange = (val) => {
        this.props.form.resetFields('productId');
        this.props.dispatch({ type: 'signingConfiguration/updateReducers', payload: { key: 'enableData', val: [] }});
        this.props.dispatch({ type: 'signingConfiguration/getProductList', payload: {companyCreditId: val}});
    }

    handleProductChange = (val) => {
        let params = {
            projectId: this.props.form.getFieldValue('projectId'),
            productId: val
        }
        this.props.dispatch({ type: 'signingConfiguration/getEnableData', payload: params});
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { projectList, productList, enableData } = this.props.store;
        const isLoding1 = this.props.loading.effects['signingConfiguration/getProjectList'];
        const isLoding2 = this.props.loading.effects['signingConfiguration/getProductList'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        return (
            <div>
                <Spin spinning={ isLoding1 }>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>签约配置基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择项目">
                                    {
                                        getFieldDecorator('projectId', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择项目' }]
                                        })(
                                            <Select name='projectId' onChange={this.handleSelectChange}>
                                                {
                                                    projectList && projectList.length 
                                                        ? projectList.map((item, index)=> <Option key={index} value={item.id}>{item.projectName}</Option>)
                                                        : null 
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择产品">
                                    {
                                        getFieldDecorator('productId', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择产品' }]
                                        })(
                                            <Select name='productId' loading={isLoding2} onChange={this.handleProductChange}>
                                                {
                                                    productList && productList.length 
                                                        ? productList.map((item, index)=> <Option key={index} value={item.id}>{item.productName}</Option>)
                                                        : null 
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <p className='h_text_style' style={{margin:0}}>合同签约配置（合同签约会按照配置的签约顺序依次签约）</p>
                    <DragTableComp data={enableData} update={this.handleAddCredit} history={this.props.history} formEvent={this.props.form}/>
                </Spin>
            </div>
        )
    }
}

export default AddComp;

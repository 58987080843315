import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import ModalMsgComp from './modalMsgComp';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

@Form.create()
@connect((store) => ({ store: store.borrowerCredit, loading: store.loading }))
class ListComp extends Component {

    componentDidMount () {
        this.getBorrowerCreditListData();
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerModeEnumerate', payload: 'financing_mode'});
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerStatusEnumerate', payload: 'credit_state'});
    }

    getBorrowerCreditListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                if(values.dates && values.dates.length) {
                    values.beginBorrowerEndTime = moment(values.dates[0]).format('YYYY-MM-DD');
                    values.endBorrowerEndTime = moment(values.dates[1]).format('YYYY-MM-DD');
                    values.dates = undefined;
                }
                if(pagination) {
                    values.pageNum = pagination.current;
                    values.pageSize = pagination.pageSize;
                }
                this.props.dispatch({ type: 'borrowerCredit/getBorrowerCreditListData', payload: values});
            }
        })
    }

    handleHistoryGoAdd = () => {
        this.props.history.push('/admin/borrowerCredit/add');
    }

    handleCurrentChange = (pagination) => {
        this.getBorrowerCreditListData(pagination)
    }

    handleFormSearch = debounce(() => {
        this.getBorrowerCreditListData();
    }, 300)

    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getBorrowerCreditListData();
    }, 300)

    render() {
        const { getFieldDecorator } = this.props.form;
        const { borrowerCreditList, borrowerModeEnumerate, borrowerStatusEnumerate } = this.props.store;
        const isLoding = this.props.loading.effects['borrowerCredit/getBorrowerCreditListData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '序号',
                key: 'index',
                render: (text, record, index) => `${(borrowerCreditList.pageNum-1)*(borrowerCreditList.pageSize)+(index+1)}`,
                width: 60
            },
            {
                title: '借款方名称',
                dataIndex: 'borrowerName',
                key: 'borrowerName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.borrowerName){
                        a.borrowerName=""
                    }else if(!b.borrowerName){
                        b.borrowerName=""
                    }
                    return a.borrowerName.localeCompare(b.borrowerName)
                }
            },
            {
                title: '融资模式',
                dataIndex: 'productModeName',
                key: 'productModeName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.productModeName){
                        a.productModeName=""
                    }else if(!b.productModeName){
                        b.productModeName=""
                    }
                    return a.productModeName.localeCompare(b.productModeName)
                }
            },
            {
                title: '授信额度（元）',
                dataIndex: 'totalAmountName',
                key: 'totalAmountName',
                width: 145,
                sorter: (a,b)=>{
                    if(!a.totalAmountName){
                        a.totalAmountName=""
                    }else if(!b.totalAmountName){
                        b.totalAmountName=""
                    }
                    return a.totalAmountName.replace(/[^\d.]/g,"")-b.totalAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '可融资额度（元）',
                dataIndex: 'haveAmountName',
                key: 'haveAmountName',
                width: 145,
                sorter: (a,b)=>{
                    if(!a.haveAmountName){
                        a.haveAmountName=""
                    }else if(!b.haveAmountName){
                        b.haveAmountName=""
                    }
                    return a.haveAmountName.replace(/[^\d.]/g,"")-b.haveAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '利率（%）',
                dataIndex: 'borrowerRate',
                key: 'borrowerRate',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.borrowerRate){
                        a.borrowerRate=""
                    }else if(!b.borrowerRate){
                        b.borrowerRate=""
                    }
                    return a.borrowerRate-b.borrowerRate
                }
            },
            {
                title: '授信开始日期',
                dataIndex: 'borrowerStartRateName',
                key: 'borrowerStartRateName',
                width: 120,
                sorter: (a, b) => {
                    let aTime = new Date(a.borrowerStartRateName).getTime()
                    let bTime = new Date(b.borrowerStartRateName).getTime()
                    return aTime-bTime
                }

            },
            {
                title: '授信结束日期',
                dataIndex: 'borrowerEndRateName',
                key: 'borrowerEndRateName',
                width: 120,
                sorter: (a, b) => {
                    let aTime = new Date(a.borrowerEndRateName).getTime()
                    let bTime = new Date(b.borrowerEndRateName).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.projectName){
                        a.projectName=""
                    }else if(!b.projectName){
                        b.projectName=""
                    }
                    return a.projectName.localeCompare(b.projectName)
                }
            },
            {
                title: '产品名称',
                dataIndex: 'productName',
                key: 'productName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.productName){
                        a.productName=""
                    }else if(!b.productName){
                        b.productName=""
                    }
                    return a.productName.localeCompare(b.productName)
                }
            },
            {
                title: '资金方名称',
                dataIndex: 'capitalName',
                key: 'capitalName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.capitalName){
                        a.capitalName=""
                    }else if(!b.capitalName){
                        b.capitalName=""
                    }
                    return a.capitalName.localeCompare(b.capitalName)
                }
            },
            {
                title: '核心企业名称',
                dataIndex: 'companyName',
                key: 'companyName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.companyName){
                        a.companyName=""
                    }else if(!b.companyName){
                        b.companyName=""
                    }
                    return a.companyName.localeCompare(b.companyName)
                }
            },
            {
                title: '授信状态',
                dataIndex: 'borrowerStateName',
                key: 'borrowerStateName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.borrowerStateName){
                        a.borrowerStateName=""
                    }else if(!b.borrowerStateName){
                        b.borrowerStateName=""
                    }
                    return a.borrowerStateName.localeCompare(b.borrowerStateName)
                }
            },
            {
                title: '上级状态',
                dataIndex: 'productStateName',
                key: 'productStateName',
                width: 120,
                sorter: (a,b)=>{
                    if(!a.productStateName){
                        a.productStateName=""
                    }else if(!b.productStateName){
                        b.productStateName=""
                    }
                    return a.productStateName.localeCompare(b.productStateName)
                }
            },
            {
                title: '操作',
                width: 250,
                fixed: 'right',
                render: (text, record) => {
                    const editPath = `/admin/borrowerCredit/edit?id=${record.id}`;
                    const detailPath = `/admin/borrowerCredit/detail?id=${record.id}`;
                    const editNode = <Button className='com-btn-opr com-small-btn-size mr5' href={editPath}>修改</Button>;
                    const detailNode = <Button className='com-btn-opr com-small-btn-size mr5' href={detailPath}>详情</Button>;
                    return <div>
                        {
                            record.borrowerState === '1' 
                                ? <React.Fragment>
                                    {detailNode}
                                    {editNode}
                                    <ModalMsgComp type={0} id={record.id} status={0} updata={this.getBorrowerCreditListData} />
                                </React.Fragment> 
                                : null
                        }
                        {
                            record.borrowerState === '0' 
                                ? <React.Fragment>
                                    {detailNode}
                                    {
                                        record.productState !== '0'
                                        ? <ModalMsgComp type={1} id={record.id} status={1} updata={this.getBorrowerCreditListData} />
                                        : null
                                    }
                                </React.Fragment> 
                                : null
                        }
                        {
                            record.borrowerState === '2' 
                            ? <React.Fragment>
                                {editNode}
                                {detailNode}
                            </React.Fragment> 
                            : null
                        }
                    </div>
                }
            }
        ];
        const tabelHeadBtn = <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleHistoryGoAdd}>新增</Button>;
        
        return (
            <div>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="借款方名称">
                                {
                                    getFieldDecorator('borrowerName', {})(
                                        <Input name='borrowerName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目名称">
                                {
                                    getFieldDecorator('projectName', {})(
                                        <Input name='projectName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="产品名称">
                                {
                                    getFieldDecorator('productName', {})(
                                        <Input name='productName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="资金方名称">
                                {
                                    getFieldDecorator('capitalName', {})(
                                        <Input name='capitalName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="核心企业名称">
                                {
                                    getFieldDecorator('companyName', {})(
                                        <Input name='companyName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="授信结束期">
                                {
                                    getFieldDecorator('dates', {})(
                                        <RangePicker name='dates' style={{width: '100%'}} suffixIcon=' ' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="授信状态">
                                {
                                    getFieldDecorator('borrowerState', {
                                        initialValue: ''
                                    })(
                                        <Select name='borrowerState'>
                                            <Option value=''>全部</Option>
                                            {
                                                borrowerStatusEnumerate && borrowerStatusEnumerate.length 
                                                ? borrowerStatusEnumerate.map((item, index)=> <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                : null
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="融资模式">
                                {
                                    getFieldDecorator('productMode', {
                                        initialValue: ''
                                    })(
                                        <Select name='productMode'>
                                            <Option value=''>全部</Option>
                                            {
                                                borrowerModeEnumerate && borrowerModeEnumerate.length 
                                                ? borrowerModeEnumerate.map((item, index)=> <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                : null
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    <div className='wraper-cd-center'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Form>
                <Table 
                    columns={columns}
                    dataSource={borrowerCreditList.data}
                    rowKey={(record, index) => index}
                    title={() => tabelHeadBtn}
                    scroll={{ x: 1500 }}
                    loading={isLoding}

                    pagination = {{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: borrowerCreditList.totalCount <= 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `总共 ${borrowerCreditList.totalCount} 条`,
                        total: borrowerCreditList.totalCount,
                        current: borrowerCreditList.pageNum,
                        pageSize: borrowerCreditList.pageSize,
                    }}
                    onChange = {this.handleCurrentChange}
                />
            </div>
        )
    }
}

export default ListComp;

import React, { Component } from 'react';
import { Table } from 'antd';
import RenderData from './renderData'
class QuotaTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          totalCount: 0,
          pageNum: 1,
          pageSize: 10,
        }
    }
    render() { 
        const columns = [
            {
              title: '申请人',
              dataIndex: 'enterpriseName',
              sorter:(a,b)=>{
                    if(!a.acceptorBank){
                        a.acceptorBank=""
                    }else if(!b.acceptorBank){
                        b.acceptorBank=""
                    }
                    return a.acceptorBank.localeCompare(b.acceptorBank)
                },
            },
            {
              title: '联系电话',
              dataIndex: 'contactMobile',
              sorter: (a, b) => a.totalAmount - b.totalAmount,
            },
            {
              title: '申请日期',
              dataIndex: 'createTime',
              sorter: (a, b) => {
                let aTime = new Date(a.createTime).getTime()
                let bTime = new Date(b.createTime).getTime()
                return aTime-bTime
            },
            },
            {
                title: '审核日期',
                dataIndex: 'auditTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.auditTime).getTime()
                    let bTime = new Date(b.auditTime).getTime()
                    return aTime-bTime
                },
            },
            {
                title: '总额度（元）',
                dataIndex: 'totalAmount',
                sorter: (a, b) => a.totalAmount - b.totalAmount,
            },
            {
                title: '开始日期',
                dataIndex: 'startTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.startTime).getTime()
                    let bTime = new Date(b.startTime).getTime()
                    return aTime-bTime
                },
            },
            {
                title: '到期日期',
                dataIndex: 'endTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.endTime).getTime()
                    let bTime = new Date(b.endTime).getTime()
                    return aTime-bTime
                },
            },
            {
                title: '状态',
                dataIndex: 'auditStatusCdDesc',
            },
            {
              title: '操作',
              width:"166px",
              render: (record) => {
                  return <RenderData data={record} />
              }
          },
          ];
          const { data } = this.props
          return ( 
              <>
                  <Table 
                  bordered 
                  columns={columns} 
                  rowKey={(record, index) => index}
                  dataSource={data.data}  
                  pagination = {{
                      showSizeChanger: true,
                      showQuickJumper: true,
                      hideOnSinglePage: data.totalCount <= 10,
                      pageSizeOptions: ['10', '20', '50', '100'],
                      showTotal: total => `总共 ${data.totalCount} 条`,
                      total: data.totalCount,
                      current: data.pageNum,
                      pageSize: data.pageSize,
                  }}
                  onChange = {this.props.handleCurrentChange}
                  />
              </>
           );
    }
}
 
export default QuotaTable;
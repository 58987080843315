
import scfBaseApi from '@/request/api/scfBaseApi';
import scfBusinessApi from '@/request/api/scfBusinessApi';
const findDicLtApi = scfBusinessApi['findDicLtApi'];
const getAllEnterpriseApi = scfBusinessApi['getAllEnterpriseApi'];
const getBorrowerCreditDetailApi = scfBusinessApi['getBorrowerCreditDetailApi'];
const getAllProjectByBusRoleApi = scfBusinessApi['getAllProjectByBusRoleApi'];
const queryBorrowerApi = scfBaseApi['queryBorrowerApi'];
const getProjectProductsApi = scfBusinessApi['getProjectProductsApi'];
const getborrowerCreditListApi = scfBusinessApi['getborrowerCreditListApi'];

export default {
    //命名空间
    namespace: "borrowerCredit",
    //仓库初始化状态
    state: {
        borrowerCreditList: [],
        borrowerModeEnumerate: [],
        borrowerStatusEnumerate: [],
        borrowerProjectEnumerate: [],
        borrowerProjectProducts: [],
        borrowerName: [],
        productDetails: {},
        loanTermEnumerate: [],
        borrowerDetailData: {}
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        *getBorrowerCreditListData(action, { call, put }) {
            const result = yield call(getborrowerCreditListApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'borrowerCreditList', val: result.data } })
        },
        *getBorrowerProjectProducts(action, { call, put }) {
            const params = { companyCreditId: action.payload }
            const result = yield call(getProjectProductsApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'borrowerProjectProducts', val: result.data } })
        },
        *getBorrowerName(action, { call, put }) {
            const result = yield call(queryBorrowerApi)
            yield put({ type: 'updateReducers', payload: { key: 'borrowerName', val: result.data } })
        },
        *getAllEnterprise(action, { call, put }) {
            const result = yield call(getAllEnterpriseApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'productDetails', val: result.data } })
        },
        *getBorrowerDetailData(action, { call, put }) {
            const params = { id: action.payload };
            const result = yield call(getBorrowerCreditDetailApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'borrowerDetailData', val: result.data } })         
        },
        //静态枚举
        *getBorrowerModeEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'borrowerModeEnumerate', val: result.data } })
        },
        *getBorrowerStatusEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'borrowerStatusEnumerate', val: result.data } })
        },
        *getBorrowerProjectEnumerate(action, { call, put }) {
            const result = yield call(getAllProjectByBusRoleApi)
            yield put({ type: 'updateReducers', payload: { key: 'borrowerProjectEnumerate', val: result.data } })
        },
        *getLoanTermEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'loanTermEnumerate', val: result.data } })
        }
    }
};

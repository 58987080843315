import React, { Component } from 'react'
import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message,Table} from 'antd';
import {toPercent,moneyMore,money} from '../../../utils/formatCheck'


export default class JdQuoteTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            tableDataJd:[
                // {
                //     acceptanceType: "杜娟",
                //     amountRange: "18",
                //     duedateRange: "中国",
                //     priceMargin: "123"
                // },
                
       
            ],
            intervalId:"",
            intervalId2:"",
            setTimeoutId:""
        }
    }
   componentDidMount(){
       this.JDQuote()
    //    var intervalId = setInterval(() => {
    //       this.JDQuote()
    //     }, 7200000);
        // this.setState({
        //     intervalId,
        // })

    var setTimeoutId = setTimeout(() => {
        this.move()
    }, 3000);

    this.setState({
        setTimeoutId,
    })
  
}

move = () =>{
    var oList = document.getElementById('list')
    var height = oList.offsetHeight
    var h = parseInt(height)
    var n = 1;
    var intervalId2 = setInterval(() => {
        n++
        oList.style.top = -n*1+"px"
        var d = -n
        if(d <= 162-h-100){
            this.JDQuote()
          n=1
          oList.style.top = 0+'px'
        }
    }, 100);
    this.setState({
      intervalId2,
  })
  
}


componentWillUnmount = () => {
    let {intervalId,intervalId2,setTimeoutId} = this.state
    // clearInterval(intervalId)
    clearInterval(intervalId2)
    clearInterval(setTimeoutId)
}
   JDQuote = () =>{
    let params = {
       
    }
    scfBusinessApi.queryQuoteApi(params).then(res=>{
          this.setState({
            tableDataJd:res.data.list
          })
    }).catch(err=>{
        message.error(err.message)
    })
}
    render() {
        let {tableDataJd} = this.state
        // const columns = [
        //     {
        //         title: '发布时间',
        //         dataIndex: 'publishDate',
        //         key: 'publishDate',
        //         width: '15%',
        //         className:'moneyColor',
        //     },
        //     {
        //         title: '承兑人',
        //         dataIndex: 'acceptorName',
        //         key: 'acceptorName',
        //         width: '23%',
        //         className:'moneyColor',
        //     },
        //     {
        //         title: '到日期',
        //         dataIndex: 'due_date',
        //         key: 'due_date',
        //         width: '15%',
        //         className:'moneyColor',

        //     },
        //     {
        //         title: '票面金额（万元）',
        //         dataIndex: 'faceValue',
        //         key: 'faceValue',
        //         width: '15%',
        //         className:'moneyColor',
              

        //     },
        //     {
        //         title: '每十万贴息（元）',
        //         dataIndex: 'discount',
        //         key: 'discount',
        //         className:'moneyColor',
        //         width: '10%',

        //     },
        //     {
        //         title: '年利息',
        //         dataIndex: 'apr',
        //         key: 'apr',
        //         className:'moneyColor',
        //         width: '6%',
              

        //     },
        //     {
        //         title: '瑕疵',
        //         dataIndex: 'flawName',
        //         key: 'flawName',
        //         className:'moneyColor',
        //         width: '8%',
               

        //     },
        //     {
        //         title: '交易渠道',
        //         dataIndex: 'channelTypeName',
        //         key: 'channelTypeName',
        //         className:'moneyColor',
        //         width: '8%',

        //     },
        // ];
        return (
                // <Table
                
                //             bordered={false}
                //             columns={columns}
                //             dataSource={tableDataJd}
                //             scroll={{  y: 160 }} 
                //             pagination={false}
                //             rowKey={(record, index) => index}
                //             rowClassName={record => {
                //              return 'bgc';
                //          }}
                        // rowClassName={(record, index) => {
                        //     console.log(record,index)
                        // }}
                      
                        // /> 


                        <div className="wrapTable">
                        <ul className="header">
                          <li className="cell time">发布时间</li>
                          <li className="cell person">承兑人</li>
                          <li className="cell time">到期日</li>
                          <li className="cell faceValue">票面金额(万元)</li>
                          <li className="cell discount">每十万贴息(元)</li>
                          <li className="cell other">年利息</li>
                          <li className="cell other">瑕疵</li>
                          <li className="cell channelTypeName">交易渠道</li>
                        </ul>
                        <div className="bodyWrap">
                          <ul id="list">
                            {/* <li
                              v-for="(item, index) in arr"
                              :key="index"
                              class="row"
                            >
                              <span class="cell">{{ item }}</span>
                              <span v-for="(n) in 4" :key="n*30" class="cell">{{ n }}</span>
                            </li> */}
                    {
                    tableDataJd.map(item => {
                       return   (
                        <li key={item.id}>
                            <span className="cell time">{item.publishDate}</span>
                            <span className="cell person">{item.acceptorName}</span>
                            <span className="cell time">{item.dueDate}</span>
                            <span className="cell faceValue">{moneyMore(item.faceValue)}</span>
                            <span className="cell discount">{moneyMore(item.discount)}</span>
                            <span className="cell other">{item.apr==0?'竞价':toPercent(item.apr)}</span>
                            <span className="cell other">{item.flawName}</span>
                            <span className="cell channelTypeName">{item.channelTypeName}</span>
                        </li>)
                    })
                    }
                 {/* {
                    [1,2,3,4,5,6,7,89,0,1,1,1,,1,1].map((item,index) => {
                       return   (
                        <li key={index}>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                        </li>)
                    })
                    } */}
                    {/* <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>

                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li> */}

                    
                    
                          </ul>
                        </div>
                      </div>
           
        )
    }
}

import React, {Component} from 'react';
import {Form, Input, Button, message, Row, Col, Select, Table} from 'antd';
import scfBusinessApi from "../../../request/api/scfBusinessApi";
import queryString from "query-string";
import {withRouter} from "dva/router";
import scfBaseApi from '../../../request/api/scfBaseApi';


const FormItem = Form.Item;
const Option = Select.Option;

@withRouter
class InquireForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgTypeCdArr: [],
            businessRoleArr: [],
            tableData: [],
            totalCount: 0,
            pageNum: 1,
            pageSize: 10,
            orgName: "",
            cardNo: "",
            orgType: "",
            businessRole: ""
        }
    }
    componentDidMount() {
        this.getDicLt("orgTypeCd");
        this.getDicLt("businessRole");
        this.getClientInfoList();
    }

    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType: type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取客户信息列表
    getClientInfoList = () => {
        let {orgName, cardNo, orgType, businessRole, pageNum, pageSize} = this.state;
        let params = {orgName, cardNo, orgType, businessRole, pageNum, pageSize};
        scfBaseApi.orgListApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            orgName: "",
            cardNo: "",
            orgType: "",
            businessRole: "",
        })
        this.setState({
            orgName: "",
            cardNo: "",
            orgType: "",
            businessRole: "",
            pageNum: 1,
        }, () => {
            this.getClientInfoList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {orgName, cardNo, orgType, businessRole} = values;
                this.setState({orgName, cardNo, orgType, businessRole, pageNum: 1},
                    () => {
                        this.getClientInfoList();
                    })
            }
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getClientInfoList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getClientInfoList();
        })
    }
    //跳转详情
    jumpDetail = (orgId = 0, orgType = "") => {
        let data = {addCurrent: 1};
        localStorage.setItem("clientInfoManage", JSON.stringify(data));
        this.props.history.push({
            pathname: `/admin/clientManage/clientInfo/view`,
            search: queryString.stringify({
                orgId,
                orgType
            }),
        })
    }
    //添加用户信息
    addClientInfo = () => {
        // let data = {addCurrent: 0};
        let value = {"orgType":"org","businessRole":["coreorg"],"idType":"1","addCurrent":0}
        // localStorage.setItem("clientInfoManage", JSON.stringify(data));
        localStorage.setItem("clientInfoManage", JSON.stringify(value));
        this.props.history.push("/admin/clientManage/clientInfo/add");
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
    handleClickHeader = (row) =>{
        row.className=row.className+" left"
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {orgTypeCdArr, businessRoleArr, tableData, totalCount, pageNum, pageSize} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'left'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal: total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '企业名称',
                dataIndex: 'orgName',
                align: 'left',
                key: 'orgName',
                sorter: (a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
            },
            {
                title: '证件类型',
                dataIndex: 'idTypeCdDesc',
                key: 'idTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.idTypeName){
                        a.idTypeName=""
                    }else if(!b.idTypeName){
                        b.idTypeName=""
                    }
                    return a.idTypeName.localeCompare(b.idTypeName)
                }
            },
            {
                title: '证件号码',
                dataIndex: 'cardNo',
                key: 'cardNo',
                sorter: (a,b)=>{
                    if(!a.cardNo){
                        a.cardNo=""
                    }else if(!b.cardNo){
                        b.cardNo=""
                    }
                    return a.cardNo.localeCompare(b.cardNo)
                }
            },
            {
                title: '企业类型',
                dataIndex: 'orgTypeCdDesc',
                key: 'orgTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.orgTypeName){
                        a.orgTypeName=""
                    }else if(!b.orgTypeName){
                        b.orgTypeName=""
                    }
                    return a.orgTypeName.localeCompare(b.orgTypeName)
                }
            },
            // {
            //     title: '业务角色',
            //     dataIndex: 'roleCdDesc',
            //     key: 'roleCdDesc',
            //     align: 'left',
            //     sorter: (a,b)=>{
            //         if(!a.businessRoleName){
            //             a.businessRoleName=""
            //         }else if(!b.businessRoleName){
            //             b.businessRoleName=""
            //         }
            //         return a.businessRoleName.localeCompare(b.businessRoleName)
            //     },
            //     onHeaderCell: row => this.handleClickHeader(row),
            // },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split('T')[0] : "";
                    let time = text != null ? text.split('T')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '操作',
                render: (record) => {
                    return <span className="com-hand com-hand-color" onClick={() => {
                        this.jumpDetail(record.id, record.orgType)
                    }}>详情</span>
                }
            },
        ];
        return (
            <div className="inquire-form-wrapper">
                <Form {...formRowItem} onSubmit={this.handleSubmit}>
                    <Row>
                        <Col span={12}>
                            <FormItem label="企业名称">
                                {
                                    getFieldDecorator('orgName', {
                                        initialValue: "",

                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="证件号码">
                                {
                                    getFieldDecorator('cardNo', {
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem label="企业类型">
                                {
                                    getFieldDecorator('orgType', {
                                        initialValue: ""
                                    })(<Select>
                                        <Option key="" value="">全部</Option>
                                        {
                                            orgTypeCdArr.length !== 0 ? orgTypeCdArr.map(record => {
                                                return <Option key={record.dicKey}
                                                               value={record.dicKey}>{record.dicValue}</Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="业务角色">
                                {
                                    getFieldDecorator('businessRole', {
                                        initialValue: ""
                                    })((<Select>
                                        <Option key="" value="">全部</Option>
                                        {
                                            businessRoleArr.length !== 0 ? businessRoleArr.map(record => {
                                                return <Option key={record.dicKey}
                                                               value={record.dicKey}>{record.dicValue}</Option>
                                            }) : null
                                        }
                                    </Select>))
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <div className="btn-item">
                        <Button type="default" className="com-middle-btn-size" onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <div>
                    {/* <h2>客户信息列表</h2> */}
                    <h2>企业信息列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size" onClick={this.addClientInfo}>+新增</Button>
                    </div>
                </div>
                <Table
                    className="freezing-deposit-table"
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => index}
                    pagination={totalCount > 10 ? pagination : false}
                />
            </div>
        );
    }
}

export default Form.create()(InquireForm);
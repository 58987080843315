// import React, { Component } from 'react'
// import scfBusinessApi from '../../../request/api/scfBusinessApi'
// import { message,Table} from 'antd';


// export default class FmQuoteTable extends Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             tableData:[],
//             intervalId:""
//         }
//     }

//     setRowClassName = () =>{
//         return 'rowStyle'
//     }
//    componentDidMount(){
//     this.Quote()
//     var intervalId = setInterval(() => {
//           this.Quote()
//         }, 7200000);
//         this.setState({
//             intervalId,
//         })
    
//         // window.onscroll = function(){
//         //     var scrollT = document.documentElement.scrollTop||document.body.scrollTop;
//         //     var scrollH = document.documentElement.scrollHeight||document.body.scrollHeight;
//         //     var clientH = document.documentElement.clientHeight||document.body.clientHeight
//         //     if(scrollT>500){
//         //         alert("滚轮已经大于500，置顶按钮出现");
//         //     }
//         //     if(scrollT == scrollH - clientH){
//         //        console.log("到底部了");
//         //     }else if(scrollT == 0){
//         //        console.log("到顶部了");
//         //     }
//         //     }
// }
// componentWillUnmount = () => {
//     let {intervalId} = this.state
//     clearInterval(intervalId)
// }
//    Quote = () =>{
//     let params = {
//         bankType:"2" 
//     }
//     scfBusinessApi.fuminIcipApi(params).then(res=>{
//           this.setState({
//             tableData:res.data
//           })
//     }).catch(err=>{
//         message.error(err.message)
//     })
// }
//     render() {
//         let {tableData} = this.state
//         const columns = [
//             {
//                 title: '承兑人类型',
//                 dataIndex: 'cfbicipbankLevelCdDesc',
//                 key: 'cfbicipbankLevelCdDesc',
//                 className:'moneyColor',
//                 // className: "bgc",
//                 // onHeaderCell: row => this.handleClickHeader(row),


//             },
//             {
//                 title: '票面金额范围',
//                 dataIndex: 'amount',
//                 key: 'amount',
//                 className:'moneyColor',
//                 width: '30%',

//             },
//             {
//                 title: '到日期范围',
//                 dataIndex: 'date',
//                 key: 'date',
//                 width: '30%',
//                 className:'moneyColor',

//             },
//             {
//                 title: '贴现报价',
//                 dataIndex: 'rate',
//                 key: 'rate',
//                 className:'moneyColor',

//             },
//         ];
//         return (
//                 <Table
//                             bordered={false}
//                             columns={columns}
//                             dataSource={tableData}
//                             scroll={{  y: 162 }} 
//                             pagination={false}
//                             rowKey={(record, index) => index}
//                             // rowClassName={this.setRowClassName}
//                             onRow={(record,index) => {
//                             return {
//                               onClick: event => {}, // 点击行
//                               onDoubleClick: event => {},
//                               onContextMenu: event => {},
//                               onMouseEnter: event => {
//                                this.className+="rowStyle"
//                               }, // 鼠标移入行
//                               onMouseLeave: event => {},
//                             };
//                           }}
                      
//                         /> 
           
//         )
//     }
// }
import React, { Component } from 'react'
import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message,Table} from 'antd';


export default class JdQuoteTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            tableData:[
                // {
                //     acceptanceType: "杜娟",
                //     amountRange: "18",
                //     duedateRange: "中国",
                //     priceMargin: "123"
                // },
                
       
            ],
            intervalId:"",
            intervalId2:"",
            setTimeoutId:""
        }
    }
   componentDidMount(){
       this.Quote()
    //    var intervalId = setInterval(() => {
    //       this.Quote()
    //     }, 7200000);
    //     this.setState({
    //         intervalId,
    //     })

    var setTimeoutId = setTimeout(() => {
        this.move()
    }, 3000);

    this.setState({
        setTimeoutId,
    })
  
}

move = () =>{
    var oList = document.getElementById('listFm')
    var height = oList.offsetHeight
    var h = parseInt(height)
    var n = 1;
    var intervalId2 = setInterval(() => {
        n++
        oList.style.top = -n*1+"px"
        var d = -n
        if(d <= 162-h-100){
            this.Quote()
          n=1
          oList.style.top = 0+'px'
        }
    }, 100);
    this.setState({
      intervalId2,
  })
  
}


componentWillUnmount = () => {
    let {intervalId,intervalId2,setTimeoutId} = this.state
    // clearInterval(intervalId)
    clearInterval(intervalId2)
    clearInterval(setTimeoutId)
}
   Quote = () =>{
    let params = {
        bankType:"2" 
    }
    scfBusinessApi.fuminIcipApi(params).then(res=>{
          this.setState({
            tableData:res.data
          })
    }).catch(err=>{
        message.error(err.message)
    })
}
    render() {
        let {tableData} = this.state
        // const columns = [
        //     {
        //         title: '发布时间',
        //         dataIndex: 'publishDate',
        //         key: 'publishDate',
        //         width: '15%',
        //         className:'moneyColor',
        //     },
        //     {
        //         title: '承兑人',
        //         dataIndex: 'acceptorName',
        //         key: 'acceptorName',
        //         width: '23%',
        //         className:'moneyColor',
        //     },
        //     {
        //         title: '到日期',
        //         dataIndex: 'due_date',
        //         key: 'due_date',
        //         width: '15%',
        //         className:'moneyColor',

        //     },
        //     {
        //         title: '票面金额（万元）',
        //         dataIndex: 'faceValue',
        //         key: 'faceValue',
        //         width: '15%',
        //         className:'moneyColor',
              

        //     },
        //     {
        //         title: '每十万贴息（元）',
        //         dataIndex: 'discount',
        //         key: 'discount',
        //         className:'moneyColor',
        //         width: '10%',

        //     },
        //     {
        //         title: '年利息',
        //         dataIndex: 'apr',
        //         key: 'apr',
        //         className:'moneyColor',
        //         width: '6%',
              

        //     },
        //     {
        //         title: '瑕疵',
        //         dataIndex: 'flawName',
        //         key: 'flawName',
        //         className:'moneyColor',
        //         width: '8%',
               

        //     },
        //     {
        //         title: '交易渠道',
        //         dataIndex: 'channelTypeName',
        //         key: 'channelTypeName',
        //         className:'moneyColor',
        //         width: '8%',

        //     },
        // ];
        return (
                // <Table
                
                //             bordered={false}
                //             columns={columns}
                //             dataSource={tableData}
                //             scroll={{  y: 160 }} 
                //             pagination={false}
                //             rowKey={(record, index) => index}
                //             rowClassName={record => {
                //              return 'bgc';
                //          }}
                        // rowClassName={(record, index) => {
                        //     console.log(record,index)
                        // }}
                      
                        // /> 


                        <div className="wrapTable">
                        <ul className="header">
                          <li className="cell cellFm-cfbicipbankLevelCdDesc">承兑人类型</li>
                          <li className="cell cellFm-amount">票面金额范围</li>
                          <li className="cell cellFm-date">到日期范围</li>
                          <li className="cell cellFm-rate">贴现报价</li>
                         
                        </ul>
                        <div className="bodyWrap">
                          <ul id="listFm">
                            {/* <li
                              v-for="(item, index) in arr"
                              :key="index"
                              class="row"
                            >
                              <span class="cell">{{ item }}</span>
                              <span v-for="(n) in 4" :key="n*30" class="cell">{{ n }}</span>
                            </li> */}
                    {
                    tableData.map((item,index) => {
                       return   (
                        <li key={index}>
                            <span className="cell cellFm-cfbicipbankLevelCdDesc">{item.cfbicipbankLevelCdDesc}</span>
                            <span className="cell cellFm-amount">{item.amount}</span>
                            <span className="cell cellFm-date">{item.date}</span>
                            <span className="cell cellFm-rate">{item.rate}</span>
                        </li>)
                    })
                    }
                 {/* {
                    [1,2,3,4,5,6,7,89,0,1,1,1,,1,1].map((item,index) => {
                       return   (
                        <li key={index}>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                           <span className="cell">1</span>
                        </li>)
                    })
                    } */}
                    {/* <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>

                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li>
                    <li>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                        <span className="cell">1</span>
                    </li> */}

                    
                    
                          </ul>
                        </div>
                      </div>
           
        )
    }
}

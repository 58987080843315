import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBusinessApi from "../../../request/api/scfBusinessApi";
import moment from 'moment';
import { money100 } from '../../../utils/formatCheck'
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;

class CommercialTicketTrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,//当前页
            pageSize: 10,//每页大小
            totalCount: 0,
            tableData: [],
            ticketBeginDate:"",//出票起始日期
            ticketEndDate:"",//出票结束日期
            ticketExpireBeginDate:"",//汇票到期日起始日期
            ticketExpireEndDate:"",//汇票到期日结束日期
            statusList:[],//状态列表
        }
    }
    componentDidMount() {
        this.handleSubmit(1)
        this.getStatusList()
    }

    getStatusList = () =>{
        let params = {
            typeList:['ticketTransStatusCd']
        }
        scfBusinessApi.dictionaryApi(params).then(res=>{
            this.setState({
                statusList:res.data.ticketTransStatusCd
            })
           
        }).catch(err=>{
            message.error(err.message)
        })
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
        //查询
        handleSubmit = (page) => {
            const { ticketBeginDate, ticketEndDate,ticketExpireBeginDate,ticketExpireEndDate, pageNum,pageSize } = this.state;
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let { ticketNo, acceptorName,ticketTransStatusCd } = values;
                    let params = {
                        ticketBeginDate,
                        ticketEndDate,
                        ticketExpireBeginDate,
                        ticketExpireEndDate,
                        ticketNo,
                        acceptorName,
                        pageNum: page,
                        pageSize,
                        ticketTransStatusCd

                    }
                    scfBusinessApi.getPageListApi(params).then(res => {
                        this.setState({
                            tableData: res.data,
                            pageNum: page
                        })
                    }).catch(err => {
                        message.error(err.message);
                    });
    
                }
            })
        } 
    //出票日期change
    TicketDateChange = (data) => {
        if (data.length <= 0) {
            this.setState({
                ticketBeginDate: '',
                ticketEndDate: ''
            })
        } else {
            let ticketBeginDate = moment(data[0]).format('YYYY-MM-DD');
            let ticketEndDate = moment(data[1]).format('YYYY-MM-DD');
            this.setState({
                ticketBeginDate,
                ticketEndDate,
            })
        }
    };
           //汇票到期日期日期change
           ticketExpireDate = (data) => {
            if (data.length <= 0) {
                this.setState({
                    ticketExpireBeginDate: '',
                    ticketExpireEndDate: ''
                })
            } else {
                let ticketExpireBeginDate = moment(data[0]).format('YYYY-MM-DD');
                let ticketExpireEndDate = moment(data[1]).format('YYYY-MM-DD');
                this.setState({
                    ticketExpireBeginDate,
                    ticketExpireEndDate,
                })
            }
        };


        //分页change
        handleTableChange = (pagination, filters, sorter) => {
            this.handleSubmit(pagination.current)
        };
    
        //查询按钮
        handleClick = () => {
            this.handleSubmit(1)
        };
   //重置
    handleReset = () => {
        this.props.form.setFieldsValue({
            ticketNo: "",
            ticketDate: "",
            ticketExpireDate: "",
            acceptorName: "",
            ticketTransStatusCd:"",
        });
        this.setState({
            ticketBeginDate: "",
            ticketEndDate: "",
            ticketExpireBeginDate: "",
            ticketExpireEndDate: "",
            pageNum: 1,
            pageSize: 10,
        }, () => {
            this.handleSubmit(1);
        })
    }
        changeShowSize = (current, pageSize) => {
            this.setState({
                pageSize
            }, () => {
                this.handleSubmit(current)
            })
        }
        handleClickHeader = (row) =>{
            row.className=row.className+" left"
        }
        
        toTransfer = (id,ticketLedgerId)=>{
            this.props.history.push(`/admin/commercialTicketManage/CtTransfor/${id}/${ticketLedgerId}`)
        }
        Detail = (treeLevel,id,ticketLedgerId,wkId,nodeId)=>{
            localStorage.setItem("treeLevel",treeLevel)
            this.props.history.push(`/admin/commercialTicketManage/CtSign/Detail/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
        }
        Sign = (id,ticketLedgerId,wkId,nodeId)=>{
            this.props.history.push(`/admin/commercialTicketManage/CtSign/Sign/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
        }
        SignExamine = (id,ticketLedgerId,wkId,nodeId)=>{
            this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/signExamine/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
        }
    render() {
        let { getFieldDecorator } = this.props.form;
        let { pageNum,tableData,statusList } = this.state;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };

        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '票据号码',
                dataIndex: 'ticketNo',
                key: 'ticketNo',
                sorter:(a,b)=>{
                    if(!a.ticketNo){
                        a.ticketNo=""
                    }else if(!b.ticketNo){
                        b.ticketNo=""
                    }
                    return a.ticketNo-b.ticketNo
                },
               
            },
            {
                title: '票据金额(元)',
                render: (record) => {
                    return <span>{money100(record.ticketAmount)}</span>
                },
                key: 'ticketAmount',
                sorter:(a,b)=>{
                    if(!a.ticketAmount){
                        a.ticketAmount=""
                    }else if(!b.ticketAmount){
                        b.ticketAmount=""
                    }
                    return a.ticketAmount.replace(/[^\d.]/g,"")-b.ticketAmount.replace(/[^\d.]/g,"")
                },
               
           
            },
            {
                title: '票据剩余额度(元)',
                render: (record) => {
                    return <span>{money100(record.remainAmount)}</span>
                },
                key: 'remainAmount',
                sorter:(a,b)=>{
                    if(!a.remainAmount){
                        a.remainAmount=""
                    }else if(!b.remainAmount){
                        b.remainAmount=""
                    }
                    return a.remainAmount.replace(/[^\d.]/g,"")-b.remainAmount.replace(/[^\d.]/g,"")
                },
               
           
            },
            {
                title: '已转让额度(元)',
                render: (record) => {
                    return <span>{money100(record.transferredAmount)}</span>
                },
                key: 'transferredAmount',
                sorter:(a,b)=>{
                    if(!a.transferredAmount){
                        a.transferredAmount=""
                    }else if(!b.transferredAmount){
                        b.transferredAmount=""
                    }
                    return a.transferredAmount.replace(/[^\d.]/g,"")-b.transferredAmount.replace(/[^\d.]/g,"")
                },
               
               
            },
            {
                title: '出票日期',
                dataIndex: 'ticketDate',
                key: 'ticketDate',
                sorter: (a, b) => {
                    let aTime = new Date(a.ticketDate).getTime()
                    let bTime = new Date(b.ticketDate).getTime()
                    return aTime-bTime
                }

            },
            {
                title: '汇票到期日',
                dataIndex: 'ticketExpireDate',
                key: 'ticketExpireDate',
                sorter: (a, b) => {
                    let aTime = new Date(a.ticketExpireDate).getTime()
                    let bTime = new Date(b.ticketExpireDate).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '签收日期',
                dataIndex: 'signForDate',
                key: 'signForDate',
                sorter: (a, b) => {
                    let aTime = new Date(a.signForDate).getTime()
                    let bTime = new Date(b.signForDate).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '承兑人名称',
                dataIndex: 'acceptorName',
                key: 'acceptorName',
                sorter:(a,b)=>{
                    if(!a.acceptorName){
                        a.acceptorName=""
                    }else if(!b.acceptorName){
                        b.acceptorName=""
                    }
                    return a.acceptorName.localeCompare(b.acceptorName)
                }
            },
            {
                title: '承兑人账号',
                dataIndex: 'acceptorAccountNo',
                key: 'acceptorAccountNo',
                sorter:(a,b)=>{
                    if(!a.acceptorAccountNo){
                        a.acceptorAccountNo=""
                    }else if(!b.acceptorAccountNo){
                        b.acceptorAccountNo=""
                    }
                    return a.acceptorAccountNo.localeCompare(b.acceptorAccountNo)
                }
            },
            {
                title: '承兑人开户行',
                dataIndex: 'acceptorBank',
                key: 'acceptorBank',
                sorter:(a,b)=>{
                    if(!a.acceptorBank){
                        a.acceptorBank=""
                    }else if(!b.acceptorBank){
                        b.acceptorBank=""
                    }
                    return a.acceptorBank.localeCompare(b.acceptorBank)
                }
            },
            {
                title: '票据状态',
                dataIndex: 'ticketTransStatusCdDesc',
                key: 'ticketTransStatusCdDesc',
                sorter:(a,b)=>{
                    if(!a.ticketTransStatusCdDesc){
                        a.ticketTransStatusCdDesc=""
                    }else if(!b.ticketTransStatusCdDesc){
                        b.ticketTransStatusCdDesc=""
                    }
                    return a.ticketTransStatusCdDesc.localeCompare(b.ticketTransStatusCdDesc)
                }
            },
            {
                title: '操作',
                fixed: 'right',
                width: 200,
                render: (record) => {
                    return <div className="flex-around-center">
                         <Button type="primary" onClick={()=>this.Detail(record.treeLevel,record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>详情</Button>
                        {(record.ticketTransStatusCd==="0000"||record.ticketTransStatusCd==="1010")&&record.transferTypeCd==="1"?<Button type="primary" onClick={()=>this.toTransfer(record.id,record.ticketLedgerId)}>转让</Button>:null}
                        {record.ticketTransStatusCd==="1000"&&record.sysOperationType==="00"?<Button type="primary" onClick={()=>this.Sign(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>签收</Button>:null}
                        {record.ticketTransStatusCd==="1000"&&record.sysOperationType==="10"?<Button type="primary" onClick={()=>this.SignExamine(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>签收审核</Button>:null}
                         
                    </div>

                }
            },
        ];

        return (
            <div>
                
                <Form className="" {...formRowItem}>
                    <Row>
                    <Col span={8}>
                            <FormItem label="票据号码">
                                {
                                    getFieldDecorator('ticketNo', {
                                        initialValue: ""
                                    })(<Input placeholder="" />)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="出票日期">
                                {
                                    getFieldDecorator('ticketDate', {
                                        initialValue: "",
                                    })(<RangePicker onChange={this.TicketDateChange} style={{ width: '100%' }} />)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="汇票到期日">
                                {
                                    getFieldDecorator('ticketExpireDate', {
                                        initialValue: "",
                                    })(<RangePicker onChange={this.ticketExpireDate} style={{ width: '100%' }} />)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <FormItem label="承兑人">
                                {
                                    getFieldDecorator('acceptorName', {

                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input placeholder="" />)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                                <FormItem label="票据状态">
                                    {
                                        getFieldDecorator('ticketTransStatusCd', {

                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Select>
                                             {statusList.map(item=>{
                                                return (<Option value={item.dicKey} key={item.dicKey}>{item.dicValue}</Option>)
                                            })}
                                        </Select>)
                                    }
                                </FormItem>
                            </Col>
                        
                    </Row>
                    <Row>
                    <Col span={24}>
                            <Form.Item>
                            <div style={{width: '100%'}}>
                            <Button type="primary" onClick={this.handleClick} style={{marginRight:'20px'}} className="com-middle-btn-size">查询</Button>
                            <Button type="default" onClick={this.handleReset} className="com-middle-btn-size">重置</Button>
                            </div>
                        </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    columns={columns}
                    dataSource={tableData.data}
                    rowKey={(record, index) => record.id}
                    pagination={{
                        showTotal: total => `总共${tableData.totalCount}条`,
                        total: tableData.totalCount? tableData.totalCount : 0,
                        showQuickJumper: true,
                        onShowSizeChange: this.changeShowSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        current: pageNum,
                    }}
                    onChange={this.handleTableChange}
                />
    
            </div>
        );
    }
}

export default Form.create()(CommercialTicketTrade);




import React, {Component} from 'react';
import {Button, Col, Form, message, Row, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import ModalTipTwoBtn from "../../../components/modalTipTwoBtn";
import {withRouter} from "dva/router";
import TreeGraph from "./treeGraph";

const FormItem = Form.Item;
const Option = Select.Option;
@withRouter
class ShowOrganize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgName: "",
            parentOrgName: "",
            isShowModal: false,  //弹框
            isShowAddOrEdit: false,//true-新增
            orgList: [],   //客户列表
            parentOrgList: [],  //上级机构列表
            organizeData: {},        //组织机构数据
            orgId: 0,
            parentOrgId: 0,         //上级机构id
            organizeStatus: 0,   //组织架构框架 0-不显示 1-显示可编辑  2-不可编辑
            changeStatus: 0,     //0-客户、上级都不更改  1-仅客户更改 2-仅上级更改  3-两者都更改 媒介存状态用
            renderStatus: 0,   // 同changeStatus，实际渲染用
            submitStatus:0, //确认客户关系   0-未确认 1-已确认
            defaultValue:'',
            currentOrgId:'',
            superiorName: localStorage.getItem('companyName') || '无'
        }
    }

    componentDidMount(){
        let {id, item} = this.props;
        let organizeStatus = 0
        if (item === "add") {
            this.getRelation();
        } else if (item === "edit"){
            this.setState({orgId:id});
            organizeStatus = 1;
            this.getRelationByOrgId(id);
        } else {
            organizeStatus = 2
        }
        this.setState({
            organizeStatus
        })
    }
    //获取新增页客户关系
    getRelation = () => {
        scfBaseApi.departQueryOrgRelationApi().then(res => {
            let data = res.data;
            this.setState({
                orgList: data.orgs,
                parentOrgList: data.parentOrgs
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取编辑页客户关系
    getRelationByOrgId = (id) => {
        scfBaseApi.queryOrgRelationByOrgIdApi({id}).then(res => {
            let data = res.data;
            this.setState({
                orgName: data.orgName,
                currentOrgId: data.id,
                // parentOrgList: data.parentOrgs,
                orgList: data.orgs,
                parentOrgId:data.parentId===0?"":data.parentId,
                parentOrgName:data.parentOrgName==="无"?"":data.parentOrgName,

            });
            this.props.form.setFieldsValue({orgName:data.orgName})
        }).catch(err => {
            message.error(err.message);
        })
    }

    changeOrg = (value, e, type = "") => {
        console.log(e)
        this.setState({
            // [type]: e.props.orgname,
            [type]: value,
            currentOrgId: e.props.orgid
        });
    }

    //客户关系确认
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            console.log(values)
            if (!err) {
                let {orgId, parentOrgId,currentOrgId} = this.state;
                let changeStatus = 0;
                // if(values.orgId!==undefined){
                if(values.orgName!==undefined){
                    //添加
                    // if (values.orgId !== orgId && values.parentOrgId === parentOrgId) {
                    //     changeStatus = 1;
                    // } else if (values.orgId === orgId && values.parentOrgId !== parentOrgId) {
                    //     changeStatus = 2;
                    // } else if (values.orgId !== orgId && values.parentOrgId !== parentOrgId) {
                    //     changeStatus = 3;
                    // }
                    // this.setState({orgId: values.orgId});
                    if (currentOrgId !== orgId && values.parentOrgId === parentOrgId) {
                        changeStatus = 1;
                    } else if (currentOrgId === orgId && values.parentOrgId !== parentOrgId) {
                        changeStatus = 2;
                    } else if (currentOrgId !== orgId && values.parentOrgId !== parentOrgId) {
                        changeStatus = 3;
                    }
                    this.setState({orgId: currentOrgId});
                }else{
                    //编辑
                   if (values.parentOrgId !== parentOrgId){
                        changeStatus = 2;
                    }
                }
                this.setState({
                    isShowModal: true,
                    // parentOrgId: values.parentOrgId === undefined ? null : values.parentOrgId,
                    parentOrgId: 23,
                    changeStatus, 
                    title: "提示",
                    // text: "确认建立该客户的机构关系？",
                    text: "确认建立该企业的机构关系？",
                });
            }
        })
    }
    //确认客户关系
    handleOk = () => {
        let {id, item} = this.props;
        if(item=='add'){
            let {parentOrgName,currentOrgId,parentOrgId, changeStatus} = this.state;
            // let params = {parentOrgName, orgId, parentOrgId};
            let params = { orgId:currentOrgId };
            scfBaseApi.departUpdateOrgApi(params).then(res => {
                message.success(res.message);
                this.setState({
                    submitStatus:1,
                    isShowModal: false,
                    // organizeStatus: 1,
                    organizeStatus: 0,
                    renderStatus: changeStatus,
                });
            }).catch(err => {
                message.error(err.message);
            })
        }else if(item=='edit'){
            let {parentOrgName, currentOrgId, parentOrgId, changeStatus} = this.state;
            // let params = {parentOrgName, orgId, parentOrgId};
            let params = {orgId:currentOrgId};
            scfBaseApi.departUpdateOrgApi(params).then(res => {
                message.success(res.message);
                this.setState({
                    submitStatus:1,
                    isShowModal: false,
                    organizeStatus: 1,
                    renderStatus: changeStatus,
                   
                });
            }).catch(err => {
                message.error(err.message);
            })
        }
      
    }
    //取消客戶信息
    handleCancel = () => {
        this.setState({
            isShowModal: false
        });

    }
    //重置客户
    handleReset = () => {
        this.setState({
            submitStatus:0,
            renderStatus:0,
        })
    }

    handleReturn = () => {
        this.props.history.push("/admin/clientManage/organize/search");
    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {item,id} = this.props;
        let {orgList, parentOrgList, isShowModal, title, text, organizeStatus, orgName, parentOrgName,organizeData,renderStatus,submitStatus, orgId, parentOrgId} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'right'
        };
        let modalProps = {
            title,
            text,
            operationOk: this.handleOk,
            operationCancel: this.handleCancel,


        };
       let treeProps={
           id,
           item,
           parentOrgId,
           parentOrgName,
           orgId,
           orgName,
           organizeStatus,
           renderStatus,
       }
        return (
            <div>
                {
                    item === "add" || item === "edit" ? <div>

                        <h2>1.企业机构关系</h2>
                        <Form onSubmit={this.handleSubmit}  {...formRowItem} >
                            <Row type="flex" align="middle">
                                <Col span={12}>
                                    {/* {
                                        item === "add" ? <FormItem label="客户名称">
                                            {
                                                getFieldDecorator('orgId', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "请选择客户"
                                                        }
                                                    ],
                                                    initialValue: "",
                                                })(<Select disabled={submitStatus === 1 ? true : false}
                                                           onChange={(val, e) => this.changeOrg(val, e, "orgName")}>
                                                    {
                                                        orgList != null && orgList.length !== 0 ? orgList.map((record, index) => {
                                                            return <Option key={record.id}
                                                                           value={record.id}
                                                                           orgname={record.orgName}>{record.orgName}</Option>
                                                        }) : null
                                                    }
                                                </Select>)
                                            }
                                        </FormItem> : <FormItem label="客户名称">{orgName}</FormItem>

                                    }
                                    <FormItem label="上级机构" style={{marginBottom: 0}}>
                                        {
                                            getFieldDecorator('parentOrgId', {
                                                rules: [],
                                                initialValue: "",
                                            })(<Select onChange={(val, e) => this.changeOrg(val, e, "parentOrgName")}
                                                       disabled={submitStatus === 1 ? true : false}>
                                                <Option key="" value="" orgname="">请选择</Option>
                                                {
                                                    parentOrgList != null && parentOrgList.length !== 0 ? parentOrgList.map((record, index) => {
                                                        return <Option key={record.id}
                                                                       value={record.id}
                                                                       orgname={record.orgName}>{record.orgName}</Option>
                                                    }) : null
                                                }

                                            </Select>)
                                        }
                                    </FormItem> */}


                    
                                 {
                                        item === "add" || item === 'edit'? <FormItem label="企业名称">
                                            {
                                                getFieldDecorator('orgName', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "请选择企业"
                                                        }
                                                    ],
                                                    initialValue: '',
                                                })(<Select disabled={submitStatus === 1 ? true : false}
                                                           onChange={(val, e) => this.changeOrg(val, e, "orgName")}>
                                                    {
                                                        orgList != null && orgList.length !== 0 ? orgList.map((record, index) => {
                                                            return <Option key={record.id}
                                                                           value={record.orgName}
                                                                           orgid={record.id}
                                                                           orgname={record.orgName}>{record.orgName}</Option>
                                                        }) : null
                                                    }
                                                </Select>)
                                            }
                                        </FormItem> : <FormItem label="企业名称">{orgName}</FormItem>

                                    }
                                    {/* <FormItem label="上级机构" style={{marginBottom: 0}}>
                                        {
                                            getFieldDecorator('parentOrgId', {
                                                rules: [],
                                                initialValue: "",
                                            })(<Select onChange={(val, e) => this.changeOrg(val, e, "parentOrgName")}
                                                       disabled={submitStatus === 1 ? true : false}>
                                                <Option key="" value="" orgname="">请选择</Option>
                                                {
                                                    parentOrgList != null && parentOrgList.length !== 0 ? parentOrgList.map((record, index) => {
                                                        return <Option key={record.id}
                                                                       value={record.id}
                                                                       orgname={record.orgName}>{record.orgName}</Option>
                                                    }) : null
                                                }

                                            </Select>)
                                        }
                                    </FormItem> */}

                                    <FormItem label="上级机构">{this.state.superiorName}</FormItem>

                                    
                                   
                                </Col>
                                <Col span={12}>
                                    <div className="">
                                        <Button type="primary" className="com-middle-btn-size"
                                                onClick={this.handleReset}>重置企业</Button>
                                        <Button type="primary" className="com-middle-btn-size" htmlType="submit"
                                                style={{marginLeft: 30}}>企业信息确认</Button>
                                        <Button type="primary" className="com-middle-btn-size" style={{marginLeft: 60}}
                                                onClick={this.handleReturn}>返回</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div> : null
                }
                {organizeStatus !== 0 ? <TreeGraph {...treeProps}></TreeGraph> : null}

                {isShowModal === true ? <ModalTipTwoBtn {...modalProps} /> : null}
            </div>
        );
    }
}

export default Form.create()(ShowOrganize);
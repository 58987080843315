import React, { Component } from 'react';
import { connect } from 'dva';
import FormBtnComp from './formBtnComp';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Select, Row, Col, Input, InputNumber, Descriptions, Radio, Spin, message } from 'antd';

const { Option } = Select;
const addproductApi = scfBusinessApi['addproductApi'];

@Form.create()
@connect((store) => ({ store: store.productManage, loading: store.loading }))
class AddComp extends Component {

    componentDidMount () {
        this.getModifyEnterpriseCredit();
        this.props.dispatch({ type: 'productManage/updateReducers', payload: { key: 'priseCreditDetail', val: {} }});
        this.props.dispatch({ type: 'productManage/getProductModeList', payload: 'financing_mode'});
        this.props.dispatch({ type: 'productManage/getDateEnumerate', payload: 'time_unit'});
        this.props.dispatch({ type: 'productManage/getRepaymentEnumerate', payload: 'repayment_method'});
    }

    handleAddCredit = (value = {}) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                Object.assign(values, value);
                values.productLoanPeriod = values.productLoanPeriod && values.productLoanPeriod.length ? values.productLoanPeriod.join(',') : undefined;
                addproductApi(values).then(res => {
                    message.success(res.message);
                    this.props.history.push('/admin/productManage/list')
                 }).catch(err => message.error(err.message))
            }
        })
    }

    getModifyEnterpriseCredit = () => {
        this.props.dispatch({ type: 'productManage/modifyEnterpriseCredit'});
    }

    handleSelectChange = (val, eve) => {
        let params = {
            id: val
        };
        this.props.dispatch({ type: 'productManage/getEnterpriseCreditDetail', payload: params});
    }

    handleStartIntervalFormatter = (rule, value, callback) => {
        const { form } = this.props;
        if(form.getFieldValue('productEndRate') && form.getFieldValue('productEndRate') < value && value) {
            callback(`请小于等于最大产品利率！`);
        }
        callback();
    }

    handleEndIntervalFormatter = (rule, value, callback) => {
        const { form } = this.props;
        if(form.getFieldValue('productStartRate') && form.getFieldValue('productStartRate') > value && value) {
            callback(`请大于等于最小产品利率！`);
        }
        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { productList, priseCreditDetail, productModeList, dateEnumerate, repaymentEnumerate } = this.props.store;
        const isLoding1 = this.props.loading.effects['productManage/modifyEnterpriseCredit'];
        const isLoding2 = this.props.loading.effects['productManage/getEnterpriseCreditDetail'];
        const isLoding3 = this.props.loading.effects['productManage/getRepaymentEnumerate'];
        const isLoding4 = this.props.loading.effects['productManage/getProductModeList'];
        const ItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 19},
            },
            labelAlign: 'left'
        };
        const formItemLayout = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            }
        };
        return (
            <div>
                <Row>
                    <Col span={8}>
                        <Form.Item label='项目名称' {...ItemLayout}>
                            {
                                getFieldDecorator('companyCreditId', {
                                    initialValue: '',
                                    rules: [{ required: true, message: '请选择项目！' }]
                                })(
                                    <Select name='companyCreditId' style={{width: '100%'}} onChange={this.handleSelectChange} loading={isLoding1}>
                                        {
                                            productList && productList.length 
                                            ? productList.map((item, index) => <Option key={index} value={item.id}>{item.projectName}</Option>)
                                            : null
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Spin spinning={ isLoding2 ? isLoding2 : (isLoding1 || isLoding3 || isLoding4) }>
                    <Form {...formItemLayout}>
                        <Descriptions bordered column={2} size='small'>
                            <Descriptions.Item label="资金方名称">
                                {priseCreditDetail && priseCreditDetail.capitalName ? priseCreditDetail.capitalName : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业名称">
                                {priseCreditDetail && priseCreditDetail.companyName ? priseCreditDetail.companyName : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业授信额度">
                                {priseCreditDetail && priseCreditDetail.totalAmount ? priseCreditDetail.totalAmount : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业剩余授信额度">
                                {priseCreditDetail && priseCreditDetail.haveAmount !==null ? priseCreditDetail.haveAmount : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="授信开始日期">
                                {priseCreditDetail && priseCreditDetail.companyStartTimeName ? priseCreditDetail.companyStartTimeName : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="授信截止日期">
                                {priseCreditDetail && priseCreditDetail.companyEndTimeName ? priseCreditDetail.companyEndTimeName : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="产品名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productName', {
                                            initialValue: '',
                                            rules: [{ required: true, whitespace: true, message: '请输入产品名称！' }]
                                        })(
                                            <Input name='productName'></Input>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="产品利率（年化%）">
                                <Row className='wrap_text_center'>
                                    <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', margin: 0, textAlign: 'center' }}>
                                        {
                                            getFieldDecorator('productStartRate', {
                                                initialValue: '',
                                                rules: [
                                                    { required: true, message: '请输入产品利率！' },
                                                    {
                                                        validator: this.handleStartIntervalFormatter
                                                    },
                                                    {
                                                        pattern: /^\d+$/,
                                                        message: '请输入合法数字！'
                                                    }
                                                ]
                                            })(
                                                <InputNumber 
                                                    name='productStartRate' 
                                                    min={0} 
                                                    style={{width: '120px'}}
                                                ></InputNumber>
                                            )
                                        }
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: '44px', textAlign: 'center' }}>——</span>
                                    <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', margin: 0, textAlign: 'center' }}>
                                        {
                                            getFieldDecorator('productEndRate', {
                                                initialValue: '',
                                                rules: [
                                                    { required: true, message: '请输入产品利率！' },
                                                    {
                                                        validator: this.handleEndIntervalFormatter
                                                    },
                                                    {
                                                        pattern: /^\d+$/,
                                                        message: '请输入合法数字！'
                                                    }
                                                ]
                                            })(
                                                <InputNumber 
                                                    name='productEndRate' 
                                                    style={{width: '120px'}}
                                                    min={0}
                                                ></InputNumber>
                                            )
                                        }
                                    </Form.Item>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label="融资模式">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productMode', {
                                            initialValue: '1',
                                            rules: [{ required: true, message: '请选择融资模式！' }]
                                        })(
                                            <Select name='productMode'>
                                                {
                                                    productModeList && productModeList.length 
                                                    ? productModeList.map((item, index) => <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="产品额度（元）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('totalAmount', {
                                            initialValue: '',
                                            rules: [
                                                { required: true, message: '请输入产品额度！' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                name='totalAmount' 
                                                min={1} 
                                                max={priseCreditDetail && priseCreditDetail.haveAmount ? priseCreditDetail.haveAmount * 1 : null}
                                                style={{width: '100%'}} 
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            ></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="提前还款手续费率（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productEarlyRate', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber name='productEarlyRate' min={0} style={{width: '100%'}}></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="逾期利率（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productOverdueRate', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber name='productOverdueRate' min={0} style={{width: '100%'}}></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="最大融资期限">
                                    <Row className='wrap_text_center'>
                                        <Col span={11} className='wrap_text_center'>
                                            <Form.Item style={{margin: 0}}>
                                                {
                                                    getFieldDecorator('productMaxDuetime', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                pattern: /^\d+$/,
                                                                message: '请输入合法数字！'
                                                            }
                                                        ]
                                                    })(
                                                        <InputNumber name='productMaxDuetime' min={0} style={{width: '120px'}}></InputNumber>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} className='wrap_text_center'>
                                            <Form.Item style={{margin: 0}}>
                                                {
                                                    getFieldDecorator('productMaxUnit', {
                                                        initialValue: '2'
                                                    })(
                                                        <Select name='productMaxUnit' style={{width: '120px'}}>
                                                            {
                                                                dateEnumerate && dateEnumerate.length 
                                                                    ? dateEnumerate.map((item, index) => <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                                    : null
                                                            }
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label="融资比例（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productFinanceScale', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber name='productFinanceScale' min={0} style={{width: '100%'}}></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="还款方式">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productRepayWay', {
                                            initialValue: ''
                                        })(
                                            <Radio.Group name='productRepayWay'>
                                                {
                                                    repaymentEnumerate && repaymentEnumerate.length 
                                                        ? repaymentEnumerate.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                        : null
                                                }
                                            </Radio.Group>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <FormBtnComp submitFlag={this.props.form.getFieldValue('productRepayWay')} handleAddCredit={this.handleAddCredit} history={this.props.history} />
                </Spin>
            </div>
        )
    }
}

export default AddComp;

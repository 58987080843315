import React, {Component} from 'react';
import {withRouter} from "dva/router";
import InquireForm from "./component/inquireForm";
import AddClient from "./component/addClient";
import AddBusinessInfo from "./component/addBusinessInfo";
import "./index.scss";
class Index extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }

    render() {
        let item = this.props.match.params.item;
        return (
            <div className="client-info-manage-wrapper">
                {(() => {
                    switch (item) {
                        case  "search":
                            return <InquireForm/>  //查询客户信息
                        case  "add":
                            return <AddClient/>     //添加客户信息
                        case  "view":
                            return <AddBusinessInfo />   //查看
                        default:
                            break;
                    }
                })()
                }
            </div>
        );
    }
}

export default withRouter(Index);
import React, {Component} from 'react';
import {Form, Input, Button, message, Row, Col, Select, Table} from 'antd';
import scfBaseApi from "../../../request/api/scfBaseApi"
import scfBusinessApi from "../../../request/api/scfBusinessApi"
import queryString from "query-string";
import {withRouter} from "dva/router";
const FormItem = Form.Item;
const Option = Select.Option;
@withRouter
class SearchInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessRoleArr: [],
            tableData:[],
            totalCount:0,
            orgName:"",
            cardNo:"",
            businessRole:"",
            pageNum:1,
            pageSize:10,
        }
    }

    componentDidMount() {
        this.getDicLt("businessRole");
        this.getClientRelateList();
    }

    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType:type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取客户关系列表
    getClientRelateList=()=>{
        let {orgName,cardNo,businessRole,pageNum,pageSize} = this.state;
        let params = {orgName,cardNo,businessRole,pageNum,pageSize};
        scfBaseApi.listbyroleApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData:data.data,
                totalCount:data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    handleReset = () => {
        this.props.form.setFieldsValue({
            orgName: "",
            cardNo: "",
            businessRole:"",
        })
        this.setState({
            orgName: "",
            cardNo: "",
            businessRole:"",
            pageNum: 1,
        }, () => {
            this.getClientRelateList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {orgName,cardNo,businessRole} = values;
                this.setState({orgName,cardNo,businessRole,pageNum:1},
                    () => {
                        this.getClientRelateList();
                    })
            }
        })

    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getClientRelateList();
        })
    }
    changeShowSize=(current, pageSize)=>{
        this.setState({
            pageSize
        }, () => {
            this.getClientRelateList();
        })
    }
    jumpRelateMaintain = (orgId=0,businessRole=0) => {
        this.props.history.push({
            pathname: `/admin/clientRelateManage/relateMaintain/maintain`,
            search: queryString.stringify({
                orgId,
                businessRole
            }),
        })
    };
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return null;
        };
    }
    handleClickHeader = (row) =>{
        row.className=row.className+" left"
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {businessRoleArr,tableData,totalCount,businessRole,pageNum,pageSize,} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'left'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize:pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange:this.changeShowSize,
            pageSizeOptions:['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        }
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '客户名称',
                dataIndex: 'orgName',
                key: 'orgName',
                align:'left',
                sorter: (a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
            },
            {
                title: '证件类型',
                dataIndex: 'idTypeCdDesc',
                key: 'idTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.idTypeName){
                        a.idTypeName=""
                    }else if(!b.idTypeName){
                        b.idTypeName=""
                    }
                    return a.idTypeName.localeCompare(b.idTypeName)
                }
            },
            {
                title: '证件号码',
                dataIndex: 'cardNo',
                key: 'cardNo',
                sorter: (a,b)=>{
                    if(!a.cardNo){
                        a.cardNo=""
                    }else if(!b.cardNo){
                        b.cardNo=""
                    }
                    return a.cardNo.localeCompare(b.cardNo)
                }
            },
            {
                title: '业务角色',
                dataIndex: 'roleCdDesc',
                key: 'roleCdDesc',
                sorter: (a,b)=>{
                    if(!a.businessRoleName){
                        a.businessRoleName=""
                    }else if(!b.businessRoleName){
                        b.businessRoleName=""
                    }
                    return a.businessRoleName.localeCompare(b.businessRoleName)
                },
            },
            {
                title: '操作',
                render: (record) => {
                    return <span className="com-hand com-hand-color" onClick={()=>{this.jumpRelateMaintain(record.id,record.businessRole)}}>关系维护</span>
                }
            },
        ];

        return (
            <div className="search-info-wrapper">
                <Form {...formRowItem} onSubmit={this.handleSubmit}>
                    <Row>
                        <Col span={12}>
                            <FormItem label="客户名称">
                                {
                                    getFieldDecorator('orgName', {
                                        initialValue: "",

                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="证件号码">
                                {
                                    getFieldDecorator('cardNo', {
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem label="业务角色">
                                {
                                    getFieldDecorator('businessRole', {
                                        initialValue: ""
                                    })(<Select>
                                        <Option key="" value="">全部</Option>
                                        {
                                            businessRoleArr.length !== 0 ? businessRoleArr.map(record => {
                                                return <Option key={record.dicKey} value={record.dicKey}> {record.dicValue} </Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <div className="btn-item">
                        <Button type="default" className="com-middle-btn-size" onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <Table
                    className="freezing-deposit-table"
                    columns={ columns }
                    dataSource={tableData}index
                    rowKey={ (record,index) =>index}
                    pagination={totalCount>10?pagination:false}
                />
            </div>
        );
    }
}

export default Form.create()(SearchInfo);
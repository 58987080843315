import React, { Component } from "react";
import "../../index.scss";
import Background from "../../../../assets/img/4444.jpg";
import scfBusinessApi from "../../../../request/api/scfBusinessApi";
import scfBaseApi from "../../../../request/api/scfBaseApi";
import { message } from "antd";
import { money100 } from "../../../../utils/formatCheck";
var nzhcn = require("nzh/cn"); //直接使用简体中文

export default class ticketTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketDetail: "",
            Capitalize: "",
        };
    }
    componentDidMount() {
        let { ticketLedgerId } = this.props;
        let params = {
            id: ticketLedgerId,
        };
        scfBusinessApi
            .getTicketDetailApi(params)
            .then((res) => {
                this.setState({
                    ticketDetail: res.data,
                    Capitalize: res.data.ticketAmount,
                });
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    render() {
        let { ticketDetail, Capitalize } = this.state;

        let ticketStyle = {
            width: "100%",
            textAlign: "center",
            fontSize:'14px',
        };
        let background = {
            width: "100%",
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "0 0",
            padding: "30px 20px 20px 20px",
        };
        return (
            <div style={background}>
                <h2 style={{ textAlign: "center" , fontSize:'18px' ,margin:'20px 0'}}>电子商业承兑汇票</h2>
                <div style={{ display: "flex" }}>
                    <div style={{ marginBottom: "0px", flex: "1" }}>
                        <div>
                            出票日期：<span>{ticketDetail.ticketDate}</span>
                        </div>
                        <div>
                            汇票到期日：
                            <span>{ticketDetail.ticketExpireDate}</span>
                        </div>
                    </div>
                    <div
                        style={{
                            marginBottom: "0px",
                            flex: "1",
                            paddingLeft: "100px",
                            boxSizing: "border-box",
                        }}
                    >
                        <div>
                            票据状态：
                            <span>{ticketDetail.ticketStatusCdDesc}</span>
                        </div>
                        <div>
                            票据号码：<span>{ticketDetail.ticketNo}</span>
                        </div>
                    </div>
                </div>
                <table border="1" style={ticketStyle}>
                    <tbody>
                        <tr>
                            <td rowSpan="4">出票人</td>
                            <td>账号</td>
                            <td colSpan="2">{ticketDetail.drawerAccountNo}</td>
                            <td rowSpan="4">收款人</td>
                            <td>账号</td>
                            <td>{ticketDetail.payeeAccountNo}</td>
                        </tr>
                        <tr>
                            <td>全称</td>
                            <td colSpan="2">{ticketDetail.drawerName}</td>
                            <td>全称</td>
                            <td>{ticketDetail.payeeName}</td>
                        </tr>
                        <tr>
                            <td>开户行</td>
                            <td colSpan="2">{ticketDetail.drawerBank}</td>
                            <td>开户行</td>
                            <td>{ticketDetail.payeeBank}</td>
                        </tr>
                        <tr>
                            <td> 开户行号</td>
                            <td colSpan="2">{ticketDetail.payeeBankNo}</td>
                            <td>开户行号</td>
                            <td>{ticketDetail.payeeBankNo}</td>
                        </tr>
                        {ticketDetail.type == 2 ? (
                            <>
                                <tr>
                                    <td rowSpan="2">出票保证人信息</td>
                                    <td>保证人名称</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeName}
                                    </td>
                                    <td>地址</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeAddress}
                                    </td>
                                </tr>
                                <tr>
                                    <td>保证日期</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeDate}
                                    </td>
                                    <td></td>
                                    <td colSpan="2"></td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <td rowSpan="2">出票保证人信息</td>
                                    <td>保证人账号</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeAccountNo}
                                    </td>
                                    <td>保证人开户行</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeBank}
                                    </td>
                                </tr>
                                <tr>
                                    <td>保证人名称</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeName}
                                    </td>
                                    <td>保证人开户行号</td>
                                    <td colSpan="2">
                                        {ticketDetail.drawerPledgeBankNo}
                                    </td>
                                </tr>
                            </>
                        )}

                        <tr>
                            <td colSpan="2">票据金额</td>
                            <td>小写</td>
                            <td>{money100(ticketDetail.ticketAmount)}</td>
                            <td colSpan="2">人民币(大写)</td>
                            <td>
                                {nzhcn.toMoney(Capitalize, {
                                    outSymbol: false,
                                })}
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">承兑人</td>
                            <td>承兑人账号</td>
                            <td colSpan="2">
                                {ticketDetail.acceptorAccountNo}
                            </td>
                            <td>承兑人开户行</td>
                            <td colSpan="2">{ticketDetail.acceptorBank}</td>
                        </tr>
                        <tr>
                            <td>承兑人名称</td>
                            <td colSpan="2">{ticketDetail.acceptorName}</td>
                            <td>承兑人开户行号</td>
                            <td colSpan="2">{ticketDetail.acceptorBankNo}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">交易合同号</td>
                            <td colSpan="2">{ticketDetail.tradeContractNo}</td>
                            <td rowSpan="2">承兑信息</td>
                            <td colSpan="2" style={{ textAlign: "left" }}>
                                出票人承诺：本汇票信息请予以承兑,到期无条件付款
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">是否可转让</td>
                            <td colSpan="2">
                                {ticketDetail.transferTypeCdDesc}
                            </td>
                            <td colSpan="2" style={{ textAlign: "left" }}>
                                <p>
                                    出票人承诺：本汇票信息请予以承兑,到期无条件付款.
                                </p>
                                <p>承兑日期：{ticketDetail.acceptorDate}</p>
                            </td>
                        </tr>
                        {ticketDetail.type == 2 ? (
                            <>
                                <tr>
                                    <td rowSpan="2">承兑人保证信息</td>
                                    <td>保证人名称</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeName}
                                    </td>
                                    <td>地址</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeAddress}
                                    </td>
                                </tr>
                                <tr>
                                    <td>日期</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeDate}
                                    </td>
                                    <td></td>
                                    <td colSpan="2"></td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <td rowSpan="2">承兑人保证信息</td>
                                    <td>保证人账号</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeAccountNo}
                                    </td>
                                    <td>保证人开户行</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeBank}
                                    </td>
                                </tr>
                                <tr>
                                    <td>保证人名称</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeName}
                                    </td>
                                    <td>保证人开户行号</td>
                                    <td colSpan="2">
                                        {ticketDetail.acceptorPledgeBankNo}
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td rowSpan="2">评级信息</td>
                            <td>出票人</td>
                            <td colSpan="2">
                                评级主体:{ ticketDetail.drawerRatingLevel&&ticketDetail.drawerRatingLevelExpireDate?ticketDetail.drawerName:''}
                            </td>
                            <td colSpan="2">信用等级:{ticketDetail.drawerRatingLevel}</td>
                            <td colSpan="2">
                                评级到期日:
                                {ticketDetail.drawerRatingLevelExpireDate}
                            </td>
                        </tr>
                        <tr>
                            <td>承兑人</td>
                            <td colSpan="2">
                                评级主体:{ ticketDetail.acceptorRatingLevel&&ticketDetail.acceptorRatingLevelExpireDate?ticketDetail.acceptorName:''}
                            </td>
                            <td colSpan="2">信用等级:{ticketDetail.acceptorRatingLevel}</td>
                            <td colSpan="2">
                                评级到期日:
                                {ticketDetail.acceptorRatingLevelExpireDate}
                            </td>
                        </tr>
                        <tr>
                            <td>备注：</td>
                            <td colSpan="6">{ticketDetail.ticketRemark}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { message } from 'antd';
class Facerecognition extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
                <div className='face-box'>
                    <div className='face-Qrcode'>
                        <h2>需要法定代表人进行人脸识别</h2>
                        <div className="face-Qrcode-div">
                            <p>您可通过手机微信扫描下方二维码进行验证</p>
                            <img src=''/>
                            <p>注意不要关闭本页面，移动端验证通过后点击本页面"下一步"进行后续合同签署</p>
                        </div>
                    </div>
                    <div className='face-identify'>
                        <h2>需要法定代表人进行人脸识别</h2>
                    </div>
                </div>
            </>
         );
    }
}
 
export default Facerecognition;
import React, { Component } from 'react';
import { Button, Col, Form, Input,Radio, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBaseApi from "../../../../request/api/scfBaseApi";

const FormItem = Form.Item;


class AddRoleAuthority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            router:"",//判断路径
            detail:"",
            otherOrgName:"",
            acceptor:""
        }
    }
  componentDidMount(){
      let router = this.props.match.params.item;
      
      if(router==="resubmit"){
          this.getDetail()
       
      }
  }

  getDetail = () =>{
    let params = {
        id:JSON.parse(localStorage.getItem("infoId"))
    }
    scfBaseApi.infoDetailApi(params).then(res=>{
         this.setState({
             detail:res.data
         },()=>{
            this.props.form.setFieldsValue({
                role:this.state.detail.inviteRoleCd,
                name:this.state.detail.otherOrgName
              })
         })
    }).catch(err=>{
        message.error(err.message)
    })
}
   
    goBack = ()=>{
        this.props.history.push("/admin/commercialTicketManage/CtAuthorityManage")
    }
    confirm = ()=>{
        let router = this.props.match.params.item
        if(router==="resubmit"){
            this.props.form.validateFields((err,values)=>{
                let {role} = values
                let params = {
                    id:localStorage.getItem("infoId"),
                    role:role,
                    action: "10"
                }
                scfBaseApi.auditApi(params).then(res=>{
                    message.success(res.message)
                    this.props.history.push("/admin/commercialTicketManage/CtAuthorityManage")
                }).catch(err=>{
                    message.error(err.message)
                })
            })

        }else if(router==="add"){
            this.props.form.validateFields((err,values)=>{
                scfBaseApi.addRelationsApi(values).then(res=>{
                    message.success(res.message)
                    this.props.history.push("/admin/commercialTicketManage/CtAuthorityManage")
                }).catch(err=>{
                    message.error(err.message)
                })
            })
        } 
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
        // localStorage.removeItem("name")
        // localStorage.removeItem("role")
    }
render() {
        let { getFieldDecorator } = this.props.form;
        let {detail} = this.state
        let router = this.props.match.params.item;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };

       return (
            <div>
                <Form className="" {...formRowItem}>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="我方角色">
                                        {
                                            
                                            <span>出票人</span>
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="新增他方角色">
                                        {
                                            getFieldDecorator('role', {
                                                validateTrigger: "onBlur",
                                                initialValue: ""
                                            })(<Radio.Group>
                                                <Radio value="acceptor">承兑人</Radio>
                                                <Radio value="payee">收款人</Radio>
                                              </Radio.Group>)
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    {router==="resubmit"?(
                                     <FormItem label="新增他方企业">
                                     {
                                         getFieldDecorator('name', {
                                             validateTrigger: "onBlur",
                                             initialValue: ""
                                         })(<Input disabled placeholder="" />)
                                     }
                                 </FormItem>
                                    ):(
                                        <FormItem label="新增他方企业">
                                        {
                                            getFieldDecorator('name', {
                                                validateTrigger: "onBlur",
                                                initialValue: ""
                                            })(<Input placeholder="" />)
                                        }
                                    </FormItem>
                                    )}
                                    
                                </Col>        
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <div style={{ width: '100%' }}>
                                            <Button type="default" onClick={this.goBack} className="com-middle-btn-size" style={{ marginRight: '20px' }}>返回</Button>
                                            <Button type="primary" onClick={this.confirm}  className="com-middle-btn-size">确认</Button>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                    </Form>
                </div>
        );
    }
}

export default Form.create()(AddRoleAuthority);




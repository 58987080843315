import React, {Component} from 'react';
import SearchRole from "./component/searchRole";
import "./index.scss";
class Index extends Component {
    render() {
        return (
            <div className="search-role-wrapper">
                <SearchRole></SearchRole>
            </div>
        );
    }
}

export default Index;
import React, { Component } from 'react';
import AddComp from './component/addComp';
import EditComp from './component/editComp';
import ListComp from './component/listComp';
import DetailComp from './component/detailComp';
import './index.scss';

export default class BorrowerCredit extends Component {
    
    render() {
        let item = this.props.match.params.item;
        let reactNode = <React.Fragment></React.Fragment>;
        switch (item) {
            case 'list': reactNode = <ListComp {...this.props} />; break;
            case 'add': reactNode = <AddComp {...this.props} />; break;
            case 'edit': reactNode = <EditComp {...this.props} />; break;
            case 'detail': reactNode = <DetailComp {...this.props}></DetailComp>; break;
            default: break;
        }

        return (
            <div className='BorrowerCredit_style'>
                { reactNode }
            </div>
        )
    }
}

import React, {Component} from 'react';
import SearchUser from "./component/searchUser";
class Index extends Component {
    render() {
        return (
            <div>
                <SearchUser></SearchUser>
            </div>
        );
    }
}

export default Index;
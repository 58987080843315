import React, { Component } from 'react';
import { Row, Col , Button } from 'antd';
import { withRouter } from 'dva/router';
import AuditHistory from '../../businessTicketManage/component/aditHistory'
import QuotaInfo from './quotaInfo'
import TicketTable from '../../../containers/commercialTicketManage/client/component/ticketTable'
import PayMoneyDetail from './payMoneyDetail'
import url from 'url'
import { getStorage } from '../../../utils/tools'
@withRouter

class IssueDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ticketLedgerId:url.parse(this.props.location.search,true).query.id,
            type:url.parse(this.props.location.search,true).query.type,
            drawerOrgId:url.parse(this.props.location.search,true).query.drawerOrgId,
            companyID:getStorage('companyID'),
         }
    }
    goBack = ()=>{
        window.history.go(-1)
    }
    render() { 
        const { ticketLedgerId , type , drawerOrgId , companyID } = this.state
        return ( 
            <>
                <div>
                    <Row>
                        {/* {
                            drawerOrgId == companyID?<QuotaInfo/>:null
                        } */}
                        
                        <h1 style={{fontSize:"18px",fontWeight:'600',marginBottom:'30px'}}>发行票据信息</h1>
                        <TicketTable ticketLedgerId={ticketLedgerId}/>
                        {/* {
                            type==2?<AuditHistory businessId={ticketLedgerId} businessType={'2040'}/>:<AuditHistory businessId={ticketLedgerId} businessType={'2020'}/>
                        }
                        {
                            type==2?<PayMoneyDetail ticketLedgerId={ticketLedgerId}/>:null
                        } */}
                        <div style={{textAlign:'center',margin:'20px 0'}}>
                            <Button onClick={this.goBack}>返回</Button>
                        </div>
                    </Row>
                </div>
                
            </>
         );
    }
}
 
export default IssueDetail;
import React, { Component } from 'react';
import { connect } from 'dva';
import { getUrlParam } from '@/utils/tools';
import ReviewContranct from './reviewContract';
import { Form, Row, Col, Spin, Table, Button } from 'antd';

@connect((store) => ({ store: store.signingConfiguration, loading: store.loading }))
class DetailComp extends Component {

    componentDidMount () {
        this.props.dispatch({type: 'signingConfiguration/getDetailData', payload: { signConfigId: getUrlParam('id')}});
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { detailData } = this.props.store;
        const isLoding1 = this.props.loading.effects['signingConfiguration/getDetailData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '签约顺序',
                dataIndex: 'signOrder',
                key: 'signOrder',
                sorter:(a,b)=>{
                    if(!a.signOrder){
                        a.signOrder=""
                    }else if(!b.signOrder){
                        b.signOrder=""
                    }
                    return a.signOrder.localeCompare(b.signOrder)
                }
            },
            {
                title: '合同名称',
                key: 'contractName',
                sorter:(a,b)=>{
                    if(!a.contractName){
                        a.contractName=""
                    }else if(!b.contractName){
                        b.contractName=""
                    }
                    return a.contractName.localeCompare(b.contractName)
                },
                render: (text, record) => {
                    return (
                        <ReviewContranct text={record.contractName} relativePath={record.relativePath} newFileName={record.newFileName} originalFileName={record.originalFileName} />
                    )
                }
            }
        ];
        return (
            <div>
                <Spin spinning={isLoding1}>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>签约配置基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择项目">
                                    {detailData && detailData.projectName ? detailData.projectName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择产品">
                                    {detailData && detailData.productName ? detailData.productName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <p className='h_text_style'>合同签约配置（合同签约会按照配置的签约顺序依次签约）</p>
                    <Table 
                        columns={columns}
                        dataSource={detailData.configDetails}
                        rowKey={(record, index) => index}
                        pagination={false}
                    />
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default DetailComp;

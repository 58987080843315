import React, { Component } from 'react'
import { Button, Form, Table, Input, Row, Col, message } from "antd";
import "../../index.scss"
import TreeGraph from './treeGraph'
import TicketTable from './ticketTable'
import TicketDetailTable from './ticketDetailTable'
import scfBusinessApi from "../../../../request/api/scfBusinessApi";

const { TextArea } = Input;
const FormItem = Form.Item;

class CtTransforDetailDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            pageNum: "1",
            total: "",
            router: "",
            ticketMessage: "",
            signExamineticketMessage: "",
        }
    }
    goBack = () => {
        this.props.history.go(-1)
    }
    componentDidMount() {
        let router = this.props.match.params.item;

        this.setState({
            router: router
        })
        if (router === "signExamine") {
            this.getsignExamineTicketMessage()
        }
        //  else {
        //     this.getTicketMessage()
        // }

        this.getAuditHistory()
    }
    // //票据信息
    // getTicketMessage = () => {
    //     let params = {
    //         id: this.props.match.params.id
    //     }
    //     scfBusinessApi.getTransferDetailApi(params).then(res => {
    //         this.setState({
    //             ticketMessage: res.data
    //         })
    //     }).catch(err => {
    //         message.error(err.message);
    //     })
    // }
    //签收审核的票据详情
    getsignExamineTicketMessage = () => {
        let params = {
            id: this.props.match.params.id
        }
        scfBusinessApi.getDetailApi(params).then(res => {
            this.setState({
                signExamineticketMessage: res.data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //审核通过
    Approved = () => {
        let { router } = this.state;
        const { id, wkId, nodeId } = this.props.match.params
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.id = id;
                values.wkId = wkId;
                values.nodeId = nodeId;
                values.operationStatus = "20"
                if (router === "examine") {
                    scfBusinessApi.auditTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message)
                    })
                } else if (router === "revokeexamine") {
                    scfBusinessApi.auditRevokeTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message)
                    })
                } else if (router === "signExamine") {
                    scfBusinessApi.auditSignTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message)
                    })
                }

            }
        })
    }
    //审核驳回
    Reject = () => {
        const { router } = this.state
        const { id, wkId, nodeId } = this.props.match.params
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.id = id;
                values.wkId = wkId;
                values.nodeId = nodeId;
                values.operationStatus = "40"
                if (router === "examine") {
                    scfBusinessApi.auditTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message)
                    })
                } else if (router === "revokeexamine") {
                    scfBusinessApi.auditRevokeTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message)
                    })
                } else if (router === "signExamine") {
                    scfBusinessApi.auditSignTransferApi(values).then(res => {
                        message.success(res.message)
                        this.props.history.push("/admin/commercialTicketManage/CtTradeClient")
                    }).catch(err => {
                        message.error(err.message)
                    })
                }
            }
        })
    }

    //撤销
    revoke = () => {

        let { router } = this.state

        if (router === "rerevoke") {
            let { id } = this.props.match.params
            let params = {
                id,
            }
            scfBusinessApi.reRevokeTransferApi(params).then(res => {
                message.success(res.message)
                this.props.history.go(-1)
            }).catch(err => {
                message.error(err.message)
            })
        } else if (router === "revoke") {
            let { id } = this.props.match.params
            let params = {
                id,
            }
            scfBusinessApi.revokeTransferApi(params).then(res => {
                message.success(res.message)
                this.props.history.go(-1)
            }).catch(err => {
                message.error(err.message)
            })
        }

    }
    //审核历史
    getAuditHistory = () => {
        let params = {
            businessId: this.props.match.params.id,
            businessType: "2030"
        }
        scfBusinessApi.auditHistoryApi(params).then(res => {
            this.setState({
                dataSource: res.data
            })
        }).catch(err => {
            message.error(err.message)
        })
    }

    render() {
        let { dataSource, ticketMessage, signExamineticketMessage, router } = this.state
        let { getFieldDecorator } = this.props.form;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'sysBusinessTypeCdDesc',
                key: 'sysBusinessTypeCdDesc',

            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
                key: 'stateCdDesc',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];
        return (
            <div >
                <div >
                    {
                        router === "signExamine" ? (
                            <div>
                                <h2>票据信息</h2>
                                <ul>
                                    <li>票据号码：{signExamineticketMessage.ticketNo}</li>
                                    <li>出票日期：{signExamineticketMessage.ticketDate}</li>
                                    <li>汇票到期日期：{signExamineticketMessage.ticketExpireDate}</li>
                                    <li>转入方：{signExamineticketMessage.ticketFromOrgName}</li>
                                    <li>票据金额：{signExamineticketMessage.ticketAmount}</li>
                                    <li>转入日期：{signExamineticketMessage.createTime}</li>
                                </ul>
                            </div>) : (
                                <TicketDetailTable id={this.props.match.params.id}></TicketDetailTable>
                            )
                            // <div>
                            //     <div className="wrap">
                            //         <div>
                            //             <h2>票据信息</h2>
                            //             <ul>
                            //                 <li>票据号码：{ticketMessage.ticketNo}</li>
                            //                 <li>出票日期：{ticketMessage.ticketDate}</li>
                            //                 <li>汇票到期日期：{ticketMessage.ticketExpireDate}</li>
                            //                 <li>转入方：{ticketMessage.ticketFromOrgName}</li>
                            //             </ul>
                            //         </div>
                            //         <div>
                            //             <h2>票据额度信息</h2>
                            //             <ul>
                            //                 <li>票据额度(元)：{ticketMessage.ticketAmount}</li>
                            //                 <li>票据剩余额度(元)：{ticketMessage.remainAmount}</li>
                            //             </ul>
                            //         </div>
                            //     </div>
                            //     <div>
                            //         <h2>转让信息</h2>
                            //         <ul>
                            //             <li>转让额度(元):{ticketMessage.transferAmount}</li>
                            //             <li>转让方：{ticketMessage.ticketToOrgName}</li>
                            //             <li>转让方账号：{ticketMessage.toBankCnap}</li>
                            //             <li>开户行：{ticketMessage.toBankName}</li>
                            //             <li>开户行行号：{ticketMessage.toBankNumber}</li>
                            //         </ul>
                            //     </div>
                            // </div>
                    }
                   
                </div>

                <div>
                    <div className="marginTopOrBottom">
                        <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                    </div>
                    <div>
                        <TreeGraph id={this.props.match.params.id}></TreeGraph>
                    </div>
                </div>

                <div className="marginTopOrBottom">
                    <h2>审核历史</h2>
                    <Table dataSource={dataSource} columns={columns} className="table" pagination={false} />
                </div>
                {
                        router === "examine" || router === "revokeexamine" || router === "signExamine" ? (
                            <div className="marginTopOrBottom">
                                {/* <h2>审核意见</h2> */}
                                <Form>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem label="审核意见:" >
                                                {
                                                    getFieldDecorator('des', {
                                                        initialValue: "",
                                                        validate: [
                                                            {
                                                                trigger: "onBlur",
                                                                rules: [{ required: true, message: "必填" }]
                                                            },
                                                        ]
                                                    })(<TextArea rows={4} />)
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>) : null
                    }
                <div className="marginTopOrBottom textAlignCenter">
                    <Button onClick={this.goBack} className="com-middle-btn-size">返回</Button>
                    {
                        router === "examine" || router === 'revokeexamine' || router === "signExamine" ? (<>
                            <Button type="primary" className="com-middle-btn-size" onClick={this.Reject} style={{ marginLeft: '20px' }}>审核驳回</Button>
                            <Button type="primary" className="com-middle-btn-size" onClick={this.Approved} style={{ marginLeft: '20px' }}>审核通过</Button>
                        </>) : null
                    }
                    {router === "revoke" || router === "rerevoke" ? <Button type="primary" className="com-middle-btn-size" style={{ marginLeft: '20px' }} onClick={this.revoke}>撤销</Button> : null}
                </div>

            </div>
        )
    }
}
export default Form.create()(CtTransforDetailDetail);
import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Spin,Table, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import {checkPhone} from "../../../utils/formatCheck";
import AddUserModal from "./addUserModal";
import DetailModal from "./detailModal";
import ModalTipTwoBtn from "../../../components/modalTipTwoBtn";
import scfBusinessApi from '../../../request/api/scfBusinessApi';

const FormItem = Form.Item;
const Option = Select.Option;

class SearchUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            isShowModal:false,
            systemId:"",
            userType: "",
            userMobile: "",
            userName: "",
            orgName: "",
            isShowAddUser: false,//新增用户
            userId: 0,//用户id
            isAdd: false, //新增：isAdd=true  编辑：isAdd=false
            isDetail:false,//详情
            modalStatus:0,//1-禁用  2-启用  3-重置
            title: "",
            text: "",
            systemArr:[],
            userTypeArr:[],
        }
    }

    componentDidMount() {
        this.getSystemQueryAll();
        this.getDicLt("userType");
        this.getUserList();
    }
    //获取所属系统
    getSystemQueryAll = () => {
        scfBaseApi.systemQueryAllApi().then(res => {
            let data = res.data;
            this.setState({
                systemArr: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType:type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取用户列表
    getUserList = () => {
        let {pageNum, pageSize, systemId, userType, userMobile, userName, orgName} = this.state;
        let params = {systemId, userType, userMobile, userName, orgName, pageNum, pageSize};
        scfBaseApi.userListApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getUserList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getUserList();
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            systemId:"",
            userType:"",
            userMobile: "",
            userName: "",
            orgName: "",
        });
        this.setState({
            systemId:"",
            userType:"",
            userMobile: "",
            userName: "",
            orgName: "",
            pageNum: 1,
        }, () => {
            this.getUserList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {systemId, userType, userMobile, userName, orgName} = values;
                this.setState({systemId, userType, userMobile, userName, orgName,pageNum:1},
                    () => {
                        this.getUserList();
                    })
            }
        })
    }

    handleOk = () => {
        let {userId, modalStatus} = this.state;
        if (modalStatus === 1) {
            scfBaseApi.userForbiddenApi({id:userId}).then(res => {
                message.success(res.message);
                this.setState({
                    isShowModal: false
                });
                this.getUserList();
            }).catch(err => {
                message.error(err.message);
            })
        }else if(modalStatus === 2){
            scfBaseApi.userEnableApi({id:userId}).then(res => {
                message.success(res.message);
                this.setState({
                    isShowModal: false
                });
                this.getUserList();
            }).catch(err => {
                message.error(err.message);
            })
        } else {
            scfBaseApi.userResetApi({id:userId}).then(res => {
                message.success(res.message);
                this.setState({
                    isShowModal: false
                });
                this.getUserList();
            }).catch(err => {
                message.error(err.message);
            })
        }
    }
    handleCancel = () => {
        this.setState({
            isShowModal: false
        });

    }
    //新增用户
    handleAddUser = () => {
        let systemId=this.props.form.getFieldValue('systemId');
        if(systemId===""){
             message.warn("请选择所属系统");
        }else{
            this.setState({
                isShowAddUser: true,
                isAdd: true,
            })
        }       
    }
    //编辑用户
    handleEdit = (userId = 0) => {
        this.setState({
            isShowAddUser: true,
            isAdd: false,
            userId
        })
    }
      //用户详情
      handleDetail = (userId = 0) => {
        this.setState({
            isDetail: true,
            userId
        })
    }

    //详情弹框消失
    detailOk = () =>{
        this.setState({
            isDetail: false
        }, () => {
            this.getUserList();
        });
    }
    //编辑/新增弹框确定后
    operationOk = () => {
        this.setState({
            isShowAddUser: false
        }, () => {
            this.getUserList();
            if(localStorage.getItem("textJson")){
                localStorage.removeItem("textJson")
            }
            
            localStorage.removeItem("systemId")
        });
    }

    //编辑/新增弹框取消后
    operationCancel = () => {
        this.setState({
            isShowAddUser: false
        },()=>{
            if(localStorage.getItem("textJson")){
                localStorage.removeItem("textJson")
            }
            localStorage.removeItem("systemId")
        });
    }
    //禁用启用
    handleDisable = (userId=0, status="") => {
        let text = "";
        let title = "";
        if (status === "0") {
            title = "禁用";
            text = "  <p>确认要禁用该用户？</p>\n" +
                   " <p>禁用后，该用户不可使用我方任何系统！</p>"
        } else {
            title = "启用";
            text = "  <p>确认要启用该用户？</p>\n" +
                   " <p>启用后，该用户可使用我方系统！</p>"
        }
        this.setState({
            userId,
            modalStatus: status==="0"?1:2,
            isShowModal: true,
            title,
            text,
        });
    }
    handleResetPsd = (userId) => {
        this.setState({
            userId,
            modalStatus: 3,
            isShowModal: true,
            title: "重置密码",
            text: "  <p>确认要重置该用户密码？</p>\n" +
                " <p>重置密码后默认登录密码为：000000</p>"
        });
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return null;
        };
    }

    
    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount, isShowModal, isShowAddUser,isDetail, isAdd, userId, title, text,systemArr,userTypeArr} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 3, span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {offset: 1, span: 12}
            },
            labelAlign: 'right'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width:100,
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: React.ReactNode=(
                    <div>
                        <span>登录账号</span>
                        <br/>
                        <span>（手机号）</span>
                    </div>
                ),
                dataIndex: 'userMobile',
                key: 'userMobile',
                sorter:(a,b)=>{
                    if(!a.userMobile){
                        a.userMobile=""
                    }else if(!b.userMobile){
                        b.userMobile=""
                    }
                    return a.userMobile-b.userMobile
                },
            },
            {
                title: '用户姓名',
                dataIndex: 'userName',
                key: 'userName',
                sorter:(a,b)=>{
                    if(!a.userName){
                        a.userName=""
                    }else if(!b.userName){
                        b.userName=""
                    }
                    return a.userName.localeCompare(b.userName)
                },
            },
            {
                title: '邮箱',
                dataIndex: 'userEmail',
                key: 'userEmail',
                sorter:(a,b)=>{
                    if(!a.userEmail){
                        a.userEmail=""
                    }else if(!b.userEmail){
                        b.userEmail=""
                    }
                    return a.userEmail.localeCompare(b.userEmail)
                },
            },
            // {
            //     title: '所属企业名称',
            //     dataIndex: 'orgName',
            //     key: 'orgName',
            //     width:150,
            //     sorter:(a,b)=>{
            //         if(!a.orgName){
            //             a.orgName=""
            //         }else if(!b.orgName){
            //             b.orgName=""
            //         }
            //         return a.orgName.localeCompare(b.orgName)
            //     },
            // },
            // {
            //     title: '所属企业部门',
            //     dataIndex: 'departNames',
            //     key: 'departNames',
            //     width:150,
            //     sorter:(a,b)=>{
            //         if(!a.departNames){
            //             a.departNames=""
            //         }else if(!b.departNames){
            //             b.departNames=""
            //         }
            //         return a.departNames.localeCompare(b.departNames)
            //     },
            // },
            // {
            //     title: '用户类型',
            //     dataIndex: 'userTypeName',
            //     key: 'userTypeName',
            //     width:60,
            //     sorter:(a,b)=>{
            //         if(!a.userTypeName){
            //             a.userTypeName=""
            //         }else if(!b.userTypeName){
            //             b.userTypeName=""
            //         }
            //         return a.userTypeName.localeCompare(b.userTypeName)
            //     },
            // },
            // {
            //     title: '所属系统',
            //     dataIndex: 'systemName',
            //     key: 'systemName',
            //     width:100,
            //     sorter:(a,b)=>{
            //         if(!a.systemName){
            //             a.systemName=""
            //         }else if(!b.systemName){
            //             b.systemName=""
            //         }
            //         return a.systemName.localeCompare(b.systemName)
            //     },
            // },
            {
                title: '状态',
                dataIndex: 'userStatusCdDesc',
                key: 'userStatusCdDesc',
               
                sorter:(a,b)=>{
                    if(!a.userStatusName){
                        a.userStatusName=""
                    }else if(!b.userStatusName){
                        b.userStatusName=""
                    }
                    return a.userStatusName.localeCompare(b.userStatusName)
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width:150,
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split('T')[0] : "";
                    let time = text != null ? text.split('T')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                width:100,
                sorter:(a,b)=>{
                    if(!a.systemTypeName){
                        a.systemTypeName=""
                    }else if(!b.systemTypeName){
                        b.systemTypeName=""
                    }
                    return a.systemTypeName.localeCompare(b.systemTypeName)
                },
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime',
                width:150,
                sorter: (a, b) => {
                    let aTime = new Date(a.modifyTime).getTime()
                    let bTime = new Date(b.modifyTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split('T')[0] : "";
                    let time = text != null ? text.split('T')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '修改人',
                dataIndex: 'modifyUserName',
                key: 'modifyUserName',
                width:100,
                sorter:(a,b)=>{
                    if(!a.systemTypeName){
                        a.systemTypeName=""
                    }else if(!b.systemTypeName){
                        b.systemTypeName=""
                    }
                    return a.systemTypeName.localeCompare(b.systemTypeName)
                },
            },
            {
                title: '操作',
                width: 200,
                render: (record) => {
                    return <div className="flex-around-center">
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleDetail(record.id)
                        }}>详情</Button>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleEdit(record.id)
                        }}>编辑</Button>

                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleDisable(record.id, record.userStatus)
                        }}>{record.userStatus === "0" ? "禁用" : "启用"}</Button>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleResetPsd(record.id)
                        }}>重置密码</Button>
                    </div>

                }
            },
        ];
        let modalProps = {
            title,
            text,
            operationOk: this.handleOk,
            operationCancel: this.handleCancel,

        };
        let addModalProps = {
            isAdd,
            userId,
            systemIda:this.state.systemId,
            operationOk: this.operationOk,
            operationCancel: this.operationCancel,
        }
        let detailModalProps = {
            isDetail,
            userId,
            systemId:this.state.systemId,
            detailOk: this.detailOk,
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit} className="" {...formRowItem}>
                    <Row>
                        <Col span={8}>
                            <FormItem label="所属系统">
                                {
                                    getFieldDecorator('systemId', {
                                        initialValue:"",
                                        rules: [{ required: true, message: '请选择所属系统' }]
                                    })(<Select >
                                        {
                                            systemArr.length !== 0 ? systemArr.map(record => {
                                                return <Option key={record.id} value={record.id} systemname={record.systemName}> {record.systemName} </Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="用户类型">
                                {
                                    getFieldDecorator('userType', {
                                        initialValue:""
                                    })(<Select>
                                        <Option key="" value="">全部</Option>
                                        {
                                            userTypeArr.length !== 0 ? userTypeArr.map(record => {
                                                return <Option key={record.dicKey} value={record.dicKey}> {record.dicValue} </Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="手机号">
                                {
                                    getFieldDecorator('userMobile', {
                                        rules: [
                                            {validator: checkPhone}
                                        ],
                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <FormItem label="用户姓名">
                                {
                                    getFieldDecorator('userName', {
                                        rules: [
                                            {
                                                max: 50,
                                                message: "最大长度为50位,请重新输入"
                                            }],
                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="所属企业名称">
                                {
                                    getFieldDecorator('orgName', {
                                        rules: [
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            }],
                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <div className="com-diff-two-btn">
                        <Button type="default" className="com-middle-btn-size" onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <div>
                    <h2>用户信息列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size" onClick={this.handleAddUser}>+新增</Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount>10?pagination:false}
                />
                {isShowModal === true ? <ModalTipTwoBtn {...modalProps} /> : null}
                {isShowAddUser === true ? <AddUserModal {...addModalProps}/> : null}
                {isDetail === true?<DetailModal {...detailModalProps}></DetailModal>:null}
            </div>
        );
    }
}

export default Form.create()(SearchUser);
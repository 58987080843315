import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";



import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

class addInvitateCompany extends Component {
    constructor(props){
      super(props)
      this.state={
        addVisible:true,//添加邀请企业弹框
      }
    }
   componentDidMount(){
       
   }
        //确认邀请
        okInvitate = () => {
            this.props.form.validateFields((err, values) => {
                console.log(values)
                if (!err) {
                    
                    scfBaseApi.inviteApi(values).then(res => {
                        message.success(res.message);
                        this.props.hideModal()
                    }).catch(err => {
                        message.error(err.message);
                    });
                    
                }
            })
        }
        //取消邀请
        cancelInvitate = () => {
            this.props.hideModal()
        }
    render() {
        let {addVisible} = this.state
        let { getFieldDecorator } = this.props.form;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };
        return (
            <div>
                 {/* 添加企业modal */}
                 <Modal
                    title="添加邀请企业"
                    visible={addVisible}
                    onOk={this.okInvitate}
                    onCancel={this.cancelInvitate}
                    centered
                >
                    <Form className="" {...formRowItem}>
                        <Row>
                            <Col span={24}>
                                <FormItem label="邀请企业名称">
                                    {
                                        getFieldDecorator('name', {
                                            initialValue: "",
                                            validate: [
                                                {
                                                  trigger: "onBlur",
                                                  rules: [{ required: true,message: "必填" }]
                                                },
                                            ]
                                        })(<Input placeholder="" />)
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

            </div>
        )
    }
}
export default Form.create()(addInvitateCompany);
import React, { Component } from 'react'
import { Menu, Dropdown, Button, Select, Form, Table, Row, Col, Input, message, AutoComplete } from "antd";
import "../../index.scss"
import TreeGraph from "./treeGraph"
import TicketTable from "./ticketTable"
import scfBusinessApi from "../../../../request/api/scfBusinessApi";

const FormItem = Form.Item;
const { Option } = Select;

class AddTransforClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            pageNum: "1",
            total: "",
            router: "",
            ticketMessage: "",
            children: [],
            newValue: "",
            record: "",
            transferMessage: "",//转让方信息
            toOrgName: "",//转让方名称
            inputValue:"",
           
        }
    }
    goBack = () => {
        this.props.history.go(-1)
    }
    componentDidMount() {
        let router = this.props.match.params.item
        this.setState({
            router: router
        },()=>{
            this.getDate()
        })
        this.getAuditHistory()
        
    }
        
        //审核历史
        getAuditHistory = () =>{
            let params ={
                businessId:this.props.match.params.id,
                businessType:"2030"
            }
            scfBusinessApi.auditHistoryApi(params).then(res=>{
               this.setState({
                dataSource:res.data.data
               })
            }).catch(err=>{
                message.error(err.message)
            })
        }
    getDate = () =>{
        let {router} = this.state
        if(router==="resubmit"){
            this.getResubmitDetail()
        }else if(this.state.router==="addTransfor"){
            this.getDetail()
    }
}
//转让列表id获取详情
getResubmitDetail = () =>{
    console.log(this.props.match.params.id)
    scfBusinessApi.getTransferDetailApi({id:this.props.match.params.id}).then(res => {
        this.setState({
            ticketMessage: res.data
        }, () => {
            this.props.form.setFieldsValue({
                transferAmount:this.state.ticketMessage.transferAmount,
                ticketToOrgName:this.state.ticketMessage.ticketToOrgName,
                ticketToBankNo:this.state.ticketMessage.toBankCnap,
                ticketToBankName:this.state.ticketMessage.toBankName,
                ticketToBankNumber:this.state.ticketMessage.toBankNumber
            })
        })
}).catch(err => {
    message.error(err.message);
})
}

//获取详情
getDetail = () =>{
    scfBusinessApi.getDetailApi({id:this.props.match.params.id}).then(res => {
        this.setState({
            ticketMessage: res.data
        },()=>{
            this.transferList()
        })
}).catch(err => {
    message.error(err.message);
})
}
//转让方列表
transferList = () => {
    let { ticketMessage } = this.state
    let params = {
        fromOrgId: ticketMessage.ticketToOrgId
    }
    scfBusinessApi.getTransferorListApi(params).then(res => {
        let data = res.data
        this.setState({
            children: data
        })
    }).catch(err => {
        message.error(err.message)
    })
}


    onClick = (option) => {
        this.setState({
            inputValue: option.item.props.children
        }, () => {
            // this.props.change(this.state.inputValue,option.key)
            this.props.form.setFieldsValue({
                ticketToOrgName: this.state.inputValue
            })
        })

        let params = {
            toOrgId: option.key
        }
        scfBusinessApi.getTransferorListApi(params).then(res => {
            let data = res.data[0]
            this.props.form.setFieldsValue({
                ticketToBankNo: data.toBankCnap,
                ticketToBankName: data.toBankName,
                ticketToBankNumber: data.toBankNumber
            })
        }).catch(err => {
            message.error(err.message)
        })
    }
    confirm = () => {
        const { router} = this.state
        if (router === "addTransfor") {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let {transferAmount,ticketToOrgName,ticketToBankNo,ticketToBankName,ticketToBankNumber} = values
                    let params = {
                        transferAmount,
                        ticketToOrgName,
                        ticketToBankNo,
                        ticketToBankName,
                        ticketToBankNumber,
                        id: this.props.match.params.id
                    }
                    scfBusinessApi.addTransferApi(params).then(res => {
                        message.success(res.message);
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message);
                    });
                }
            })

        } else if (router === "resubmit") {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let {transferAmount,ticketToOrgName,ticketToBankNo,ticketToBankName,ticketToBankNumber} = values
                    let params = {
                        transferAmount,
                        ticketToOrgName,
                        ticketToBankNo,
                        ticketToBankName,
                        ticketToBankNumber,
                        id: this.props.match.params.id
                    }
                    scfBusinessApi.reAddTransferApi(params).then(res => {
                        message.success(res.message);
                        this.props.history.go(-1)
                    }).catch(err => {
                        message.error(err.message);
                    });
                }
            })
        }
    }

    handleValidator = (rule, val, callback) => {
        let { ticketMessage } = this.state
        if (val % 1 != 0 || val > ticketMessage.remainAmount || val==0) {
            callback("转让额度必须为1的整数倍，不能为0且不能大于票据剩余额度")
        } else {
            callback()
        }
    }


    getTransferMessage = (toOrgId) => {
        let params = {
            toOrgId: toOrgId
        }
        scfBusinessApi.getTransferorListApi(params).then(res => {
            let data = res.data[0]
            this.props.form.setFieldsValue({
                ticketToBankNo: data.toBankCnap,
                ticketToBankName: data.toBankName,
                ticketToBankNumber: data.toBankNumber
            })
            this.setState({
                toOrgName: data.toOrgName
            })
        }).catch(err => {
            message.error(err.message)
        })
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
    render() {
        let { dataSource, ticketMessage,children, router } = this.state
        let { getFieldDecorator } = this.props.form;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'left'
        };
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'businessNode',
                key: 'businessNode',

            },
            {
                title: '操作人',
                dataIndex: 'createUser',
                key: 'createUser',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateName',
                key: 'stateName',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];
        const menu = (
            <Menu onClick={this.onClick}>
                {
                    children.map(item => {
                        return <Menu.Item key={item.toOrgId}>{item.toOrgName}</Menu.Item>
                    })

                }
            </Menu>
        );
        return (
            <div className="CtTransforDetailDetail">
                <div className="CtTransforDetailDetail-record">
                   
                        <div>
                            <h2>票据信息</h2>
                            <ul>
                                <li>票据号码：{ticketMessage.ticketNo}</li>
                                <li>出票日期：{ticketMessage.ticketDate}</li>
                                <li>汇票到期日期：{ticketMessage.ticketExpireDate}</li>
                                <li>转入方：{ticketMessage.ticketFromOrgName}</li>
                            </ul>
                        </div>
                        <div>
                            <h2>票据额度信息</h2>
                            <ul>
                                <li>票据额度(元)：{ticketMessage.ticketAmount}</li>
                                <li>票据剩余额度(元)：{ticketMessage.remainAmount}</li>
                            </ul>
                        </div>
                  

                    <div>
                        <Form className="" {...formRowItem}>
                            <Row>
                                <Col span={20}>
                                    <FormItem label="输入转让额度(元)">

                                        {
                                            getFieldDecorator('transferAmount', {
                                                initialValue: "",
                                                validate: [
                                                    {
                                                        trigger: "onBlur",
                                                        rules: [{ required: true, message: "必填" },
                                                        { validator: this.handleValidator }]
                                                    },
                                                ],

                                            })(<Input placeholder="" allowClear/>)
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20}>
                                    {/* <FormItem label="选择转让方" >
                                {
                                        getFieldDecorator('ticketToOrgName', {
                                            initialValue: "",
                                            validate: [
                                                {
                                                  trigger: "onBlur",
                                                  rules: [{ required: true,message:"必填" },
                                                ]
                                                },
                                            ]
                                        })
                                       
                                        (<Select onChange={(value)=>{this.getTransferMessage(value)}}>
                                               {children.map((item,index)=>{
                                                    return (<Option key={index} value={item.toOrgId}>{item.toOrgName}</Option>)
                                                })}
                                          </Select>)
                                    }
                                </FormItem> */}
                                    {router==="addTransfor"?(
                                         <FormItem label="选择转让方" >
                                         <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                                             {
                                                 getFieldDecorator('ticketToOrgName', {
                                                     initialValue: "",
                                                     validate: [
                                                         {
                                                             trigger: "onBlur",
                                                             rules: [{ required: true, message: "必填" },
                                                             ]
                                                         },
                                                     ]
                                                 })
                                                     (<Input autoComplete="off" allowClear />)
                                             }
                                         </Dropdown>
                                     </FormItem>
                                    ):router==="resubmit"?( <FormItem label="选择转让方" >
                        
                                        {
                                            getFieldDecorator('ticketToOrgName', {
                                                initialValue: "",
                                                validate: [
                                                    {
                                                        trigger: "onBlur",
                                                        rules: [{ required: true, message: "必填" },
                                                        ]
                                                    },
                                                ]
                                            })
                                                (<Input disabled />)
                                        }
                                  
                                </FormItem>):null}
                                   
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20}>
                                    <FormItem label="选择转让方账号" >
                                        {
                                            getFieldDecorator('ticketToBankNo', {
                                                initialValue: "",
                                                validate: [

                                                    {
                                                        trigger: "onBlur",
                                                        rules: [{ required: true, message: "必填" }]
                                                    },
                                                ]
                                            })(<Input placeholder="" allowClear />)
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20}>
                                    <FormItem label="开户行">
                                        {
                                            getFieldDecorator('ticketToBankName', {
                                                initialValue: "",
                                                validate: [

                                                    {
                                                        trigger: "onBlur",
                                                        rules: [{ required: true, message: "必填" }]
                                                    },
                                                ]
                                            })(<Input placeholder="" allowClear />)
                                        }
                                    </FormItem>
                                </Col>

                            </Row>
                            <Row>
                                <Col span={20}>
                                    <FormItem label="开户行行号">
                                        {
                                            getFieldDecorator('ticketToBankNumber', {
                                                initialValue: "",
                                                validate: [
                                                    {
                                                        trigger: "onBlur",
                                                        rules: [{ required: true, message: "必填" }]
                                                    },
                                                ]
                                            })(<Input placeholder="" allowClear />)
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>

                                        <Button type="default" onClick={this.goBack} style={{ marginLeft: '20px' }} className="com-middle-btn-size">返回</Button>
                                        <Button type="primary" onClick={this.confirm} style={{ marginLeft: '20px' }} className="com-middle-btn-size">确认</Button>


                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                   
                </div>



                {/* <div className="CtTransforDetailDetail-ticketContent">
                    <div className="CtTransforDetailDetail-ticketContent-front">原票详情
                     <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                    </div>
                    <div className="CtTransforDetailDetail-ticketContent-behind">
                     <TreeGraph id={this.props.match.params.id}></TreeGraph>
                    </div>
                </div> */}
            </div>
        )
    }
}
export default Form.create()(AddTransforClient);
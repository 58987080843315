import React, { Component } from 'react';
import { Table , Button , message } from 'antd';
import { queryPaymoneyDetailApi , queryExportCurrentExcelApi } from '../../../request/api/quota'
import { money100 } from '../../../utils/formatCheck'
import ExportJsonExcel from "js-export-excel";
class PaymoneyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ExcelData:[],
            tableData:[]
        }
    }
    componentDidMount(){
      this.getAuditHistroyInfo()
      this.getExportCurrentExcel()
    }
    getAuditHistroyInfo = ()=>{
      const { ticketLedgerId  } = this.props
      queryPaymoneyDetailApi({id:ticketLedgerId}).then(res => {
          if(res.code=='2000'){
              this.setState({
                  tableData:res.data
              })
          }else{
              message.info(res.message)
          }
      }).catch(err => {
          message.error(err.message);
      })
  }
  getExportCurrentExcel = ()=>{
    const { ticketLedgerId  } = this.props
    queryExportCurrentExcelApi({id:ticketLedgerId}).then(res => {
        if(res.code=='2000'){
            this.setState({
                ExcelData:res.data
            })
        }else{
            message.info(res.message)
        }
    }).catch(err => {
        message.error(err.message);
    })
}
  //导出excel
  handleExportCurrentExcel = () => {
        let { ExcelData } = this.state;
        let sheetFilter = ["ticketToOrgName", "transferAmount", "toBankCnap", "toBankName", "toBankNumber"];
        let option = {};
        option.fileName = '付款明细';
        option.datas = [
            {
                sheetData: ExcelData,
                sheetName: '付款明细',
                sheetFilter: sheetFilter,
                sheetHeader: ['企业名称', '付款金额（元）', '账号', '开户行', '开户行行号'],
                columnWidths: [10,10,10,10,10]
            },
        ];

        var toExcel = new ExportJsonExcel(option); //new
        toExcel.saveExcel(); //保存
    }
    render() { 
        const columns = [
            {
              title: '企业名称',
              dataIndex: 'ticketToOrgName',
              sorter:(a,b)=>{
                if(!a.ticketToOrgName){
                    a.ticketToOrgName=""
                }else if(!b.ticketToOrgName){
                    b.ticketToOrgName=""
                }
                return a.ticketToOrgName.localeCompare(b.ticketToOrgName)
            }
              
            },
            {
              title: '付款金额（元）',
              render: (record) => {
                return <span>{money100(record.transferAmount)}</span>
            },
              sorter:(a,b)=>{
                if(!a.transferAmount){
                    a.transferAmount=""
                }else if(!b.transferAmount){
                    b.transferAmount=""
                }
                return a.transferAmount-b.transferAmount
            },
            },
            {
              title: '账号',
              dataIndex: 'toBankCnap',
              sorter:(a,b)=>{
                if(!a.toBankCnap){
                    a.toBankCnap=""
                }else if(!b.toBankCnap){
                    b.toBankCnap=""
                }
                return a.toBankCnap-b.toBankCnap
            },
            },
            {
                title: '开户行',
                dataIndex: 'toBankName',
                sorter:(a,b)=>{
                    if(!a.toBankName){
                        a.toBankName=""
                    }else if(!b.toBankName){
                        b.toBankName=""
                    }
                    return a.toBankName.localeCompare(b.toBankName)
                }
            },
            {
                title: '开户行行号',
                dataIndex: 'toBankNumber',
                sorter:(a,b)=>{
                    if(!a.toBankNumber){
                        a.toBankNumber=""
                    }else if(!b.toBankNumber){
                        b.toBankNumber=""
                    }
                    return a.toBankNumber-b.toBankNumber
                },
            }
          ];
        const { tableData } = this.state  
        return ( 
            <>
                <div className='ah-box'>
                    <div style={{height:'36px'}}>
                        <h1 style={{float:'left',margin:'4px 36px 36px 0'}}>付款明细</h1>
                        <Button type='primary' onClick={this.handleExportCurrentExcel} style={{float:'left'}}>导出明细</Button>
                    </div>
                    <Table 
                      bordered 
                      columns={columns} 
                      dataSource={tableData}  
                      pagination={false}
                      />
                </div>
            </>
         );
    }
}
 
export default PaymoneyDetail;
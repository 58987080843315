import React, { Component } from 'react';
class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
                <div className="result-box">
                    <h1>恭喜您企业注册成功！</h1>
                    <p>您可以先“账户管理”去开通交易渠道，方便后续业务操作。</p>
                </div>
            </>
         );
    }
}
 
export default Result;
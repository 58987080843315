import React, { Component } from 'react';
import { Form, Input, message, Modal, Select, TreeSelect, Button, Table } from "antd";
import { checkPhone } from "../../../utils/formatCheck";
import scfBaseApi from "../../../request/api/scfBaseApi";
import AddCompanyModal from "./addCompanyModal"
const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_CHILD } = TreeSelect;
class AddModal extends Component {

    constructor(props) {
        super(props);
        this.addCompany = React.createRef();
        this.state = {
            visible: true,
            companyList: [],  //企业列表
            departList: [],  //部门列表
            roleList: [],
            systemArr: [],
            systemType: "1",  //1-客户端  2-运营端,
            userMobile: "",
            orgName: "",
            tableData: [
                //   {
                //     orgName:"111",
                //     departNames:"222",
                //     systemName:"333",
                //     roleName:"444,555,666"
                //    },
                //    {
                //     orgName:"111",
                //     departNames:"222",
                //     systemName:"333",
                //     roleName:"444,555,666"
                //    }
            ],
            orgDepartIds: [],
            roles: [],
            addCompanyModal: false,//添加企业弹框
            systemId: "",
            systemName: "",
            id: "",
            count: 0
        }
    }

    componentDidMount() {
        let { tableData, count, orgDepartIds, systemId } = this.state
        let { isAdd, userId , systemIda } = this.props;
        if (isAdd === false) {
            scfBaseApi.userQueryApi({ id: userId , systemId:systemIda}).then(res => {
                let data = res.data;

                this.setState({
                    systemId: data.systemId,
                    systemName: data.systemName
                })



                data.orgList.map(item => {

                    let departNameArr = item.departs.map(val => val.departName);
                    let departName = departNameArr.join();



                    let roleNameArr = item.roleOrgList.map(val => val.roleName)
                    let roleOrgList = roleNameArr.join()
                    count = count + 1
                    tableData.push({
                        id: count,
                        systemName: data.systemName,
                        orgName: item.orgName,
                        departs: departName,
                        roleOrgList: roleOrgList
                    })


                    orgDepartIds.push({
                        orgId: item.id,
                        departIds: item.departs.map(val => val.id),
                        systemId: data.systemId,
                        roles: item.roleOrgList.map(val => val.id)

                    })
                })
                this.setState({
                    tableData,
                    orgDepartIds,
                    count,
                    userMobile: data.userMobile,
                })
                this.props.form.setFieldsValue({

                    userName: data.userName,
                    userEmail: data.userEmail
                });
            }).catch(err => {
                message.error(err.message);
            })
        }
    }
    // getSystemQueryAll = () => {
    //     scfBaseApi.systemQueryAllApi().then(res => {
    //         let data = res.data;
    //         this.setState({
    //             systemArr: data
    //         })
    //     }).catch(err => {
    //         message.error(err.message);
    //     })
    // }

    handleOk = (e) => {
        e.preventDefault();
        let { isAdd, userId } = this.props;
        let idJson = JSON.parse(localStorage.getItem("idObject"))
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { isAdd, userId } = this.props;
                let { tableData, systemId, id, orgDepartIds, } = this.state;
                if(tableData.length==0){
                    message.info("请先添加企业")
                    return
                }
                // let apiName=isAdd===true?"userAddApi":"userUpdateApi";
                if (isAdd === true) {
                    let { userMobile, userName, userEmail } = values
                    let params = {
                        userMobile,
                        userName,
                        userEmail,
                        orgDepartIds,

                    }
                    // if(isAdd!==true){
                    //     values.id=userId
                    // }
                    //    if(systemType==="1"){
                    //        values.orgName=orgName;
                    //    }
                    scfBaseApi.userAddApi(params).then(res => {
                        message.success(res.message);
                        this.props.operationOk();
                    }).catch(err => {
                        message.error(err.message);
                    })
                } else {
                    let { userMobile, userName, userEmail } = values
                    let { orgDepartIds } = this.state
                    let { userId } = this.props
                    let params = {
                        id: userId,
                        userName,
                        userEmail,
                        orgDepartIds,

                    }
                    // if(isAdd!==true){
                    //     values.id=userId
                    // }
                    //    if(systemType==="1"){
                    //        values.orgName=orgName;
                    //    }
                    scfBaseApi.userUpdateApi(params).then(res => {
                        message.success(res.message);
                        this.props.operationOk();
                    }).catch(err => {
                        message.error(err.message);
                    })
                }

            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });
    }
    // changeSystemId = (value, e) => {
    //     this.setState({
    //         systemType: e.props.systemtype
    //     });
    //     this.props.form.resetFields(["roles"]);
    //     this.getRoleList(value);
    //     if (e.props.systemtype === "1") {
    //         this.props.form.resetFields(["orgId", "depart"]);
    //         this.setState({ departList: [] });
    //         this.getCompanyList(value);
    //     }
    // }
    // // 获取企业列表
    // getCompanyList = (value) => {
    //     scfBaseApi.orgQueryApi({ id: value }).then(res => {
    //         let data = res.data;
    //         this.setState({
    //             companyList: data
    //         })
    //     }).catch(err => {
    //         message.error(err.message);
    //     })
    // }
    // //获取角色列表
    // getRoleList = (value) => {
    //     scfBaseApi.roleQueryApi({ id: value }).then(res => {
    //         let data = res.data;
    //         this.setState({
    //             roleList: data
    //         })
    //     }).catch(err => {
    //         message.error(err.message);
    //     })
    // }
    // //获取部门列表
    // changeOrgId = (value, e, isCheckOrg) => {
    //     if (isCheckOrg === true) {
    //         this.setState({ orgName: e.props.orgname });
    //     }
    //     scfBaseApi.departQueryApi({ id: value }).then(res => {
    //         let data = res.data;
    //         this.setState({
    //             departList: data,
    //         })
    //     }).catch(err => {
    //         message.error(err.message);
    //     })
    // }
    //添加企业弹框出现
    AddCompanyShow = () => {
        this.setState({
            addCompanyModal: true
        })

    }
    //添加企业弹框消失
    handleAddCompany = () => {
        this.setState({
            addCompanyModal: false
        })
    }
    //存储添加企业信息
    saveMessage = () => {
        let { tableData, orgDepartIds, count } = this.state
        let obj = JSON.parse(localStorage.getItem("obj"))
        let idJson = JSON.parse(localStorage.getItem("idObject"))
        count = count + 1
        this.setState({
            count: count
        }, () => {
            obj.id = this.state.count
            if (obj.roleOrgList !== null) {
                obj.roleOrgList = obj.roleOrgList.join()
            }
            if (obj.departs !== undefined) {
                obj.departs = obj.departs.join()
            }


            tableData.push(obj)
            this.setState({
                tableData,
            })
        })
        orgDepartIds.push(idJson)




        // orgDepartIds.push(idJson)
        // this.setState({
        //     orgDepartIds,
        // })

        // roleArr.map(item => {
        //     roles.push(item)
        // })

        // this.setState({
        //     roles,
        // })


    }

    delete = (id) => {
        console.log(id)
        let { tableData, orgDepartIds } = this.state
        let index = tableData.findIndex((item) => item.id === id)
        tableData.splice(index, 1)
        orgDepartIds.splice(index, 1)
        this.setState({
            tableData,
        })
        this.setState({
            orgDepartIds
        })
    }
    render() {
        let { getFieldDecorator } = this.props.form;
        let { isAdd } = this.props;
        let { tableData, addCompanyModal } = this.state;
        let { visible, systemType, companyList, departList, roleList, systemArr, userMobile } = this.state;
        let newData = JSON.parse(JSON.stringify(departList).replace(/id/g, 'value').
            replace(/departName/g, 'title')
        )
        let addCompanyprops = {
            systemId: this.state.systemId,
            systemName: this.state.systemName,
            isAdd: this.props.isAdd
        }
        let formItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            },
            labelAlign: 'right'
        };
        let columns = [

            {
                title: '所属企业名称',
                dataIndex: 'orgName',
                key: 'orgName',

            },
            {
                title: '所属企业部门',
                dataIndex: 'departs',
                key: 'departs',


            },
            {
                title: '所属系统',
                dataIndex: 'systemName',
                key: 'systemName',

            },
            {
                title: '角色',
                dataIndex: 'roleOrgList',
                key: 'roleOrgList',


            },
            {
                title: '操作',
                render: (record) => {
                    console.log(record)
                    return <div className="flex-around-center">
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.delete(record.id)
                        }}>删除</Button>
                    </div>

                }
            },
        ];
        let tProps = {
            treeData: newData,
            onChange: this.onDepartChange,
            treeCheckable: true,
            listHeight: 80,
            showCheckedStrategy: SHOW_CHILD,
            style: { width: '100%', },
        }
        return (
            <div>
                {/* // 新增用户/用户编辑弹框 */}
                <Modal
                    title={isAdd === true ? "新增用户" : "用户编辑"}
                    visible={visible}
                    width="800px"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    maskClosable={false}
                    okText={isAdd === true ? "确认新增" : "确认修改"}
                >
                    <Form onSubmit={this.handleOk} {...formItem}>
                        {
                            isAdd === true ? <FormItem label="登录账号（手机号）">
                                {
                                    getFieldDecorator('userMobile', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入登录账号'
                                            },
                                            { validator: checkPhone }
                                        ],
                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input />)
                                }
                            </FormItem> : <FormItem label="登录账号(手机号)">{userMobile}</FormItem>
                        }
                        {/* {
                        isAdd===true?<FormItem label="所属系统">
                            {
                                getFieldDecorator('systemId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择所属系统'
                                        },
                                    ],
                                    validateTrigger: "onBlur",
                                    initialValue: "",
                                })(<Select onChange={this.changeSystemId}>
                                    {
                                        systemArr.length !== 0 ? systemArr.map(record => {
                                            return <Option key={record.id} value={record.id} systemtype={record.systemType}> {record.systemName} </Option>
                                        }) : null
                                    }
                                </Select>)
                            }
                        </FormItem>:null
                    } */}
                        <FormItem label="用户姓名">
                            {
                                getFieldDecorator('userName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入姓名'
                                        },
                                        {
                                            max: 50,
                                            message: "最大长度为50位,请重新输入"
                                        }],
                                    initialValue: "",
                                    validateTrigger: "onBlur"
                                })(<Input />)
                            }
                        </FormItem>
                        <FormItem label="邮箱">
                            {
                                getFieldDecorator('userEmail', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: '请输入正确的邮箱地址'
                                        }
                                    ],
                                    initialValue: "",
                                    validateTrigger: "onBlur"
                                })(<Input />)
                            }
                        </FormItem>
                        {/* {systemType==="1"?<FormItem label="所属企业">
                        {
                            getFieldDecorator('orgId', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属企业'
                                    },
                                ],
                                initialValue: "",
                            })(<Select onChange={(val,e)=>this.changeOrgId(val,e,true)}>
                                {
                                    companyList.length>0 ? companyList.map(record => {
                                        return <Option key={record.id} value={record.id} orgname={record.orgName}> {record.orgName} </Option>
                                    }) : null
                                }
                            </Select>)
                        }
                    </FormItem>:null
                    } */}
                        {/* {
                        systemType==="1"? <FormItem label="所属部门">
                            {
                                getFieldDecorator('depart', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择所属部门'
                                        },
                                    ],
                                })(<TreeSelect {...tProps}/>)
                            }
                        </FormItem>:null
                    } */}

                        {/* <FormItem label="角色">
                        {
                            getFieldDecorator('roles', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择角色'
                                    },
                                ],
                            })(<Select mode="multiple">
                                    {
                                        roleList.length>0?roleList.map(record => {
                                            return <Option key={record.id} value={record.id}> {record.roleName} </Option>
                                        }) : null
                                    }
                                </Select>)


                        }
                    </FormItem> */}
                    </Form>
                    <div>
                        <div>

                            <div style={{ marginTop: 30, marginBottom: 30 }}>
                                <Button type="primary" className="com-middle-btn-size" onClick={this.AddCompanyShow}>添加企业</Button>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowKey={(record, index) => index}
                            pagination={false}
                        />
                    </div>

                </Modal>

                {addCompanyModal ? <AddCompanyModal {...addCompanyprops} handleAddCompany={this.handleAddCompany} saveMessage={this.saveMessage}></AddCompanyModal> : null}

            </div>
        );
    }
}

export default Form.create()(AddModal);
import React, {Component} from 'react';
import {Button, Select, Row, Col, message} from "antd";
import { setStorage } from "@/utils/tools";
import {withRouter, Link} from "dva/router";
import scfBaseApi from "../../request/api/scfBaseApi";
import "./index.scss";

const Option = Select.Option;

@withRouter
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            businessRoles: [],
            companyId: "",
        }
    }

    componentDidMount() {
        this.getUserOrgAndRole();

    }

    //获取用户下的机构列表和机构下的业务角色
    getUserOrgAndRole = () => {
        scfBaseApi.getUserOrgsApi().then(res => {
            let data = res.data;
            this.setState({
                companyList: data
            });
            if (data.length === 1) {
                this.handleSelectChange(data[0].id);
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleSelectChange = (val) => {
        let {companyList} = this.state;
        let businessRoles = companyList.filter(item => item.id === val)[0].businessRoles;
        let companyName = companyList.filter(item=> item.id === val)[0].orgName
        this.setState({businessRoles, companyId: val});
        setStorage('companyID',val);
        setStorage('companyName', companyName);

    };
    handleCheckRole = (role) => {
        let {companyId} = this.state;
        let params = {
            userCurrentRole: role,
            currentOrgId: companyId
        }
        scfBaseApi.setCurrentUserApi(params).then(res => {
            message.success(res.message);
            setTimeout(() => {
                if(role=='operate'){
                    this.props.history.push('/admin/home');
                }else{
                    this.props.history.push('/admin/homeClient');
                }
            }, 1000);
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleReturn = () => {
        localStorage.clear();
        this.props.history.push({pathname: '/login'});
    }
    addCompanyRouter = ()=>{
        this.props.history.push('/companyInfoEntry');
    }
    render() {
        let {companyList, businessRoles, companyId} = this.state;
        return (
            <div className='business-role-select-wrapper'>
                <div className='business-role-select-content'>
                    <h5 className="com-text-center com-h5-size com-h5-height"><b>请您选择业务角色</b></h5>
                    <h6 className="com-text-center second-title"><b>不同的业务角色可以使用平台的业务不同</b></h6>
                    <div>
                        <Row gutter={16}>
                            <div className="checkout-company">
                                <label style={{fontSize: 14, marginLeft: 0}}>请选择企业:</label>
                                <Select
                                    placeholder ='请选择企业'
                                    onChange={this.handleSelectChange}
                                    className="select-option"
                                    defaultValue={companyId}
                                    value={companyId}
                                >
                                    {
                                        companyList.map(record => {
                                            return <Option key={record.id} value={record.id}> {record.orgName} </Option>
                                        })
                                    }
                                </Select>
                                {/* <Button type='primary' style={{marginLeft:'20px'}} onClick={this.addCompanyRouter}>新增企业</Button> */}
                            </div>
                            {businessRoles.length > 0 ?
                                businessRoles.map((record, index) => {
                                    return <Col key={index} className="gutter-row" span={8}>
                                        <div className="gutter-box">
                                            <div className="account-box com-shadow-radius">
                                                <h5 className="com-text-center com-h5-size com-h5-height">
                                                    <b>{record.businessRoleName}</b></h5>
                                                <ul>
                                                    <li>
                                                        <span>可使用功能</span>
                                                    </li>
                                                    <li>
                                                        <span>1.应收账款融资</span>
                                                    </li>

                                                    <li>
                                                        <span>2.票据所有功能</span>
                                                    </li>
                                                </ul>
                                                <div className="com-text-center check-btn-wapper">
                                                    <Button type="primary" className="check-btn"
                                                            onClick={() => this.handleCheckRole(record.businessRole)}>选择</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                })
                                : null
                            }
                        </Row>
                    </div>
                    <div className="com-only-bun">
                            <Button type="default" className="com-middle-btn-size"
                                    onClick={this.handleReturn}>返回</Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default Index;



import React, { Component } from 'react';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table, message, Radio ,Tabs , InputNumber , Typography ,AutoComplete  } from "antd";
import { withRouter } from 'dva/router';
import { getStorage } from "@/utils/tools";
import { queryRelationsInfoApi , queryCompanyBankInfoApi , querygetAddBussinessIssueApi , querypayMoneyGetDetailApi ,getQuotaStatusApi } from  '../../../request/api/quota'
import moment from 'moment';
import url from 'url'
import { isEmpyt  } from '../../../utils/formatCheck'
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
var nzhcn = require("nzh/cn"); //直接使用简体中文
@withRouter

class AddIssuse extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            companyID: "",
            companyName: "",
            isFalg:true,
            relationsData:{},
            account:[],
            companyBankData:[],
            type:1,
            data:{},
            flag:false,
            ticketTransStatusCd:[],
            id:url.parse(this.props.location.search,true).query.id,
            value: '',
            dataSource: [],
            valuepayee:'',
            dataSourcepayee:[],
            dataSourcepayeeAccountNo:[],
            valueacceptor:'',
            dataSourceacceptor:[],
            dataSourceacceptorAccountNo:[],
            loading:false


        }
    }
    componentDidMount(){
        const { id } = this.state
        if(!isEmpyt(id)){
            this.setState({
                flag:true
            })
            this.getInitDetail(id)
        }
        this.setState({
            companyID: getStorage("companyID"),
            companyName: getStorage("companyName"),
        });
        this.getRelationsInfo()
        this.getCompanyBankInfo()
        this.getTicketInfo()
    }
    getTicketInfo = ()=>{
        getQuotaStatusApi({dicType:'ticketStatusCd'}).then(res => {
            if(res.code=='2000'){
                if(res.data.length>0){
                    this.setState({
                        ticketTransStatusCd:res.data
                    })
                }
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getInitDetail = (id)=>{
        querypayMoneyGetDetailApi({id}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    data:res.data,
                    type:res.data.type,
                    ticketStatusCd:res.data.ticketStatusCd
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getRelationsInfo = ()=>{
        queryRelationsInfoApi().then(res => {
            if(res.code=='2000'){
                if( Object.keys(res.data).length!=0){
                    let datapayee = res.data.payee
                    let dataacceptor = res.data.acceptor
                    let arrpayee = []
                    let arracceptor = []
                    datapayee.map(item=>{
                        arrpayee.push(item.account[0].name)
                    })
                    dataacceptor.map(item=>{
                        arracceptor.push(item.account[0].name)
                    })
                    this.setState({
                        relationsData:res.data,
                        valuepayee:datapayee.name,
                        valueacceptor:dataacceptor.name,
                        dataSourcepayee:arrpayee,
                        dataSourceacceptor:arracceptor
                    })
                }
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getCompanyBankInfo = ()=>{
        queryCompanyBankInfoApi().then(res => {
            if(res.code=='2000'){
                let data = res.data
                if(data.length>0){
                    let arr = []
                    data.map(item=>{
                        arr.push(item.accountNo)
                    })
                    this.setState({
                        companyBankData:res.data,
                        value:res.data[0].accountNo,
                        dataSource:arr
                    })
                }
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        const { drawerRatingLevelExpireDate ,acceptorRatingLevelExpireDate, ticketExpireDateName,drawerPledgeDate,acceptorPledgeDate,drawerOrgId,payeeOrgId, acceptorOrgId , type , flag , data , id , ticketStatusCd } = this.state
        this.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            if(type==2){
                values.drawerPledgeDate = drawerPledgeDate
                values.acceptorPledgeDate = acceptorPledgeDate
            }
            values.type = type
            values.drawerRatingLevelExpireDate = drawerRatingLevelExpireDate
            values.ticketExpireDate = ticketExpireDateName
            values.acceptorRatingLevelExpireDate = acceptorRatingLevelExpireDate
            values.ticketStatusCd = ticketStatusCd
            if(flag){
                values.drawerOrgId = data.drawerOrgId
                values.payeeOrgId = data.payeeOrgId
                values.acceptorOrgId = data.acceptorOrgId
                values.id = id
            }else{
                values.drawerOrgId = drawerOrgId
                values.payeeOrgId = payeeOrgId
                values.acceptorOrgId = acceptorOrgId
            }
            console.log(JSON.stringify(values))
            this.setState({loading:true})
            this.getAddBussinessIssue(values)
          }
        });
    };
    getAddBussinessIssue = (values)=>{
        querygetAddBussinessIssueApi(values).then(res => {
            this.setState({loading:false})
            if(res.code=='2000'){
                this.props.history.push(`/admin/businessTicketIssue/issueShanjian`)
                message.success(res.message)
            }else{
                message.info(res.data)
            }
        }).catch(err => {
            this.setState({loading:false})
            message.error(err.message);
        })
    }
    onChangeTwo = (date)=>{
        this.setState({
            drawerRatingLevelExpireDate:moment(date).format('YYYY-MM-DD')
        })
    }
    onChangeOne = (date)=>{
        this.setState({
            acceptorRatingLevelExpireDate:moment(date).format('YYYY-MM-DD')
        })
    }
    onChangeTicket = (date)=>{
        this.setState({
            ticketExpireDateName:moment(date).format('YYYY-MM-DD')
        })
    }
    disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };
    drawerPledgeDateChange = (date)=>{
        this.setState({
            drawerPledgeDate:moment(date).format('YYYY-MM-DD')
        })
    }
    acceptorPledgeDateChange = (date)=>{
        this.setState({
            acceptorPledgeDate:moment(date).format('YYYY-MM-DD')
        })
    }

    //出票人
    handleSelectDrawerNameChange = (val, e) => {
        this.setState({
            drawerOrgId:e.props.orgId
        })
        this.props.form.setFieldsValue({ 
            drawerBank: e.props.bankName,
            drawerBankNo:e.props.bankNumber
         });
    }  
      //收款人
    handleSelectBankChange = (val, e) => {
        this.setState({
            payeeOrgId:e.props.orgId
        })
        this.props.form.setFieldsValue({ 
            payeeBank: e.props.bankName,
            payeeBankNo:e.props.bankNumber
         });
    }
    handleSelectChange = (val, e) => {
        this.props.form.setFieldsValue({ 
            payeeAccountNo:'',
            payeeBank:'',
            payeeBankNo:''
        });
        this.setState({
            account: e.props.account
        })
      }
    //承兑人
    handlAcceptorNameChange = (val, e) => {
        this.setState({
            acceptorOrgId:e.props.orgId
        })
        this.props.form.setFieldsValue({ 
            acceptorBank: e.props.bankName,
            acceptorBankNo:e.props.bankNumber
         });
    }
    handleSelectAnChange = (val, e) => {
        this.props.form.setFieldsValue({ 
            acceptorAccountNo:'',
            acceptorBank:'',
            acceptorBankNo:''
        });
        this.setState({
            accountAnList: e.props.accountAnList
        })
      }  



    onChangeRadio = e => {
        let calendar = moment().add(e.target.value, 'months').calendar()
        let calendara = calendar.replace('/','-')
        let calendarb = calendara.replace('/','-')
        this.setState({
            ticketExpireDateName:moment(calendarb).format('YYYY-MM-DD') 
        })
        this.props.form.setFieldsValue({
            ticketExpireDate:moment(calendarb)
        })
      };
    callback = (key)=>{
        if(key==1){
            this.setState({
                isFalg:true,
                type:1
            })
        }else{
            this.setState({
                isFalg:false,
                type:2
            })
        }
    }  
    MoneyChange = (value)=>{
        this.props.form.setFieldsValue({ bigAmount:nzhcn.toMoney(value,{outSymbol:false}) });
    }

    handleFormReset = ()=>{
        window.history.go(-1)
    }
    handleChange = (value)=>{
        console.log(`selected ${value}`);
        this.setState({
            ticketStatusCd:value
        })
    }
    
    onChangedrawer = value => {
        this.setState({ value ,drawerOrgId:null });
    };
    onChangeDrawerBank = (value)=>{
        this.setState({
            drawerBank:value
        })
    }
    onChangeDrawerBankNo = (value)=>{
        this.setState({
            drawerBankNo:value
        })
    }
    onSelect=(value)=>{
        const { companyBankData } = this.state
        companyBankData.map((item,index)=>{
            if(item.accountNo==value){
                console.log(item.orgId)
                this.setState({
                    drawerOrgId:item.orgId
                })
                this.props.form.setFieldsValue({ 
                    drawerBank: item.bankName,
                    drawerBankNo:item.bankNumber
                 });
            }
        })
        
    }    

    onSelectpayee=(value)=>{
        const { relationsData } = this.state
        relationsData.payee.map((item,index)=>{
            if(item.name==value){
                let account = item.account
                let arr = []
                account.map(item=>{
                    arr.push(item.accountNo) 
                })
                this.props.form.setFieldsValue({ 
                    payeeAccountNo:'',
                    payeeBank:'',
                    payeeBankNo:''
                });
                this.setState({
                    dataSourcepayeeAccountNo:arr,
                    account: item.account
                })
            }
        })
    }

    onChangepayeeName = (value)=>{
        this.props.form.setFieldsValue({ 
            payeeName:value
        });
    }


    onSelectpayeeAccountNo=(value)=>{
        const { account } = this.state
        account.map((item,index)=>{
            if(item.accountNo==value){
                this.setState({
                    payeeOrgId:item.orgId
                })
                this.props.form.setFieldsValue({ 
                    payeeBank: item.bankName,
                    payeeBankNo:item.bankNumber
                 });
            }
        })
    }

    onChangepayeeAccountNo = (value)=>{
        this.props.form.setFieldsValue({ 
            payeeOrgId:null
        });
    }
    onChangepayeeBank = (value)=>{
        this.props.form.setFieldsValue({ 
            payeeBank: value
         });
    }
    onChangepayeeBankNo = (value)=>{
        this.props.form.setFieldsValue({ 
            payeeBankNo:value
         });
    }


    onSelectacceptor=(value)=>{
        const { relationsData } = this.state
        relationsData.acceptor.map((item,index)=>{
            if(item.name==value){
                let account = item.account
                let arr = []
                account.map(item=>{
                    arr.push(item.accountNo) 
                })
                this.props.form.setFieldsValue({ 
                    acceptorAccountNo:'',
                    acceptorBank:'',
                    acceptorBankNo:''
                });
                this.setState({
                    dataSourceacceptorAccountNo:arr,
                    accountAnList: item.account
                })
            }
        })
    }

    onChangeacceptor = (value)=>{
        this.props.form.setFieldsValue({ 
            acceptorName:value
        });
    }



    onSelectacceptorAccountNo=(value)=>{
        const { accountAnList } = this.state
        accountAnList.map((item,index)=>{
            if(item.accountNo==value){
                this.setState({
                    acceptorOrgId:item.orgId
                })
                this.props.form.setFieldsValue({ 
                    acceptorBank: item.bankName,
                    acceptorBankNo:item.bankNumber
                 });
            }
        })
    }

    onChangeacceptorAccountNo = (value)=>{
        this.props.form.setFieldsValue({ 
            acceptorOrgId:null
        });
    }


    onChangeacceptorBank = (value)=>{
        this.props.form.setFieldsValue({ 
            acceptorBank: value
         });
    }
    onChangeacceptorBankNo = (value)=>{
        this.props.form.setFieldsValue({ 
            acceptorBankNo:value
         });
    }
    render() { 
        const { getFieldDecorator } = this.props.form;
        const { isFalg , companyName , relationsData ,account , companyBankData , accountAnList , flag , data , ticketTransStatusCd ,value,  dataSource ,valuepayee , dataSourcepayee , dataSourcepayeeAccountNo , valueacceptor , dataSourceacceptor ,dataSourceacceptorAccountNo ,loading } = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        return ( 
            <>
                <div  className='addIssue-box'>
                    <div className='addIssue-contant'>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{margin:"20px 0 60px 0"}}>
                            <Title level={3}>票据信息</Title>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="当前票据状态">
                                        {getFieldDecorator('ticketStatusCd', {
                                            initialValue: data.ticketStatusCd,
                                            rules: [{ required: true, message: '请选择票据状态!' }],
                                        })(
                                            <Select placeholder="请选择票据状态"  onChange={this.handleChange}>
                                            {
                                                    ticketTransStatusCd.map((item,index)=>{
                                                        return <Option key={index} value={item.dicKey}>{item.dicValue}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="票据号码">
                                        {getFieldDecorator('ticketNo', {
                                            initialValue: data.ticketNo,
                                            rules: [{ required: true, message: '请输入票据号码!' }],
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left'>
                                            出票人信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <div className='addIssue-right'>
                                            <Form.Item label="全称">
                                                {getFieldDecorator('drawerName', {
                                                    initialValue: companyName
                                                })(<Input />)}
                                            </Form.Item>
                                            <Form.Item label="账号">
                                                {
                                                    getFieldDecorator('drawerAccountNo', {
                                                        initialValue: data.drawerAccountNo,
                                                        rules: [{ required: true, message: '请输入账号!' }],
                                                    })(
                                                        // <Select 
                                                        // name='drawerAccountNo' 
                                                        // showSearch 
                                                        // disabled={flag}  
                                                        // onChange={(val, e) => this.handleSelectDrawerNameChange(val, e)} 
                                                        // >
                                                        //     {
                                                        //         companyBankData && companyBankData.length 
                                                        //         ? companyBankData.map((item, index)=> <Option orgId ={item.orgId} bankName = {item.bankName} bankNumber={item.bankNumber} key={item.orgId} value={item.accountNo}>{item.accountNo}</Option>)
                                                        //         : null
                                                        //     }
                                                        // </Select>
                                                        <AutoComplete
                                                            value={value}
                                                            dataSource={dataSource}
                                                            onSelect={this.onSelect}
                                                            onChange={this.onChangedrawer}
                                                            placeholder="请输入或选择账号"
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="开户行">
                                                {getFieldDecorator('drawerBank', {
                                                    initialValue: data.drawerBank,
                                                    rules: [{ required: true, message: '请输入开户行!' }],
                                                })(<Input onChange={this.onChangeDrawerBank}/>)}
                                            </Form.Item>
                                            <Form.Item label="开户行号">
                                                {getFieldDecorator('drawerBankNo', {
                                                    initialValue: data.drawerBankNo,
                                                    rules: [{ required: false, message: '请输入开户行号!' }],
                                                })(<Input onChange={this.onChangeDrawerBankNo}/>)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Col>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left'>
                                            收款人信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <div className='addIssue-right'>
                                            <Form.Item label="全称">
                                                {
                                                    getFieldDecorator('payeeName', {
                                                        initialValue: data.payeeName,
                                                        rules: [{ required: true, message: '请输入或选择全程!' }],
                                                    })(
                                                        // <Select name='drawerAccountNo' disabled={flag}   onChange={(val, e) => this.handleSelectChange(val, e)}>
                                                        //     {
                                                        //         relationsData.payee && relationsData.payee.length 
                                                        //         ? relationsData.payee.map((item, index)=> <Option key={index} orgId ={item.orgId} account={item.account}  value={item.name}>{item.name}</Option>)
                                                        //         : null
                                                        //     }
                                                        // </Select>
                                                        <AutoComplete
                                                            value={valuepayee}
                                                            dataSource={dataSourcepayee}
                                                            onSelect={this.onSelectpayee}
                                                            onChange={this.onChangepayeeName}
                                                            placeholder="请输入或选择账号"
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="账号">
                                                {
                                                    getFieldDecorator('payeeAccountNo', {
                                                        initialValue: data.payeeAccountNo,
                                                        rules: [{ required: true, message: '请选择开户行!' }],
                                                    })(
                                                        // <Select name='payeeAccountNo' disabled={flag}  onChange={(val, e) => this.handleSelectBankChange(val, e)}>
                                                        //     {
                                                        //         account && account.length 
                                                        //         ? account.map((item, index)=> <Option bankName={item.bankName} orgId ={item.orgId} bankNumber={item.bankNumber} key={index} value={item.accountNo}>{item.accountNo}</Option>)
                                                        //         : null
                                                        //     }
                                                        // </Select>
                                                        <AutoComplete
                                                            dataSource={dataSourcepayeeAccountNo}
                                                            onSelect={this.onSelectpayeeAccountNo}
                                                            onChange={this.onChangepayeeAccountNo}
                                                            placeholder="请输入或选择账号"
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="开户行">
                                                {getFieldDecorator('payeeBank', {
                                                    initialValue: data.payeeBank,
                                                    rules: [{ required: true, message: '请输入开户行!' }],
                                                })(<Input onChange={this.onChangepayeeBank}/>)}
                                            </Form.Item>
                                            <Form.Item label="开户行号">
                                                {getFieldDecorator('payeeBankNo', {
                                                    initialValue: data.payeeBankNo,
                                                    rules: [{ required: false, message: '请输入开户行号!' }],
                                                })(<Input  onChange={this.onChangepayeeBankNo}/>)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left'>
                                            票据信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <div className='addIssue-right'>
                                            <Form.Item label="票据金额小写">
                                                {getFieldDecorator('ticketAmount', {
                                                    initialValue:data.ticketAmount,
                                                    rules: [{ required: true, message: '请输入票据金额小写!' }],
                                                })(<InputNumber  min={0.01} style={{width:'100%'}} onChange={this.MoneyChange}/>)}
                                            </Form.Item>
                                            <Form.Item label="票据金额大写">
                                                {getFieldDecorator('bigAmount', {
                                                    initialValue: data.bigAmount,
                                                    rules: [{ required: false, message: '请输入票据金额大写!' }],
                                                })(<Input disabled/>)}
                                            </Form.Item>
                                            <Form.Item label="汇票到期日">
                                                {getFieldDecorator('ticketExpireDate', {
                                                    initialValue:JSON.stringify(data) == "{}"?'':moment(data.ticketExpireDate),
                                                    rules: [{ required: true, message: '请选择汇票到期日!' }],
                                                })(  <DatePicker disabledDate={this.disabledDate} onChange={this.onChangeTicket} />)}
                                            </Form.Item>
                                            <Radio.Group onChange={this.onChangeRadio} style={{marginLeft:'20px'}}>
                                                <Radio value={1}>一个月</Radio>
                                                <Radio value={3}>三个月</Radio>
                                                <Radio value={6}>六个月</Radio>
                                                <Radio value={12}>十二个月</Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Col>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left'>
                                            承兑人信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <div className='addIssue-right'>
                                            <Form.Item label="全称">
                                                {
                                                    getFieldDecorator('acceptorName', {
                                                        initialValue: data.acceptorName,
                                                        rules: [{ required: true, message: '请选择承兑人全称!' }],
                                                    })(
                                                        // <Select name='acceptorName' disabled={flag}    onChange={(val, e) => this.handleSelectAnChange(val, e)}>
                                                        // {
                                                        //     relationsData.acceptor && relationsData.acceptor.length 
                                                        //     ? relationsData.acceptor.map((item, index)=> <Option key={index} accountAnList={item.account}  value={item.name}>{item.name}</Option>)
                                                        //     : null
                                                        // }
                                                        // </Select>
                                                        <AutoComplete
                                                            value={valueacceptor}
                                                            dataSource={dataSourceacceptor}
                                                            onSelect={this.onSelectacceptor}
                                                            onChange={this.onChangeacceptor}
                                                            placeholder="请输入或选择账号"
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="账号">
                                                {
                                                    getFieldDecorator('acceptorAccountNo', {
                                                        initialValue: data.acceptorAccountNo,
                                                        rules: [{ required: true, message: '请选择账号' }],
                                                    })(
                                                        // <Select name='acceptorAccountNo' disabled={flag} onChange={(val, e) => this.handlAcceptorNameChange(val, e)}>
                                                        // {
                                                        //     accountAnList && accountAnList.length 
                                                        //     ? accountAnList.map((item, index)=> <Option orgId ={item.orgId} bankName={item.bankName} bankNumber={item.bankNumber} key={index} value={item.accountNo}>{item.accountNo}</Option>)
                                                        //     : null
                                                        // }
                                                        // </Select>
                                                        <AutoComplete
                                                        dataSource={dataSourceacceptorAccountNo}
                                                        onSelect={this.onSelectacceptorAccountNo}
                                                        onChange={this.onChangeacceptorAccountNo}
                                                        placeholder="请输入或选择账号"
                                                    />
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="开户行">
                                                {getFieldDecorator('acceptorBank', {
                                                    initialValue: data.acceptorBank,
                                                    rules: [{ required: true, message: '请输入开户行!' }],
                                                })(<Input onChange={this.onChangeacceptorBank}/>)}
                                            </Form.Item>
                                            <Form.Item label="开户行号">
                                                {getFieldDecorator('acceptorBankNo', {
                                                    initialValue: data.acceptorBankNo,
                                                    rules: [{ required: false, message: '请输入开户行号!' }],
                                                })(<Input onChange={this.onChangeacceptorBankNo}/>)}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>


                            <Row>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left' style={{height:'310px',lineHeight:'310px'}}>
                                            出票保证信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <Tabs onChange={this.callback}>
                                            <TabPane tab="账户信息" key="1">
                                                <div className='addIssue-right'>
                                                    <Form.Item label="全称">
                                                        {getFieldDecorator('drawerPledgeName', {
                                                            initialValue: data.drawerPledgeName,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                    <Form.Item label="账号">
                                                        {getFieldDecorator('drawerPledgeAccountNo', {
                                                            initialValue: data.drawerPledgeAccountNo,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                    <Form.Item label="开户行">
                                                        {getFieldDecorator('drawerPledgeBank', {
                                                            initialValue: data.drawerPledgeBank,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                    <Form.Item label="开户行号">
                                                        {getFieldDecorator('drawerPledgeBankNo', {
                                                            initialValue: data.drawerPledgeBankNo,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="地址信息" key="2">
                                                <div className='addIssue-right'>
                                                    <Form.Item label="全称">
                                                        {getFieldDecorator('drawerPledgeName', {
                                                            initialValue: data.drawerPledgeName,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                    <Form.Item label="地址">
                                                        {getFieldDecorator('drawerPledgeAddress', {
                                                            initialValue: data.drawerPledgeAddress,
                                                        })(<Input />)}
                                                    </Form.Item>
                                                    <Form.Item label="保证日期">
                                                        {getFieldDecorator('drawerPledgeDate', {
                                                            initialValue:moment(data.drawerPledgeDate),
                                                            rules: [{ required: false, message: '请选择保证日期!' }],
                                                        })(  <DatePicker onChange={this.drawerPledgeDateChange} />)}
                                                    </Form.Item>
                                                </div>  
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Col>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left' style={{height:'310px',lineHeight:'310px'}}>
                                            承兑保证信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        {
                                                isFalg?<div className='addIssue-right' style={{marginTop:'79px'}}>
                                                <Form.Item label="全称">
                                                    {getFieldDecorator('acceptorPledgeName', {
                                                        initialValue: data.acceptorPledgeName,
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label="账号">
                                                    {getFieldDecorator('acceptorPledgeAccountNo', {
                                                        initialValue: data.acceptorPledgeAccountNo,
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label="开户行">
                                                    {getFieldDecorator('acceptorPledgeBank', {
                                                        initialValue: data.acceptorPledgeBank,
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label="开户行号">
                                                    {getFieldDecorator('acceptorPledgeBankNo', {
                                                        initialValue: data.acceptorPledgeBankNo,
                                                    })(<Input />)}
                                                </Form.Item>
                                            </div>:<div className='addIssue-right' style={{marginTop:'79px'}}>
                                            <Form.Item label="全称">
                                                {getFieldDecorator('acceptorPledgeName', {
                                                    initialValue: data.acceptorPledgeName,
                                                })(<Input />)}
                                            </Form.Item>
                                            <Form.Item label="地址">
                                                {getFieldDecorator('acceptorPledgeAddress', {
                                                    initialValue: data.acceptorPledgeAddress,
                                                })(<Input />)}
                                            </Form.Item>
                                            <Form.Item label="保证日期">
                                                {getFieldDecorator('acceptorPledgeDate', {
                                                    initialValue:moment(data.acceptorPledgeDate),
                                                    rules: [{ required: false, message: '请输入保证日期!' }],
                                                })(  <DatePicker onChange={this.acceptorPledgeDateChange} />)}
                                            </Form.Item>
                                        </div>  
                                        }
                                    </Col>
                                </Col>
                            </Row>



                            <Row>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left' style={{height:'120px',lineHeight:'120px'}}>
                                            承兑信息
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <div className='addIssue-right' style={{paddingLeft:'30px',marginTop:'15px'}}>
                                            <p>出票人承诺： 本汇票请予以承兑，到期无条件付款</p>
                                            <p>承兑人承诺： 本汇票已经承兑，到期无条件付款</p>
                                            <p>承兑日期： --</p>
                                        </div>
                                    </Col>
                                </Col>
                                <Col span={12}>
                                    <div className='addIssue-right'>
                                        <Form.Item label="交易合同号">
                                            {getFieldDecorator('tradeContractNo', {
                                                initialValue: data.tradeContractNo,
                                                rules: [{ required: false, message: '请输入交易合同号!' }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="是否可转让">
                                            {getFieldDecorator('transferTypeCd', {
                                                initialValue:data.transferTypeCd?parseInt(data.transferTypeCd):'',
                                                rules: [{ required: true, message: '请选择是否可转让!' }],
                                            })(<Radio.Group name="transferTypeCd">
                                                <Radio value={1}>可转让</Radio>
                                                <Radio value={2}>不可转让</Radio>
                                            </Radio.Group>)}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>



                            <Row>
                                <Col span={12}>
                                    <Col span={6}>
                                        <div className='addIssue-left'>
                                            评级信息
                                        </div>
                                    </Col>
                                    <Col span={18} className='cread-h2'>
                                        <h2>出票人评级信息</h2>
                                        <Form.Item label="信用等级">
                                            {getFieldDecorator('drawerRatingLevel', {
                                                initialValue: data.drawerRatingLevel,
                                                rules: [{ required: false, message: '请输入信用等级!' }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <h2>承兑人评级信息</h2>
                                        <Form.Item label="信用等级">
                                            {getFieldDecorator('acceptorRatingLevel', {
                                                initialValue: data.acceptorRatingLevel,
                                                rules: [{ required: false, message: '请输入信用等级!' }],
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                </Col>
                                <Col span={12}>
                                    <div className='addIssue-right'>
                                    <h2> &nbsp;&nbsp; </h2>
                                    <Form.Item label="评级到期日">
                                        {getFieldDecorator('drawerRatingLevelExpireDate', {
                                            initialValue:JSON.stringify(data) == "{}"?'':moment(data.drawerRatingLevelExpireDate),
                                            rules: [{ required: false, message: '请选择评级到期日!' }],
                                        })(  <DatePicker onChange={this.onChangeOne} />)}
                                    </Form.Item>
                                    <h2> &nbsp;&nbsp; </h2>
                                    <Form.Item label="评级到期日">
                                        {getFieldDecorator('acceptorRatingLevelExpireDate', {
                                            initialValue:JSON.stringify(data) == "{}"?'':moment(data.acceptorRatingLevelExpireDate),
                                            rules: [{ required: false, message: '请选择评级到期日!' }],
                                        })(  <DatePicker onChange={this.onChangeTwo} />)}
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>


                            <Row>
                                <Col span={24}>
                                    <Col span={3}>
                                        <div className='addIssue-left'>
                                            出票人备注
                                        </div>
                                    </Col>
                                    <Col span={21}>
                                        {getFieldDecorator('ticketRemark', {
                                            initialValue: data.ticketRemark,
                                            rules: [{ required: false, message: '请输入出票人备注!' }],
                                        })(  <textarea className='textarea'></textarea>)}
                                    </Col>
                                </Col>
                            </Row>
                            <div className='wraper-cd-right'>
                                <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>返回</Button>
                                <Button className='com-btn-opr com-middle-btn-size mr20' loading={loading} htmlType="submit" >确定</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
         );
    }
}
 
const AddIssuseForm = Form.create()(AddIssuse);
export default AddIssuseForm;
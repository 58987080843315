import React, { Component } from 'react';
import { Form, Input, message, Modal, Select, TreeSelect, Button, Table } from "antd";
import { checkPhone } from "../../../utils/formatCheck";
import scfBaseApi from "../../../request/api/scfBaseApi";
const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_CHILD } = TreeSelect;
class AddCompanyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

            companyList: [],  //企业列表
            departList: [],  //部门列表
            roleList: [], //角色列表
            systemArr: [], //所属系统
            systemId: "",//系统id
            systemType: "1",  //1-客户端  2-运营端,
            userMobile: "",
            systemName: "",//系统名称
            orgName: "", //企业名称
            departNames: "",//部门名称
            roleName: "",//角色名称
            tableData: "",
            visible: true,
            textJson: {},
            sysId: "",
            obj: {},
            idObject:{},
            idCount: 0,
           

        }
    }

    componentDidMount() {
        this.getSystemQueryAll();  //所有系统
        this.getCompanyList();   //所有企业

        if(this.props.isAdd===false){
          
          let { obj,idObject} = this.state
          let sysName = this.props.systemName
          let sysId = this.props.systemId
          obj.systemName = sysName
          idObject.systemId = sysId
          this.setState({
            obj: obj,
            idObject:idObject,
          },()=>{
            this.getRoleList(this.props.systemId)
          })
          
        }
    }


    //获取所属系统
    getSystemQueryAll = () => {
        scfBaseApi.systemQueryAllApi().then(res => {
            let data = res.data;
            this.setState({
                systemArr: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    // 获取企业列表
    getCompanyList = (value) => {
        scfBaseApi.orgQueryApi({ id: value }).then(res => {
            let data = res.data;
            this.setState({
                companyList: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    //1.企业  根据企业id获取部门列表
    changeOrgId = (value, e) => {
        console.log(value, e)
        let { obj,idObject } = this.state
        let name = e.props.orgname
        let orgid = value
        obj.orgName = name
        idObject.orgId = orgid
        this.setState({
            obj: obj,
            idObject

        })

        //查询部门
        scfBaseApi.departQueryApi({ id: value }).then(res => {
            let data = res.data;
            this.setState({
                departList: data,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //2.获取部门
    changeDepart = (value, e) => {
        console.log(value, e)
        let { obj,idObject } = this.state
        let depart = e
        let departid = value
        obj.departs = depart
        idObject.departIds=departid
        this.setState({
            obj: obj,
            idObject:idObject,
        })

    }

    // 3. 系统
    changeSystemId = (value, e) => {
        console.log(value, e)
        let { obj } = this.state
        let sysName = e.props.systemname
        obj.systemName = sysName
        this.setState({
            obj: obj
        })

        // this.setState({  
        //     systemType:e.props.systemtype
        // });
        // this.props.form.resetFields(["roles"]);
        this.getRoleList(value);

        // if(e.props.systemtype==="1"){
        //     this.props.form.resetFields(["orgId", "depart"]);
        //     this.setState({departList:[]});
        //     this.getCompanyList(value);
        // }
    }
    //4.角色
    changeRole = (value, e, isCheckOrg) => {
        console.log(value, e)
        let { obj,idObject } = this.state
        let roleArr = e.map(item => item.props.children[1])
        let roleid = value
        obj.roleOrgList = roleArr
        idObject.roles = roleid
        this.setState({
            obj: obj,
            idObject:idObject
        })
    }

    //获取角色列表
    getRoleList = (value) => {
        scfBaseApi.roleQueryApi({ id: value }).then(res => {
            let data = res.data;
            this.setState({
                roleList: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }



    //弹框消失
    handleOk = () => {
        if(this.props.isAdd==true){
            this.props.form.validateFields((err, values) => {
                if (!err) {
                        let { obj } = this.state
                        localStorage.setItem("idObject", JSON.stringify(values))
                        localStorage.setItem("obj", JSON.stringify(obj))
                        this.props.saveMessage()
                        this.props.handleAddCompany()
                }
            })
        }else{
            let { obj, idObject} = this.state
            localStorage.setItem("idObject", JSON.stringify(idObject))
            localStorage.setItem("obj", JSON.stringify(obj))
            this.props.saveMessage()
            this.props.handleAddCompany()
        }
     

    }
    //弹框消失
    handleCancel = () => {
        this.props.handleAddCompany()
    }

    // handleValidator = (rule, val, callback) => {
    // let {sysId} = this.state
    //     this.props.form.validateFields((err,value)=>{
    //         if(!err){

    //                 if (value.systemId==sysId) {
    //                     callback("添加企业所选系统要一致")
    //                 } else {
    //                     callback()
    //                 }

    //         }
    //     })



    // }


    render() {
        let { getFieldDecorator } = this.props.form;
        let { visible, companyList, departList, roleList, systemArr, userMobile } = this.state;
        let newData = JSON.parse(JSON.stringify(departList).replace(/id/g, 'value').
            replace(/departName/g, 'title')
        )
        let formItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            },
            labelAlign: 'right'
        };
        let tProps = {
            treeData: newData,
            onChange: this.onDepartChange,
            treeCheckable: true,
            listHeight: 80,
            showCheckedStrategy: SHOW_CHILD,
            style: { width: '100%', },

        }
        return (
            <div>

                <Modal
                    title={"添加企业"}
                    visible={visible}
                    // width = "800px"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    maskClosable={false}

                >
                    <Form onSubmit={this.handleOk} {...formItem}>

                        <FormItem label="所属企业">
                            {
                                getFieldDecorator('orgId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择所属企业'
                                        },
                                    ],
                                    initialValue: "",
                                })(<Select onChange={(val, e) => this.changeOrgId(val, e, true)}>
                                    {
                                        companyList.length > 0 ? companyList.map(record => {
                                            return <Option key={record.id} value={record.id} orgname={record.orgName}> {record.orgName} </Option>
                                        }) : null
                                    }
                                </Select>)
                            }
                        </FormItem>



                        <FormItem label="所属部门">
                            {
                                getFieldDecorator('departIds', {
                                })(<TreeSelect multiple {...tProps} onChange={(val, e) => this.changeDepart(val, e, true)} />)
                            }
                        </FormItem>
                        {this.props.isAdd===true?(
                                                 <FormItem label="所属系统">
                                                 {
                                                     getFieldDecorator('systemId', {
                                                         rules: [
                                                             {
                                                                 required: true,
                                                                 message: '请选择所属系统'
                                                             },
                                                             // { validator: this.handleValidator }
                                                         ],
                                                         validateTrigger: "onBlur",
                                                         initialValue: "",
                                                     })(<Select onChange={this.changeSystemId}>
                                                         {
                                                             systemArr.length !== 0 ? systemArr.map(record => {
                                                                 return <Option key={record.id} value={record.id} systemtype={record.systemType} systemname={record.systemName}> {record.systemName} </Option>
                                                             }) : null
                                                         }
                                                     </Select>)
                                                 }
                                             </FormItem>
                        ):( <FormItem label="所属系统">
                        {
                            getFieldDecorator('systemId', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属系统'
                                    },
                                    // { validator: this.handleValidator }
                                ],
                                validateTrigger: "onBlur",
                                initialValue: "",
                            })(<span>{this.props.systemName}</span>)
                        }
                    </FormItem>)
                        }

                        <FormItem label="角色">
                            {
                                getFieldDecorator('roles', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择角色'
                                        },
                                    ],
                                })(<Select mode="multiple" onChange={(val, e) => this.changeRole(val, e, true)}>
                                    {
                                        roleList.length > 0 ? roleList.map(record => {
                                            return <Option key={record.id} value={record.id}> {record.roleName} </Option>
                                        }) : null
                                    }
                                </Select>)
                            }
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(AddCompanyModal);
import React, { Component } from 'react';
import { Modal, Button , message} from 'antd';
import { withRouter } from 'dva/router';
import { queryAuditSubimitApi  } from '../../../request/api/quota'
@withRouter
class AuditModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible: false
         }
    }
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {
        console.log(e);
        const { id , auditStatusCd } = this.props
        this.auditSubmit( id , auditStatusCd )
      };
      auditSubmit = ( id , auditStatusCd )=>{
          let params = {
              id:id
          }
          if(auditStatusCd==2){
            params.status = 0
          }else if(auditStatusCd==5){
              params.status = 1
          }
        queryAuditSubimitApi(params).then(res => {
            if(res.code=='2000'){
                this.props.history.push(`/admin/businessTicketManage/quota`)
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
        this.setState({
            visible: false,
        });
      }
      //暂停
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    render() { 
        const { id , auditStatusCd } = this.props
        return ( 
            <>
                <Button type="primary" style={{border:'#e16f58',background:'#e16f58'}} onClick={this.showModal}>
                {
                    auditStatusCd==2?'暂停':'启动'
                }
                </Button>
                <Modal
                title={auditStatusCd==2?'暂停':'启动'}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                <p style={{textAlign:'center'}}>是否确认要{auditStatusCd==2?'暂停':'启动'}该借款方商票发行？</p>
                </Modal>
            </>
         );
    }
}
 
export default AuditModal;
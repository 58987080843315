import React, { Component } from 'react';
import './index.scss';

import Day from './component/Day';
import Month from './component/Month';
import FuminAccount from './component/FuminAccount';
import Map from './component/Map';
import Pie from './component/Pie';
import JdQuoteTable from './component/JdQuoteTable'
import FmQuoteTable from './component/FmQuoteTable'
import scfBusinessApi from '../../request/api/scfBusinessApi'
import { message } from 'antd';
import {money100,moneyFormat,moneyMore,money} from '../../utils/formatCheck'



class StatisticsEcharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDataJd:[],
            platformAmount:"",
            dealmakingAmount:"",//撮合交易额
            fmdealAmount:"",//富民交易额
            ticketAmount:""//京东交易额
        }
    }
    componentDidMount() {
            this.getData()
            this.DealAmount()
        var intervalId = setInterval(() => {
            this.getData()
            this.DealAmount()
          }, 60000);
       
      
    }

    getData = () =>{
        //平台交易总额
        scfBusinessApi.busTomalAmountApi().then(res=>{
            this.setState({
                platformAmount:res.data
            })
        }).catch(err=>{
            message.error(err.message)
        })
    

        //撮合交易
        scfBusinessApi.actualValueApi().then(res=>{
            this.setState({
                dealmakingAmount:res.data.totalAmount
            })
        }).catch(err=>{
            message.error(err.message)
        })
    }
    

    DealAmount = () =>{
        //6-富民快贴
        let fmparams={
            type:"6"
        }
        scfBusinessApi.dealAmountApi(fmparams).then(res=>{
            this.setState({
                fmdealAmount:res.data.totalAmount
            })
        }).catch(err=>{
            message.error(err.message)
        })

        //线下票据登记总交易金额
        // let params={
           
        // }
        // scfBusinessApi.ticketAmountApi(params).then(res=>{
        //     this.setState({
        //         ticketAmount:res.data.totalAmount
        //     })
        // }).catch(err=>{
        //     message.error(err.message)
        // })
          //3-京东秒贴
        let params={
            type:"3"
        }
        scfBusinessApi.dealAmountApi(params).then(res=>{
            this.setState({
                ticketAmount:res.data.totalAmount
            })
        }).catch(err=>{
            message.error(err.message)
        })
        
    }

    // handleClickHeader = (row) => {
    //     console.log(row)
    //     // row.className=row.className+"bgc"
    // }
    render() {
        let { platformAmount,dealmakingAmount,ticketAmount,fmdealAmount} = this.state
        
        return (
            <div className="figure">
                <div className="flex top">
                    <div className="wrap">
                    <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <div className="inner">
                            <p className="fontSize22px">平台交易总额(元)</p>
                            <p className="fontSize32px">{money(platformAmount)}</p>
                        </div>
                    </div>
                    <div className="wrap">
                    <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <div className="inner">
                            <p className="fontSize22px">撮合业务总交易额(元)</p>
                            <p className="fontSize32px">{money(dealmakingAmount)}</p>
                        </div>
                    </div>
                    <div className="wrap">
                    <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <div className="inner">
                            {/* <p className="fontSize22px">线下交易额(元)</p> */}
                            <p className="fontSize22px">企汇安秒贴业务总交易额(元)</p>
                            <p className="fontSize32px">{money(ticketAmount)}</p>
                            {/* <p className="fontSize32px">{moneyMore(9000.12)}</p> */}
                        </div>
                    </div>
                    <div className="wrap">
                    <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <div className="inner">
                            <p className="fontSize22px">富民秒贴业务总交易额(元)</p>
                            <p className="fontSize32px">{money(fmdealAmount)}</p>
                        </div>
                    </div>
                    {/* <div style={{ width: '40%',height:'100%' }} className="rgb"> */}
                    <div style={{ width: '29%', height: '100%',marginBottom: '5px',position:'relative'}}>
                    <div className="boxHeader">
                                <div className="left monthTitle">
                                <div className="box box1"></div>
                                富民账户交易
                                </div>
                                <div className="center">
                                
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="boxBody">
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <FuminAccount></FuminAccount>
                            </div>
                        
                    </div>
                </div>

                <div className="flex center">
                    <div style={{ width: '30%',height:"100%", marginRight: '10px' }}>
                        <div style={{ width: '100%', height: '50%',marginBottom: '5px',position:'relative'}}>
                            {/* <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <div className="monthTitle">平台月实时交易额</div>
                            */}
                            <div className="boxHeader">
                                <div className="left monthTitle">
                                <div className="box box1"></div>
                                票据分类报价
                                </div>
                                <div className="center">
                                
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="boxBody">
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <Month></Month>
                            </div>
                            
                        </div>
                        <div style={{ width: '100%', height: '50%',position:'relative'}}>
                        <div className="boxHeader">
                                <div className="left monthTitle">
                                <div className="box box1"></div>
                                平台日实时交易额
                                </div>
                                <div className="center">
                                
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="boxBody">
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <Day></Day>
                            </div>
                            
                        </div>
                    </div>
                    {/* <div style={{ width: '40%', height: '100%', marginRight: '10px'}}>
                        <Map></Map>
                    </div> */}
                     <div style={{ width: '40%', height: '100%',marginRight: '10px',position:'relative'}}>
                            <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <Map></Map>  
                    </div>
                    {/* <div style={{ width: '30%',height:"100%" }} className="rgb"> */}
                    <div style={{ width: '30%', height: '100%',position:'relative'}}>
                    <div className="boxHeader">
                                <div className="left monthTitle province">
                                <div className="box box1"></div>
                                省级区域交易
                                </div>
                                <div className="center">
                                
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="boxBody">
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                            <Pie></Pie>
                            </div>
                        
                    </div>
                </div>

                <div className="flex bottom">
                    <div className="BotL">
                            <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <h2 style={{color:' #fff',textAlign:'center'}}>撮合交易数据</h2>
                        <JdQuoteTable></JdQuoteTable>
                    </div>
                    <div className="BotR">
                            <div className="box box1"></div>
                            <div className="box box2"></div>
                            <div className="box box3"></div>
                            <div className="box box4"></div>
                        <h2 style={{color:' #fff',textAlign:'center'}}>富民渠道实时报价行数据</h2>
                        <FmQuoteTable></FmQuoteTable>
                    </div>
                </div>

            </div>
        );
    }
}

export default StatisticsEcharts;

import React, { Component } from 'react';
import { Button, Modal, message } from 'antd';
import scfBusinessApi from '@/request/api/scfBusinessApi';

const { confirm } = Modal;
// const contractTemplateDeleteApi = scfBusinessApi['contractTemplateDeleteApi'];
const resourceFiledeleteSApi = scfBusinessApi['resourceFiledeleteSApi'];


class RemoveContract extends Component {

    handleRemove = (resolve, reject) => {
        const { contractTemplateId, removeType } = this.props;
        if(removeType) {
            // const params = { contractTemplateId: `${contractTemplateId}` };
            // contractTemplateDeleteApi(params).then(res => {
            //     // message.success(res.message);
            //     this.props.handleRemoveFile();
            //     return resolve();
            // }).catch(err => {
            //     message.error(err.message)
            //     return reject(); 
            // });
            setTimeout(() => {
                this.props.handleRemoveFile();
                return resolve();
            }, 1500);
        }else {
            const params = { resourceFileId: contractTemplateId };
            resourceFiledeleteSApi(params).then(res => {
                // message.success(res.message);
                this.props.handleRemoveFile();
                return resolve();
            }).catch(err => {
                message.error(err.message)
                return reject(); 
            });
        }
    }

    showConfirm = () => {
        confirm({
            title: '是否确认要删除合同模板？',
            content: '',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.handleRemove(resolve, reject);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    render() {
        return (
            <Button className='com-btn-opr com-small-btn-size mr5' onClick={this.showConfirm}>删除</Button>
        )
    }
}

export default RemoveContract;

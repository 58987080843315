import React, { Component } from 'react'
import echarts from 'echarts';
import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message } from 'antd';

export default class Day extends Component {
    constructor(props){
        super(props)
        this.state={
            intervalId:""
        }
    }
    componentDidMount() {
        this.initalECharts();

        
    var intervalId = setInterval(() => {
          this.initalECharts();
        }, 3600000);
        this.setState({
            intervalId,
        })
    }
        componentWillUnmount = () => {
        let {intervalId} = this.state
        clearInterval(intervalId)
    }
    initalECharts = () => {
        let params = {
            timeUnitCd:"5"
        }
        scfBusinessApi.mtRealAmountApi(params).then(res=>{
            let data1 = res.data
            let dataNew = []
              data1.map(item=>{
                let arr = []
                arr.push(item.date)
                arr.push(item.amount)
                dataNew.push(arr)
            })

            const myChart = echarts.init(document.getElementById('day'));
            // var data = [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2000-06-12", 68], ["2000-06-13", 92], ["2000-06-14", 130], ["2000-06-15", 245], ["2000-06-16", 139], ["2000-06-17", 115], ["2000-06-18", 111], ["2000-06-19", 309], ["2000-06-20", 206], ["2000-06-21", 137], ["2000-06-22", 128], ["2000-06-23", 85], ["2000-06-24", 94], ["2000-06-25", 71], ["2000-06-26", 106], ["2000-06-27", 84], ["2000-06-28", 93], ["2000-06-29", 85], ["2000-06-30", 73], ["2000-07-01", 83], ["2000-07-02", 125], ["2000-07-03", 107], ["2000-07-04", 82], ["2000-07-05", 44], ["2000-07-06", 72], ["2000-07-07", 106], ["2000-07-08", 107], ["2000-07-09", 66], ["2000-07-10", 91], ["2000-07-11", 92], ["2000-07-12", 113], ["2000-07-13", 107], ["2000-07-14", 131], ["2000-07-15", 111], ["2000-07-16", 64], ["2000-07-17", 69], ["2000-07-18", 88], ["2000-07-19", 77], ["2000-07-20", 83], ["2000-07-21", 111], ["2000-07-22", 57], ["2000-07-23", 55], ["2000-07-24", 60]];
            var data = dataNew;
            var dateList = data.map(function (item) {
                return item[0];
            });
            var valueList = data.map(function (item) {
                return item[1];
            });
    
            var option = {
    
                // Make gradient line here
                visualMap: [
                {
                    show: false,
                    type: 'continuous',
                    seriesIndex: 1,
                    dimension: 0,
                    min: 0,
                    max: dateList.length - 1
                }
            ],
    
    
                title: [
                //     {
                //     left: 'center',
                //     text: '平台日实时交易额折线图',
                //     textStyle: {
                //         // color: 'rgb(179, 239, 255)',
                //         color:'#fff',
                //         fontSize: 16
                //     },
                // }, 
                // {
                //     top: '55%',
                //     left: 'center',
                //     text: 'Gradient along the x axis'
                // }
            ],
                tooltip: {
                    trigger: 'axis',
                    // textStyle: {
                    //     color: '#8dd7fc',
                    //     fontSize: 16
                    // },
                },
                xAxis: [
                    {
                    data: dateList,
                    axisLabel: {
                        show: true,
                         textStyle: {
                           color: '#fff',  //更改坐标轴文字颜色
                           fontSize : 14      //更改坐标轴文字大小
                         }
                      },
                      axisLine:{
                        lineStyle:{
                           color:'#fff' //更改坐标轴颜色
                        }
                  }
                    }, 
                // {
                //     data: dateList,
                //     gridIndex: 1
                // }
                
            ],
                yAxis: [
                    {
                    splitLine: { show: false },
                    axisLabel: {
                        show: true,
                         textStyle: {
                           color: '#fff',  //更改坐标轴文字颜色
                           fontSize : 14      //更改坐标轴文字大小
                         }
                      },
                      axisLine:{
                        lineStyle:{
                           color:'#fff' //更改坐标轴颜色
                        }
                  }
                    }, 
                // {
                //     splitLine: { show: false },
                //     gridIndex: 1
                // }
            ],
                grid: [
                    {
                    left: '20%',
                    bottom: '25%',
                    height: '70%'
                     },
            ],
                series: [{
                    type: 'line',
                    showSymbol: false,
                    areaStyle: {
                        normal: {
                          color: '#8dd7fc' //改变区域颜色
                        }
                      },
                      itemStyle: {
                        normal: {
                          color: '#8dd7fc', //改变折线点的颜色
                          lineStyle: {
                            color: '#8dd7fc' //改变折线颜色
                          }
                        }
                      },
                    data: valueList

                },
                // {
                //     type: 'line',
                //     showSymbol: false,
                //     data: valueList,
                //     xAxisIndex: 1,
                //     yAxisIndex: 1
                // }
            ]
            };
            myChart.setOption(option);
            window.addEventListener('resize', function() {
                myChart.resize(); 
                  })
        }).catch(err=>{
            message.error(err.message)
        })
       
    }
    render() {
        return (
          
                <div id="day" style={{ width: '100%', height: '100%',padding:'5px'}}></div>
           
        )
    }
}

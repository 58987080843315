import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { unCrudeHttp } from '@/request/http';
import { Modal, Pagination, Spin } from 'antd';


class ReviewContranct extends Component {

    state = {
        visible: false,
        base64: null,
        loadingFlag: true,
        numPages: null,
        pageNumber: 1,
    }

    componentWillMount () {
        let path = window.API_HOST.replace(/\/service/, '');
        pdfjs.GlobalWorkerOptions.workerSrc = `${path}/pdf.worker.min.js`;
    }   

    handleGetContractBase64 = () => {
        let params = {
            originalFileName: this.props.originalFileName,
            relativePath: this.props.relativePath,
            newFileName: this.props.newFileName
        }
        this.setState({ loadingFlag: true });
        unCrudeHttp({
            method: 'post',
            url: `${window.API_HOST}/business/resourcefile/download`,
            responseType: "blob",
            data: params
        }).then(res => {
            const reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onload = e => {
                this.setState({
                    base64: e.target.result,
                    loadingFlag: false
                })
            }
        }).catch(err => {
            this.setState({ loadingFlag: false });
        })
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
        this.handleGetContractBase64();
    };
    
    handleOk = e => {
        this.setState({
          visible: false,
        });
    };
    
    handleCancel = e => {
        this.setState({
          visible: false,
        });
    };
    
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }
    
    onChangePage=(page)=>{
        this.setState({ pageNumber:page });
    }

    render() {
        const { base64, pageNumber, numPages, loadingFlag } = this.state;
        return (
            <React.Fragment>
                <p className='text-h-style' onClick={this.showModal}>{this.props.text ? this.props.text : null}</p>
                <Modal
                    title="PDF Browse Modal"
                    width={1000}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{textAlign: "center"}}
                >   
                    <Spin spinning={loadingFlag}>
                        <div className='wrap-pdf-bgColor'>
                            <Document
                                file={ base64 }
                                onLoadSuccess={this.onDocumentLoad}
                                renderMode='canvas'
                                options={{
                                    cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                    cMapPacked: true,
                                }}
                            >
                                <Page pageNumber={pageNumber}  scale={1.5}/>
                            </Document>
                        </div>
                        <Pagination
                            total={numPages}
                            showTotal={total => `共 ${total ? total : 0} 页`}
                            current={pageNumber}
                            pageSize={1}
                            size='small'
                            onChange={this.onChangePage}
                        />
                    </Spin>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ReviewContranct;

import React, { Component } from 'react'
import {Table,Button, message} from 'antd'
import scfBusinessApi from "../../../request/api/scfBusinessApi";

export default class WaitDealt extends Component {
    constructor(props){
        super(props)
        this.state={
            tableData:[],
            

        }
    }
    componentDidMount(){
        this.getDate()
    }

    getDate = () =>{
       
        scfBusinessApi.todoQueryApi().then(res=>{
             this.setState({
               tableData: res.data

             })
        }).catch(err=>{
            message.error(err.message)
        })
    }
    goBack = () =>{
        this.props.history.go(-1)
    }

 //待办跳详情
 toDetail = (relationId,sysBusinessType,operationType,wkId,nodeId) => {
    if(sysBusinessType==="201020"){ //商票业务开通审核
        if(operationType==="10"||operationType=="00"){
            this.props.history.push(`/admin/businessTicketManage/detail?id=${relationId}&audit=1&wkId=${wkId}&nodeId=${nodeId}`) 
        }
    }
}
    render() {
        let {tableData}=this.state;
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                sorter:(a,b)=>{
                    if(!a.title){
                        a.title=""
                    }else if(!b.title){
                        b.title=""
                    }
                    return a.title.localeCompare(b.title)
                },
               
            },
            {
                title: '申请时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }
            },
            {
                title: '申请方',
                dataIndex: 'orgName',
                key: 'orgName',
                sorter:(a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                }
            },
            {
                title: '申请人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                sorter:(a,b)=>{
                    if(!a.createUserName){
                        a.createUserName=""
                    }else if(!b.createUserName){
                        b.createUserName=""
                    }
                    return a.createUserName.localeCompare(b.createUserName)
                }
            },
           
            {
                title: '操作',
                render: (record) => {
                    return <div className="flex-around-center">
                         <Button type="primary" onClick={()=>this.toDetail(record.relationId,record.sysBusinessType,record.operationType,record.wkId,record.nodeId)}>去操作</Button>
                    </div>

                }
            },
        ];
        return (
            <div>
                <h2>待办事项</h2>
                <Button style={{marginBottom: '20px'}} onClick={this.goBack}>返回</Button>
               
              
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={false}
                />
            </div>
        )
    }
}

import React, { Component } from 'react';
import {Form, Input, Radio, Button, message, DatePicker, Select, Divider, Row, Col} from 'antd';
import scfBaseApi from "../../request/api/scfBaseApi";
const Option = Select.Option;
class Picker extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            provinceArr:[],
            orgCityArr:[],
            orgDistrictArr:[]
         }
    }
    componentDidMount(){
        this.getProvince();
    }
    //获取省
    getProvince = () => {
        scfBaseApi.queryProvinceApi().then(res => {
            let data = res.data;
            this.setState({
                provinceArr: data
            })

        }).catch(err => {
            message.error(err.message);
        })

    }
    //根据省获取市
    handleChangeProvince = (value, e) => {
        let id = e.key;
        const { index } =  this.props
        scfBaseApi.queryMunicipalApi({id}).then(res => {
            let data = res.data;
            this.props.clearPickerCity(index)
            this.setState({
                orgCityArr: data,
                orgDistrictArr:[],
            });

        }).catch(err => {
            message.error(err.message);
        })
    }
    //根据市获取县
    handleChangeCity = (value, e ) => {
        let id = e.key;
        const { index } =  this.props
        scfBaseApi.queryDistrictApi({id}).then(res => {
            let data = res.data;
            this.props.clearPickerCounty(index)
            this.setState({
                orgDistrictArr:data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    render() { 
        const { provinceArr, orgCityArr,orgDistrictArr } = this.state;
        const { getFieldDecorator ,index } = this.props
        const formAddressItem = {
            wrapperCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 18},
            },
            labelAlign: 'right'
        };
        //省
        const formProvinceItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'right'
        }
        //市
        const formCityItem = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
            labelAlign: 'right'
        }
        return ( 
            <>
                <div className="com-clearfix">
                    <Form.Item label="注册地址" {...formProvinceItem} className="com-fl"
                            style={{width: "50%"}}>
                        {
                            getFieldDecorator(`province${index}`, {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择省'
                                    },
                                ],
                            })(<Select onChange={(val,e)=>this.handleChangeProvince(val,e)}
                                    placeholder='请选择省'>
                                {
                                    provinceArr.map(record => {
                                        return <Option value={record.areaName}
                                                    key={record.id}>{record.areaName}</Option>
                                    })
                                }
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item className="com-fl" style={{width: "25%"}} {...formCityItem}>
                        {
                            getFieldDecorator(`city${index}`, {
                                rules: [{
                                    required: true,
                                    message: '请选择市'
                                }],
                            })(<Select onChange={(val,e)=>this.handleChangeCity(val,e)}
                                    placeholder='请选择市'>
                                {
                                    orgCityArr.map(record => {
                                        return <Option value={record.areaName}
                                                    key={record.id}>{record.areaName}</Option>
                                    })
                                }
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item className="com-fl" style={{width: "25%"}} {...formCityItem}>
                        {
                            getFieldDecorator(`county${index}`, {
                                rules: [{
                                    required:true,
                                    message: '请选择县'
                                }],
                            })(<Select placeholder='请选择县'>
                                {
                                    orgDistrictArr.map(record => {
                                        return <Option value={record.areaName}
                                                    key={record.id}>{record.areaName}</Option>
                                    })
                                }
                            </Select>)
                        }
                    </Form.Item>
                </div>
                <Form.Item label="" {...formAddressItem}>
                    {
                        getFieldDecorator(`address${index}`, {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入详细地址'
                                },
                                {
                                    max: 1000,
                                    message: "最大长度为100位,请重新输入"
                                }
                            ],
                        })(<Input placeholder="请精确填写，具体到门牌号"/>)
                    }
                </Form.Item>
            </>
         );
    }
}
 
export default Picker;
import React, { Component } from 'react';
import { Button ,message } from 'antd';
import { withRouter } from 'dva/router';
import { querypayMoneyGetDetailApi , queryIssusSiginInfoSubmitApi } from '../../../request/api/quota'
import { money100 } from '../../../utils/formatCheck'

@withRouter

class PaymoneyInfoSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{}
         }
    }
    componentDidMount(){
        this.getpayMoneyGetDetail()
    }
    getpayMoneyGetDetail = ()=>{
        const { ticketLedgerId  } = this.props
        querypayMoneyGetDetailApi({id:ticketLedgerId}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    data:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    payMoney = ()=>{
        let params = {
            id:this.props.ticketLedgerId,
            but:'10',
            nodeType:'3'
        }
        queryIssusSiginInfoSubmitApi(params).then(res => {
            if(res.code=='2000'){
                this.props.history.push(`/admin/bussinessTicketPayMoney/paymoney`)
                message.success(res.message)
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    goBack = ()=>{
        window.history.go(-1)
    }
    render() { 
        const { data } = this.state
        const { audit } = this.props
        return ( 
            <>
                <div className='paymoney-box'>
                    <h1>付款</h1>
                    <div className='pay-contant'>
                        <p><span>付款总金额（元）:</span> {money100(data.ticketAmount ) }</p>
                        <p><span>汇票到期日:</span>  { data.ticketExpireDate }</p>
                    </div>
                    <h1>付款账号</h1>
                    <div className='pay-contant'>
                        <p><span>全称:</span>  { data.payeeName }</p>
                        <p><span>账号:</span>  { data.payeeAccountNo }</p>
                        <p><span>开户行:</span>  { data.payeeBank }</p>
                        <p><span>开户行号:</span>  { data.payeeBankNo }</p>
                    </div>
                    {
                        audit==1?<div className='payMoney-foot'>
                        <Button onClick={this.goBack}>返回</Button>
                        <Button type='primary' onClick={this.payMoney}>付款</Button>
                    </div>:null
                    }
                </div>
            </>
         );
    }
}
 
export default PaymoneyInfoSubmit;
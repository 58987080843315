import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'dva/router';

class RouterMap extends Component {
    render() {
        let { routes, history } = this.props;
        let defaultRoute = routes.filter(item => item.redirect);
        let DefaultRoute = defaultRoute.map((item, index) => {
            return <Redirect from={item.redirect} to={item.component} key={index} exact />;
        });
        routes = routes.filter(item => !item.redirect);
        return <Router history={history}>
            <Switch>
                {
                    routes.map((item, index) => {
                        const children = item.children === undefined ? [] : item.children;
                        const Comp = item.component;
                        return <Route key={item.name} path={item.path} component={(props) => {
                            return <Comp routes={children} {...props}></Comp>;
                        }} />
                    }).concat(DefaultRoute)
                }
            </Switch>
        </Router>
    }
}
export default RouterMap;

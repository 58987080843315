import React, { Component } from 'react';
import { connect } from 'dva';
import RemoveContract from './removeContract';
import ReviewContranct from './reviewContract';
import EditableTable from './editableTable';
import { getUrlParam } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Button, Row, Col, Spin, message, Checkbox, Upload } from 'antd';

const contractTemplateUpdateApi = scfBusinessApi['contractTemplateUpdateApi'];
const resourceFileUploadApi = scfBusinessApi['resourceFileUploadApi'];

@Form.create()
@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class EditComp extends Component {

    state = {
        showUploadText: 0,
        contractData: {},
        filelist: [],
        flag: true
    }

    componentDidMount () {
        this.handleRemoveFile();
        this.props.dispatch({ type: 'contractTemplateManage/getContractDetailData', payload: getUrlParam('id')});
        this.props.dispatch({ type: 'contractTemplateManage/getSignatoryEnumerate', payload: 'signatory_type'});
    }

    componentWillReceiveProps (newProps) {
        const { flag } = this.state;
        const { contractDetailData } = newProps.store;
        if(flag && contractDetailData.file) {
            let obj = contractDetailData.file;
            let opt = { 
                resourceFileId: contractDetailData.resourceFileId,
                configs: contractDetailData.configs
            };
            Object.assign(obj, opt);
            this.props.dispatch({ type: 'contractTemplateManage/updateReducers', payload: { key: 'addContractData', val: obj }});
            this.setState({
                flag: false,
                filelist: contractDetailData.file,
                showUploadText: 3,
            })
        }
    }

    handleAddCredit = (data) => {
        const { addContractData } = this.props.store;
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                let params = {};
                let newData = []
                data.map(item => newData.push({ 
                    id: item.id, 
                    domainName: item.domainName, 
                    sysField: item.sysField, 
                    ruleType: item.ruleType 
                }));
                params.contractTemplateId = getUrlParam('id') * 1;
                params.signatorys = values.signatorys;
                params.resourceFileId = addContractData.resourceFileId;
                params.configs = newData;
                contractTemplateUpdateApi(params).then(res => {
                    if(res.code === '0') message.success(res.message);
                    this.props.history.push('/admin/contractTemplateManage/list');
                }).catch(err => message.error(err.message))
            }
        })
    }

    handleFileUpload = () => {
        const { contractData } = this.state;
        const params = {
            resourceFileId: contractData.resourceFileId,
            relativePath: contractData.relativePath,
            newFileName: contractData.newFileName,
            originalFileName: contractData.originalFileName
        };
        this.props.dispatch({ type: 'contractTemplateManage/getAddContractData', payload: params}).then(res => {
            if(res && res.message) {
                this.setState({ showUploadText: 0 });
            }else {
                this.setState({ showUploadText: 3 });
            }
        })
    }

    handleFileLoad = () => {
        this.refs.fileLoad.buttonNode.click();
    }

    handleRemoveFile = () => {
        this.props.form.resetFields('fileLoad');
        this.setState({
            showUploadText: 0,
            contractData: {},
            filelist: []
        })
        this.props.dispatch({ type: 'contractTemplateManage/updateReducers', payload: { key: 'addContractData', val: {} }});
    }

    customRequest = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        this.setState({ showUploadText: 1 }) 
        resourceFileUploadApi(formData).then(res => {
            // message.success(`${info.file.name} 上传成功！`);
            this.setState({ 
                contractData: res.data
            })
            this.handleFileUpload();
        }).catch(err => {
            // message.error(`${info.file.name} 上传失败！`);
            this.handleRemoveFile();
        })
    }

    beforeUpload = (info) => {
        const isPDF = info.type === 'application/pdf';
        const isLt8M = info.size / 1024 / 1024  < 100;
        if (!isPDF) {
            message.error('当前模板格式支持支PDF，上传前请设置好模板中的相应输入域的信息');
            return false;
        }else {
            if (!isLt8M) {
                message.error('文件必须小于8MB!');
                return false;
            }
        }
        return isPDF && isLt8M;
    }

    projectRender () {
        const { contractDetailData } = this.props.store;
        const formItemLayout = {
            labelCol: { span: 8, },
            wrapperCol: { span: 14, offset: 2 }
        };
        return (
            <Row>
                <Col span={6}>
                    <Form.Item label="请选择项目" {...formItemLayout}>
                        {contractDetailData && contractDetailData.projectName ? contractDetailData.projectName : null}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="请选择产品" {...formItemLayout}>
                        {contractDetailData && contractDetailData.productName ? contractDetailData.productName : null}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    ifAssignProductRender () {
        const { contractDetailData } = this.props.store;
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <Form.Item label="是否指定产品">
                            {contractDetailData && contractDetailData.ifAssignProductName ? contractDetailData.ifAssignProductName : null}
                        </Form.Item>
                    </Col>
                </Row>
                {
                    contractDetailData && contractDetailData.ifAssignProduct === '1'
                        ? this.projectRender()
                        :  null
                }
            </React.Fragment>
        )
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { showUploadText } = this.state;
        const { signatoryEnumerate, addContractData, contractDetailData } = this.props.store;
        const isLoding1 = this.props.loading.effects['contractTemplateManage/getSignatoryEnumerate'];
        const isLoding3 = this.props.loading.effects['contractTemplateManage/getContractDetailData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: ''
        };
        return (
            <div>
                <Spin spinning={ isLoding1 || isLoding3 }>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>合同基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同名称">
                                    {contractDetailData && contractDetailData.contractName ? contractDetailData.contractName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同编号">
                                    {contractDetailData && contractDetailData.contractNum ? contractDetailData.contractNum : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="签署方">
                                    {
                                        getFieldDecorator('signatorys', {
                                            initialValue: contractDetailData && contractDetailData.signatorys ? contractDetailData.signatorys : [],
                                            rules: [{ required: true, message: '请选择签署方' }]
                                        })(
                                            <Checkbox.Group name='signatorys'>
                                                {
                                                    signatoryEnumerate && signatoryEnumerate.length 
                                                    ? signatoryEnumerate.map((item, index)=> <Checkbox key={index} value={item.dicKey}>{item.dicValue}</Checkbox>)
                                                    : null
                                                }
                                            </Checkbox.Group>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="是否短信鉴权">
                                    {contractDetailData && contractDetailData.ifAssignProductName ? contractDetailData.ifAssignProductName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同类型">
                                    {contractDetailData && contractDetailData.contractTypeName ? contractDetailData.contractTypeName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            contractDetailData && contractDetailData.contractType === '01'
                                ? this.ifAssignProductRender()
                                :  null
                        }
                        <Row>
                            <Col span={12}>
                                <div className='mask-dialog'></div>
                                <Form.Item label="模板上传">
                                    {
                                        showUploadText === 0 
                                        ? <Button className='com-btn-opr com-middle-btn-size mr20' icon='upload' onClick={this.handleFileLoad}>点击上传模板</Button>
                                        : showUploadText === 1
                                            ? <Button className='com-btn-opr com-middle-btn-size mr20' loading={true}>上传中</Button>
                                            : <React.Fragment>
                                                <ReviewContranct relativePath={addContractData.relativePath} newFileName={addContractData.newFileName} originalFileName={addContractData.originalFileName} />
                                                <RemoveContract removeType={true} contractTemplateId={addContractData.resourceFileId} handleRemoveFile={this.handleRemoveFile}  />
                                            </React.Fragment>
                                    }
                                    {
                                        getFieldDecorator('fileLoad', {
                                            initialValue: this.state.filelist,
                                            valuePropName: 'filelist',
                                            rules: [{ required: true, message: '请上传模板' }]
                                        })(
                                            <Upload 
                                                accept='application/pdf'
                                                customRequest={this.customRequest}                         
                                                beforeUpload = {this.beforeUpload}
                                                showUploadList={false} 
                                            >
                                                <Button style={{display: 'none'}} ref='fileLoad'></Button>
                                            </Upload>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <p className='h_text_style'>字段信息配置</p>
                    <EditableTable data={addContractData.configs} subMitFn={this.handleAddCredit} history={this.props.history} />
                </Spin>
            </div>
        )
    }
}

export default EditComp;

import React, { Component } from 'react';
import { message } from 'antd';
import scfBusinessApi from "../../../request/api/scfBusinessApi";
import { money100 } from '../../../utils/formatCheck'
class PayMoneyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ticketDetail:{}
         }
    }
    componentDidMount(){
        this.getInitData()
    }
    getInitData = ()=>{
        let {ticketLedgerId} = this.props
        let params={
            id:ticketLedgerId
        }
        scfBusinessApi.getTicketDetailApi(params).then(res=>{
         this.setState({
            ticketDetail: res.data,
         })
       }).catch(err=>{
           message.error(err.message)
       })
    }
    render() { 
        const { ticketDetail } = this.state
        return ( 
            <>
                <div className='payMoneyDetail-box'>
                    <h1>提示付款</h1>
                    <div>
                        <span>全称:</span>
                        <span>{ticketDetail.acceptorName}</span>
                    </div>
                    <div>
                        <span>账号:</span>
                        <span>{ticketDetail.acceptorAccountNo}</span>
                    </div>
                    <div>
                        <span>开户行:</span>
                        <span>{ticketDetail.acceptorBank}</span>
                    </div>
                    <div>
                        <span>开户行号:</span>
                        <span>{ticketDetail.acceptorBankNo}</span>
                    </div>
                    <h1>付款信息</h1>
                    <div>
                        <span>付款金额（元）:</span>
                        <span>{money100(ticketDetail.ticketAmount) }</span>
                    </div>
                </div>
            </>
         );
    }
}
 
export default PayMoneyDetail;
import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBaseApi from "../../request/api/scfBaseApi";
import scfBusinessApi from "../../request/api/scfBusinessApi";
import moment from 'moment';
import AddInvitateCompany from "./component/addInvitateCompany"
import ReLaunch from "./component/reLaunch"



const FormItem = Form.Item;
const { Option } = Select;


class InvitateCompanyManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: "",//当前页
            pageSize: 10,//每页大小
            tableData: [],
            addVisibleModal: false,//添加企业邀请
            reLaunch: false,//重新发起
            withdrawVisible: false,  //撤回弹框  
            invitateId: "",//邀请id
            name: "",//企业名称
            inviteCode: "",//邀请码
            statusList:[]
        }
    }
    componentDidMount() {
        this.handleSubmit(1)
        this.getStatusList() 
       
       
    }

    getStatusList = () =>{
        let params = {
            typeList:['inviteStatusCd']
        }
        scfBusinessApi.dictionaryApi(params).then(res=>{
            this.setState({
                statusList:res.data.inviteStatusCd
            })
           
        }).catch(err=>{
            message.error(err.message)
        })
    }


    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }

    //查询
    handleSubmit = (page) => {
        const { pageNum, pageSize } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { status, name } = values;
                let params = {
                    status,
                    name,
                    pageSize,
                    pageNum: page
                }
                scfBaseApi.invitesApi(params).then(res => {
                    this.setState({
                        tableData: res.data,
                        pageNum: page
                    })
                }).catch(err => {
                    message.error(err.message);
                });

            }
        })
    }
    //重置
    handleReset = () => {
        this.props.form.setFieldsValue({
            name: "",
            status: "",
        });
        this.setState({
            pageNum: 1,
            pageSize: 10,
        }, () => {
            this.handleSubmit(1);
        })
    }

    //分页change
    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            pageNum: pagination.current
        })
        this.handleSubmit(pagination.current)
    };

    //查询按钮
    handleClick = () => {
        this.handleSubmit(1)
    };
    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.handleSubmit(current)
        })
    }
    handleClickHeader = (row) => {
        row.className = row.className + " left"
    }
    //撤回
    withdraw = (id) => {
        this.setState({
            withdrawVisible: true,
            inviteId: id,
        })
    }
    //确认撤回
    okWithdraw = () => {
        let { inviteId, pageNum } = this.state
        let params = {
            action: "50",
            inviteId
        }
        scfBaseApi.inviteIdApi(params).then(res => {
            message.success(res.message);
            this.cancelWithdraw()
            this.handleSubmit(pageNum)
        }).catch(err => {
            message.error(err.message);
        })
    }
    //取消撤回
    cancelWithdraw = () => {
        this.setState({
            withdrawVisible: false
        })
    }

    //新增邀请
    addInvition = () => {
        this.setState({
            addVisibleModal: true
        })
    }
    //新增邀请弹框消失
    hideModal = () => {
        this.setState({
            addVisibleModal: false
        }, () => {
            this.handleSubmit(1)
        })
    }
    //重新发起
    reLaunch = (name, inviteCode, inviteId) => {
        this.setState({
            reLaunch: true,
            name,
            inviteCode,
            inviteId
        })
    }
    //重新发起弹框消失
    hideReLaunchModal = () => {
        let { pageNum } = this.state
        this.setState({
            reLaunch: false
        }, () => {
            this.handleSubmit(pageNum)
        })
    }
    render() {
        let { getFieldDecorator } = this.props.form;
        let { statusList,pageNum, reLaunch, inviteId, name, inviteCode, tableData, addVisibleModal, withdrawVisible } = this.state;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };

        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '客户名称',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    if (!a.name) {
                        a.name = ""
                    } else if (!b.name) {
                        b.name = ""
                    }
                    return a.name.localeCompare(b.name)
                },

            },
            {
                title: '业务角色',
                dataIndex: 'roleCdDesc',
                key: 'roleCdDesc',
                sorter: (a, b) => {
                    if (!a.roleCdDesc) {
                        a.roleCdDesc = ""
                    } else if (!b.roleCdDesc) {
                        b.roleCdDesc = ""
                    }
                    return a.roleCdDesc.localeCompare(b.roleCdDesc)
                },

            },
            {
                title: '邀请时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }

            },
            {
                title: '邀请码',
                dataIndex: 'inviteCode',
                key: 'inviteCode',
                sorter: (a, b) => {
                    if (!a.inviteCode) {
                        a.inviteCode = ""
                    } else if (!b.inviteCode) {
                        b.inviteCode = ""
                    }
                    return a.inviteCode.localeCompare(b.inviteCode)
                },
            },
            {
                title: '状态',
                dataIndex: 'inviteStatusCdDesc',
                key: 'inviteStatusCdDesc',
                sorter: (a, b) => {
                    if (!a.inviteStatusCdDesc) {
                        a.inviteStatusCdDesc = ""
                    } else if (!b.inviteStatusCdDesc) {
                        b.inviteStatusCdDesc = ""
                    }
                    return a.inviteStatusCdDesc.localeCompare(b.inviteStatusCdDesc)
                },
            },
            {
                title: '操作',
                fixed: 'right',
                render: (record) => {
                    return <div className="flex-around-center" >
                        {record.inviteStatusCd === "0" ? <Button type="primary" onClick={() => this.withdraw(record.id)}>撤回</Button> : null}
                        {record.inviteStatusCd === "1" ? <span>--</span> : null}
                        {record.inviteStatusCd === "2" ? <Button type="primary" onClick={() => this.reLaunch(record.name, record.inviteCode, record.id)}>重新发起</Button> : null}
                    </div>

                }
            },
        ];

        return (
            <div>
                <div>
                    <Form className="" {...formRowItem}>
                        <Row>
                            <Col span={12}>
                                <FormItem label="客户名称">
                                    {
                                        getFieldDecorator('name', {
                                            initialValue: ""
                                        })(<Input placeholder="" />)
                                    }
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="状态">
                                    {
                                        getFieldDecorator('status', {

                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Select>
                                            {statusList.map(item=>{
                                                return (<Option value={item.dicKey} key={item.dicKey}>{item.dicValue}</Option>)
                                            })}
                    
                                        </Select>)
                                    }
                                </FormItem>
                            </Col>

                        </Row>
                        <Row style={{ paddingLeft: '25%' }}>

                            <Col span={24}>
                                <Form.Item>
                                    <div className="com-diff-two-btn">
                                        <Button type="primary" onClick={this.handleClick} style={{ marginRight: '20px' }} className="com-middle-btn-size">查询</Button>
                                        <Button type="default" onClick={this.handleReset} className="com-middle-btn-size">重置</Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{ marginTop: 30, marginBottom: 30 }}>
                    <Button type="primary" className="com-middle-btn-size" onClick={this.addInvition}>添加邀请</Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={tableData.list}
                    rowKey={(record, index) => record.id}
                    pagination={{
                        showTotal: total => `总共${tableData.total}条`,
                        total: tableData.total ? tableData.total : 0,
                        showQuickJumper: true,
                        onShowSizeChange: this.changeShowSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        current: pageNum,
                    }}
                    onChange={this.handleTableChange}
                />
                {/* 添加企业邀请 */}
                {addVisibleModal ? <AddInvitateCompany hideModal={this.hideModal}></AddInvitateCompany> : null}
                {/* 重新发起 */}
                {reLaunch ? <ReLaunch hideReLaunchModal={this.hideReLaunchModal} name={name} inviteCode={inviteCode} inviteId={inviteId}></ReLaunch> : null}
                {/* 撤回madal */}
                <Modal
                    title="撤回邀请企业"
                    visible={withdrawVisible}
                    onOk={this.okWithdraw}
                    onCancel={this.cancelWithdraw}
                    centered
                >
                    <p>确定要撤回邀请企业？</p>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(InvitateCompanyManage);




import React, { Component } from 'react';
class TicketStatusEnum extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    render() { 
        const { data , creditStateList } = this.props
        return ( 
            <>
                {
                    creditStateList.map((record,index)=>{
                        if(record.dicKey==data){
                            return record.dicValue
                        }
                    })
                }
            </>
         );
    }
}
 
export default TicketStatusEnum;
import React, {Component} from 'react';
import {Form,Input,Modal} from "antd";

const FormItem = Form.Item;


class addModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,

        }
    }
    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                    this.setState({
                         visible: false
                    });
                this.props.operationOk(values.departName);
            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });
    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {visible} = this.state;
        let formItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 2, span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16}
            },
            labelAlign: 'right'
        };
        return (
            <Modal
                title="添加部门"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Form onSubmit={this.handleOk} {...formItem}>
                    <FormItem label="部门名称">
                        {
                            getFieldDecorator('departName',{
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入角色名称'
                                    },
                                    {
                                        max: 100,
                                        message: "最大长度为100位,请重新输入"
                                    }
                                ],
                                validateTrigger: "onBlur",
                                initialValue: ""
                            })(<Input/>)
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(addModal);
import React, {Component} from "react";
import {connect} from "dva";
import loginApi from "@/request/api/login";
import scfBaseApi from "../../request/api/scfBaseApi";
import {checkPhone,checkPwd} from "../../utils/formatCheck"
import {Form, Input, Button, message, Row , Checkbox } from "antd";
import {setStorage , setCookie , getCookie , delCookie} from "@/utils/tools";
import CryptoJS from "crypto-js";
import "./index.scss";

const FormItem = Form.Item;
const userLogin = loginApi["userLoginApi"];
const getAesApi = loginApi["getAesApi"];

@connect((store) => ({store: store.login, loading: store.loading}))
class LoginUser extends Component {
    state = {
        stateKey: "",
        stateIv: "",
        loadingFlag: false,
        checkedFlag:false,
    };

    componentDidMount() {
        this.handleGetAesApi();
        this.initSetValue();
    }

    initSetValue = ()=>{
        if(getCookie('userMobile') && getCookie('userPassword')){
            this.props.form.setFieldsValue({
                userMobile:getCookie('userMobile'),
                userPassword:getCookie('userPassword') 
            })
            this.setState({
                checkedFlag:true
            })
        }
    }

    handleGetAesApi = () => {
        getAesApi()
            .then((res) => {
                this.setState({
                    stateIv: res.data.AES_IV,
                    stateKey: res.data.AES_KEY,
                    loadingFlag: false,
                });
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    handleEncrypt = (word) => {
        const {stateKey, stateIv} = this.state;
        const key = CryptoJS.enc.Utf8.parse(stateKey);
        const iv = CryptoJS.enc.Utf8.parse(stateIv);
        let encrypted = CryptoJS.AES.encrypt(word, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString();
    };

    //账户密码登录
    handleUserSubmit = (e) => {
        e.preventDefault();
        const { checkedFlag } = this.state;
        this.setState({loadingFlag:true})
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let pwd = this.handleEncrypt(values.userPassword);
                let upd = values.userPassword;
                values.userPassword = pwd;
                values.code ="qqaaa";
                userLogin(values).then(res => {
                    if (res.code === '2000') {
                        localStorage.clear();
                        setStorage('tokens', res.data.token);
                        setStorage('userName', res.data.userName);
                        setStorage('userMobile', res.data.userMobile);
                        if( checkedFlag ){
                            setCookie('userMobile', values.userMobile, 7); //保存帐号到cookie，有效期7天
			                setCookie('userPassword', upd , 7); //保存密码到cookie，有效期7天
                        }
                        this.props.dispatch({type: 'login/setToken', payload: res.data.token});
                        this.getSearchUserInfo(res.data.userMobile)
                    }
                }).catch(err => {
                    this.setState({loadingFlag:false})
                    message.error(err.message);
                })
            }
        })
    };
    //获取用户下的机构列表和机构下的业务角色
    getUserOrgAndRole = (params) => {
        scfBaseApi.getUserOrgsApi().then(res => {
            let data = res.data;
            if (data.length === 0){
                setTimeout(() => {
                    //5是借款方角色
                    if(params==2){
                        this.props.history.push('/companyInfoEntry');
                    }else{
                        if(data[0].businessRoles[0].businessRole=='operate'){
                            this.props.history.push('/admin/home');
                        }else{
                            this.props.history.push('/admin/homeClient');
                        }
                    }
                }, 1000);
            } else if (data.length === 1 && data[0].businessRoles.length === 1){
                setStorage('companyID', res.data[0].id);
                setStorage('companyName', res.data[0].orgName);
                this.setUser(data[0].id,data[0].businessRoles[0].businessRole);
            } else {
                setTimeout(() => {
                    this.props.history.push('/businessRole/select');
                }, 1000);

            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    //判断用户是否设置交易密码：接口是查询用户信息
    getSearchUserInfo = (params)=>{
        scfBaseApi.querySearchUserInfoApi().then(res => {
            if(res.code=='2000'){
                setStorage('userId',res.data.id)
                if(res.data.userStatus==0){
                    this.getUserOrgAndRole(res.data.systemId);
                }else if(res.data.userStatus==1){
                    message.error("您的账号被禁止登录，请联系管理员重新开通！")
                }else if(res.data.userStatus==2){
                    this.props.history.push(`/setDealPassword?mobile=${params}&route=0`)
                }
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    //设置用户
    setUser = (companyId,role) => {
        let params = {
            userCurrentRole:role,
            currentOrgId: companyId
        }
        scfBaseApi.setCurrentUserApi(params).then(res => {
            message.success(res.message);
            setTimeout(() => {
                if(role=='operate'){
                    this.props.history.push('/admin/home');
                }else{
                    this.props.history.push('/admin/homeClient');
                }
            }, 1000);
        }).catch(err => {
            message.error(err.message);
        })
    }
    onChange = (e)=> {
        if(!e.target.checked){
            delCookie('userMobile');
			delCookie('userPassword');
        }
        this.setState({
            checkedFlag:e.target.checked
        })
    }
    registerRoute =()=>{
        this.props.history.push('/register');
    }
    forgotPassworldRoute = ()=>{
        this.props.history.push('/forgotPassword');
    }
    smsCodeRoute = ()=>{
        this.props.history.push('/smsCode');
    }
    render() {
        const { loadingFlag ,  checkedFlag } = this.state;
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="login-wrapper">
                <div className="login">
                    <h2 className="com-text-center">企汇安供应商管理系统</h2>
                    <div className="login-form">
                        <Form onSubmit={this.handleUserSubmit} autoComplete="off">
                            <FormItem>
                                {getFieldDecorator("userMobile", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入手机号",
                                        },
                                        {
                                            validator:checkPhone,
                                        },
                                    ],
                                })(<Input className="input-user" maxLength={11}/>
                                )}
                            </FormItem>

                            <FormItem>
                                {getFieldDecorator("userPassword", {
                                    rules:[{
                                        required:true,
                                        message:'请输入登录密码'
                                    },
                                    //     {
                                    //     validator:checkPwd,
                                    // }
                                    ],
                                    validateTrigger:"onBlur"
                                })(<Input className="input-pwd" type="password" />)}
                            </FormItem>
                            <Row>
                                <Checkbox checked={checkedFlag} onChange={this.onChange}>记住密码</Checkbox>
                                <span onClick={this.smsCodeRoute} className='pv-login'>短信验证码登录</span>
                            </Row>
                            <Button
                                className="btn-submit"
                                style={{marginTop: 30}}
                                type="primary"
                                htmlType="submit"
                                block
                                loading={loadingFlag}
                            >
                                登录
                            </Button>
                            <div className="login-foot">
                                {
                                    /**
                                     * <span onClick={this.registerRoute}>立即注册</span>
                                     */
                                }
                                <span onClick={this.forgotPassworldRoute} className='pv-login'>忘记密码?</span>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const LoginUserFrom = Form.create()(LoginUser);
export default LoginUserFrom;

import { post } from '../http';

const scfBaseApi = {
    //查借款方名称
    queryBorrowerApi(params) {
        return post('/base/org/queryBorrower', params);
    },
    //添加企业客户
    addOrgApi(params) {
        return post('/base/org/add/org', params);
    },
    //添加个体工商户客户
    addIndividualApi(params) {
        return post('/base/org/add/individual', params);
    },
    //添加自然人客户
    addPersonApi(params) {
        return post('/base/org/add/person', params);
    },
    //客户信息详情
    queryDetailApi(params) {
        return post('/base/org/queryDetail', params);
    },
    // 客户信息列表
    orgListApi(params) {
        return post('/base/org/list', params);
    },
    //识别营业执照
    ocrBusinessLicenseApi(params) {
        return post('/base/org/ocrBusinessLicense', params);
    },
    //识别身份证头像
    ocrIdCardFrontApi(params) {
        return post('/base/org/ocrIdCardFront', params);
    },
    //识别身份证国徽
    ocrIdCardBackApi(params) {
        return post('/base/org/ocrIdCardBack', params);
    },
    //核心企业授信添加查所有资金方
    queryCapitalApi(params) {
        return post('/base/org/queryCapital', params);
    },
    //根据资金方查询所有核心企业
    queryCoreOrgApi(params) {
        return post('/base/org/queryCoreOrg', params);
    },
    //客户信息确认
    departUpdateOrgApi(params) {
        return post('/base/org/updateOrg', params);
    },
    //所有企业查询
    orgQueryApi(params) {
        return post('/base/org/query', params);
    },
    // 带角色客户关系维护列表
    listbyroleApi(params) {
        return post('/base/orgrelation/listbyrole', params);
    },
    // 关系维护查询
    queryOrgApi(params) {
        return post('/base/orgrelation/queryOrg', params);
    },
    // 查询企业客户关系
    queryOrgRelationApi(params) {
        return post('/base/orgrelation/queryOrgRelation', params);
    },
    //新增客户关系查询接口
    orgrelationQueryApi(params) {
        return post('/base/orgrelation/query', params);
    },
    //新增客户关系
    orgrelationAddApi(params) {
        return post('/base/orgrelation/add', params);
    },
    //删除客户关系
    orgrelationDeleteApi(params) {
        return post('/base/orgrelation/delete', params);
    },
    //查询省
    queryProvinceApi() {
        return post('/base/area/queryprovince');
    },
    //查询市
    queryMunicipalApi(params) {
        return post('/base/area/querymunicipal', params);
    },
    //查询县
    queryDistrictApi(params) {
        return post('/base/area/querydistrict', params);
    },
    //银行列表
    queryBankListApi(params) {
        return post('/base/bank/queryBankList', params,);
    },
    // 菜单列表
    menuListApi(params) {
        return post('/base/menu/list', params);
    },
    // 菜单新增
    menuAddApi(params) {
        return post('/base/menu/add', params);
    },
    // 菜单编辑查询
    menuQueryApi(params) {
        return post('/base/menu/query', params);
    },
    //菜单编辑
    menuUpdateApi(params) {
        return post('/base/menu/update', params);
    },
    //菜单删除
    menuDeleteApi(params) {
        return post('/base/menu/delete', params);
    },
    //新增子菜单
    menuAddChildrenApi(params) {
        return post('/base/menu/addChidren', params);
    },
    //左侧管理
    queryUserLeftMenuApi(params) {
        return post('/base/menu/queryUserLeftMenu', params);
    },
    //左侧管理
    queryParentMenuApi(params) {
        return post('/base/menu/queryParentMenu', params);
    },
    //查询带角色左侧管理
    queryUserMenuByRolesApi(params) {
        return post('/base/menu/queryUserMenuByRoles', params);
    },
    //所有菜单和权限查询
    menuQueryAllApi(params) {
        return post('/base/menu/query/all', params);
    },
    //操作权限查询
    operateQueryApi(params) {
        return post('/base/oper/query', params);
    },
    //操作权限编辑
    operateUpdateApi(params) {
        return post('/base/oper/update', params);
    },
    //操作权限新增
    operateAddApi(params) {
        return post('/base/oper/add', params);
    },
    //操作权限删除
    operateDeleteApi(params) {
        return post('/base/oper/delete', params);
    },
    // 组织机构列表
    departListbyroleApi(params) {
        return post('/base/depart/listbyrole', params);
    },
    //保存组织关系
    departAddApi(params) {
        return post('/base/depart/add', params);
    },
    //企业下部门查询
    departQueryApi(params) {
        return post('/base/depart/query', params);
    },
    // 组织机构新增客户关系查询
    departQueryOrgRelationApi(params) {
        return post('/base/depart/queryOrgRelation', params);
    },
    //组织机构编辑客户关系查询
    queryOrgRelationByOrgIdApi(params) {
        return post('/base/depart/queryOrgRelationByOrgId', params);
    },
    //组织机构详情
    departDetailApi(params) {
        return post('/base/depart/deltail', params);
    },
    // 角色列表
    roleListApi(params) {
        return post('/base/role/list', params);
    },
    // 角色新增
    roleAddApi(params) {
        return post('/base/role/add', params);
    },
    // 角色查询
    roleQueryApi(params) {
        return post('/base/role/query', params);
    },
    //角色编辑
    roleUpdateApi(params) {
        return post('/base/role/update', params);
    },
    //角色删除
    roleDeleteApi(params) {
        return post('/base/role/delete', params);
    },
    //查询角色拥有的权限
    roleQueryPermissionApi(params) {
        return post('/base/role/query/pemission', params);
    },
    // 系统列表
    systemListApi(params) {
        return post('/base/system/list', params);
    },
    // 系统新增
    systemAddApi(params) {
        return post('/base/system/add', params);
    },
    // 系统查询
    systemQueryApi(params) {
        return post('/base/system/query', params);
    },
    //系统编辑
    systemUpdateApi(params) {
        return post('/base/system/update', params);
    },
    //所有系统查询
    systemQueryAllApi() {
        return post('/base/system/queryAll');
    },
    // 用户列表
    userListApi(params) {
        return post('/base/user/list', params);
    },
    // 用户新增
    userAddApi(params) {
        return post('/base/user/add', params);
    },
    // 用户编辑
    userUpdateApi(params) {
        return post('/base/user/update', params);
    },
    //用户编辑查询
    userQueryApi(params) {
        return post('/base/user/query', params);
    },
    //用户启用
    userEnableApi(params) {
        return post('/base/user/enable', params);
    },
    //用户禁用
    userForbiddenApi(params) {
        return post('/base/user/forbidden', params);
    },
    //用户重置密码
    userResetApi(params) {
        return post('/base/user/reset', params);
    },
    //获取用户下的机构列表和机构下的业务角色
    getUserOrgsApi(params) {
        return post('/base/user/getUserOrgs', params);
    },
    //选择用户企业和角色
    setCurrentUserApi(params) {
        return post('/base/user/setCurrentUser', params);
    },
    //企业注册
    querycompanyRegesterApi(params) {
        return post('/base/v1/corp/reg', params);
    },
    //查询用户信息：判断是否设置交易密码
    querySearchUserInfoApi(params) {
        return post('/base/user/getUserInfo', params);
    },
    //查询企业关系
    relationsApi(params) {
        return post('/base/v1/org/relations', params);
    },
    //添加企业关联关系
    addRelationsApi(params) {
        return post('/base/v1/org/relation', params);
    },
    //企业关系审批动作
    auditApi(params) {
        let obj1 = Object.assign({}, params);
        delete obj1.id
        return post(`/base/v1/org/relation/${params.id}/audit`, obj1);
    },
    //获取邀请注册列表
    invitesApi(params) {
        return post('/base/v1/invites', params);
    },
    //新增注册邀请
    inviteApi(params) {
        return post('/base/v1/invite', params);
    },
    //操作注册邀请状态
    inviteIdApi(params) {
        let obj1 = Object.assign({}, params);
        delete obj1.inviteId
        return post(`/base/v1/invite/${params.inviteId}/audit`, obj1);
    },
    //详情
    infoDetailApi(params) {
        let obj = Object.assign({}, params);
        delete obj.id
        return post(`/base/v1/org/relations/${params.id}/info`,obj);
    },
  


}
export default scfBaseApi;
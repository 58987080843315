import React, {Component} from 'react';
import {withRouter} from "dva/router";
import SearchInfo from "./component/searchInfo";
import RelateMaintain from "./component/relateMaintain";
import "./index.scss";
class Index extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }
    render() {
        let item = this.props.match.params.item;
        return (
            <div className="client-relate-maintain-wrapper">
                {(() => {
                    switch (item) {
                        case  "search":
                            return <SearchInfo/>  //查询客户信息
                        case  "maintain":
                            return <RelateMaintain/>     //添加客户信息
                        default:
                            break;
                    }
                })()
                }
            </div>
        );
    }
}

export default withRouter(Index);
import React from 'react';
import echarts from 'echarts/lib/echarts';
import tree from 'echarts/lib/chart/tree';
import { Button, message } from "antd";
import { withRouter } from "dva/router";
import scfBusinessApi from "../../../../request/api/scfBusinessApi";
@withRouter
class TreeGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            data: this.props.data,
            checkedId: 0,
            isShowAdd: false,
            orgId: this.props.orgId,
            level: 1,  //树状图层级  默认显示层级
            treeMessage: "",//树信息
        };
    }

    componentDidMount() {
        let treeDom = document.getElementById('main');
        let myChart = echarts.init(treeDom);
        this.myChart = myChart;
        // this.handelUpdateEcharts({});
        // this.myChart.on('contextmenu', this.handleMouseover);
        // this.myChart.on('click', this.handleClickCircle);
        // this.getDepartDetail();
        this.getTreeMessage();
        window.onresize = this.myChart.resize;
    }

        //树信息 treeMessage
        getTreeMessage = () => {
            let params = {
                id: this.state.id
            }
            scfBusinessApi.getAdminTicketBackApi(params).then(res => {
                if(res.data.children.length==0){
                    return
                    
                }else{
                    let data = res.data.children[0];
                    data.level = -1;
                    this.handelUpdateEcharts(data);
                }
               
            }).catch(err => {
                message.error(err.message);
            })
        }
    // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     let { renderStatus, orgName, parentOrgName, orgId, parentOrgId } = nextProps;
    //     //由于添加和编辑中客户和上级机构改变，更新数据
    //     if (renderStatus != this.props.renderStatus) {
    //         let treeData = this.myChart.getOption().series[0].data;
    //         if (renderStatus === 1) {
    //             this.setState({ orgId });
    //             treeData[0].children[0].id = orgId;
    //             treeData[0].children[0].departName = orgName;
    //             treeData[0].children[0].children = [];
    //             this.handelUpdateEcharts(treeData[0]);
    //         } else if (renderStatus === 2) {
    //             treeData[0].id = parentOrgId;
    //             treeData[0].departName = parentOrgName;
    //             this.handelUpdateEcharts(treeData[0]);
    //         } else if (renderStatus === 3) {
    //             this.setState({ orgId });
    //             treeData[0] = {
    //                 id: parentOrgId,
    //                 onlyId: new Date().getTime() - 10,
    //                 departName: parentOrgName,
    //                 children: [{
    //                     id: orgId,
    //                     departName: orgName,
    //                     onlyId: new Date().getTime() - 20,
    //                     children: []
    //                 }]
    //             }
    //             this.handelUpdateEcharts(treeData[0]);
    //         }

    //     }
    // }
    //重绘chartTree
    getChartTree = (data) => {
        console.log(data)
        let option = {
            calculable: false,
            series: [
                {
                    name: '树图',
                    type: 'tree',
                    orient: 'TB', // 垂直布局
                    data: [data],
                    rootLocation: { x: 'center', y: '15%' }, // 根节点位置  {x: 'center',y: 10}
                    nodePadding: 20,
                    layerPadding: 40,
                    // borderColor: 'black',
                    // symbol: 'emptyCircle',
                    symbolSize: [300, 100],
                    symbol: 'rect',  //子级选择区域的形状默认circle(圆形),rect矩形
                    symbolKeepAspect: true,  //图形缩放保持长宽比
                    edgeShape: 'polyline',
                    // roam:"move",
                    initialTreeDepth: -1, //节点展开的层级
                    //容器位置
                    left: 'center',
                    bottom: 50,
                    top: 50,
                    width: "100%",
                    expandAndCollapse: true,  //子树折叠和展开
                    //节点样式
                    itemStyle: {
                        color: "#333333",
                        normal: {
                            color: '#ffffff', // 节点背景色
                            borderColor: '#1890ff',
                            label: {
                                show: true,
                                position: ['50%', '50%'],
                                fontWeight: 'normal',
                                lineHeight: 24,
                                align: 'center',  //文本水平对齐方式
                                verticalAlign: "middle",//文本垂直对齐方式
                                color: '#696969',
                                fontSize: 14,
                                formatter: function (params) {
                                   console.log(params)
                                    if (params.data.level === -1 ) {
                                        return [
                                            `出票人名称：${params.data.ticketFromOrgName}`,
                                            `收款人名称：${params.data.ticketToOrgName}`,
                                            `收票日期：${params.data.createTime}`,
                                            `票面金额(元)：${params.data.transferAmount}`
                                         ].join('\n')
                                    }else {
                                        return [
                                            `背书转让人名称：${params.data.ticketFromOrgName}`,
                                            `被背书转让人名称：${params.data.ticketToOrgName}`,
                                            `背书转让日期：${params.data.createTime}`,
                                            `背书转让额度(元)：${params.data.transferAmount}`
                                         ].join('\n')
                                    }
                                },
                            },
                            //树图边样式
                            lineStyle: {
                                color: '#1890ff',
                                width: 3
                                // type: 'broken' // 'curve'|'broken'|'solid'|'dotted'|'dashed'
                            }
                        },
                        emphasis: {
                            label: {
                                show: false
                            }
                        }
                    },
                }

            ],
            expandAndCollapse: true,    //子树折叠和展开的交互，默认打开
            animationDuration: 550,     //初始动画的时长，支持回调函数,默认1000
            animationDurationUpdate: 750//数据更新动画的时长，默认300
        }
        this.myChart.clear();
        this.myChart.setOption(option, true);

    }
    //获取初始组织架构
    //获取组织机构编辑页详情和详情
    getDepartDetail = () => {
        let { id, item, parentOrgId, parentOrgName, orgId, orgName } = this.props;
        if (item === "edit" || item === "detail") {
            scfBusinessApi.getAdminTicketBackApi({ id }).then(res => {
                let data = res.data;
                data.departLevel = -1;
                data.onlyId = new Date().getTime() - 10;
                data.children[0].departLevel = 0;
                data.children[0].onlyId = new Date().getTime() - 20;
                this.handelUpdateEcharts(data);
            }).catch(err => {
                message.error(err.message);
            })
        } else {
            let data = {
                id: parentOrgId,
                onlyId: new Date().getTime() - 10,
                departName: parentOrgName,
                departLevel: -1,
                children: [{
                    id: orgId,
                    departName: orgName,
                    onlyId: new Date().getTime() - 20,
                    departLevel: 0,
                    children: [],
                }]
            }
            this.handelUpdateEcharts(data);
        }
    }
    //更新charts数据
    handelUpdateEcharts = (data) => {
        this.getChartTree(data);
        this.handleEchartsSize();
    }


    //调整容器高度和宽度
    handleEchartsSize = () => {
        let nodes = this.myChart._chartsViews[0]._data._graphicEls;
        let allNode = 0;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i] === undefined) {
                continue;
            }
            allNode++;
        }

        let currentHeight = 150 * allNode;
        let currentWidth = 70 * allNode;
        let newHeight = Math.max(currentHeight, 300);
        let newWidth = Math.max(currentWidth, window.innerWidth);
        let treeDom = document.getElementById('main');
        treeDom.style.height = newHeight + 'px';
        // treeDom.style.width = newWidth + 'px';
        this.myChart.resize();
    }
    handleMouseover = (params) => {
        params.event.event.preventDefault()  //屏蔽浏览器默认行为
    }

    handleClickCircle = (param) => {
        this.handleEchartsSize();

    }


    render() {
        return (
            <div className="tree-graph-wrapper">

                <div id="main" className="tree-img"></div>


            </div>

        )
    }
}

export default TreeGraph;




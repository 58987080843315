import React, {Component} from 'react';
import {Button, message, Table} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import ModalTipTwoBtn from "../../../components/modalTipTwoBtn/index";
import AddModal from "./addModal";
class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum:1,
            pageSize:10,
            totalCount: 0,
            tableData:[],
            isShowModal:false,
            isShowAdd:false,//显示新增
            relationOrgId:0,
            orgId:this.props.orgId,
            businessRole:this.props.businessRole, //关联的角色
            relationBusinessRole:this.props.relationBusinessRole //自身的角色

        }
    }
    componentDidMount() {
        this.getCompanyList();
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {orgId,businessRole} = nextProps;
        if(this.props.orgId != orgId){
            this.setState({
                orgId,
                businessRole
            },()=>{
                this.getCompanyList();
            })
        }
    }
    //获取企业列表
    getCompanyList = () => {
        let {pageNum,pageSize,orgId, businessRole,relationBusinessRole} = this.state;
        let params={
            orgId,
            businessRole:relationBusinessRole,
            relationBusinessRole:businessRole,
            pageNum,
            pageSize,
          };
          scfBaseApi.queryOrgRelationApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum:current
        }, () => {
            this.getCompanyList();
        })
    }

    changeShowSize=(current, pageSize)=>{
        this.setState({
            pageSize
        }, () => {
            this.getCompanyList();
        })
    }
    handleDelete=(relationOrgId=0)=>{
        this.setState({
            isShowModal:true,
            relationOrgId
        })
    }
    handleNewAdd=()=>{
        this.setState({
            isShowAdd:true,

    })
    }
    //右侧操作按钮调用的方法
    operationOk=()=>{
        let { relationOrgId,orgId,businessRole,relationBusinessRole}=this.state;
        let params={orgId, relationOrgId,businessRole:relationBusinessRole,relationBusinessRole:businessRole};
        scfBaseApi.orgrelationDeleteApi(params).then(res => {
            message.success(res.message);
            this.setState({
                isShowModal:false
            });
            this.getCompanyList();
        }).catch(err =>{
            message.error(err.message)
        })
    }
    //此方法固定，卸载弹框dom
    operationCancel=()=>{
        this.setState({
            isShowModal:false
        })
    }
    //关闭添加弹框
    operationCancelAdd=()=>{
        this.setState({
            isShowAdd:false
        })
    }
    operationOkAdd=()=>{
        this.setState({
            isShowAdd:false
        });
        this.getCompanyList();
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return null;
        };
    }
    render() {
        let {businessRoleName}=this.props;
        let {pageNum,pageSize,tableData,totalCount,isShowModal,isShowAdd,orgId,businessRole,relationBusinessRole}=this.state;
        let pagination={
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize:pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange:this.changeShowSize,
            pageSizeOptions:['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let modalProps={
            title:"删除",
            text:"是否删除该关联客户!",
            operationOk: this.operationOk,
            operationCancel:this.operationCancel,

        };
        let addModalProps={
            orgId,
            businessRole,
            relationBusinessRole,
            operationOk:this.operationOkAdd,
            operationCancel:this.operationCancelAdd
        };
        let columns = [
            {
                title: '序号',
                dataIndex:'id',
                key:'id',
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '客户名称',
                dataIndex: 'orgName',
                key: 'orgName',
                sorter: (a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                }
            },
            {
                title: '证件类型',
                dataIndex: 'idTypeCdDesc',
                key: 'idTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.idTypeName){
                        a.idTypeName=""
                    }else if(!b.idTypeName){
                        b.idTypeName=""
                    }
                    return a.idTypeName.localeCompare(b.idTypeName)
                }
            },
            {
                title: '证件号码',
                dataIndex: 'cardNo',
                key: 'cardNo',
                sorter: (a,b)=>{
                    if(!a.cardNo){
                        a.cardNo=""
                    }else if(!b.cardNo){
                        b.cardNo=""
                    }
                    return a.cardNo-b.cardNo
                }
            },
            {
                title: '操作',
                render: (record) => {
                    return <Button type="primary" className="com-table-btn" onClick={()=>{this.handleDelete(record.id)}}>删除</Button>
                }
            },
        ];
        return (
            <div className="company-list-wrapper">
                <div className="flex-between describe-maintain">
                    <h5>已关联{businessRoleName}列表</h5>
                    <Button type="primary" className="com-middle-btn-size" onClick={this.handleNewAdd}>+新增</Button>
                </div>
                <Table
                    className="freezing-deposit-table"
                    columns={ columns }
                    dataSource={tableData}
                    rowKey={ (record,index) => record.id }
                    pagination={totalCount>10?pagination:false}
                />
                {isShowAdd === true?<AddModal {...addModalProps}/>:null}
                {isShowModal === true ? <ModalTipTwoBtn {...modalProps} /> : null}
            </div>
        );
    }
}

export default CompanyList;
import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import scfBusinessApi from "../../../request/api/scfBusinessApi";
import moment from 'moment';
import '../index.scss'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;

class CommercialTicketAuthorityManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,//当前页
            pageSize: 10,//每页大小
            startTime: "",
            endTime: "",
            totalCount: 0,
            tableData: [],
            statusList:[],//状态列表
            roleList:[],//角色列表
        }
    }
    componentDidMount() {
        this.handleSubmit(1)
        this.getStatusList()
        this.getRoleList()
    }
//获取状态
    getStatusList = () =>{
        let params={
            typeList:["relationStatusCd"]
        }
        scfBusinessApi.dictionaryApi(params).then(res=>{
            this.setState({
                statusList:res.data.relationStatusCd
            })
        })   
    }
//获取角色
    getRoleList =() =>{
        let params={
            typeList:["roleCd"]
        }
        scfBusinessApi.dictionaryApi(params).then(res=>{
            let data = res.data.roleCd
            this.setState({
                roleList:data.splice(0,3)
            })
        })   
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }

    //分页change
    handleTableChange = (pagination, filters, sorter) => {
         this.handleSubmit(pagination.current)
    };

    //查询按钮
    handleClick = () => {
        this.handleSubmit(1)
    };

      //查询
      handleSubmit = (page) => {
        const { pageSize } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { name,status,otherRole,role } = values;
                let params = {
                   name,
                   status,
                   otherRole,
                   role,
                   pageSize,
                   pageNum:page,
                }
                scfBaseApi.relationsApi(params).then(res => {
                    this.setState({
                        tableData: res.data,
                        pageNum: page
                    })
                }).catch(err => {
                    message.error(err.message);
                });

            }
        })
    } 
    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.handleSubmit(current)
        })
    }

      //重置
      handleReset = () => {
        this.props.form.setFieldsValue({
            name: "",
            status: "",
            role: "",
            otherRole: "",
            
        });
        this.setState({
            pageNum: 1,
            pageSize: 10,
        }, () => {
            this.handleSubmit(1);
        })
    }
    handleClickHeader = (row) => {
        row.className = row.className + " left"
    }

    confirmInvitation = (id) => {
        this.props.history.push(`/admin/commercialTicketManage/ConfirmRoleAuthority/${id}`)
    }
    resubmit = (id) =>{
        localStorage.setItem("infoId",id)
        this.props.history.push(`/admin/commercialTicketManage/AddRoleAuthority/resubmit`)
    }
   //新增他方角色权限
    addRoleAuthority =()=>{
        this.props.history.push(`/admin/commercialTicketManage/AddRoleAuthority/add`)
    }
    render() {
        let { getFieldDecorator } = this.props.form;
        let { pageNum, statusList,roleList, tableData } = this.state;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };

        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '他方企业名称',
                dataIndex: 'otherOrgName',
                key: 'otherOrgName',
                sorter: (a, b) => {
                    if (!a.otherOrgName) {
                        a.otherOrgName = ""
                    } else if (!b.otherOrgName) {
                        b.otherOrgName = ""
                    }
                    return a.otherOrgName.localeCompare(b.otherOrgName)
                },

            },
            {
                title: '联系人电话',
                dataIndex: 'mobile',
                key: 'mobile',
                sorter: (a, b) => {
                    if (!a.mobile) {
                        a.mobile = ""
                    } else if (!b.mobile) {
                        b.mobile = ""
                    }
                    return a.mobile - b.mobile
                },
            },
            {
                title: '他方权限角色',
                dataIndex: 'otherOrgRoleCdDesc',
                key: 'otherOrgRoleCdDesc',
                sorter: (a, b) => {
                    if (!a.otherOrgRoleCdDesc) {
                        a.otherOrgRoleCdDesc = ""
                    } else if (!b.otherOrgRoleCdDesc) {
                        b.otherOrgRoleCdDesc = ""
                    }
                    return a.otherOrgRoleCdDesc.localeCompare(b.otherOrgRoleCdDesc)
                },

            },
            {
                title: '我方权限角色',
                dataIndex: 'roleCdDesc',
                key: 'roleCdDesc',
                sorter: (a, b) => {
                    if (!a.roleCdDesc) {
                        a.roleCdDesc = ""
                    } else if (!b.roleCdDesc) {
                        b.roleCdDesc = ""
                    }
                    return a.roleCdDesc.localeCompare(b.roleCdDesc)
                },
            },
            {
                title: '新增日期',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }

            },
            {
                title: '状态',
                dataIndex: 'relationStatusCdDesc',
                key: 'relationStatusCdDesc',
                sorter: (a, b) => {
                    if (!a.relationStatusCdDesc) {
                        a.relationStatusCdDesc = ""
                    } else if (!b.relationStatusCdDesc) {
                        b.relationStatusCdDesc = ""
                    }
                    return a.relationStatusCdDesc.localeCompare(b.relationStatusCdDesc)
                },
            },
            {
                title: '操作',
                fixed: 'right',
                render: (record) => {
                    return <div className="flex-around-center">
                        {record.operate==="10"?<Button type="primary" onClick={()=>this.confirmInvitation(record.id)}>确认邀请</Button>:
                        record.operate==="20"?<Button type="primary" onClick={()=>this.resubmit(record.id)}>重新提交</Button>:<span>--</span> }
                        
                    </div>

                }
            },
        ];

        return (
            <div className="commercialTicketPay">
                <div>
                    <Form className="" {...formRowItem}>
                        <Row>
                            <Col span={8}>
                                <FormItem label="企业名称">
                                    {
                                        getFieldDecorator('name', {
                                            initialValue: ""
                                        })(<Input placeholder="" />)
                                    }
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="状态">
                                    {
                                        getFieldDecorator('status', {
                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Select>
                                            <Option value="">全部</Option>
                                             {statusList.map(item=>{
                                                return (<Option value={item.dicKey} key={item.dicKey}>{item.dicValue}</Option>)
                                            })}
                                        </Select>)
                                    }
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="他方权限角色">
                                    {
                                        getFieldDecorator('otherRole', {

                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Select>
                                            <Option value="">全部</Option>
                                            {roleList.map(item=>{
                                                return (<Option value={item.dicKey} key={item.dicKey}>{item.dicValue}</Option>)
                                            })}
                                        </Select>)
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <FormItem label="我方权限角色">
                                    {
                                        getFieldDecorator('role', {
                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Select>
                                            <Option value="">全部</Option>
                                           {roleList.map(item=>{
                                                return (<Option value={item.dicKey} key={item.dicKey}>{item.dicValue}</Option>)
                                            })}
                                        </Select>)
                                    }
                                </FormItem>
                            </Col>
                            <Col span={16}>
                                <Form.Item>
                                    <div style={{ width: '100%' }}>
                                        <Button type="primary" onClick={this.handleClick} style={{ marginRight: '20px' }} className="com-middle-btn-size">查询</Button>
                                        <Button type="default" onClick={this.handleReset} className="com-middle-btn-size">重置</Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size" onClick={this.addRoleAuthority}>新增他方角色权限</Button>
                    </div>
                
                <Table
                    columns={columns}
                    dataSource={tableData.list}
                    rowKey={(record, index) => record.id}
                    pagination={{
                        showTotal: total => `总共${tableData.total}条`,
                        total: tableData.total ? tableData.total : 0,
                        showQuickJumper: true,
                        onShowSizeChange: this.changeShowSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        current: pageNum,
                    }}
                    onChange={this.handleTableChange}
                />

            </div>
        );
    }
}

export default Form.create()(CommercialTicketAuthorityManage);




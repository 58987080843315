import React, { Component } from 'react'
import "../../index.scss"
import Background from '../../../../assets/img/4444.jpg';
import scfBusinessApi from "../../../../request/api/scfBusinessApi";
import scfBaseApi from '../../../../request/api/scfBaseApi';
import { message } from 'antd';
var nzhcn = require("nzh/cn"); //直接使用简体中文



export default class ticketTable extends Component {
   constructor(props){
       super(props)
       this.state={
           ticketMessage:"",
           transferAmountCapitalize:"",
           ticketAmountCapitalize:"",
           remainAmountCapitalize:"",
       }
   }
    componentDidMount(){
       this.getTicketMessage()
      }
         //票据信息
    getTicketMessage = () => {
        let {id} = this.props
        let params = {
            id: id
        }
        scfBusinessApi.getTransferDetailApi(params).then(res => {
            this.setState({
                ticketMessage: res.data,
                transferAmountCapitalize:res.data.transferAmount,
                ticketAmountCapitalize:res.data.ticketAmount,
                remainAmountCapitalize:res.data.remainAmount,

            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    render() {
        let {ticketMessage,remainAmountCapitalize,ticketAmountCapitalize,transferAmountCapitalize} = this.state
      
        let ticketStyle = {
                width: '100%',
                textAlign: 'center',
                
        }
        let background = {
                width: '100%',
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'repeat',
                backgroundPosition: '0 0',
                padding: '30px 20px 20px 20px'
        }
        return (
            <div style={background}>
                <h2 style={{textAlign:'center'}}>电子商业承兑汇票-转让</h2>
                <div style={{display:'flex'}}>
                    <div style={{marginBottom: '0px',flex:'1'}}>
                        <div>交易日期：<span>{ticketMessage.createTime}</span></div>
                        <div>汇票到期日：<span>{ticketMessage.ticketExpireDate}</span></div>
                    </div>
                    <div style={{marginBottom: '0px',flex:'1',paddingLeft:'100px',boxSizing:'border-box'}}>
                        <div>票据状态：<span>{ticketMessage.ticketTransStatusCdDesc}</span></div>
                        <div>票据号码：<span>{ticketMessage.ticketNo}</span></div>
                    </div>
                </div>
                <table border="1" style={ticketStyle}>
                           <tbody>
                               <tr>
                                   <td rowSpan="4">出票人</td>
                                   <td></td>
                                   <td colSpan="2"></td>
                                   <td rowSpan="4">收款人</td>
                                   <td>账号</td>
                                   <td>{ticketMessage.toBankCnap}</td> 
                               </tr>
                               <tr>
                                   <td>全称</td>
                                   <td colSpan="2">{ticketMessage.ticketFromOrgName}</td>
                                   <td>全称</td>
                                   <td>{ticketMessage.ticketToOrgName}</td>
                                    
                               </tr>
                               <tr>
                                   <td></td>
                                   <td colSpan="2"></td>
                                   <td>开户行</td>
                                   <td>{ticketMessage.toBankName}</td>
                               </tr>
                               <tr>
                                   <td></td>
                                   <td colSpan="2"></td>
                                   <td>开户行号</td>
                                   <td>{ticketMessage.toBankNumber}</td>
                               </tr>
                               <tr>
                                   <td colSpan="2">票据金额</td>
                                   <td>小写</td>
                                   <td>{ticketMessage.transferAmount}</td>
                                   <td colSpan="2">人民币（大写）</td>
                                   <td>{nzhcn.toMoney(transferAmountCapitalize,{outSymbol:false})}</td>
                               </tr>
                               <tr>
                                   <td colSpan="2">原票据额度</td>
                                   <td>小写</td>
                                   <td>{ticketMessage.ticketAmount}</td>
                                   <td colSpan="2">人民币（大写）</td>
                                   <td>{nzhcn.toMoney(ticketAmountCapitalize,{outSymbol:false})}</td>
                               </tr>
                               <tr>
                                   <td colSpan="2">剩余票据额度</td>
                                   <td>小写</td>
                                   <td>{ticketMessage.remainAmount}</td>
                                   <td colSpan="2">人民币（大写）</td>
                                   <td>{nzhcn.toMoney(remainAmountCapitalize,{outSymbol:false})}</td>
                               </tr>
                           </tbody>
                       </table>
            </div>
        )
    }
}

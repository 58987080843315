import React ,{ Component } from 'react';
import { Modal} from 'antd';
import "./index.scss";

class  ModalTipTwoBtn extends Component {
    constructor(props){
        super(props);
        this.state={
            visible:true,
        }
    }

    //右侧有操作按钮（确定、禁用等）
    handleOk=()=>{
        this.props.operationOk();
    }

    //左侧无实际操作按钮（取消等）
    handleCancel=()=>{
        this.setState({
            visible:false  //不显示弹框
        },()=>{
            this.props.operationCancel(); //在引入弹框页面清理挂载的Dom
        })
    }

    render(){
        const {visible} = this.state;
        const {  title,text,okText,cancelText} = this.props;
        return (
                <Modal
                    title={title}
                    visible={visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    maskClosable={false}
                    okText={okText===undefined?"确定":okText}
                    cancelText={cancelText===undefined?"取消":cancelText}
                    width={500}
                    className="model-two-wrapper"
                >
                    <div className="com-text-center" dangerouslySetInnerHTML={{__html:text}}></div>
                </Modal>
        )
    }
}
export default ModalTipTwoBtn;
import React, { Component } from 'react';
import { connect } from 'dva';
import { getUrlParam } from '@/utils/tools';
import { Spin, Button, Descriptions } from 'antd';

@connect((store) => ({ store: store.borrowerCredit, loading: store.loading }))
class DetailComp extends Component {

    componentDidMount () {
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerDetailData', payload: getUrlParam('id')});
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { borrowerDetailData } = this.props.store;
        const isLoding1 = this.props.loading.effects['borrowerCredit/getBorrowerDetailData'];
        return (
            <div className='addComp_style'>
                <Spin spinning={isLoding1}>
                    <Descriptions title="项目-产品信息" bordered column={2} size="small">
                        <Descriptions.Item label="项目名称">{ borrowerDetailData && borrowerDetailData.projectName ? borrowerDetailData.projectName : null }</Descriptions.Item>
                        <Descriptions.Item label="产品名称">{ borrowerDetailData && borrowerDetailData.productName ? borrowerDetailData.productName : null }</Descriptions.Item>
                        <Descriptions.Item label="核心企业名称">{ borrowerDetailData && borrowerDetailData.companyName ? borrowerDetailData.companyName : null }</Descriptions.Item>
                        <Descriptions.Item label="资金方名称">{ borrowerDetailData && borrowerDetailData.capitalName ? borrowerDetailData.capitalName : null }</Descriptions.Item>
                        <Descriptions.Item label="融资模式">{ borrowerDetailData && borrowerDetailData.productModeName ? borrowerDetailData.productModeName : null }</Descriptions.Item>
                        <Descriptions.Item label="产品利率（%）">{ borrowerDetailData && borrowerDetailData.productStartRate ? borrowerDetailData.productStartRate : ''} {borrowerDetailData && borrowerDetailData.productEndRate ? `-${borrowerDetailData.productEndRate}` : null }</Descriptions.Item>
                        <Descriptions.Item label="提前还款手续费率（%）">{ borrowerDetailData && borrowerDetailData.productEarlyRate ? borrowerDetailData.productEarlyRate : null }</Descriptions.Item>
                        <Descriptions.Item label="逾期利率（%）">{ borrowerDetailData && borrowerDetailData.productOverdueRate ? borrowerDetailData.productOverdueRate : null }</Descriptions.Item>
                        <Descriptions.Item label="融资比例（%）">{ borrowerDetailData && borrowerDetailData.productFinanceScale ? borrowerDetailData.productFinanceScale : null }</Descriptions.Item>
                        <Descriptions.Item label="产品可用授信额度（元）">{ borrowerDetailData && borrowerDetailData.totalAmount ? borrowerDetailData.totalAmount : null }</Descriptions.Item>
                        <Descriptions.Item label="还款方式">{ borrowerDetailData && borrowerDetailData.productRepayWayName ? borrowerDetailData.productRepayWayName : null }</Descriptions.Item>
                        <Descriptions.Item label="借款期限">{ borrowerDetailData && borrowerDetailData.productLoanPeriodName ? borrowerDetailData.productLoanPeriodName : null }</Descriptions.Item>
                    </Descriptions>
                    <p style={{height: 24}}></p>
                    <Descriptions title="借款方授信信息" bordered column={2} size="small">
                        <Descriptions.Item label="借款方名称">{ borrowerDetailData && borrowerDetailData.borrowerName ? borrowerDetailData.borrowerName : null }</Descriptions.Item>
                        <Descriptions.Item label="借款方授信额度（元）">{ borrowerDetailData && borrowerDetailData.totalAmount !== null ? borrowerDetailData.totalAmount : null }</Descriptions.Item>
                        <Descriptions.Item label="产品利率（%）">{ borrowerDetailData && borrowerDetailData.borrowerRate !== null ? borrowerDetailData.borrowerRate : null }</Descriptions.Item>
                        <Descriptions.Item label="融资比例（%）">{ borrowerDetailData && borrowerDetailData.borrowerFinanceScale !== null ? borrowerDetailData.borrowerFinanceScale : null }</Descriptions.Item>
                        <Descriptions.Item label="授信起始日">{ borrowerDetailData && borrowerDetailData.borrowerStartRateName ? borrowerDetailData.borrowerStartRateName : null }</Descriptions.Item>
                        <Descriptions.Item label="授信到期日">{ borrowerDetailData && borrowerDetailData.borrowerEndRateName ? borrowerDetailData.borrowerEndRateName : null }</Descriptions.Item>
                        <Descriptions.Item label="借款期限">{ borrowerDetailData && borrowerDetailData.borrowerLoanPeriodName ? borrowerDetailData.borrowerLoanPeriodName : null }</Descriptions.Item>
                    </Descriptions>
                </Spin>
                <div className='wraper-cd-center' style={{marginTop: 24}}>
                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                </div>
            </div>
        )
    }
}

export default DetailComp;

import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBusinessApi from "../../request/api/scfBusinessApi";
import moment from 'moment';
import JSONPretty from 'react-json-pretty';
import "./index.scss"

const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;

class InterfaceQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,//当前页
            pageSize: 10,//每页大小
            status: "",//状态
            requestKey: "",//请求报文关键字
            responseKey: "",//响应报文关键字
            requestMessage: "",//请求报文详情
            responseMessage: "",//响应报文详情
            startTime: "",
            endTime: "",
            totalCount: 0,
            tableData: [],
            visible: false,//控制解密报文弹框
        }
    }
    componentDidMount() {
        this.getList();
    }
    //获取列表
    getList = () => {
        let { pageNum, pageSize } = this.state;
        let params = { pageNum, pageSize,channel:"CFB_ICIP"};
        scfBusinessApi.listApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data,
                totalCount: data.total,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //重置
    handleReset = () => {
        this.props.form.setFieldsValue({
            requestDate: "",
            status: "",
            requestKey: "",
            responseKey: "",
        });
        this.setState({
            requestDate: "",
            status: "",
            requestKey: "",
            responseKey: "",
            pageNum: 1,
            pageSize: 10,
            startTime: "",
            endTime: ""
        }, () => {
            this.getList();
        })
    }
     //搜索
     handleSubmit = (page) => {
        const { startTime, endTime, pageNum,pageSize } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { status, requestKey, responseKey } = values;
                let params = {
                    pageSize,
                    startTime,
                    endTime,
                    status,
                    requestKey,
                    responseKey,
                    pageNum:page,
                    channel:"CFB_ICIP"
                }
                scfBusinessApi.listApi(params).then(res => {
                    this.setState({
                        tableData: res.data,
                        pageNum: page,
                    })
                }).catch(err => {
                    message.error(err.message);
                });

            }
        })
    }

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
    //请求时间change
    handleRequestDateChange = (data) => {
        if (data.length <= 0) {
            this.setState({
                startTime: '',
                endTime: ''
            })
        } else {
            let startTime = moment(data[0]).utcOffset(480).format('YYYY-MM-DD HH:mm:ss');
            let endTime = moment(data[1]).utcOffset(480).format('YYYY-MM-DD HH:mm:ss');
            this.setState({
                startTime,
                endTime,
            })
        }
    };

    //解密报文弹框显示
    showModal = (record) => {
        this.setState({
            requestMessage: record.requestMessage,
            responseMessage: record.responseMessage,
            visible: true
        })
    }

    handleCancel = e => {
        this.setState({
            visible: false
        })
    };
        //分页change
        handleTableChange = (pagination, filters, sorter) => {
            this.handleSubmit(pagination.current);
        };
    
        //查询按钮
        handleClick = () => {
            this.handleSubmit(1);
        };
        changeShowSize = (current, pageSize) => {
            this.setState({
                pageSize
            }, () => {
                this.getList();
            })
        }
        handleClickHeader = (row) =>{
            row.className=row.className+" left"
        }
    render() {
        let { getFieldDecorator } = this.props.form;
        let { pageNum, pageSize, tableData, totalCount, requestMessage, responseMessage } = this.state;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };

        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '接口名称',
                dataIndex: 'apiName',
                align: 'left',
                key: 'apiName',
                width: 277,
                sorter:(a,b)=>{
                    if(!a.apiName){
                        a.apiName=""
                    }else if(!b.apiName){
                        b.apiName=""
                    }
                    return a.apiName.localeCompare(b.apiName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
           
            },
            {
                title: '接口地址',
                dataIndex: 'apiPath',
                align: 'left',
                key: 'apiPath',
                width: 277,
                sorter:(a,b)=>{
                    if(!a.apiPath){
                        a.apiPath=""
                    }else if(!b.apiPath){
                        b.apiPath=""
                    }
                    return a.apiPath.localeCompare(b.apiPath)
                },
                onHeaderCell: row => this.handleClickHeader(row),
               
            },
            {
                title: '服务方',
                dataIndex: 'channelName',
                key: 'channelName',
                sorter:(a,b)=>{
                    if(!a.channelName){
                        a.channelName=""
                    }else if(!b.channelName){
                        b.channelName=""
                    }
                    return a.channelName.localeCompare(b.channelName)
                }
              
            },
            {
                title: '状态',
                dataIndex: 'apiReturnCode',
                key: 'apiReturnCode',
                sorter:(a,b)=>{
                    if(!a.apiReturnCode){
                        a.apiReturnCode=""
                    }else if(!b.apiReturnCode){
                        b.apiReturnCode=""
                    }
                    return a.apiReturnCode.localeCompare(b.apiReturnCode)
                }
            },
            {
                title: '请求流水号',
                dataIndex: 'transNo',
                key: 'transNo',
                width: 277,
                sorter: (a, b) => {
                    if(!a.transNo){
                        a.transNo=""
                    }else if(!b.transNo){
                        b.transNo=""
                    }
                    // var arr = ['',"1","3"]
                    // arr.sort((a,b)=>{
                    //     return a-b
                    // })
                    // console.log(arr)
                    return a.transNo - b.transNo
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                }

            },

            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                sorter:(a,b)=>{
                    if(!a.createUserName){
                        a.createUserName=""
                    }else if(!b.createUserName){
                        b.createUserName=""
                    }
                    return a.createUserName.localeCompare(b.createUserName)
                }
            },
            {
                title: '操作',
                render: (record) => {

                    return <div className="flex-around-center">
                        <span onClick={() => this.showModal(record)} className="handle">查看解密报文</span>
                    </div>

                }
            },
        ];

        return (
            <div>
                <Form onSubmit={this.handleSubmit} className="" {...formRowItem}>
                    <Row>
                        <Col span={8}>
                            <FormItem label="请求时间">
                                {
                                    getFieldDecorator('requestDate', {
                                        initialValue: "",
                                    })(<RangePicker showTime onChange={this.handleRequestDateChange} style={{ width: 266.66 }} />)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="请求状态">
                                {
                                    getFieldDecorator('status', {
                                        initialValue: ""
                                    })(<Select>
                                        <Option key="" value="">请选择</Option>
                                        <Option key="" value="success">成功</Option>
                                        <Option key="" value="fail">失败</Option>
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="请求报文">
                                {
                                    getFieldDecorator('requestKey', {
                                        initialValue: ""
                                    })(<Input placeholder="请求报文" />)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <FormItem label="响应报文">
                                {
                                    getFieldDecorator('responseKey', {

                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input placeholder="响应报文" />)
                                }
                            </FormItem>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                            <div>
                            <Button type="primary" onClick={this.handleClick} style={{marginRight:'20px'}} className="com-middle-btn-size">查询</Button>
                            <Button type="default" onClick={this.handleReset} className="com-middle-btn-size">重置</Button>
                            </div>
                        </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    columns={columns}
                    dataSource={tableData.list}
                    rowKey={(record, index) => record.id}
                    pagination={{
                        showTotal: total => `总共${tableData.total}条`,
                        total: tableData.total? tableData.total : 0,
                        showQuickJumper: true,
                        onShowSizeChange: this.changeShowSize,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        current: pageNum,
                    }}
                    onChange={this.handleTableChange}
                />
                

                <Modal
                    title="解密报文"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    width="740px"
                    className="messageDetails"
                    footer={null}
                >
                    <div className="message res">
                        <div className="title">请求解密报文</div>
                        <div className="content">
                            <JSONPretty id="json-pretty" data={requestMessage}></JSONPretty>
                        </div>
                    </div>
                    <div className="message req">
                        <div className="title">响应解密报文</div>
                        <div className="content">
                            <JSONPretty id="json-pretty" data={responseMessage}></JSONPretty>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(InterfaceQuery);




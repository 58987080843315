import React, {Component} from 'react';
import {Form, Input, message, Modal, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import scfBusinessApi from '../../../request/api/scfBusinessApi';

const FormItem = Form.Item;
const Option = Select.Option;

class AddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            systemCode: "",
            systemTypeArr:[]
        }
    }

    componentDidMount() {
        let {modalStatus, systemId} = this.props;
        this.getDicLt("systemType");
        if (modalStatus === 2) {
            scfBaseApi.systemQueryApi({id: systemId}).then(res => {
                let data = res.data;
                let {systemCode, systemName, systemType} = data;
                this.props.form.setFieldsValue({
                    systemName,
                    systemType
                });
                this.setState({systemCode});
            }).catch(err => {
                message.error(err.message);
            })
        }
    }
    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType:type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {modalStatus, systemId} = this.props;
                let {systemCode} = this.state;
                let apiName = modalStatus === 1 ? "systemAddApi" : "systemUpdateApi";
                if (modalStatus === 2) {
                    values.id = systemId;
                    values.systemCode = systemCode;
                }
                scfBaseApi[apiName](values).then(res => {
                    message.success(res.message);
                    this.setState({
                        isShowModal: false
                    });
                    this.props.operationOk();
                }).catch(err => {
                    message.error(err.message);
                })

            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });
    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {modalStatus} = this.props;
        let {visible, systemCode,systemTypeArr} = this.state;
        let formItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 2, span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16}
            },
            labelAlign: 'right'
        };
        return (
            <Modal
                title={modalStatus === 1 ? "新增" : "编辑"}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Form onSubmit={this.handleOk} {...formItem}>
                    {
                        modalStatus === 1 ? <FormItem label="系统ID">
                            {
                                getFieldDecorator('systemCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入系统ID'
                                        },
                                    ],
                                    validateTrigger: "onBlur",
                                    initialValue: ""
                                })(<Input/>)
                            }
                        </FormItem> : <FormItem label="系统ID">{systemCode}</FormItem>
                    }

                    <FormItem label="系统名称">
                        {
                            getFieldDecorator('systemName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入系统名称'
                                    },
                                    {
                                        max: 50,
                                        message: "最大长度为50位,请重新输入"
                                    }],
                                initialValue: "",
                                validateTrigger: "onBlur"
                            })(<Input/>)
                        }
                    </FormItem>
                    <FormItem label="系统类型">
                        {
                            getFieldDecorator('systemType', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择系统类型'
                                    },
                                ],
                                validateTrigger: "onBlur",
                                initialValue: "",
                            })(<Select>
                                {
                                    systemTypeArr.length !== 0 ? systemTypeArr.map(record => {
                                        return <Option key={record.dicKey} value={record.dicKey}>{record.dicValue}</Option>
                                    }) : null
                                }
                            </Select>)
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(AddModal);
import React, { Component } from "react";
import { Form, Input, Row, Col, Checkbox, Button , message} from "antd";
import ReactSimpleVerify from "react-simple-verify";
import InputGroup from "react-input-groups";
import "react-input-groups/lib/css/styles.css";
import "react-simple-verify/dist/react-simple-verify.css";
import url from "url";
import FileUploadForm from '../../components/fileUpload';
import { sendsmsApi , setDealPasswordApi } from '../../request/api/login'
import loginApi from "@/request/api/login";
import { noPassByMobile , checkIdNumber ,isEmpyt } from "../../utils/formatCheck";
import CryptoJS from "crypto-js";
let temer;
const getAesApi = loginApi["getAesApi"];

class SetDealPassWord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateKey: "",
            stateIv: "",
            loadingFlag: false,
            verify:false,
            codeNum: 60,
            codeText:'获取验证码',
            disabledFalg:false,
            kaptchaId:'',
            confirmTradePwd:'',
            tradePwd:'',
            frontUrl:'',
            route:url.parse(this.props.location.search, true).query.route,
            mobile: url.parse(this.props.location.search, true).query.mobile,
        };
    }
    componentDidMount() {
        this.handleGetAesApi();
    }
    handleGetAesApi = () => {
        getAesApi()
            .then((res) => {
                this.setState({
                    stateIv: res.data.AES_IV,
                    stateKey: res.data.AES_KEY,
                    loadingFlag: false,
                });
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    handleEncrypt = (word) => {
        const {stateKey, stateIv} = this.state;
        const key = CryptoJS.enc.Utf8.parse(stateKey);
        const iv = CryptoJS.enc.Utf8.parse(stateIv);
        let encrypted = CryptoJS.AES.encrypt(word, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString();
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { tradePwd ,  confirmTradePwd , mobile , kaptchaId } = this.state
                if(isEmpyt(tradePwd)){
                    message.info("请设置交易密码!")
                    return
                }
                if(isEmpyt(confirmTradePwd)){
                    message.info("请设置确认密码!")
                    return
                }
                if(tradePwd!=confirmTradePwd){
                    message.info("两次密码不一致，请重新设置!")
                    return
                }
                values.tradePwd = this.handleEncrypt(tradePwd) 
                values.confirmTradePwd = this.handleEncrypt(confirmTradePwd) 
                values.mobile = mobile
                values.kaptchaId = kaptchaId
                delete values.agreement
                this.setDealPassword(values)
            }
        });
    };
    setDealPassword = (params)=>{
        setDealPasswordApi(params).then(res=>{
            if(res.code=='2000'){
                // if(route==0){
                //     this.props.history.push('/login')
                // }else{
                //     this.props.history.push('/login')
                // }
                this.props.history.push('/login')
                message.success(res.message)
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
            message.error(err.message)
        })
    }
    success = ()=>{
        this.setState({
            verify:true
        })
    }
    getSmsCode = (params)=>{
        sendsmsApi(params).then(res=>{
            if(res.code=='2000'){
                this.setState({
                    kaptchaId:res.data.kaptchaId
                })
                message.success(res.message)
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
            message.error(err.message)
        })
    }
    getCode = ()=>{
        const { codeNum , verify ,mobile } = this.state;
        if(verify){
            let num = codeNum;
            let params = {
                phoneNumbers:mobile,
                usage:2
            }
            this.setState({
                disabledFalg:true
            })
            this.getSmsCode(params)
            temer = setInterval(() => {
                num--;
                if (num === 0) {
                    clearInterval(temer)
                    this.setState({
                        codeNum: 60,
                        codeText: '重新获取',
                        disabledFalg:false
                    });
                } else {
                    this.setState({
                        codeNum: num,
                        codeText: num + 's后重新获取',
                        disabledFalg:true
                    });
                }
            }, 1000);
        }else{
            message.info("请先拖动滑块进行校验")
        }
    }
    getValueTradePwd = (value) => {
        if(value.length==6){
            const { confirmTradePwd } = this.state
            if(!isEmpyt(confirmTradePwd)&&confirmTradePwd!=value){
                message.info("两次输入的密码不一致，请重新填写")
            }
            this.setState({
                tradePwd:value
            })
        }
    };
    getValueConfirmTradePwd = (value) => {
        if(value.length==6){
            const { tradePwd } = this.state
            if(!isEmpyt(tradePwd)&&tradePwd!=value){
                message.info("两次输入的密码不一致，请重新填写")
            }
            this.setState({
                confirmTradePwd:value
            })
        }
    };
    //删除上传文件
    handleRemoveFile = () => {

    }
    //确定上传的field 和value
    handleFileChange = (field, val) => {

    };
    render() {
        const {codeText , disabledFalg , mobile , frontUrl } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 4,
                },
            },
        };
        return (
            <>
                <div className="bg-img">
                    <div className="register-title">
                        <h1 className="register-h1">
                            为保证您在系统中交易安全性，请设置交易密码，步骤如下
                        </h1>
                        <p className="register-p"></p>
                    </div>
                    <div className="SDP-box">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                <Form.Item label='证件图片（选填）'>
                                    <FileUploadForm uploadText="身份证头像面"
                                                    action="ocrIdCardBackApi"
                                                    preview={ frontUrl }
                                                    field="imgFrontId"
                                                    handleFileChange={this.handleFileChange}
                                                    handleRemoveFile={this.handleRemoveFile}
                                                    key="imgFrontId"/>
                                    {
                                        getFieldDecorator('imgFrontId', {
                                            initialValue: ''
                                        })(<Input type="hidden"/>)
                                    }
                                    <FileUploadForm uploadText="身份证国徽面"
                                                    action="ocrIdCardFrontApi"
                                                    handleFileChange={this.handleFileChange}
                                                    handleRemoveFile={this.handleRemoveFile}
                                                    preview={ frontUrl }
                                                    field="imgBackId "
                                                    key="imgBackId "/>
                                    {
                                        getFieldDecorator('imgBackId ', {
                                            initialValue: ''
                                        })(<Input type="hidden"/>)
                                    }
                                </Form.Item>
                                <Form.Item label="姓名">
                                {getFieldDecorator("name", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入姓名",
                                        }
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="身份证号码">
                                {getFieldDecorator("idNo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入身份证号码",
                                        },
                                        {
                                            validator:checkIdNumber,
                                        }
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="交易密码" className='start-box'>
                                {getFieldDecorator("tradePwd", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "请输入登录密码",
                                        },
                                    ],
                                })(
                                    <InputGroup
                                        getValue={this.getValueTradePwd}
                                        length={6}
                                        type={"box"}
                                    />
                                )}
                                <span className='start-b'>*</span>
                            </Form.Item>
                            <Form.Item label="确认密码" className='start-box'>
                                {getFieldDecorator("confirmTradePwd", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "请输入确认密码",
                                        },
                                    ],
                                })(
                                    <InputGroup
                                        getValue={this.getValueConfirmTradePwd}
                                        length={6}
                                        type={"box"}
                                    />
                                )}
                                <span className='start-b'>*</span>
                            </Form.Item>
                            <Form.Item label="手机号码">
                                {getFieldDecorator("mobile", {
                                    initialValue: noPassByMobile(mobile),
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入手机号码",
                                        },
                                    ],
                                })(<Input style={{border:'none',color:'black',background:'white',cursor:'default'}} disabled={true}/>)}
                            </Form.Item>
                            <Form.Item label="验证">
                                <ReactSimpleVerify
                                    ref="verify"
                                    success={this.success}
                                />
                            </Form.Item>
                            <Form.Item label="验证码">
                                <Row gutter={8}>
                                    <Col span={12}>
                                        {getFieldDecorator("kaptcha", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入验证码",
                                                },
                                            ],
                                        })(<Input maxLength={6}/>)}
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={this.getCode} disabled={disabledFalg}>{ codeText }</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout} style={{textAlign:'center'}}>
                                <Button type="primary" htmlType="submit">
                                    立即设置
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const SetDealPassWordForm = Form.create()(SetDealPassWord);
export default SetDealPassWordForm;

import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce, getUrlParam } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Button, Input, InputNumber, DatePicker, message, Spin, Descriptions } from 'antd';

const modifyEnterpriseCreditApi = scfBusinessApi['modifyEnterpriseCreditApi'];

@Form.create()
@connect((store) => ({ store: store.coreBusinessCredit, loading: store.loading }))
class EditComp extends Component {

    state = {
        flag: true
    }

    componentDidMount () {
        this.getEnterpriseCreditDetail(getUrlParam('id'));
    }

    handleEditCredit = debounce(() => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                values.id = getUrlParam('id');
                values.companyEndTime = moment(values.companyEndTime).format('YYYY-MM-DD HH:mm:ss');
                values.companyStartTime = undefined;
                modifyEnterpriseCreditApi(values).then(res => {
                    message.success(res.message);
                    this.props.history.push('/admin/coreBusinessCredit/list');
                }).catch(err => message.error(err.message))
            }
        })
    }, 300)

    getEnterpriseCreditDetail = (val) => {
        let params = { id: val };
        this.props.dispatch({ type: 'coreBusinessCredit/getEnterpriseCreditDetail', payload: params});
    }

    getAllcapitalData = () => {
        this.props.dispatch({ type: 'coreBusinessCredit/getAllcapitalData'});
    }
    
    disabledStartDate = startValue => {
        const endDate = this.props.form.getFieldValue('companyEndTime');
        if (!startValue || !endDate) {
          return startValue < moment().subtract(1, "days");
        } 
        return startValue.valueOf() > endDate.valueOf() || startValue < moment().subtract(1, "days");
    }
    
    disabledEndDate = endValue => {
        const startDate = this.props.form.getFieldValue('companyStartTime');
        if (!endValue || !startDate) {
          return endValue < moment().subtract(1, "days");
        }
        return endValue.valueOf() <= startDate.valueOf();
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { detailData } = this.props.store;
        const isLoding1 = this.props.loading.effects['coreBusinessCredit/getEnterpriseCreditDetail'];
        const formItemLayout = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            }
        };
        
        return (
            <div>
                <Spin spinning={ isLoding1 }>
                    <Form {...formItemLayout} className='wraper-cd-center'>
                        <Descriptions bordered column={2} size='small' className='descriptions-h-width'>
                            <Descriptions.Item label="项目名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('projectName', {
                                            initialValue: detailData && detailData.projectName ? detailData.projectName : null,
                                            rules: [{ required: true, whitespace: true, message: '请输入项目名称!' }]
                                        })(
                                            <Input name='projectName' />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="项目编号">
                                <Form.Item style={{margin: 0}}>
                                    {detailData && detailData.projectNo ? detailData.projectNo : null}
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="资金方名称">
                                <Form.Item style={{margin: 0}}>
                                    {detailData && detailData.capitalName ? detailData.capitalName : null}
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业名称">
                                <Form.Item style={{margin: 0}}>
                                    {detailData && detailData.companyName ? detailData.companyName : null}
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信额度(元)" span={2}>
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('totalAmount', {
                                            initialValue: detailData && detailData.totalAmount !== null ? detailData.totalAmount : null,
                                            rules: [
                                                { required: true, message: '请选择授信额度(元)!' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                min={0}
                                                name='totalAmount'
                                                style={{width: '100%'}}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信起始日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyStartTime', {
                                            // initialValue: null,
                                            initialValue: detailData && detailData.companyStartTimeName ? moment(detailData.companyStartTimeName, 'YYYY/MM/DD') : null,
                                            rules: [{ required: true, message: '请选择授信起始日!' }]
                                        })(
                                            <DatePicker 
                                                disabled
                                                name='companyStartTime' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledStartDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信到期日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyEndTime', {
                                            // initialValue: null,
                                            initialValue: detailData && detailData.companyEndTimeName ? moment(detailData.companyEndTimeName, 'YYYY/MM/DD') : null,
                                            rules: [{ required: true, message: '请选择授信到期日!' }]
                                        })(
                                            <DatePicker 
                                                name='companyEndTime' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledEndDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleEditCredit}>提交</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default EditComp;

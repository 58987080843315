import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Spin, Table, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import AddOrEditModal from "./addOrEditModal";
import ModalTipTwoBtn from "../../../components/modalTipTwoBtn";


const FormItem = Form.Item;
const Option = Select.Option;

class SearchRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            systemId:"",//系统id
            roleName:"",
            roleId:0,
            isShowModal:false,  //删除弹框
            isShowAddOrEdit: false,//true-新增
            modalStatus: 0,//1-新增  2-编辑
            title:"",
            text:"",
            systemArr:[],
        }
    }

    componentDidMount() {
        this.getSystemQueryAll();
    }
    //所属系统
    getSystemQueryAll = () => {
        scfBaseApi.systemQueryAllApi().then(res => {
            let data = res.data;
            this.setState({
                systemArr: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取用户列表
    getRoleList = () => {
        let {pageNum, pageSize, systemId,roleName} = this.state;
        let params = {systemId,roleName, pageNum, pageSize};
        scfBaseApi.roleListApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getRoleList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getRoleList();
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            systemId:"",
            roleName:"",
        });
        this.setState({
            systemId:"",
            roleName:"",
            pageNum: 1,
        }, () => {
            this.getRoleList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { systemId,roleName} = values;
                this.setState({systemId,roleName,pageNum:1},
                    () => {
                        this.getRoleList();
                    })
            }
        })
    }

    //新增系统
    handleAddSystem = () => {
        let systemId=this.props.form.getFieldValue('systemId');
        if(systemId===""){
             message.warn("请选择所属系统");
        }else{
            this.setState({
                isShowAddOrEdit: true,
                modalStatus: 1,
                systemId:systemId,
            })
        }

    }
    //编辑系统
    handleEdit = (roleId = 0) => {
        this.setState({
            isShowAddOrEdit: true,
            modalStatus: 2,
            roleId
        })
    }
    //编辑/新增弹框确定后
    operationOk = () => {
        this.setState({
            isShowAddOrEdit: false
        }, () => {
            this.getRoleList();
        });
    }
    //编辑/新增弹框取消后
    operationCancel = () => {
        this.setState({
            isShowAddOrEdit: false
        });
    }
    //删除角色
    handleDelete = (roleId=0) => {
        this.setState({
            roleId,
            isShowModal: true,
            title: "删除",
            text: "  <p>确认要删除该角色吗？</p>\n"
        });
    }
    //确定删除
    handleOk = () => {
        let {roleId} = this.state;
            scfBaseApi.roleDeleteApi({id:roleId}).then(res => {
                message.success(res.message);
                this.setState({
                    isShowModal: false
                });
                this.getRoleList();
            }).catch(err => {
                message.error(err.message);
            })

    }

    //取消删除
    handleCancel = () => {
        this.setState({
            isShowModal: false
        });

    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount, isShowAddOrEdit,
            modalStatus, roleId,systemId,  title, text,isShowModal,systemArr} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset:6, span:3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'left'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let columns = [
            {
                title: '序号',
                key:"xuHao",
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '角色ID',
                dataIndex: 'id',
                key: 'id',
                sorter:(a,b)=>{
                    if(!a.id){
                        a.id=""
                    }else if(!b.id){
                        b.id=""
                    }
                    return a.id.localeCompare(b.id)
                }
            },
            {
                title: '角色名称',
                dataIndex: 'roleName',
                key: 'roleName',
                sorter:(a,b)=>{
                    if(!a.roleName){
                        a.roleName=""
                    }else if(!b.roleName){
                        b.roleName=""
                    }
                    return a.roleName.localeCompare(b.roleName)
                }
            },
            {
                title: '创建企业名称',
                dataIndex: 'orgName',
                key: 'orgName',
                sorter:(a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                }
            },
            {
                title: '角色描述',
                dataIndex: 'roleDes',
                key: 'roleDes',
                sorter:(a,b)=>{
                    if(!a.roleDes){
                        a.roleDes=""
                    }else if(!b.roleDes){
                        b.roleDes=""
                    }
                    return a.roleDes.localeCompare(b.roleDes)
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split('T')[0] : "";
                    let time = text != null ? text.split('T')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                sorter:(a,b)=>{
                    if(!a.companyStateName){
                        a.companyStateName=""
                    }else if(!b.companyStateName){
                        b.companyStateName=""
                    }
                    return a.companyStateName.localeCompare(b.companyStateName)
                }
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.modifyTime).getTime()
                    let bTime = new Date(b.modifyTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split('T')[0] : "";
                    let time = text != null ? text.split('T')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '修改人',
                dataIndex: 'modifyUserName',
                key: 'modifyUserName',
                sorter:(a,b)=>{
                    if(!a.companyStateName){
                        a.companyStateName=""
                    }else if(!b.companyStateName){
                        b.companyStateName=""
                    }
                    return a.modifyUserName.localeCompare(b.modifyUserName)
                }
            },
            {
                title: '操作',
                key:"caoZuo",
                render: (record) => {
                    return <div>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleEdit(record.id)
                        }}>编辑</Button>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleDelete(record.id)
                        }}>删除</Button>

                    </div>

                }
            },
        ];
        let modalProps = {
            title,
            text,
            operationOk: this.handleOk,
            operationCancel: this.handleCancel,

        };
        let addOrEditModalProps = {
            modalStatus,
            roleId,
            systemId,
            operationOk: this.operationOk,
            operationCancel: this.operationCancel,
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit}  {...formRowItem} >
                    <Row>
                        <Col span={12}>
                            <FormItem label="所属系统">
                                {
                                    getFieldDecorator('systemId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属系统'
                                            },
                                        ],
                                        validateTrigger: "onBlur",
                                        initialValue: "",
                                    })(<Select>
                                        {
                                            systemArr.length !== 0 ? systemArr.map(record => {
                                                return <Option key={record.id} value={record.id}> {record.systemName} </Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="角色名称">
                                {
                                    getFieldDecorator('roleName', {
                                        rules: [
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            },
                                        ],
                                        initialValue: "",
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>

                    </Row>
                    <div className="com-diff-two-btn">
                        <Button type="default" className="com-middle-btn-size"
                                onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <div>
                    <h2>角色信息列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size"
                                onClick={this.handleAddSystem}>+新增</Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount>10?pagination:false}
                />
                {isShowModal === true ? <ModalTipTwoBtn {...modalProps} /> : null}
                {isShowAddOrEdit === true ? <AddOrEditModal {...addOrEditModalProps}/> : null}
            </div>
        );
    }
}

export default Form.create()(SearchRole);
import React, { Component } from 'react';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import RemoveComp from './removeComp';
import { Form, Input, Row, Col, Button, Table } from "antd";


@Form.create()
@connect((store) => ({ store: store.signingConfiguration, loading: store.loading }))
class ListComp extends Component {

    state = {
        tableCurrent: 1
    }

    componentDidMount () {
        this.handleFormSearch();
    }

    getSigningConfigurationList = (pageNum) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                if(pageNum) values.pageNum = pageNum;
                this.props.dispatch({type: 'signingConfiguration/getSigningConfigurationList', payload: values});
            }
        })
    }

    handleHistoryGoAdd = () => {
        this.props.history.push('/admin/signingConfiguration/add');
    }

    handleCurrentChange = (pagination) => {
        this.getSigningConfigurationList(pagination.current)
    }

    handlePageSizeChange = () => {

    }

    handleFormSearch = debounce(() => {
        this.getSigningConfigurationList();
    }, 300)

    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getSigningConfigurationList();
    }, 300)

    render() {
        const { tableCurrent } =  this.state;
        const { getFieldDecorator } = this.props.form;
        const { signingConfigurationList } = this.props.store;
        const isLoding = this.props.loading.effects['signingConfiguration/getSigningConfigurationList'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '序号',
                dataIndex: 'seqNo',
                key: 'seqNo'
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                key: 'projectName',
                sorter:(a,b)=>{
                    if(!a.projectName){
                        a.projectName=""
                    }else if(!b.projectName){
                        b.projectName=""
                    }
                    return a.projectName.localeCompare(b.projectName)
                },
            },
            {
                title: '产品',
                dataIndex: 'productName',
                key: 'productName',
                sorter:(a,b)=>{
                    if(!a.productName){
                        a.productName=""
                    }else if(!b.productName){
                        b.productName=""
                    }
                    return a.productName.localeCompare(b.productName)
                },
            },
            {
                title: '资金方名称',
                dataIndex: 'capitalName',
                key: 'capitalName',
                sorter:(a,b)=>{
                    if(!a.capitalName){
                        a.capitalName=""
                    }else if(!b.capitalName){
                        b.capitalName=""
                    }
                    return a.capitalName.localeCompare(b.capitalName)
                },
            },
            {
                title: '核心企业名称',
                dataIndex: 'coreCompanyName',
                key: 'coreCompanyName',
                sorter:(a,b)=>{
                    if(!a.coreCompanyName){
                        a.coreCompanyName=""
                    }else if(!b.coreCompanyName){
                        b.coreCompanyName=""
                    }
                    return a.coreCompanyName.localeCompare(b.coreCompanyName)
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record) => {
                    const editPath = `/admin/signingConfiguration/edit?id=${record.id}`;
                    const detailPath = `/admin/signingConfiguration/detail?id=${record.id}`;
                    const editNode = <Button className='com-btn-opr com-small-btn-size mr5' href={editPath}>修改</Button>;
                    const detailNode = <Button className='com-btn-opr com-small-btn-size mr5' href={detailPath}>详情</Button>;
                    return <div>
                        {detailNode}
                        {editNode}
                        <RemoveComp id={record.id} updata={this.getSigningConfigurationList}/>
                    </div>
                }
            }
        ];
        const tabelHeadBtn = <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleHistoryGoAdd}>新增</Button>;
        
        return (
            <div>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="项目">
                                {
                                    getFieldDecorator('projectName', {
                                        initialValue: ''
                                    })(
                                        <Input name='projectName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="产品">
                                {
                                    getFieldDecorator('productName', {
                                        initialValue: ''
                                    })(
                                        <Input name='productName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="资金方">
                                {
                                    getFieldDecorator('capitalName', {
                                        initialValue: ''
                                    })(
                                        <Input name='capitalName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="核心企业">
                                {
                                    getFieldDecorator('coreCompanyName', {
                                        initialValue: ''
                                    })(
                                        <Input name='coreCompanyName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='wraper-cd-center'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Form>
                <Table 
                    columns={columns}
                    dataSource={signingConfigurationList.data}
                    rowKey={(record, index) => index}
                    title={() => tabelHeadBtn}
                    loading={isLoding}

                    pagination = {{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: signingConfigurationList.totalCount <= 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `总共 ${signingConfigurationList.totalCount} 条`,
                        total: signingConfigurationList.totalCount,
                        current: tableCurrent,
                        pageSize: signingConfigurationList.pageSize,
                    }}
                    onChange = {this.handleCurrentChange}
                    onShowSizeChange = {this.handlePageSizeChange}
                />
            </div>
        )
    }
}

export default ListComp;

import React, {Component} from 'react';
import {message,Tabs,Descriptions} from "antd";
import {withRouter} from "dva/router";
import queryString from "query-string";
import scfBaseApi from "../../../request/api/scfBaseApi";
import CompanyList from "./companyList";

const {TabPane} = Tabs;
const DescriptionsItem = Descriptions.Item;

class RelateMaintain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:0,
            otherBusinessRole:"",
            otherBusinessRoleName:"",
            anotherBusinessRole:"",
            anotherBusinessRoleName:"",
            orgName:"",
            cardNo:"",
            businessRoleName:"",
        }
    }

    componentDidMount() {
        let search = queryString.parse(this.props.history.location.search);
        let params = {
            orgId: search.orgId,
            businessRole: search.businessRole
        }
        scfBaseApi.queryOrgApi(params).then(res => {
            let data = res.data;
            this.setState({
                id:data.id,
                otherBusinessRole:data.otherBusinessRole,
                otherBusinessRoleName:data.otherBusinessRoleName,
                anotherBusinessRole:data.anotherBusinessRole,
                anotherBusinessRoleName:data.anotherBusinessRoleName,
                orgName:data.orgName,
                cardNo:data.cardNo,
                businessRoleName:data.businessRoleName
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    render() {
        let { id,otherBusinessRole,otherBusinessRoleName,anotherBusinessRole,
            anotherBusinessRoleName,orgName,cardNo, businessRoleName} = this.state;
        let search = queryString.parse(this.props.history.location.search);
        let tabFirst = {
            orgId:id,
            relationBusinessRole:search.businessRole,
            businessRole:otherBusinessRole,
            businessRoleName:otherBusinessRoleName,
        };
        let tabSecond = {
            orgId:id,
            relationBusinessRole:search.businessRole,
            businessRole:anotherBusinessRole,
            businessRoleName:anotherBusinessRoleName,
        }


        return (
            <div className="relate-maintain-wrapper">
                <Descriptions bordered column={3} style={{marginBottom:50}} className="com-shadow-radius">
                    <DescriptionsItem label="客户名称"><b>{orgName}</b></DescriptionsItem>
                    <DescriptionsItem label="统一社会信用代码"><b>{cardNo}</b></DescriptionsItem>
                    <DescriptionsItem label="业务角色"><b>{businessRoleName}</b></DescriptionsItem>
                </Descriptions>
                <Tabs defaultActiveKey="0"  type="card">
                    <TabPane tab={otherBusinessRoleName} key="0">
                        <CompanyList key="0" {...tabFirst}/>
                    </TabPane>
                    <TabPane tab={anotherBusinessRoleName} key="1">
                        <CompanyList key="1" {...tabSecond}/>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default withRouter(RelateMaintain);
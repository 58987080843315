import React, { Component } from 'react';
import { Button , message } from 'antd';
import { withRouter } from "dva/router";
import { queryIssusSiginInfoSubmitApi  } from '../../../request/api/quota'

@withRouter

class GetTicketInfoSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    Sigin = (params)=>{
        const { id, nodeType , nodeId, wkId } = this.props
        let values = {}
        if(params==1){
            values.but = '40'
        }else if(params==2){
            values.but = '10'
        }
        values.id = parseInt(id) 
        values.wkId = wkId
        values.nodeId = nodeId
        values.nodeType = nodeType
        this.getAuditAgreeInfo(values)
    }
    getAuditAgreeInfo = (params)=>{
        queryIssusSiginInfoSubmitApi(params).then(res => {
            if(res.code=='2000'){
                this.props.history.push(`/admin/businessTicketIssue/issue`);
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    goBack = ()=>{
        this.props.history.push(`/admin/businessTicketIssue/issue`);
    }
    render() { 
        return ( 
            <>
                <div style={{textAlign:'center'}}>
                    <Button onClick={this.goBack}>返回</Button>
                    <Button style={{margin:'20px'}} type='primary' onClick={()=>{
                        this.Sigin(1)
                    }}>收票驳回</Button>
                    <Button  type='primary'  onClick={()=>{
                        this.Sigin(2)
                    }}>收票提交</Button>
                </div>
            </>
         );
    }
}
 
export default GetTicketInfoSubmit;
import {post} from '../http';
import { getStorage } from "@/utils/tools";

export async function getQuotaStatusApi(params){
    return post(`/business/dictionary/findDicLt`, params,true,50000,"ap2");
} 

export async function getQuotaListApi(params){
    return post(`/business/ticketApply/list`, params,true,50000,"ap2");
} 

export async function queryAuditInfoApi(params){
    return post(`/business/ticketApply/getDetail`, params,true,50000,"ap2");
} 

export async function queryAuditHistroyInfoApi(params){
    return post(`/business/audit/auditHistory`, params,true,50000,"ap2");
}

export async function queryAuditInfoSubmitApi(params){
    return post(`/business/ticketApply/audit`, params,true,50000,"ap2");
} 

export async function queryAuditSubimitApi(params){
    return post(`/business/ticketApply/startOrStop`, params,true,50000,"ap2");
} 

export async function queryEvaluationSheet(params){
    return post(`/business/dictionary?typeList=enterpriseNatureCd,coreEnterpriseTypeCd,enterpriseRateCd,ratingAgenciesCd,industryCd,businessScaleCd,debtScaleCd,netProfitScaleCd,accountsPayableScaleCd,typePaymentCd,accountPeriodCd,financingModeCd,financeCostsCd,underLevelCd`, params,true,50000,"ap2");
} 

export async function queryinitDdata(params){
    return post(`/business/ticketApply/getTicketApplyOrgOpenInfo`, params,true,50000,"ap2");
} 

export async function queryticketApplyAdd(params){
    return post(`/business/ticketApply/add`, params,true,50000,"ap2");
} 

export async function getCreditListApi(params){
    return post(`/business/ticket/publish/queryList`, params,true,50000,"ap2");
} 

export async function queryRelationsInfoApi(params){
    return post(`/base/v1/org/relations/info`, params,true,50000,"ap2");
} 

export async function queryCompanyBankInfoApi(params){
    let id = getStorage("companyID")
    return post(`/base/v1/org/${id}/bank/info`, params,true,50000,"ap2");
} 

export async function querygetAddBussinessIssueApi(params){
    return post(`/business/ticket/publish/add`, params,true,50000,"ap2");
} 

export async function queryIssuseAuditInfoSubmitApi(params){
    return post(`/business/ticket/publish/draw/audit`, params,true,50000,"ap2");
} 

export async function queryIssusSiginInfoSubmitApi(params){
    return post(`/business/ticket/publish/sign`, params,true,50000,"ap2");
} 

export async function queryPaymoneyDetailApi(params){
    return post(`/business/ticket/publish/getPayDetail`, params,true,50000,"ap2");
} 

export async function queryExportCurrentExcelApi(params){
    return post(`/business/ticket/publish/exportDetail`, params,true,50000,"ap2");
} 

export async function querypayMoneyGetDetailApi(params){
    return post(`/business/ticket/publish/getDetail`, params,true,50000,"ap2");
} 

export async function queryPayMoneyAuditSubmittApi(params){
    return post(`/business/ticket/publish/pay/audit`, params,true,50000,"ap2");
} 

export async function queryAllExportCurrentExcelApi(params){
    return post(`/business/ticket/publish/exportList`, params,true,50000,"ap2");
} 

export async function queryBankListApi(params){
    return post(`/business/ticket/publish/bank/queryBankList`, params,true,50000,"ap2");
} 

export async function queryDeleteApi(params){
    return post(`/business/ticket/publish/delete/v1`, params,true,50000,"ap2");
} 

import React, { Component } from 'react';
import { Form , Row, Col, Select, Button, message, Upload , Table , Input ,  Popconfirm , DatePicker ,Spin } from "antd";
import moment from 'moment';
import { queryBankListApi } from '../../../request/api/quota'
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { withRouter } from "react-router-dom";
const resourceFileUploadApi = scfBusinessApi['resourceFileUploadApi'];
const resourceBatchFileUploadApi = scfBusinessApi['resourceBatchFileUploadApi'];
const queryAddBatchApi = scfBusinessApi['queryAddBatchApi'];
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { Option } = Select;

@withRouter

class AddbatchIssue extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            bankList:[],
            bankTypeCd:'',
            data:[],
            disabled:true,
            loading:false,
            resourceFileId:'',
            spinLoading:false,
            ticketStatus:[
              {key:'1040',value:'登记审核中'},
              {key:'1070',value:'登记驳回'},
              {key:'2000',value:'承兑待审核'},
              {key:'2040',value:'承兑审核中'},
              {key:'2070',value:'承兑驳回'},
              {key:'3070',value:'收款驳回'},
              {key:'3040',value:'收款审核中'},
              {key:'4090',value:'票据已发行'},
              {key:'5040',value:'票据付款中'},
              {key:'5070',value:'票据付款驳回'},
              {key:'7000',value:'票据已结清'}
            ]
         }
    }
    componentDidMount(){
        this.getBankList()
    }
    getBankList = ()=>{
        queryBankListApi().then(res => {
            if(res.code=='2000'){
                if(res.data.length>0){
                    let arr = res.data
                    let newArr = []
                    arr.map((item,index)=>{
                        newArr.push(JSON.parse(item))
                    })
                    this.setState({
                        bankList:newArr
                    })
                }
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleSubmitAdd = ()=>{
        const { data,resourceFileId } = this.state
        if(data.length==0){
            message.info('请先导入文件！')
            return
        }
        let params = {
            analysisVOS:data,
            resourceFileId:resourceFileId
        }
        this.setState({loading:true})
        queryAddBatchApi(params).then(res => {
            if(res.code=='2000'){
                message.success('批量添加成功')
                this.props.history.push('/admin/businessTicketIssue/issueShanjian')
            }else{
                message.info(res.message)
            }
            this.setState({loading:false})
        }).catch(err => {
            this.setState({loading:false})
            message.error(err.message);
        })
    }
    handleChange = (value)=>{
        console.log(`selected ${value}`);
        this.setState({
            bankTypeCd:value,
            disabled:false
        })
    }
    customRequest = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        formData.append('fileType', '03');
        this.setState({ showUploadText: 1,spinLoading:true }) 
        resourceFileUploadApi(formData).then(res => {
            if(res.code=='2000'){
              this.setState({
                resourceFileId:res.data.resourceFileId
              })
                this.getBatchResourceFileUplone(res.data)
            }
        }).catch(err => {
            message.error(`上传失败！`);
        })
    }
    getBatchResourceFileUplone = (params)=>{
        params.ticketPublishBankId = this.state.bankTypeCd
        resourceBatchFileUploadApi(params).then(res => {
            let data = res.data.analysisVOS
            data.map((item,index)=>{
              item.acceptorRatingLevelExpireDateStr =item.acceptorRatingLevelExpireDateStr? moment(item.acceptorRatingLevelExpireDateStr).format('YYYY-MM-DD'):null
              item.drawerRatingLevelExpireDateStr = item.drawerRatingLevelExpireDateStr?moment(item.drawerRatingLevelExpireDateStr).format('YYYY-MM-DD'):null
              item.acceptorPledgeDateStr =item.acceptorPledgeDateStr?moment(item.acceptorPledgeDateStr).format('YYYY-MM-DD'):null
              item.drawerPledgeDateStr = item.drawerPledgeDateStr?moment(item.drawerPledgeDateStr).format('YYYY-MM-DD'):null
              item.acceptorDateStr = item.acceptorDateStr?moment(item.acceptorDateStr).format('YYYY-MM-DD'):null
              item.ticketDateStr = item.ticketDateStr?moment(item.ticketDateStr).format('YYYY-MM-DD'):null
              item.ticketExpireDateStr = item.ticketExpireDateStr?moment(item.ticketExpireDateStr).format('YYYY-MM-DD'):null
              item.key = index
            })
            this.setState({ data ,spinLoading:false})
        }).catch(err => {
          this.setState({ spinLoading:false})
            message.error(err.message);
        })
    }
    onChange = (e,record,name)=>{
        const { data } = this.state
        data[record.key][name] = e.target.value
        this.setState({ data })
    }
    onChangeDatePicker = (date, dateString,record , name)=>{
        const { data } = this.state
        data[record.key][name] = dateString
        this.setState({ data })
    }
    confirm = (record)=>{
        console.log(record.key);
        const { data } = this.state
        let newData = data.filter(item=>item.key!=record.key)
        console.log(newData)
        newData.map((item,index)=>{
            item.key = index
        })
        console.log(newData)
        this.setState({data:newData})
    }
    allDelete = ()=>{
        this.setState({data:[]})
    }
    cancel = (e)=>{
        console.log(e);
        // message.error('Click on No');
    }
    handleCdChange = (value,record,name)=>{
        const { data } = this.state
        data[record.key][name] = value
        this.setState({ data })
    }
    handleTicketStatusCdChange =  (value,record,name)=>{
      const { data } = this.state
      data[record.key][name] = value
      this.setState({ data })
    }
    goHistoryBack = ()=>{
        this.props.history.push("/admin/businessTicketIssue/issueShanjian")
    }
    render() { 
        const { bankList , data ,disabled ,loading , ticketStatus ,spinLoading} = this.state
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
              title: '票据号码',
              dataIndex: 'ticketNo',
              key: 'ticketNo',
              width: 240,
              fixed: 'left',
              render:(text, record)=>{
                  return <Input type='number' value={data[record.key].ticketNo} onChange={(e)=>{
                    this.onChange(e,record,'ticketNo')
                  }}/>
              }
            },
            {
                title: '票据金额',
                dataIndex: 'ticketAmount',
                key: 'ticketAmount',
                render:(text, record)=>{
                    return <Input type='number' value={data[record.key].ticketAmount} onChange={(e)=>{
                      this.onChange(e,record,'ticketAmount')
                    }}/>
                }
            },
            {
                title: '出票日期',
                dataIndex: 'ticketDateStr',
                key: 'ticketDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].ticketDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'ticketDateStr')
                    }} />
                }
              },
            {
                title: '汇票到期日',
                dataIndex: 'ticketExpireDateStr',
                key: 'ticketExpireDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].ticketExpireDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'ticketExpireDateStr')
                    }} />
                }
              },  
              {
                title: '出票人名称',
                dataIndex: 'drawerName',
                key: 'drawerName',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerName} onChange={(e)=>{
                      this.onChange(e,record,'drawerName')
                    }}/>
                }
              },  
              {
                title: '出票账号',
                dataIndex: 'drawerAccountNo',
                key: 'drawerAccountNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerAccountNo} onChange={(e)=>{
                      this.onChange(e,record,'drawerAccountNo')
                    }}/>
                }
              },  
              {
                title: '收款人名称',
                dataIndex: 'payeeName',
                key: 'payeeName',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].payeeName} onChange={(e)=>{
                      this.onChange(e,record,'payeeName')
                    }}/>
                }
              },  
              {
                title: '收款账号',
                dataIndex: 'payeeAccountNo',
                key: 'payeeAccountNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].payeeAccountNo} onChange={(e)=>{
                      this.onChange(e,record,'payeeAccountNo')
                    }}/>
                }
              },  
              {
                title: '收款开户行',
                dataIndex: 'payeeBank',
                key: 'payeeBank',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].payeeBank} onChange={(e)=>{
                      this.onChange(e,record,'payeeBank')
                    }}/>
                }
              },  
              {
                title: '承兑人名称',
                dataIndex: 'acceptorName',
                key: 'acceptorName',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorName} onChange={(e)=>{
                      this.onChange(e,record,'acceptorName')
                    }}/>
                }
              },
              {
                title: '承兑人账号',
                dataIndex: 'acceptorAccountNo',
                key: 'acceptorAccountNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorAccountNo} onChange={(e)=>{
                      this.onChange(e,record,'acceptorAccountNo')
                    }}/>
                }
              },
              {
                title: '承兑人开户行',
                dataIndex: 'acceptorBank',
                key: 'acceptorBank',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorBank} onChange={(e)=>{
                      this.onChange(e,record,'acceptorBank')
                    }}/>
                }
              },
            {
                title: '承兑日期',
                dataIndex: 'acceptorDateStr',
                key: 'acceptorDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].acceptorDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'acceptorDateStr')
                    }} />
                }
              },  
              {
                title: '是否可转让',
                dataIndex: 'transferTypeCdStr',
                key: 'transferTypeCdStr',
                render:(text, record)=>{
                    return <Select defaultValue={data[record.key].transferTypeCdStr} style={{ width: 120 }} onChange={(val)=>{
                        this.handleCdChange(val,record,'transferTypeCdStr')
                    }}>
                                <Option value="1">是</Option>
                                <Option value="2">否</Option>
                        </Select>
                }
              }, 
              {
                title: '票据状态',
                dataIndex: 'ticketStatusCdStr',
                key: 'ticketStatusCdStr',
                render:(text, record)=>{
                    return <Select defaultValue={data[record.key].ticketStatusCdStr} style={{ width: 120 }} onChange={(val)=>{
                        this.handleTicketStatusCdChange(val,record,'ticketStatusCdStr')
                    }}>
                        {
                          ticketStatus.map((item,index)=>{
                            return <Option value={item.key} key={index}>{item.value}</Option>
                          })
                        }
                        </Select>
                }
              }, 
              {
                title: '交易合同号',
                dataIndex: 'tradeContractNo',
                key: 'tradeContractNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].tradeContractNo} onChange={(e)=>{
                      this.onChange(e,record,'tradeContractNo')
                    }}/>
                }
              }, 
              {
                title: '出票保证人名称',
                dataIndex: 'drawerPledgeName',
                key: 'drawerPledgeName',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerPledgeName} onChange={(e)=>{
                      this.onChange(e,record,'drawerPledgeName')
                    }}/>
                }
              }, 
              {
                title: '出票保证人账号',
                dataIndex: 'drawerPledgeAccountNo',
                key: 'drawerPledgeAccountNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerPledgeAccountNo} onChange={(e)=>{
                      this.onChange(e,record,'drawerPledgeAccountNo')
                    }}/>
                }
              }, 
              {
                title: '出票保证人开户行',
                dataIndex: 'drawerPledgeBank',
                key: 'drawerPledgeBank',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerPledgeBank} onChange={(e)=>{
                      this.onChange(e,record,'drawerPledgeBank')
                    }}/>
                }
              }, 
              {
                title: '出票保证人开户行号',
                dataIndex: 'drawerPledgeBankNo',
                key: 'drawerPledgeBankNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerPledgeBankNo} onChange={(e)=>{
                      this.onChange(e,record,'drawerPledgeBankNo')
                    }}/>
                }
              }, 
              {
                title: '出票保证人地址',
                dataIndex: 'drawerPledgeAddress',
                key: 'drawerPledgeAddress',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerPledgeAddress} onChange={(e)=>{
                      this.onChange(e,record,'drawerPledgeAddress')
                    }}/>
                }
              }, 
              {
                title: '出票保证人日期',
                dataIndex: 'drawerPledgeDateStr',
                key: 'drawerPledgeDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].drawerPledgeDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'drawerPledgeDateStr')
                    }} />
                }
              },  
              {
                title: '承兑保证人名称',
                dataIndex: 'acceptorPledgeName',
                key: 'acceptorPledgeName',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorPledgeName} onChange={(e)=>{
                      this.onChange(e,record,'acceptorPledgeName')
                    }}/>
                }
              }, 


              {
                title: '承兑保证人账号',
                dataIndex: 'acceptorPledgeAccountNo',
                key: 'acceptorPledgeAccountNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorPledgeAccountNo} onChange={(e)=>{
                      this.onChange(e,record,'acceptorPledgeAccountNo')
                    }}/>
                }
              }, 
              {
                title: '承兑保证人开户行',
                dataIndex: 'draacceptorPledgeBankwerPledgeBank',
                key: 'acceptorPledgeBank',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorPledgeBank} onChange={(e)=>{
                      this.onChange(e,record,'acceptorPledgeBank')
                    }}/>
                }
              }, 
              {
                title: '承兑保证人开户行号',
                dataIndex: 'acceptorPledgeBankNo',
                key: 'acceptorPledgeBankNo',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorPledgeBankNo} onChange={(e)=>{
                      this.onChange(e,record,'acceptorPledgeBankNo')
                    }}/>
                }
              }, 
              {
                title: '承兑保证人地址',
                dataIndex: 'acceptorPledgeAddress',
                key: 'acceptorPledgeAddress',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorPledgeAddress} onChange={(e)=>{
                      this.onChange(e,record,'acceptorPledgeAddress')
                    }}/>
                }
              }, 
              {
                title: '承兑保证人日期',
                dataIndex: 'acceptorPledgeDateStr',
                key: 'acceptorPledgeDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].acceptorPledgeDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'acceptorPledgeDateStr')
                    }} />
                }
              },  
              {
                title: '出票人信用等级',
                dataIndex: 'drawerRatingLevel',
                key: 'drawerRatingLevel',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].drawerRatingLevel} onChange={(e)=>{
                      this.onChange(e,record,'drawerRatingLevel')
                    }}/>
                }
              }, 
              {
                title: '出票人评级到期日',
                dataIndex: 'drawerRatingLevelExpireDateStr',
                key: 'drawerRatingLevelExpireDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].drawerRatingLevelExpireDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'drawerRatingLevelExpireDateStr')
                    }} />
                }
              }, 
              {
                title: '承兑人信用等级',
                dataIndex: 'acceptorRatingLevel',
                key: 'acceptorRatingLevel',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].acceptorRatingLevel} onChange={(e)=>{
                      this.onChange(e,record,'acceptorRatingLevel')
                    }}/>
                }
              }, 
              {
                title: '承兑人信用评级到期时间',
                dataIndex: 'acceptorRatingLevelExpireDateStr',
                key: 'acceptorRatingLevelExpireDateStr',
                render:(text, record)=>{
                    return <DatePicker defaultValue={moment(data[record.key].acceptorRatingLevelExpireDateStr, dateFormat)} format={dateFormat} onChange={(date, dateString)=>{
                        this.onChangeDatePicker(date, dateString,record,'acceptorRatingLevelExpireDateStr')
                    }} />
                }
              }, 
              {
                title: '票据备注',
                dataIndex: 'ticketRemark',
                key: 'ticketRemark',
                render:(text, record)=>{
                    return <Input type='text' value={data[record.key].ticketRemark} onChange={(e)=>{
                      this.onChange(e,record,'ticketRemark')
                    }}/>
                }
              }, 
            {
              title: '操作',
              key: 'action',
              width: "200px",
              fixed: 'right',
              render: (text, record) => (
                <Popconfirm
                title="您确定删除当前的列表么？"
                onConfirm={()=>{
                    this.confirm(record)
                }}
                onCancel={this.cancel}
                okText="是"
                cancelText="否"
              >
                <a href="#" style={{color:'#1890ff'}}>删除</a>
              </Popconfirm>
              ),
            },
          ];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        return ( 
            <>
                <div>
                  <Spin spinning={spinLoading}>
                <Form  {...formItemLayout}  className="login-form">
                    <Row>
                        <Col span={10}>
                            <Form.Item label="发行银行">
                                {getFieldDecorator('bankTypeCd', {
                                    rules: [{ required: true, message: '请选择商票发行银行' }],
                                })(
                                    <Select placeholder="请选择商票发行银行"  onChange={this.handleChange}>
                                    {
                                            bankList.map((item,index)=>{
                                                return <Option key={index} value={item.bankTypeCd}>{item.bankName}</Option>
                                            })
                                        }
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Upload 
                                customRequest={this.customRequest}                         
                                showUploadList={false} 
                                disabled = { disabled }
                            >
                                <Button type='primary' htmlType="submit" className="login-form-button">导入</Button>
                            </Upload>
                        </Col>
                    </Row>
                    <Table 
                        style={{margin:'60px 0'}}
                        columns={columns} 
                        dataSource={data} 
                        pagination={false} 
                        scroll={{ x: 5000 }}
                    />
                    <div>
                        <Button onClick={this.goHistoryBack} style={{marginRight:'30px'}}>返回</Button>
                        <Button onClick={this.allDelete} style={{marginRight:'30px'}}>删除全部</Button>
                        <Button type="primary" loading={loading} onClick={this.handleSubmitAdd}>
                            确认新增
                        </Button>
                    </div>
                    
                </Form>
                </Spin>
                </div>
            </>
         );
    }
}
 
const AddbatchIssueForm = Form.create()(AddbatchIssue);
export default AddbatchIssueForm;
import React, { Component } from 'react';
import { connect } from 'dva';
import RemoveContract from './removeContract';
import ReviewContranct from './reviewContract';
import EditableTable from './editableTable';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Button, Radio, Checkbox, Row, Col, Input, Select, Spin, message, Upload } from 'antd';

const { Option } = Select;
const contractTemplateAddApi = scfBusinessApi['contractTemplateAddApi'];
const resourceFileUploadApi = scfBusinessApi['resourceFileUploadApi'];

@Form.create()
@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class AddComp extends Component {

    state = {
        showUploadText: 0,
        contractData: {},
        filelist: []
    }

    componentDidMount () {
        this.handleRemoveFile();
        this.props.dispatch({ type: 'contractTemplateManage/getSMSEnumerate', payload: 'if_sms_authority'});
        this.props.dispatch({ type: 'contractTemplateManage/getSignatoryEnumerate', payload: 'signatory_type'});
        this.props.dispatch({ type: 'contractTemplateManage/getContractTypesEnumerate', payload: 'contract_type'});
        this.props.dispatch({ type: 'contractTemplateManage/getisProductEnumerate', payload: 'if_assign_product'});
        this.props.dispatch({ type: 'contractTemplateManage/getAllProjectListData'});
    }

    handleFileUpload = () => {
        const { contractData } = this.state;
        const params = {
            resourceFileId: contractData.resourceFileId,
            relativePath: contractData.relativePath,
            newFileName: contractData.newFileName,
            originalFileName: contractData.originalFileName
        };
        this.props.dispatch({ type: 'contractTemplateManage/getAddContractData', payload: params}).then(res => {
            if(res && res.message) {
                this.setState({ showUploadText: 0 });
            }else {
                this.setState({ showUploadText: 3 });
            }
        })
    }

    handleAddCredit = (data) => {
        const { addContractData } = this.props.store;
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                let newData = []
                data.map(item => newData.push({ domainName: item.domainName, sysField: item.sysField, ruleType: item.ruleType }));
                values.resourceFileId = addContractData.resourceFileId;
                values.configs = newData;
                values.fileLoad = undefined;
                contractTemplateAddApi(values).then(res => {
                    message.success(res.message);
                    this.handleRemoveFile();
                    this.props.history.push('/admin/contractTemplateManage/list');
                }).catch(err => message.error(err.message))
            }
        })
    }

    handleSelectChange = (val) => {
        this.props.form.resetFields('productId');
        this.props.dispatch({ type: 'contractTemplateManage/getAllProductListData', payload: val});
    }

    handleFileLoad = () => {
        this.refs.fileLoad.buttonNode.click();
    }

    handleRemoveFile = () => {
        this.props.form.resetFields('fileLoad');
        this.setState({
            showUploadText: 0,
            contractData: {}
        })
        this.props.dispatch({ type: 'contractTemplateManage/updateReducers', payload: { key: 'addContractData', val: {} }});
    }

    customRequest = (info) => {
        const formData = new FormData();
        formData.append('file', info.file);
        this.setState({ showUploadText: 1 }) 
        resourceFileUploadApi(formData).then(res => {
            // message.success(`${info.file.name} 上传成功！`);
            this.setState({ 
                contractData: res.data
            })
            this.handleFileUpload();
        }).catch(err => {
            // message.error(`${info.file.name} 上传失败！`);
            this.handleRemoveFile();
        })
    }

    beforeUpload = (info) => {
        const isPDF = info.type === 'application/pdf';
        const isLt8M = info.size / 1024 / 1024  < 100;
        if (!isPDF) {
            message.error('当前模板格式支持支PDF，上传前请设置好模板中的相应输入域的信息');
            return false;
        }else {
            if (!isLt8M) {
                message.error('文件必须小于8MB!');
                return false;
            }
        }
        return isPDF && isLt8M;
    }

    projectRender () {
        const { getFieldDecorator } = this.props.form; 
        const { allProjectListData, allProductListData } = this.props.store;
        const isLoding = this.props.loading.effects['contractTemplateManage/getAllProductListData'];
        const formItemLayout = {
            labelCol: { span: 8, },
            wrapperCol: { span: 14, offset: 2 }
        };
        return (
            <Row>
                <Col span={6}>
                    <Form.Item label="请选择项目" {...formItemLayout}>
                        {
                            getFieldDecorator('projectId', {
                                initialValue: '',
                                rules: [{ required: true, message: '请选择项目' }]
                            })(
                                <Select name='projectId' onChange={this.handleSelectChange}>
                                    {
                                        allProjectListData && allProjectListData.length 
                                        ? allProjectListData.map((item, index)=> <Option key={index} value={item.id}>{item.projectName}</Option>)
                                        : null
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="请选择产品" {...formItemLayout}>
                        {
                            getFieldDecorator('productId', {
                                initialValue: '',
                                rules: [{ required: true, message: '请选择产品' }]
                            })(
                                <Select name='productId' loading={isLoding}>
                                    {
                                        allProductListData && allProductListData.length 
                                        ? allProductListData.map((item, index)=> <Option key={index} value={item.id}>{item.productName}</Option>)
                                        : null
                                    }
                                </Select>
                            )   
                        }
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    ifAssignProductRender () {
        const { getFieldDecorator } = this.props.form; 
        const { isProductEnumerate } = this.props.store;
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <Form.Item label="是否指定产品">
                            {
                                getFieldDecorator('ifAssignProduct', {
                                    initialValue: '',
                                    rules: [{ required: true, message: '请选择是否指定产品' }]
                                })(
                                    <Radio.Group name='ifAssignProduct'>
                                        {
                                            isProductEnumerate && isProductEnumerate.length 
                                            ? isProductEnumerate.map((item, index)=> <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                            : null
                                        }
                                    </Radio.Group>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                {
                    this.props.form.getFieldValue('ifAssignProduct') === '1'
                        ? this.projectRender()
                        :  null
                }
            </React.Fragment>
        )
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { showUploadText } = this.state;
        const { signatoryEnumerate, SMSEnumerate, contractTypesEnumerate, addContractData } = this.props.store;
        const isLoding = this.props.loading.effects['contractTemplateManage/getSMSEnumerate'];
        const isLoding1 = this.props.loading.effects['contractTemplateManage/getSignatoryEnumerate'];
        const isLoding2 = this.props.loading.effects['contractTemplateManage/getContractTypesEnumerate'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        return (
            <div>
                <Spin spinning={ isLoding || isLoding1 || isLoding2 }>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>合同基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同名称">
                                    {
                                        getFieldDecorator('contractName', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入合同名称' 
                                                },
                                                {
                                                    max: 64,
                                                    message: '合同名称过长'
                                                }
                                            ]
                                        })(
                                            <Input name='contractName' />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同编号">
                                    {
                                        getFieldDecorator('contractNum', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入合同编号' 
                                                },
                                                {
                                                    pattern: /^[A-Z]{4,8}$/,
                                                    message: '请输入4-8位大写字母'
                                                }
                                            ]
                                        })(
                                            <Input name='contractNum' />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="签署方">
                                    {
                                        getFieldDecorator('signatorys', {
                                            initialValue: [],
                                            rules: [{ required: true, message: '请选择签署方' }]
                                        })(
                                            <Checkbox.Group name='signatorys'>
                                                {
                                                    signatoryEnumerate && signatoryEnumerate.length 
                                                    ? signatoryEnumerate.map((item, index)=> <Checkbox key={index} value={item.dicKey}>{item.dicValue}</Checkbox>)
                                                    : null
                                                }
                                            </Checkbox.Group>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="是否短信鉴权">
                                    {
                                        getFieldDecorator('ifSmsAuthority', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择是否短信鉴权' }]
                                        })(
                                            <Radio.Group name='ifSmsAuthority'>
                                                {
                                                    SMSEnumerate && SMSEnumerate.length 
                                                    ? SMSEnumerate.map((item, index)=> <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                    : null
                                                }
                                                <span style={{color: '#aaa'}}>选择“是”时，客户在每次签署合同时多需要做短信验证</span>
                                            </Radio.Group>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同类型">
                                    {
                                        getFieldDecorator('contractType', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择合同类型' }]
                                        })(
                                            <Radio.Group name='contractType'>
                                                {
                                                    contractTypesEnumerate && contractTypesEnumerate.length 
                                                    ? contractTypesEnumerate.map((item, index)=> <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                    : null
                                                }
                                            </Radio.Group>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            this.props.form.getFieldValue('contractType') === '01'
                                ? this.ifAssignProductRender()
                                :  null
                        }
                        <Row>
                            <Col span={12}>
                                <div className='mask-dialog'></div>
                                <Form.Item label="模板上传">
                                    {
                                        showUploadText === 0 
                                        ? <Button className='com-btn-opr com-middle-btn-size mr20' icon='upload' onClick={this.handleFileLoad}>点击上传模板</Button>
                                        : showUploadText === 1
                                            ? <Button className='com-btn-opr com-middle-btn-size mr20' loading={true}>上传中</Button>
                                            : <React.Fragment>
                                                <ReviewContranct relativePath={addContractData.relativePath} newFileName={addContractData.newFileName} originalFileName={addContractData.originalFileName}/>
                                                <RemoveContract removeType={false} contractTemplateId={addContractData.resourceFileId} handleRemoveFile={this.handleRemoveFile}  />
                                            </React.Fragment>
                                    }
                                    {
                                        getFieldDecorator('fileLoad', {
                                            initialValue: this.state.filelist,
                                            valuePropName: 'filelist',
                                            rules: [{ required: true, message: '请上传模板' }]
                                        })(
                                            <Upload 
                                                accept='application/pdf'
                                                customRequest={this.customRequest}                         
                                                beforeUpload = {this.beforeUpload}
                                                showUploadList={false} 
                                            >
                                                <Button style={{display: 'none'}} ref='fileLoad'></Button>
                                            </Upload>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <p className='h_text_style'>字段信息配置</p>
                    <EditableTable data={addContractData.configs} subMitFn={this.handleAddCredit} history={this.props.history}/>
                </Spin>
            </div>
        )
    }
}

export default AddComp;

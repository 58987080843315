import React, { Component } from 'react';
import { Button, Col, Form, Input, message, Row, Spin, Table, Select, DatePicker, Modal } from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";


import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

class reLaunch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showReLaunch: true,//添加邀请企业弹框
        }
    }
    componentDidMount() {

    }
    //确认邀请
    okInvitate = () => {
        let {inviteId} = this.props
        let params = {
            action: "10",
            inviteId
        }
        scfBaseApi.inviteIdApi(params).then(res=>{
            message.success(res.message);
            this.props.hideReLaunchModal()
        }).catch(err => {
            message.error(err.message);
        });
        
    }

        //取消邀请
        cancelInvitate = () => {
            this.props.hideReLaunchModal()
        }
        render() {
            let { showReLaunch } = this.state
            let { name, inviteCode } = this.props
            let formRowItem = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { offset: 3, span: 5 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { offset: 1, span: 12 }
                },
                labelAlign: 'right'
            };
            return (
                <div>
                    {/* 添加企业modal */}
                    <Modal
                        title="添加邀请企业"
                        visible={showReLaunch}
                        onOk={this.okInvitate}
                        onCancel={this.cancelInvitate}
                        centered
                    >
                        <Form className="" {...formRowItem}>
                            <Row style={{ textAlign: 'center' }}>
                                <Col span={24}>
                                    {
                                        <span>邀请企业名称：{name}</span>
                                    }
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'center' }}>
                                <Col span={24}>
                                    {
                                        <span>邀请码：{inviteCode}</span>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Modal>

                </div>
            )
        }
    }
    export default Form.create()(reLaunch);
import React, { Component } from 'react'
import { Button, Form, Table,Modal, message } from "antd";
import "../../index.scss"
import TreeGraph from './treeGraph'
import TicketTable from './ticketTable'
import scfBusinessApi from "../../../../request/api/scfBusinessApi";



class CtTransforDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            pageNum: "1",
            total: "",
            ModalText: '您确定要取消该笔转让撤销嘛？',
            visible: false,
            confirmLoading: false,
            id:"",
            ticketMessage:"",//票据信息
            transferList:[],//转让列表
        }
    }
    componentDidMount(){
        let id = this.props.match.params.id
        this.getTicketMessage(id)
        this.getTransferList(id)
    }

            //票据信息
            getTicketMessage = (id) => {
                let params = {
                    id: id
                }
                scfBusinessApi.getDetailApi(params).then(res => {
                    this.setState({
                        ticketMessage: res.data
                    })
                }).catch(err => {
                    message.error(err.message);
                })
            }

    //转让列表transferList
    getTransferList = (id) => {
        let params = {
            id: id
        }
        scfBusinessApi.getTransferLtApi(params).then(res => {
            this.setState({
                transferList: res.data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
    goBack = () => {
        this.props.history.push("/admin/commercialTicketManage/CtTradeClient")
    }
    Detail = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/detail/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    Examine = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/examine/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    Resubmit = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/AddTransforClient/resubmit/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    Revoke = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/revoke/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    ReRevoke = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/rerevoke/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    RevokeExamine = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/revokeexamine/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    CancelExamine = (id,ticketLedgerId,wkId,nodeId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/cancelexamine/${id}/${ticketLedgerId}/${wkId}/${nodeId}`)
    }
    AddTransfor = () => {
        let id = this.props.match.params.id
        let ticketLedgerId = this.props.match.params.ticketLedgerId
        this.props.history.push(`/admin/commercialTicketManage/AddTransforClient/addTransfor/${id}/${ticketLedgerId}`)
    }
    handleOk = () => {
    const {id} = this.state;
       let params = {
           id:id
       }
        scfBusinessApi.cancelRevokeTransferApi(params).then(res=>{
            message.success(res.message)
            this.props.history.go(-1)
        }).catch(err=>{
            message.error(err.message)
        })
        this.setState({
        visible: false,
        });
      };

    handleCancel = () => {
        this.setState({
          visible: false,
        });
      };

      showModal = (id) => {
        this.setState({
            id:id,
            visible: true,
        });
      };
    render() {
        let { visible, confirmLoading, ModalText,ticketMessage,transferList  } = this.state
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '转让持票方票号',
                dataIndex: 'ticketNo',

                key: 'ticketNo',
                sorter: (a, b) => {
                    if (!a.ticketNo) {
                        a.ticketNo = ""
                    } else if (!b.ticketNo) {
                        b.ticketNo = ""
                    }
                    return a.ticketNo - b.ticketNo
                },

            },
            {
                title: '转让额度(元)',
                dataIndex: 'transferAmount',
                key: 'transferAmount',
                sorter: (a, b) => {
                    if (!a.transferAmount) {
                        a.transferAmount = ""
                    } else if (!b.transferAmount) {
                        b.transferAmount = ""
                    }
                    return a.transferAmount.replace(/[^\d.]/g, "") - b.transferAmount.replace(/[^\d.]/g, "")
                },


            },
            {
                title: '转让到',
                dataIndex: 'ticketToOrgName',

                key: 'ticketToOrgName',
                sorter: (a, b) => {
                    if (!a.ticketToOrgName) {
                        a.ticketToOrgName = ""
                    } else if (!b.ticketToOrgName) {
                        b.ticketToOrgName = ""
                    }
                    return a.ticketToOrgName.localeCompare(b.ticketToOrgName)
                },


            },

            {
                title: '转让日期',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }

            },

            {
                title: '转让状态',
                dataIndex: 'ticketTransStatusCdDesc',
                key: 'ticketTransStatusCdDesc',
                sorter: (a, b) => {
                    if (!a.ticketTransStatusCdDesc) {
                        a.ticketTransStatusCdDesc = ""
                    } else if (!b.ticketTransStatusCdDesc) {
                        b.ticketTransStatusCdDesc = ""
                    }
                    return a.ticketTransStatusCdDesc.localeCompare(b.ticketTransStatusCdDesc)
                }
            },
            {
                title: '操作',
                width: '200',
                render: (record) => {
                    let userId = JSON.parse(localStorage.getItem("userId"))
                    return <div className="flex-around-center">
                         <Button type="primary" onClick={()=>this.Detail(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>详情</Button>
                         {record.ticketTransStatusCd==="0010"&&record.sysOperationType==="10"?<Button type="primary" onClick={()=>this.Examine(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>审核</Button>:null}
                         {(record.ticketTransStatusCd==="0020"||record.ticketTransStatusCd==="1020")&&(record.createUser === userId)?<Button type="primary" onClick={()=>this.Resubmit(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>重新提交</Button>:null}
                         {record.ticketTransStatusCd==="1000"&&record.createUser === userId?<Button type="primary" onClick={()=>this.Revoke(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>撤销</Button>:null}
                         {record.ticketTransStatusCd==="0040"&&record.createUser === userId?<Button type="primary" onClick={()=>this.showModal(record.id)}>取消撤销</Button>:null}
                         {(record.ticketTransStatusCd==="0040"&&record.createUser === userId)?<Button type="primary" onClick={()=>this.ReRevoke(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>重新撤销</Button>:null}
                         {record.ticketTransStatusCd==="0030"&&record.sysOperationType==="10"? <Button type="primary" onClick={()=>this.RevokeExamine(record.id,record.ticketLedgerId,record.wkId,record.nodeId)}>撤销审核</Button>:null}
                    </div>

                }
            },
        ];
        return (
            <div className="CtTransforDetail">
                <ul>
                    <li><span>票据号码:</span>{ticketMessage.ticketNo}</li>
                    <li><span>出票日期:</span>{ticketMessage.ticketDate}</li>
                    <li><span>汇票到期日期:</span>{ticketMessage.ticketExpireDate}</li>
                    <li><span>背书转让方:</span>{ticketMessage.ticketFromOrgName}</li>
                </ul>
                <ul className="ul">
                    <li><span>{ticketMessage.ticketAmount}元</span><span>票据额度(元)</span></li>
                    <li><span>{ticketMessage.remainAmount}元</span><span>票据剩余额度(元)</span></li>
                    <li><span>{ticketMessage.transferredAmount}元</span><span>票据已转让额度(元)</span></li>
                    <li><span>{ticketMessage.amountInTransfer}元</span><span>票据转让中额度(元)</span></li>
                </ul>
                <div className="goBack">
                    <Button onClick={this.goBack} style={{ marginRight: '10px' }}>返回</Button>
                    <Button type="primary" onClick={this.AddTransfor}>新增转让</Button>

                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={transferList}
                        rowKey={(record, index) => record.id}
                        pagination={false}
                       
                    />
                </div>
                <div>
                    <div className="marginTopOrBottom">
                    <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                    </div>
                    <div>
                        <TreeGraph id={this.props.match.params.id}></TreeGraph>
                    </div>
                </div>


                <div>
                    <Modal
                        title="取消撤销"
                        visible={visible}
                        onOk={this.handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={this.handleCancel}
                        centered
                    >
                        <p>{ModalText}</p>
                       </Modal>
                </div>
                </div>
        )
    }
}
export default Form.create()(CtTransforDetail);
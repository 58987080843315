import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Select, Row, Col, Spin, DatePicker, Button, message, Checkbox, InputNumber, Descriptions } from 'antd';

const { Option } = Select;
const addBorrowerCreditApi = scfBusinessApi['addBorrowerCreditApi'];

@Form.create()
@connect((store) => ({ store: store.borrowerCredit, loading: store.loading }))
class AddComp extends Component {

    state = {
        endDate: '',
        startDate: '',
        productId: '',
        projectName: '',
        borrowerId: '',
        loanTermEnumerateArr: []
    }

    componentDidMount () {
        this.props.dispatch({ type: 'borrowerCredit/updateReducers', payload: { key: 'productDetails', val: {} }});
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerName'});
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerProjectEnumerate'});
        this.props.dispatch({ type: 'borrowerCredit/getLoanTermEnumerate', payload: 'debt_term'});
    }

    handleAddCredit = debounce(() => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                values.borrowerStartRate = moment(values.borrowerStartRate).format('YYYY-MM-DD HH:mm:ss');
                values.borrowerEndRate = moment(values.borrowerEndRate).format('YYYY-MM-DD HH:mm:ss');
                values.productName = this.state.productId;
                values.projectName = this.state.projectName;
                values.borrowerId = values.borrowerId + '';
                values.borrowerName = this.state.borrowerId;
                values.borrowerLoanPeriod = values.borrowerLoanPeriod ? values.borrowerLoanPeriod.join(',') : undefined;
                addBorrowerCreditApi(values).then(res => {
                    message.success(res.message);
                    this.props.history.push('/admin/borrowerCredit/list')
                }).catch(err => message.error(err.message))
            }
        })
    }, 300)

    disabledStartDate = startValue => {
        const { endDate } = this.state;
        if (!startValue || !endDate) {
          return startValue < moment().subtract(1, "days");
        } 
        return startValue.valueOf() > endDate.valueOf() || startValue < moment().subtract(1, "days");
    };
    
    disabledEndDate = endValue => {
        const { startDate } = this.state;
        if (!endValue || !startDate) {
          return endValue < moment().subtract(1, "days");
        }
        return endValue.valueOf() <= startDate.valueOf();
    };

    handleProjectChange = (val, target) => {
        if(!val) return;
        this.props.form.resetFields('productId');
        this.props.dispatch({ type: 'borrowerCredit/updateReducers', payload: { key: 'productDetails', val: [] }});
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerProjectProducts', payload: val});
        this.setState({ projectName: target.props.children })
    }

    handleProductName = (val, target) => {
        if(!val) return;
        const params = { id: val }
        this.props.dispatch({ type: 'borrowerCredit/getAllEnterprise', payload: params}).then(res => {
            if(!(this.props.store.productDetails && this.props.store.productDetails.productLoanPeriod)) return;
            this.setState({
                loanTermEnumerateArr: this.handleFilterArrFn(this.props.store.productDetails.productLoanPeriod.split(','), this.props.store.loanTermEnumerate)
            })
        })
        this.setState({ productId: target.props.children })
    }

    handleStartDateChange = (value) => {
        this.setState({ startDate: value })
    }

    handleEndDateChange = (value) => {
        this.setState({ endDate: value })
    }

    handleBorrowerNameChange = (val, target) => {
        this.setState({
            borrowerId: target.props.children
        })
        if(!this.props.form.getFieldValue('companyCreditId')) {
            this.props.form.setFields({
                companyCreditId: {
                    errors: [new Error('请选择项目')]
                }
            })
        }
        if(!this.props.form.getFieldValue('productId')) {
            this.props.form.setFields({
                productId: {
                    errors: [new Error('请选择产品')]
                }
            })
        }
    }

    handleFilterArrFn = (conditionArr, filterArr) => {
        let newArr = [];
        conditionArr.forEach(item => {
            let index = 0;
            index = filterArr.findIndex(items => item === items.dicKey);
            newArr.push(filterArr[index]);
        })
        return newArr;
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loanTermEnumerateArr } = this.state;
        const { borrowerProjectEnumerate, borrowerProjectProducts, borrowerName, productDetails } = this.props.store;
        const isLoding3 = this.props.loading.effects['borrowerCredit/getBorrowerProjectProducts'];
        const isLoding5 = this.props.loading.effects['borrowerCredit/getLoanTermEnumerate'];
        const formItemLayout = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            }
        };
        return (
            <div className='addComp_style'>
                <Spin spinning={ isLoding5 }>
                    <Form {...formItemLayout} className='enble_form_style'>
                        <Descriptions title="项目-产品信息" bordered column={2} size="small">
                            <Descriptions.Item label="项目名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyCreditId', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择项目' }]
                                        })(
                                            <Select name='companyCreditId' onChange={this.handleProjectChange}>
                                                {
                                                    borrowerProjectEnumerate && borrowerProjectEnumerate.length 
                                                    ? borrowerProjectEnumerate.map((item, index)=> <Option key={index} value={item.id}>{item.projectName}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="产品名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('productId', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择产品' }]
                                        })(
                                            <Select name='productId' onChange={this.handleProductName} loading={isLoding3}>
                                                {
                                                    borrowerProjectProducts && borrowerProjectProducts.length 
                                                    ? borrowerProjectProducts.map((item, index)=> <Option key={index} value={item.id}>{item.productName}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业名称">{ productDetails && productDetails.companyName ? productDetails.companyName : null }</Descriptions.Item>
                            <Descriptions.Item label="资金方名称">{ productDetails && productDetails.capitalName ? productDetails.capitalName : null }</Descriptions.Item>
                            <Descriptions.Item label="融资模式">{ productDetails && productDetails.productModeName ? productDetails.productModeName : null }</Descriptions.Item>
                            <Descriptions.Item label="产品利率（%）">{ productDetails && productDetails.productStartRate ? productDetails.productStartRate : ''} {productDetails && productDetails.productEndRate ? `-${productDetails.productEndRate}` : null }</Descriptions.Item>
                            <Descriptions.Item label="提前还款手续费率（%）">{ productDetails && productDetails.productEarlyRate ? productDetails.productEarlyRate : null }</Descriptions.Item>
                            <Descriptions.Item label="逾期利率（%）">{ productDetails && productDetails.productOverdueRate ? productDetails.productOverdueRate : null }</Descriptions.Item>
                            <Descriptions.Item label="融资比例（%）">{ productDetails && productDetails.productFinanceScale ? productDetails.productFinanceScale : null }</Descriptions.Item>
                            <Descriptions.Item label="产品可用授信额度（元）">{ productDetails && productDetails.totalAmount ? productDetails.totalAmount : null }</Descriptions.Item>
                            <Descriptions.Item label="还款方式">{ productDetails && productDetails.productRepayWayName ? productDetails.productRepayWayName : null }</Descriptions.Item>
                            <Descriptions.Item label="借款期限">{ productDetails && productDetails.productLoanPeriodName ? productDetails.productLoanPeriodName : null }</Descriptions.Item>
                        </Descriptions>
                        <p style={{height: 24}}></p>
                        <Descriptions title="借款方授信信息" bordered column={2} size="small">
                            <Descriptions.Item label="借款方名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerId', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择借款方' }]
                                        })(
                                            <Select name='borrowerId' onChange={this.handleBorrowerNameChange}>
                                                {
                                                    borrowerName && borrowerName.length 
                                                    ? borrowerName.map((item, index)=> <Option key={index} value={item.id}>{item.orgName}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="借款方授信额度（元）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('totalAmount', {
                                            initialValue: null,
                                            rules: [
                                                { required: true, message: '请输入借款方授信额度' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                min={1}
                                                name='totalAmount'
                                                style={{width: '100%'}}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="产品利率（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerRate', {
                                            initialValue: null,
                                            rules: [
                                                { required: true, message: '请输入产品利率' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                name='borrowerRate'
                                                max={productDetails && productDetails.productEndRate ? productDetails.productEndRate : null}
                                                min={productDetails && productDetails.productStartRate ? productDetails.productStartRate : 0} 
                                                style={{width: '100%'}} 
                                                placeholder={`不能超过该产品的利率范围${
                                                    productDetails && productDetails.productStartRate ? `${productDetails.productStartRate}%` : ''
                                                }${
                                                    productDetails && productDetails.productEndRate ? `-${productDetails.productEndRate}%` : ''
                                                }`}
                                            ></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="融资比例（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerFinanceScale', {
                                            initialValue: null,
                                            rules: [{
                                                 required: true, 
                                                 message: '请输入融资比例' 
                                            }, {
                                                pattern: /^\d+$/,
                                                message: '请输入合法数字！'
                                            }]
                                        })(
                                            <InputNumber 
                                                name='borrowerFinanceScale' 
                                                min={0} 
                                                max={productDetails && productDetails.productFinanceScale ? productDetails.productFinanceScale : null}
                                                style={{width: '100%'}} 
                                                placeholder={`不能超过该产品的融资比例${
                                                    productDetails && productDetails.productFinanceScale ? `${productDetails.productFinanceScale}%` : ''
                                                }`}
                                            ></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信起始日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerStartRate', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择授信起始日' }]
                                        })(
                                            <DatePicker 
                                                name='borrowerStartRate' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledStartDate}
                                                onChange={this.handleStartDateChange}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信到期日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerEndRate', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择授信到期日' }]
                                        })(
                                            <DatePicker 
                                                name='borrowerEndRate' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledEndDate}
                                                onChange={this.handleEndDateChange} 
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            {
                                productDetails && productDetails.productRepayWay !== '1' && productDetails.productRepayWay
                                    ? <Descriptions.Item label="借款期限">
                                        <Form.Item style={{margin: 0}}>
                                            {
                                                getFieldDecorator('borrowerLoanPeriod', {
                                                    initialValue: null,
                                                    rules: [{ required: true, message: '请选择借款期限' }]
                                                })(
                                                    <Checkbox.Group name='borrowerLoanPeriod'>
                                                        <Row style={{width: 580}}>
                                                            {
                                                                loanTermEnumerateArr && loanTermEnumerateArr.length 
                                                                    ? loanTermEnumerateArr.map((item, index) => {
                                                                        
                                                                        return <Col span={4} key={index}><Checkbox value={item.dicKey}>{item.dicValue}</Checkbox></Col>
                                                                    } )
                                                                    : null
                                                            }
                                                        </Row>
                                                    </Checkbox.Group>
                                                )
                                            }
                                        </Form.Item>
                                    </Descriptions.Item>
                                    : null 
                            }
                        </Descriptions>
                    </Form>
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleAddCredit}>确定</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default AddComp;

import React, {Component} from 'react';
import {Form, Input, Radio, Button, message, DatePicker, Select, Divider, Row, Col} from 'antd';
import moment from "moment";
import {Link, withRouter} from "dva/router";
import FileUploadForm from '../../../components/fileUpload';
import {checkFixedPhone, checkIdNumber, checkUnifiedCreditCode, checkPhone,checkBankNumber} from '../../../utils/formatCheck';
import scfBusinessApi from "../../../request/api/scfBusinessApi";
import scfBaseApi from "../../../request/api/scfBaseApi";
import queryString from "query-string";

const {RangePicker} = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

@withRouter
class AddBusinessInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            isCertificatesOne: 1,
            provinceArr: [],
            orgCityArr:[],
            orgDistrictArr:[],
            operDistrictArr:[],
            operCityArr:[],
            longTermEffective: false,
            visibleModal: false,
            leagalLongTerm: false,
            isLegalPersonLongTerm: "1",//自然人证件是否长期 1-长期 0-固定
            bankList: [],  //银行列表,
            loading: false,
            orgType: "",    //客户类型    1-企业 2-工商户 3-自然人
            businessRole: "",
            idType: "",
            isLegalPersonMarriedArr: [],
            isBusinessLicenseLongTerm: "1",//营业执照  1-长期 0-固定
            idFrontPreview: "",
            bsnLcsPreview: "",
            idBackPreview: "",
            status:''
        }
    }

    componentDidMount() {
        let item = this.props.match.params.item;
        let location = this.props.history.location;
        this.getDicLt("isLegalPersonMarried");
        this.getBankList();
        if (item === "add") {
            let clientInfoManage = JSON.parse(localStorage.getItem("clientInfoManage"));
            let {orgType, businessRole, idType} = clientInfoManage;
            this.setState({orgType, businessRole, idType});
        }
        if (item === "add" && this.props.orgType !== 'person') {
            this.getProvince();
        } else if (item === "view"){
            console.log(location.search)
            let orgId = queryString.parse(location.search).orgId;
            let orgType=queryString.parse(location.search).orgType;
            let status=queryString.parse(location.search).status;
            this.setState({orgType,status},()=>{
                    this.getOrgInfo(orgId);
            });
        }
    }

    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType: type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //客户信息详情
    getOrgInfo = (orgId) => {
      scfBaseApi.queryDetailApi({id: orgId}).then(res => {
            let {
                orgType, businessLicenseResourceFileId, idType, orgName, cardNo, orgProvince, orgCity, orgCounty,
                orgAddress, businessScope, isBusinessLicenseLongTerm,
                businessLicenseValidityStart, businessLicenseValidityEnd,
                businessLicenseValidity,
                operProvince, operCity, operCounty, operAddress, orgLandline, orgFax,
                orgBank,
                idFrontResourceFileId, idBackResourceFileId, legalPersonIdType, legalPersonName,
                legalPersonCardno, isLegalPersonLongTerm, legalPersonValidityStart, legalPersonValidityEnd, legalPersonValidity,
                isLegalPersonMarried, legalPersonMobile, legalPersonEmail,
                contactName,contactEmail, contactJob, contactMobile, orgEmail,
                idBackFile, businessFile, idFrontFile
            } = res.data;
            //公用
            let idFrontPreview = {
                newFileName: idFrontFile.newFileName,
                originalFileName: idFrontFile.originalFileName,
                relativePath: idFrontFile.relativePath,
            }
            let bsnLcsPreview = {
                newFileName: businessFile.newFileName,
                originalFileName: businessFile.originalFileName,
                relativePath: businessFile.relativePath,
            }
            let idBackPreview = {
                newFileName: idBackFile.newFileName,
                originalFileName: idBackFile.originalFileName,
                relativePath: idBackFile.relativePath,
            }
            this.setState({
                bsnLcsPreview,
                idFrontPreview,
                idBackPreview
            });

            this.props.form.setFieldsValue({
                idFrontResourceFileId, idBackResourceFileId,
                legalPersonIdType, legalPersonName,
                legalPersonCardno, isLegalPersonLongTerm,
                legalPersonValidityStart: moment(legalPersonValidityStart).isValid() ? moment(legalPersonValidityStart) : null,
                legalPersonValidityEnd: moment(legalPersonValidityEnd).isValid() ? moment(legalPersonValidityEnd) : null,
                legalPersonValidity, legalPersonEmail,
                bankName: orgBank.bankName, bankCnap: orgBank.bankCnap, legalPersonMobile,
            })
            if (orgType === "org") {
                this.props.form.setFieldsValue({
                    orgEmail:orgEmail,
                    businessLicenseResourceFileId, idType, orgName, cardNo, orgProvince, orgCity, orgCounty,
                    orgAddress, businessScope, isBusinessLicenseLongTerm, businessLicenseValidity,
                    operProvince, operCity, operCounty, operAddress, orgLandline, orgFax,
                    bankChildname: orgBank.bankChildname,
                    businessLicenseValidityStart: moment(businessLicenseValidityStart).isValid() ? moment(businessLicenseValidityStart) : null,
                    businessLicenseValidityEnd: moment(businessLicenseValidityEnd).isValid() ? moment(businessLicenseValidityEnd) : null,
                    isLegalPersonMarried,
                    contactName, contactJob, contactMobile
                })
            } else if (orgType === "individual") {
                this.props.form.setFieldsValue({
                    businessLicenseValidityStart: moment(businessLicenseValidityStart).isValid() ? moment(businessLicenseValidityStart) : null,
                    businessLicenseValidityEnd: moment(businessLicenseValidityEnd).isValid() ? moment(businessLicenseValidityEnd) : null,
                    businessLicenseResourceFileId, idType, orgName, cardNo, orgProvince, orgCity, orgCounty,
                    orgAddress, businessScope, isBusinessLicenseLongTerm, businessLicenseValidity,
                    operProvince, operCity, operCounty, operAddress, orgLandline, orgFax,
                    isLegalPersonMarried
                })
            }
        }).catch(err => {
            message.error(err.message);
        })

    }
    //获取银行列表
    getBankList = () => {
        scfBaseApi.queryBankListApi().then(res => {
            let data = res.data;
            this.setState({
                bankList: data
            })
        }).catch(err => {
            message.error(err.message);
        })

    }
    //获取省
    getProvince = () => {
        scfBaseApi.queryProvinceApi().then(res => {
            let data = res.data;
            this.setState({
                provinceArr: data
            })

        }).catch(err => {
            message.error(err.message);
        })

    }
    //根据省获取市
    handleChangeProvince = (value, e,type="") => {
        let id = e.key;
        scfBaseApi.queryMunicipalApi({id}).then(res => {
            let data = res.data;
            this.props.form.resetFields([`${type}City`,`${type}County`]);
            this.setState({
                [`${type}CityArr`]: data,
                [`${type}DistrictArr`]:[],

            });

        }).catch(err => {
            message.error(err.message);
        })
    }
    //根据市获取县
    handleChangeCity = (value, e,type="") => {
        let id = e.key;
        scfBaseApi.queryDistrictApi({id}).then(res => {
            let data = res.data;
            this.props.form.resetFields([`${type}County`]);
            this.setState({
                [`${type}DistrictArr`]:data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //结束日期
    disabledEndDate = (current) => {
        return current && current < moment().endOf('day');
    };
    //开始日期
    disabledStartDate = (current) => {
        return current && current > moment().endOf('day');
    };
    //营业执
    handleChangeBusinessLicense = (e) => {
        this.setState({
            isBusinessLicenseLongTerm: e.target.value
        })
        if(e.target.value==1){
            this.props.form.setFieldsValue({
                businessLicenseValidityEnd: moment('2099-12-31').isValid() ? moment('2099-12-31') : null,
            })
        }
    }
    //提交
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.businessLicenseResourceFileId !== undefined && values.businessLicenseResourceFileId === '') {
                    message.warn('请上传营业执照！');
                } else if (values.idFrontResourceFileId === '') {
                    message.warn('请上传法人身份证人像面！');
                } else if (values.idBackResourceFileId === '') {
                    message.warn('请上传法人身份证国徽面！');
                } else {
                    let {orgType, businessRole, idType} = this.state;
                    values.orgType = orgType;
                    values.businessRoles = ['supplier'];
                    values.idType = idType;
                    values.legalPersonValidityStart = moment(values.legalPersonValidityStart).format('YYYY-MM-DD');
                    values.legalPersonValidityEnd = moment(values.legalPersonValidityEnd).format('YYYY-MM-DD');
                    if (orgType === 'org') {
                        values.businessLicenseValidityStart = moment(values.businessLicenseValidityStart).format('YYYY-MM-DD');
                        values.businessLicenseValidityEnd = moment(values.businessLicenseValidityEnd).format('YYYY-MM-DD');
                        this.setState({loading: true});
                        scfBaseApi.addOrgApi(values).then(res => {
                            this.setState({loading: false});
                            message.success(res.message);
                            this.props.history.push('/admin/clientManage/clientInfo/search');
                        }).catch(err => {
                            this.setState({loading: false});
                            message.error(err.message);
                        })
                    } else if (orgType === 'individual') {
                        values.businessLicenseValidityStart = moment(values.businessLicenseValidityStart).format('YYYY-MM-DD');
                        values.businessLicenseValidityEnd = moment(values.businessLicenseValidityEnd).format('YYYY-MM-DD');
                        this.setState({loading: true});
                        scfBaseApi.addIndividualApi(values).then(res => {
                            this.setState({loading: false});
                            message.success(res.message);
                            this.props.history.push('/admin/clientManage/clientInfo/search');
                        }).catch(err => {
                            this.setState({loading: false});
                            message.error(err.message);
                        })
                    } else {
                        this.setState({loading: true});
                        scfBaseApi.addPersonApi(values).then(res => {
                            this.setState({loading: false});
                            message.success(res.message);
                            this.props.history.push('/admin/clientManage/clientInfo/search');
                        }).catch(err => {
                            this.setState({loading: false});
                            message.error(err.message);
                        })
                    }

                }
            }
        })
    };

    //删除上传文件
    handleRemoveFile = () => {
        this.props.form.resetFields([
            "businessLicenseResourceFileId",
            "orgName",               //认证企业名称
            "legalPersonName",               //法人
            "orgAddress",       //注册地址
            "cardNo",    //统一社会信用代码,
            "businessScope",
            "isBusinessLicenseLongTerm",
            "businessLicenseValidityStart",
            "businessLicenseValidityEnd"
        ])
    }
    //确定上传的field 和value
    handleFileChange = (field, val) => {
        if (field === 'businessLicenseResourceFileId') {
            //set OCR
            this.props.form.setFieldsValue({
                businessLicenseResourceFileId: val.resourceFileId,        //营业执照图片
                orgName: val.orgName,               //认证企业名称
                legalPersonName: val.legalPersonName,               //法人
                orgAddress: val.orgAddress,       //注册地址
                cardNo: val.cardNo,    //统一社会信用代码,
                businessScope: val.businessScope,
                isBusinessLicenseLongTerm: val.isBusinessLicenseLongTerm,
                businessLicenseValidityStart: moment(val.businessLicenseValidityStart).isValid() ? moment(val.businessLicenseValidityStart) : null,
                businessLicenseValidityEnd: moment(val.businessLicenseValidityEnd).isValid() ? moment(val.businessLicenseValidityEnd) : null,
            });
            this.setState({
                isBusinessLicenseLongTerm: val.isBusinessLicenseLongTerm,
            });
        } else if (field === 'idFrontResourceFileId') {
            this.props.form.setFieldsValue({
                idFrontResourceFileId: val.resourceFileId,
                legalPersonName: val.legalPersonName,
                legalPersonCardno: val.legalPersonCardno,

            });
        } else if (field === 'idBackResourceFileId') {
            this.setState({
                isLegalPersonLongTerm: val.isLegalPersonLongTerm
            });
            this.props.form.setFieldsValue({
                idBackResourceFileId: val.resourceFileId,
                isLegalPersonLongTerm: val.isLegalPersonLongTerm,
                legalPersonValidityStart: moment(val.legalPersonValidityStart).isValid() ? moment(val.legalPersonValidityStart) : null,
                legalPersonValidityEnd: moment(val.legalPersonValidityEnd).isValid() ? moment(val.legalPersonValidityEnd) : null
            });
        } else {
            this.props.form.setFieldsValue({[field]: val});
        }
    };


    handleLglPsnLongTermChange = (e) => {
        this.setState({
            isLegalPersonLongTerm: e.target.value
        })
        if(e.target.value==1){
            this.props.form.setFieldsValue({
                legalPersonValidityEnd:moment('2099-12-31')
            })
        }
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }

    render() {
        let {match} = this.props;
        let isView = match.params.item === "view" ? true : false;
        const {
            bankList, provinceArr, orgCityArr,orgDistrictArr,
            operDistrictArr,operCityArr, loading, orgType, isLegalPersonLongTerm,
            isBusinessLicenseLongTerm, isLegalPersonMarriedArr,
            bsnLcsPreview, idFrontPreview, idBackPreview , status
        } = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
            labelAlign: 'right'
        };
        const formAddressItem = {
            wrapperCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 18},
            },
            labelAlign: 'right'
        };

        //省
        const formProvinceItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'right'
        }
        //市
        const formCityItem = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
            labelAlign: 'right'
        }
        return (
            <div className="add-business-info-wrapper">
                {
                    isView === false ? <div className="upload-img-request title-com ">
                        <h6><b>上传照片要求</b></h6>
                        <ul>
                            <li style={{fontSize: '14px'}}>1. 请上传最新证件原件的彩色扫描件或数码照，如使用复印件请加盖公章上传（推荐使用原件电子版）</li>
                            <li style={{fontSize: '14px'}}>2. 图片支持格式：jpg/jpeg/png/bmp格式；</li>
                            <li style={{fontSize: '14px'}}>3. 请使用像素大于600万像素的相机或手机拍摄，图片大小不超过10M；</li>
                            <li style={{fontSize: '14px'}}>4. 经微信传输过的图片会导致图片失真，请避免使用经微信传输过的图片；</li>
                        </ul>
                    </div> : null
                }

                <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                    {
                        orgType === 'org' || orgType === 'individual' ?
                            <div>
                                <div className="business-Licence-wrapper">
                                    <div className="title-com">
                                        <h6><b>营业执照</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft40px">
                                        <div>
                                            <FormItem style={{marginBottom: 0}}>
                                                <FileUploadForm uploadText="营业执照"
                                                                action="ocrBusinessLicenseApi"
                                                                ocr='false'
                                                                fileType='02'
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                field="businessLicenseResourceFileId"
                                                                key="businessLicenseResourceFileId"
                                                                uploadDescripte=""
                                                                preview={isView === true ? bsnLcsPreview : null}
                                                />
                                                {
                                                    getFieldDecorator('businessLicenseResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden"/>)
                                                }
                                            </FormItem>
                                        </div>
                                        <div style={{width: 700}}>
                                            <FormItem label="证件类型">
                                                {
                                                    getFieldDecorator('idType', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择证件类型'
                                                            }
                                                        ],
                                                        initialValue: "1",
                                                    })(<Radio.Group disabled={isView}><Radio value="1">统一社会信用代码类</Radio></Radio.Group>)
                                                }
                                            </FormItem>
                                            <FormItem label="名称">
                                                {
                                                    getFieldDecorator('orgName', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入企业名称'
                                                            },
                                                            {
                                                                max: 1000,
                                                                message: "最大长度为1000位,请重新输入"
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="统一社会信用代码(18位)">
                                                {
                                                    getFieldDecorator('cardNo', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入统一社会信用代码'
                                                            },
                                                            {
                                                                validator: checkUnifiedCreditCode
                                                            }
                                                        ],
                                                        initialValue: ''
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <div className="com-clearfix">
                                                <FormItem label="住所" {...formProvinceItem} className="com-fl"
                                                          style={{width: "50%"}}>
                                                    {
                                                        getFieldDecorator('orgProvince', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: '请选择省'
                                                                },
                                                            ],
                                                            initialValue: ''
                                                        })(<Select onChange={(val,e)=>this.handleChangeProvince(val,e,"org")}
                                                                   disabled={isView}
                                                                   placeholder='请选择省'>
                                                            {
                                                                provinceArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                                <FormItem className="com-fl" style={{width: "25%"}} {...formCityItem}>
                                                    {
                                                        getFieldDecorator('orgCity', {
                                                            rules: [{
                                                                required: true,
                                                                message: '请选择市'
                                                            }],
                                                            initialValue: ''
                                                        })(<Select onChange={(val,e)=>this.handleChangeCity(val,e,"org")}
                                                                   disabled={isView}
                                                                   placeholder='请选择市'>
                                                            {
                                                                orgCityArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                                <FormItem className="com-fl" style={{width: "25%"}} {...formCityItem}>
                                                    {
                                                        getFieldDecorator('orgCounty', {
                                                            rules: [{
                                                                required:orgDistrictArr.length>0?true:false,
                                                                message: '请选择县'
                                                            }],
                                                            initialValue: ''
                                                        })(<Select placeholder='请选择县' disabled={isView}>
                                                            {
                                                                orgDistrictArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                            </div>
                                            <FormItem label="" {...formAddressItem}>
                                                {
                                                    getFieldDecorator('orgAddress', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入详细地址'
                                                            },
                                                            {
                                                                max: 1000,
                                                                message: "最大长度为100位,请重新输入"
                                                            }
                                                        ],
                                                        initialValue: '',
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="请精确填写，具体到门牌号" disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="经营范围">
                                                {
                                                    getFieldDecorator('businessScope', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入经营范围'
                                                            },
                                                            {
                                                                max: 1000,
                                                                message: "最大长度为1000位,请重新输入"
                                                            }
                                                        ],
                                                        initialValue: '',
                                                        validateTrigger: "onBlur"
                                                    })(<Input.TextArea disabled={isView} style={{height: 80}}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="营业期限（止）">
                                                {
                                                    getFieldDecorator('isBusinessLicenseLongTerm', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择期限类型'
                                                            }
                                                        ],
                                                        initialValue: "1",
                                                        validateTrigger: "onBlur"
                                                    })(
                                                        <Radio.Group disabled={isView}
                                                                     onChange={this.handleChangeBusinessLicense}>
                                                            <Radio value="1">长期</Radio>
                                                            <Radio value="0">固定期限</Radio>

                                                        </Radio.Group>
                                                    )
                                                }
                                            </FormItem>
                                            <Row align="middle">
                                                <Col span={8} offset={6}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('businessLicenseValidityStart', {
                                                                rules: [{
                                                                    required: true, message: '请选择开始日期'
                                                                }],
                                                                initialValue: null
                                                            })(<DatePicker
                                                                disabled={isView}
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledStartDate}
                                                                placeholder="开始日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                                <Col span={2} className="com-text-center date-interval">———</Col>
                                                <Col span={8}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('businessLicenseValidityEnd', {
                                                                rules: [{
                                                                    required: true, message: '请选择结束日期'
                                                                }],
                                                                initialValue: isBusinessLicenseLongTerm === "1" ? moment('2099-12-31', "YYYY-MM-DD") : null
                                                            })(<DatePicker
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledEndDate}
                                                                disabled={isView === true || isBusinessLicenseLongTerm === "1" ? true : false}
                                                                placeholder="结束日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                                {/*经营地址*/}
                                <div style={{paddingLeft:'20px'}}>
                                    <div className="title-com">
                                        <h6><b>经营地址</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft323px">
                                        <div style={{width: 700}}>
                                            <div className="com-clearfix">
                                                <FormItem label="经营地址" {...formProvinceItem} className="com-fl"
                                                          style={{width: "50%"}}>
                                                    {
                                                        getFieldDecorator('operProvince', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: '请选择省'
                                                                },
                                                            ],
                                                        })(<Select onChange={(e,val)=>{this.handleChangeProvince(e,val,"oper")}}
                                                                   disabled={isView}
                                                                   placeholder='请选择省'>
                                                            {
                                                                provinceArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                                <FormItem className="com-fl" style={{width: "25%"}} {...formCityItem}>
                                                    {
                                                        getFieldDecorator('operCity', {
                                                            rules: [{
                                                                required: true,
                                                                message: '请选择市'
                                                            },
                                                            ],
                                                        })(<Select onChange={(val,e)=>this.handleChangeCity(val,e,"oper")}
                                                                   disabled={isView}
                                                                   placeholder='请选择市'>
                                                            {
                                                                operCityArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                                <FormItem className="com-fr" style={{width: "25%"}} {...formCityItem}>
                                                    {
                                                        getFieldDecorator('operCounty', {
                                                            rules: [{
                                                                required: operDistrictArr.length>0?true:false,
                                                                message: '请选择县'
                                                            },
                                                            ],
                                                        })(<Select placeholder='请选择县' disabled={isView}>
                                                            {
                                                                operDistrictArr.map(record => {
                                                                    return <Option value={record.areaName}
                                                                                   key={record.id}>{record.areaName}</Option>
                                                                })
                                                            }
                                                        </Select>)
                                                    }
                                                </FormItem>
                                            </div>
                                            <FormItem label="" {...formAddressItem}>
                                                {
                                                    getFieldDecorator('operAddress', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入详细地址'
                                                            },
                                                            {
                                                                max: 100,
                                                                message: "最大长度为100位,请重新输入"
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="请精确填写，具体到门牌号" disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="座机">
                                                {
                                                    getFieldDecorator('orgLandline', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入座机号码'
                                                            }, {
                                                                validator: checkFixedPhone,
                                                                message: '座机号码格式有误，请重填'
                                                            }],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="区号-电话" disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="传真">
                                                {
                                                    getFieldDecorator('orgFax', {
                                                        rules: [
                                                             {
                                                                validator: checkFixedPhone,
                                                                message: '号码号码格式有误，请重填'
                                                            }],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="区号-电话" disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                {/* 对公账户信息 */}
                                <div>
                                    <div className="title-com">
                                        <h6><b>{orgType === 'org' ? "对公账户信息" : "法人银行账户信息"}</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft340px">
                                        <div style={{width: 700}}>
                                            <FormItem label="开户银行">
                                                {
                                                    getFieldDecorator('bankName', {
                                                        rules: [
                                                            {
                                                                required: true, message: '请输入开户银行名称'
                                                            }],
                                                        validateTrigger: "onBlur"

                                                    })(<Select
                                                        disabled={isView}
                                                        showSearch
                                                        placeholder="请输入查询"
                                                    >
                                                        {
                                                            bankList != null && bankList.length !== 0 ? bankList.map((record, index) => {
                                                                return <Option key={record.id} value={record.name}>{record.name}</Option>
                                                            }) : null
                                                        }
                                                    </Select>)
                                                }
                                            </FormItem>
                                            {orgType === 'org' ? <FormItem label="开户支行">
                                                {
                                                    getFieldDecorator('bankChildname', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入开户支行名称'
                                                            },
                                                            {
                                                                max: 100,
                                                                message: "最大长度为100位,请重新输入"
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem> : null}
                                            <FormItem label="银行账户">
                                                {
                                                    getFieldDecorator('bankCnap', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入银行账户'
                                                            },
                                                            {validator:checkBankNumber}
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                {/* 法定代表人证件 */}
                                <div>
                                    <div className="title-com">
                                        <h6><b>法定代表人证件</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft78px">
                                        <div className="clearfix">
                                            <FormItem className="com-fl">
                                                <FileUploadForm uploadText="身份证头像面"
                                                                action="ocrIdCardFrontApi"
                                                                ocr='false'
                                                                fileType='01'
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={isView === true ? idFrontPreview : null}
                                                                field="idFrontResourceFileId"
                                                                key="idFrontResourceFileId"/>
                                                {
                                                    getFieldDecorator('idFrontResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden"/>)
                                                }
                                            </FormItem>

                                            <FormItem className="com-fl">
                                                <FileUploadForm uploadText="身份证国徽面"
                                                                ocr='false'
                                                                action="ocrIdCardBackApi"
                                                                fileType='01'
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={isView === true ? idBackPreview : null}
                                                                field="idBackResourceFileId"
                                                                key="idBackResourceFileId"/>
                                                {
                                                    getFieldDecorator('idBackResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden"/>)
                                                }
                                            </FormItem>
                                        </div>
                                        <div style={{width: 700}}>
                                            <FormItem label="选择证件类型">
                                                {
                                                    getFieldDecorator('legalPersonIdType', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择选择证件类型'
                                                            },
                                                        ],
                                                        initialValue: '2'
                                                    })(<Select disabled={isView}>
                                                        <Option value="2" key="2">中华人民共和国身份证</Option>
                                                    </Select>)
                                                }
                                            </FormItem>
                                            <FormItem label="姓名">
                                                {
                                                    getFieldDecorator('legalPersonName', {
                                                        rules: [
                                                            {
                                                                required: true, message: '请输入法人姓名'
                                                            }, {
                                                                max: 50,
                                                                message: "最大长度为50位,请重新输入"
                                                            }],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="证件号码">
                                                {
                                                    getFieldDecorator('legalPersonCardno', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入法人证件号码'
                                                            },
                                                            {
                                                                validator: checkIdNumber,
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="请输入身份证号码" disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="证件有效期（止）">
                                                {
                                                    getFieldDecorator('isLegalPersonLongTerm', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择是否长期'
                                                            }],
                                                        initialValue: "1",
                                                    })(
                                                        <Radio.Group onChange={this.handleLglPsnLongTermChange}
                                                                     disabled={isView}>
                                                            <Radio value="1">长期</Radio>
                                                            <Radio value="0">固定期限</Radio>
                                                        </Radio.Group>
                                                    )
                                                }
                                            </FormItem>
                                            <Row align="middle">
                                                <Col span={8} offset={6}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('legalPersonValidityStart', {
                                                                rules: [{
                                                                    required: true, message: '请选择开始日期'
                                                                }],
                                                                initialValue: null
                                                            })(<DatePicker
                                                                disabled={isView}
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledStartDate}
                                                                placeholder="开始日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                                <Col span={2} className="com-text-center date-interval">———</Col>
                                                <Col span={8}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('legalPersonValidityEnd', {
                                                                rules: [{
                                                                    required: true, message: '请选择结束日期'
                                                                }],
                                                                initialValue: isLegalPersonLongTerm === "1" ? moment('2099-12-31', "YYYY-MM-DD") : null
                                                            })(<DatePicker
                                                                disabled={isView}
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledEndDate}
                                                                disabled={isView === true || isLegalPersonLongTerm === "1" ? true : false}
                                                                placeholder="结束日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <FormItem label="婚姻状况">
                                                {
                                                    getFieldDecorator('isLegalPersonMarried', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择婚姻状况'
                                                            }
                                                        ],
                                                        initialValue: "0",
                                                        validateTrigger: "onBlur"
                                                    })(
                                                        <Radio.Group disabled={isView}>
                                                            {
                                                                isLegalPersonMarriedArr.length !== 0 ? isLegalPersonMarriedArr.map(record => {
                                                                    return <Radio key={record.dicKey}
                                                                                  value={record.dicKey}> {record.dicValue} </Radio>
                                                                }) : null
                                                            }
                                                        </Radio.Group>
                                                    )
                                                }
                                            </FormItem>
                                            <FormItem label="手机号">
                                                {
                                                    getFieldDecorator('legalPersonMobile', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入手机号'
                                                            },
                                                            {validator: checkPhone}
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="邮箱">
                                                {
                                                    getFieldDecorator('legalPersonEmail', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入邮箱'
                                                            },
                                                            {
                                                                type: 'email',
                                                                message: '请输入正确的邮箱地址'
                                                            }
                                                        ],
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                            </div> : <div>
                                {/* 个人身份信息 */}
                                <div>
                                    <div className="title-com">
                                        <h6><b>个人身份信息</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft78px">
                                        <div className="clearfix">
                                            <FormItem className="com-fl">
                                                <FileUploadForm uploadText="身份证头像面"
                                                                action="ocrIdCardFrontApi"
                                                                ocr='false'
                                                                fileType='01'
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={isView === true ? idFrontPreview : null}
                                                                field="idFrontResourceFileId" key="idFrontResourceFileId"/>
                                                {
                                                    getFieldDecorator('idFrontResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden" disabled={isView}/>)
                                                }
                                            </FormItem>

                                            <FormItem className="com-fl">
                                                <FileUploadForm uploadText="身份证国徽面"
                                                                action="ocrIdCardBackApi"
                                                                ocr='false'
                                                                fileType='01'
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={isView === true ? idBackPreview : null}
                                                                field="idBackResourceFileId" key="idBackResourceFileId"/>
                                                {
                                                    getFieldDecorator('idBackResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden" disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                        <div style={{width: 700}}>
                                            <FormItem label="选择证件类型">
                                                {
                                                    getFieldDecorator('legalPersonIdType', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择选择证件类型'
                                                            },
                                                        ],
                                                        initialValue: '2'
                                                    })(<Select disabled={isView}>
                                                        <Option value="2" key="2">中华人民共和国身份证</Option>
                                                    </Select>)
                                                }
                                            </FormItem>
                                            <FormItem label="姓名">
                                                {
                                                    getFieldDecorator('legalPersonName', {
                                                        rules: [
                                                            {
                                                                required: true, message: '请输入法人姓名'
                                                            }, {
                                                                max: 50,
                                                                message: "最大长度为50位,请重新输入"
                                                            }],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="证件号码">
                                                {
                                                    getFieldDecorator('legalPersonCardno', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入法人证件号码'
                                                            },
                                                            {
                                                                validator: checkIdNumber,
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="请输入身份证号码" disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="证件有效期（止）">
                                                {
                                                    getFieldDecorator('isLegalPersonLongTerm', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择是否长期'
                                                            }],
                                                        initialValue: "1",
                                                    })(
                                                        <Radio.Group onChange={this.handleLglPsnLongTermChange}
                                                                     disabled={isView}>
                                                            <Radio value="1">长期</Radio>
                                                            <Radio value="0">固定期限</Radio>
                                                        </Radio.Group>
                                                    )
                                                }
                                            </FormItem>
                                            <Row align="middle">
                                                <Col span={8} offset={6}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('legalPersonValidityStart', {
                                                                rules: [{
                                                                    required: true, message: '请选择开始日期'
                                                                }],
                                                                initialValue: null
                                                            })(<DatePicker
                                                                disabled={isView}
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledStartDate}
                                                                placeholder="开始日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                                <Col span={2} className="com-text-center date-interval">———</Col>
                                                <Col span={8}>
                                                    <FormItem {...formCityItem}>
                                                        {
                                                            getFieldDecorator('legalPersonValidityEnd', {
                                                                rules: [{
                                                                    required: true, message: '请选择结束日期'
                                                                }],
                                                                initialValue: isLegalPersonLongTerm === "1" ? moment('2099-12-31', "YYYY-MM-DD") : null
                                                            })(<DatePicker
                                                                style={{width: "100%"}}
                                                                disabledDate={this.disabledEndDate}
                                                                disabled={isView === true || isLegalPersonLongTerm === "1" ? true : false}
                                                                placeholder="结束日期"/>)
                                                        }
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <FormItem label="邮箱">
                                                {
                                                    getFieldDecorator('legalPersonEmail', {
                                                        rules: [
                                                            {
                                                                type: 'email',
                                                                message: '请输入正确的邮箱地址'
                                                            }
                                                        ],
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                {/* 个人银行账户 */}
                                <div>
                                    <div className="title-com">
                                        <h6><b>个人银行账户</b></h6>
                                        <div className="title-divider">
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                            <Divider orientation="left" dashed={true} className="divider"></Divider>
                                        </div>
                                    </div>
                                    <div className="flex-end-middle paddingLeft340px">
                                        <div style={{width: 700}}>
                                            <FormItem label="开户银行">
                                                {
                                                    getFieldDecorator('bankName', {
                                                        rules: [
                                                            {
                                                                required: true, message: '请输入开户银行名称'
                                                            },
                                                            {
                                                                max: 100,
                                                                message: "最大长度为100位,请重新输入"
                                                            }],
                                                        validateTrigger: "onBlur"

                                                    })(<Select
                                                        disabled={isView}
                                                        showSearch
                                                        placeholder="请输入查询"
                                                    >
                                                        {
                                                            bankList !== null && bankList.length !== 0 ? bankList.map((record, index) => {
                                                                return <Option key={record.id}
                                                                               value={record.bankName}>{record.bankName}</Option>
                                                            }) : null
                                                        }
                                                    </Select>)
                                                }
                                            </FormItem>
                                            <FormItem label="银行账户">
                                                {
                                                    getFieldDecorator('bankCnap', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入银行账户'
                                                            },
                                                            {validator:checkBankNumber}
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                            <FormItem label="银行预留手机号">
                                                {
                                                    getFieldDecorator('legalPersonMobile', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入手机号'
                                                            },
                                                            {validator: checkPhone}
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input disabled={isView}/>)
                                                }
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {/* 经办人证件 */}
                    {
                        orgType === 'org' ? <div>
                            <div className="title-com">
                                <h6><b>经办人证件</b></h6>
                                <div className="title-divider">
                                    <Divider orientation="left" dashed={true} className="divider"></Divider>
                                    <Divider orientation="left" dashed={true} className="divider"></Divider>
                                </div>
                            </div>
                            <div className="flex-end-middle paddingLeft343px">
                                <div style={{width: 700}}>
                                    <FormItem label="姓名">
                                        {
                                            getFieldDecorator('contactName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入经办人姓名'
                                                    }, {
                                                        max: 50,
                                                        message: "最大长度为50位,请重新输入"
                                                    }],
                                                validateTrigger: "onBlur"
                                            })(<Input disabled={isView}/>)
                                        }
                                    </FormItem>
                                    <FormItem label="职务">
                                        {
                                            getFieldDecorator('contactJob', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入经办人职务'
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "最大长度为100位,请重新输入"
                                                    }],
                                                validateTrigger: "onBlur"
                                            })(<Input disabled={isView}/>)
                                        }
                                    </FormItem>
                                    <FormItem label="手机号">
                                        {
                                            getFieldDecorator('contactMobile', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入手机号'
                                                    },
                                                    {validator: checkPhone}
                                                ],
                                                validateTrigger: "onBlur"
                                            })(<Input disabled={isView}/>)
                                        }
                                    </FormItem>
                                    <FormItem label="邮箱">
                                        {
                                            getFieldDecorator('orgEmail', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入邮箱'
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: '请输入正确的邮箱地址'
                                                    }
                                                ],
                                                initialValue: ''
                                            })(<Input disabled={isView}/>)
                                        }
                                    </FormItem>
                                </div>
                            </div>
                        </div> : null
                    }
                    {
                        isView === false ? <div className="btn-item">
                            {/* <Link to="/admin/clientManage/clientInfo/search">
                                <Button type="default" className="com-middle-btn-size">上一步</Button>
                            </Link> */}
                            <Button type="primary" htmlType="submit" className="com-middle-btn-size"
                                    loading={loading}>提交</Button>
                        </div> : <div className="btn-item">
                        {
                            status==0?<Link to="/admin/homeClient">
                            <Button type="primary" className="com-middle-btn-size">返回</Button>
                        </Link>:<Link to="/admin/clientManage/clientInfo/search">
                        <Button type="primary" className="com-middle-btn-size">返回</Button>
                    </Link>
                        }
                                    
                        </div>
                    }
                </Form>
            </div>
        );
    }
}

const AddBusinessInfoForm = Form.create()(AddBusinessInfo)
export default AddBusinessInfoForm;
import React, { Component } from 'react';
import { connect } from 'dva';
import Sortable from 'sortablejs';
import ReviewContranct from './reviewContract';
import { getUrlParam, debounce } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Row, Col, Spin, Table, Button, Popconfirm, message } from 'antd';

const signConfigUpdateApi = scfBusinessApi['signConfigUpdateApi'];

@connect((store) => ({ store: store.signingConfiguration, loading: store.loading }))
class EditComp extends Component {

    state = {
        dataSource: [],
        data: [],
        textFlag: false
    }

    componentDidMount () {
        this.props.dispatch({type: 'signingConfiguration/getDetailData', payload: { signConfigId: getUrlParam('id')}});
    }

    componentWillReceiveProps (newProps) {
        let newData = newProps.store.detailData.configDetails;
        if(newData) {
            newData.map((item, index) => item.key = index);
            this.setState({ dataSource: newData });
        }
    }

    handleEditCredit = debounce(() => {
        let { dataSource, data } = this.state;
        if(data.length <= 0 && dataSource.length > 0) {
            this.handleCommitCredit(dataSource);
        }
        this.setState({ textFlag: true });
    }, 300)

    handleCommitCredit = (data) => {
        let params = {};
        let newData = [];
        data.map((item) => newData.push({ signOrder: item.signOrder, contractTemplateId: item.contractTemplateId }));
        params = {
            signConfigId: getUrlParam('id') * 1,
            configDetails: newData
        }
        signConfigUpdateApi(params).then(res => {
            if(res.code === '0') message.success(res.message);
            this.props.history.push('/admin/signingConfiguration/list');
        }).catch(err => message.error(err.message))
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    handleDelete = (record) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            data: [...this.state.data, record],
            dataSource: dataSource.filter((item) => item.key !== record.key),
        });
    };

    handleAdd = () => {
        let { dataSource, data } = this.state;
        if(data.length <= 0) return;
        let newData = {...data.splice(data.length-1, 1)[0]};
        this.setState({
            data,
            dataSource: [...dataSource, newData],
        });
    };

    sortableGoods = (componentBackingInstance) => {
        let tab = document.getElementsByClassName('goodsTable');
        let el = tab[0].getElementsByClassName('ant-table-tbody')[0];
        let self = this;
        let sortable = Sortable.create(el, {
            // animation: 150,
            ghostClass: 'blue-background-class',
            onEnd: function (evt) {
                let menuArr = self.state.dataSource; 
                const oldEl = menuArr.splice(evt.oldIndex, 1);
                menuArr.splice(evt.newIndex, 0, oldEl[0]);
                self.setState({ dataSource: menuArr });
            }
        });
    }

    render() {
        const { detailData } = this.props.store;
        const { dataSource, data, textFlag } = this.state;
        const isLoding1 = this.props.loading.effects['signingConfiguration/getDetailData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: "签约顺序",
                key: 'index',
                render: (text,record,index)=> {
                    return `${index+1}`
                }
            },
            {
                title: "合同名称",
                key: 'contractName',
                // sorter:(a,b)=>{
                //     if(!a.contractName){
                //         a.contractName=""
                //     }else if(!b.contractName){
                //         b.contractName=""
                //     }
                //     return a.contractName.localeCompare(b.contractName)
                // },
                render: (text, record) =>{
                    return (
                        <ReviewContranct text={record.contractName} relativePath={record.relativePath} newFileName={record.newFileName} originalFileName={record.originalFileName} />
                    )
                } 
            },
            {
                title: "操作",
                render: (text, record) =>{
                    return (
                        this.state.dataSource.length >= 1 ? (
                            <Popconfirm
                                title="你确定删除吗?"
                                onConfirm={() => this.handleDelete(record)}
                            >
                                <Button className='com-btn-opr com-middle-btn-size'>删除</Button>
                            </Popconfirm>
                        ) : null
                    )
                }    
            },
        ];
        const tabelHeadBtn = <div>
            <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleAdd} icon='plus'>导入模板</Button>
            {
                data.length <= 0 && dataSource.length > 0 ? <span style={{marginLeft: 10}}>该产品项下合同已全部导入完毕！</span> : null
            }
            {
                data.length > 0 && textFlag ? <span style={{color: '#f5222d', marginLeft: 10}}>该产品项下还有合同未导入，请导入！</span> : null
            }
        </div>;
        return (
            <div className="goodsTable" ref={this.sortableGoods}>
                <Spin spinning={isLoding1}>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>签约配置基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择项目">
                                    {detailData && detailData.projectName ? detailData.projectName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="请选择产品">
                                    {detailData && detailData.productName ? detailData.productName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <p className='h_text_style' style={{margin:0}}>合同签约配置（合同签约会按照配置的签约顺序依次签约）</p>
                    <Table 
                        columns={columns}
                        rowClassName={() => "tableItem"}
                        dataSource={dataSource}
                        pagination={false}
                        title={() => tabelHeadBtn}
                    />
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleEditCredit}>确定</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default EditComp;

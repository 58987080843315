import React, {Component} from 'react';
import {Button,Form, message,Radio, Checkbox} from "antd";
import {Link} from "dva/router";
import RcViewer from "@hanyk/rc-viewer";
import BusinessLicense from "../../../assets/img/business-license.png";
import IDCard from "../../../assets/img/ID-card.png";
import scfBusinessApi from "../../../request/api/scfBusinessApi";

const FormItem = Form.Item;

class ChooseBusinessType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            spinning: false,
            title: "",
            text: "",
            businessType:"org",
            previewImage: "",
            orgTypeCdArr: [],
            businessRoleArr: []
        }
    }

    componentDidMount() {
        let data = {addCurrent: 0};
        localStorage.setItem("clientInfoManage", JSON.stringify(data));
        this.getDicLt("orgTypeCd");
        this.getDicLt("businessRole");
    }

    getDicLt = (type = "") => {
        scfBusinessApi.findDicLtApi({dicType: type}).then(res => {
            let data = res.data;
            this.setState({
                [type + "Arr"]: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    handleBusinessTypeChange = (e) => {
        this.setState({
            businessType: e.target.value,
        });
        if(e.target.value==="person"){
            this.props.form.setFieldsValue({idType:'2'});
        }else{
            this.props.form.setFieldsValue({idType:'1'});
        }
        this.props.form.setFieldsValue({businessRole:['borrower']});
    };
    handleViewExample = () => {
        let viewImg = "";
        let {businessType} = this.state;
        if (businessType !== 'person') {
            viewImg = BusinessLicense;
        } else {
            viewImg = IDCard;
        }
        this.setState({
            previewImage: viewImg,
        }, () => {
            this.viewerImg.click();
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.addCurrent = 1;
                localStorage.setItem("clientInfoManage", JSON.stringify(values));
                this.props.getBusinessType();
            }
        })
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return null;
        };
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {businessType, previewImage, orgTypeCdArr, businessRoleArr} = this.state;
        let options = {
            navbar: false,       //关闭缩略图
            fullscreen: false,   //播放全屏
            loop: false,         //是否循环 上一个 下一个
            toolbar: {
                zoomIn: {size: 'large'},  //放大
                zoomOut: {size: 'large'}, //缩小
                reset: {size: 'large'},   //重置
                prev: {show: false, size: 'large',}, //上一张
                play: {show: false, size: 'large',},  //播放
                next: {show: false, size: 'large',},  //下一张
                rotateLeft: {size: 'large'},  //左旋转
                rotateRight: {size: 'large'}, //右旋转
                flipHorizontal: {size: 'large'},  //左右翻转
                flipVertical: {size: 'large'},    //上下翻转
            }
        };
        return (
            <div className="choose-business-type-wrapper">
                <Form onSubmit={this.handleSubmit} autoComplete="off" className="apply-evaluate-content">

                    <FormItem label="请选择企业类型">
                        {
                            getFieldDecorator('orgType', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择企业类型'
                                    }
                                ],
                                initialValue:"org",
                            })(<Radio.Group onChange={this.handleBusinessTypeChange}>
                                {
                                    orgTypeCdArr.length !== 0 ? orgTypeCdArr.map(record => {
                                        return <Radio key={record.dicKey} value={record.dicKey}> {record.dicValue} </Radio>
                                    }) : null
                                }
                            </Radio.Group>)
                        }
                    </FormItem>
                    <FormItem label="请选择业务角色">
                        {
                            getFieldDecorator('businessRole', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择业务角色'
                                    }
                                ],
                                initialValue:['borrower'],
                            })(businessType === 'org' ? <Checkbox.Group>
                                {
                                    businessRoleArr.length !== 0 ? businessRoleArr.map(record => {
                                        return <Checkbox key={record.dicKey} value={record.dicKey}> {record.dicValue} </Checkbox>
                                    }) : null
                                }

                            </Checkbox.Group> : <Checkbox.Group>
                                {
                                    businessRoleArr.length !== 0 ? businessRoleArr.map(record => {
                                        if (record.dicKey === "capital" || record.dicKey === "coreorg") {
                                            return null;
                                        } else {
                                            return <Checkbox key={record.dicKey} value={record.dicKey}> {record.dicValue} </Checkbox>
                                        }

                                    }) : null
                                }
                            </Checkbox.Group>)
                        }
                    </FormItem>


                    <FormItem label="请选择证件类型">
                        {
                            getFieldDecorator('idType', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择证件类型'
                                    }
                                ],
                                initialValue:'1',
                            })(
                                <Radio.Group>
                                {businessType !== 'person' ? <Radio value='1'>统一社会信用代码类</Radio> :
                                    <Radio value='2'>中华人民共和国居民身份证</Radio>}
                            </Radio.Group>)
                        }
                        <Button type="primary" className="com-middle-btn-size view-example" onClick={this.handleViewExample}>查看示例</Button>
                    </FormItem>
                    <div className="btn-item">
                        <Link to="/admin/clientManage/clientInfo/search"><Button type="default"
                                                                                 className="com-middle-btn-size">返回</Button></Link>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">下一步</Button>
                    </div>
                </Form>
                <RcViewer style={{display: 'none'}} options={options}>
                    <img ref={(ref) => this.viewerImg = ref} alt="example" style={{width: '100%'}} src={previewImage}/>
                </RcViewer>
            </div>
        );
    }
}

const ChooseBusinessTypeForm = Form.create()(ChooseBusinessType)
export default ChooseBusinessTypeForm;
import React, {Component} from 'react';
import SearchSystem from "./component/searchSystem";
class Index extends Component {
    render() {
        return (
            <div>
                <SearchSystem></SearchSystem>
            </div>
        );
    }
}

export default Index;
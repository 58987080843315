import React, { Component } from 'react';
import { connect } from 'dva';
import { getStorage } from "@/utils/tools";
import { money100 } from '../../../utils/formatCheck'
@connect((store) => ({ 
    store: store.bussimessTicket,
    loading: store.loading 
}))

class QuotaInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    componentDidMount(){
        this.setState({
            companyName: getStorage("companyName"),
        });
        this.props.dispatch({ type: 'bussimessTicket/getOpenStatustListData', payload: {id:getStorage("companyID")}});
    }
    render() { 
        const { OpenStatustList } = this.props.store;
        const { companyName } = this.state
        return ( 
            <>
            <div className='addIssue-top'>
                <div className='addIssue-companyInfo'>
                    <h1>{companyName}</h1>
                    <p>发行企业</p>
                </div>
                <div className='addIssue-companyInfo'>
                    <h1>{money100(OpenStatustList.haveAmount) }</h1>
                    <p>剩余可发行额度（元）</p>
                </div>
                <div className='addIssue-companyInfo'>
                    <h1>{OpenStatustList.endTime}</h1>
                    <p>额度有效期（止）</p>
                </div>
            </div>
            </>
         );
    }
}
 
export default QuotaInfo;
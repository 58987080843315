import React, {Component} from 'react';
import echarts from 'echarts';
import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message } from 'antd';
import {money100,moneyFormat,moneyMore,money} from '../../../utils/formatCheck'

class Pie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            d:[],
            intervalId:""
        }
    }
    componentDidMount() {
        this.initalECharts();

    var intervalId = setInterval(() => {
         this.initalECharts();  
        }, 3600000);
        this.setState({
            intervalId,
        }) 
    }
    componentWillUnmount = () => {
        let {intervalId} = this.state
        clearInterval(intervalId)
    }
    // getDate = () =>{
    //     let {d} = this.state
    //     let params = {
    //         level:"province",
    //         name:"辽宁省"
    //     }
    //     scfBusinessApi.provincesAndCitiesAmountApi(params).then(res=>{
    //         let arr = res.data
    //         arr.map(item=>{
    //             d.push({
    //                 name:item.name,
    //                 value:item.value
    //             })
    //         })
    //     }).catch(err=>{
    //         message.error(err.message)
    //     })
    // }
    initalECharts() {
        let d = []
        let t = []
        let params = {
            // level:"province",
            // name:"北京市"
        }
        scfBusinessApi.provincesAndCitiesAmountApi(params).then(res=>{
            let arr = res.data
            arr.map(item=>{
                d.push({
                    value:item.amount,
                    name:item.name,
                })
                t.push(item.name)
            })
            const myChart = echarts.init(document.getElementById('Pie'));
            var option = {
                // title: {
                //     text: '省级区域交易',
                //     // subtext: '纯属虚构',
                //     left: 'center',
                //     textStyle: {
                //         // color: 'rgb(179, 239, 255)',
                //         color:'#fff',
                //         fontSize: 16
                //     },
                // },
                tooltip: {
                    trigger: 'item',
                    // formatter: '{b} : {c} ',
                     formatter:function(data){
                        return data.data.name + "<br/>"+ money(data.data.value);
                         }
          
                    
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: t,
                    textStyle: {
                        // color: 'rgb(179, 239, 255)',
                        color:'#fff',
                        fontSize: 16
                    },
                },
                series: [
                    {
                        // name: '访问来源',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '55%'],
                        data: d,
                        //  [
                        //     {value: 335, name: '直接访问'},
                        //     {value: 310, name: '邮件营销'},
                        //     {value: 234, name: '联盟广告'},
                        //     {value: 135, name: '视频广告'},
                        //     {value: 1548, name: '搜索引擎'}
                        // ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
                //修改每个区域颜色，和数据相对应
                // color: ['rgb(179, 239, 255)']
            };
            
            
            myChart.setOption(option);
            window.addEventListener('resize', function() {
                myChart.resize(); 
                  })
        }).catch(err=>{
            message.error(err.message)
        })
       
    }
    render() {
        return (
           
                <div id="Pie" style={{ width: '100%', height: '100%',padding:'5px'}}></div>
            
        );
    }
}

export default Pie;

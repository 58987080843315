import React, { Component } from 'react';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import { unCrudeHttp } from '@/request/http';
import ReviewContranct from './reviewContract';
import { Form, Input, Row, Col, Button, Table } from "antd";

@Form.create()
@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class ListComp extends Component {

    componentDidMount () {
        this.getContractTemplateManageListData();
    }

    getContractTemplateManageListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                if(pagination) {
                    values.pageNum = pagination.current;
                    values.pageSize = pagination.pageSize;
                }
                this.props.dispatch({ type: 'contractTemplateManage/getContractTemplateManageListData', payload: values});
            }
        })
    }

    handleFormSearch = debounce(() => {
        this.getContractTemplateManageListData();
    }, 300)

    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getContractTemplateManageListData();
    }, 300)

    handleHistoryGoAdd = () => {
        this.props.history.push('/admin/contractTemplateManage/add');
    }

    handleCurrentChange = (pagination) => {
        this.getContractTemplateManageListData(pagination)
    }

    downLoadFile = (option) => {
        let params = {
            originalFileName: option.file.originalFileName,
            relativePath: option.file.relativePath,
            newFileName: option.file.newFileName
        };  
        unCrudeHttp({
            method: 'post',
            url: `${window.API_HOST}/business/resourcefile/download`,
            responseType: "blob",
            data: params
        }).then(res => {
            let data = res.data;
            const blob = data;
            const fileName = `${option.file.originalFileName}`;
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
        });
    }
    handleClickHeader = (row) =>{
        row.className=row.className+" left"
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { contractTemplateManageListData } = this.props.store;
        const isLoding = this.props.loading.effects['contractTemplateManage/getContractTemplateManageListData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '序号',
                dataIndex: 'seqNo',
                key: 'seqNo'
            },
            {
                title: '合同名称',
                dataIndex: 'contractName',
                key: 'contractName',
                align:'left',
                sorter: (a,b)=>{
                    if(!a.contractName){
                        a.contractName=""
                    }else if(!b.contractName){
                        b.contractName=""
                    }
                    return a.contractName.localeCompare(b.contractName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
            },
            {
                title: '合同编码',
                dataIndex: 'contractNum',
                key: 'contractNum',
                sorter: (a,b)=>{
                    if(!a.contractNum){
                        a.contractNum=""
                    }else if(!b.contractNum){
                        b.contractNum=""
                    }
                    return a.contractNum.localeCompare(b.contractNum)
                }
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                key: 'projectName',
                sorter: (a,b)=>{
                    if(!a.projectName){
                        a.projectName=""
                    }else if(!b.projectName){
                        b.projectName=""
                    }
                    return a.projectName.localeCompare(b.projectName)
                }
            },
            {
                title: '产品',
                dataIndex: 'productName',
                key: 'productName',
                sorter: (a,b)=>{
                    if(!a.productName){
                        a.productName=""
                    }else if(!b.productName){
                        b.productName=""
                    }
                    return a.productName.localeCompare(b.productName)
                }
            },
            {
                title: '签署方',
                dataIndex: 'signatoryName',
                key: 'signatoryName',
                align: 'left',
                sorter: (a,b)=>{
                    if(!a.signatoryName){
                        a.signatoryName=""
                    }else if(!b.signatoryName){
                        b.signatoryName=""
                    }
                    return a.signatoryName.localeCompare(b.signatoryName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
            },
            {
                title: '资金方名称',
                dataIndex: 'capitalName',
                key: 'capitalName',
                sorter: (a,b)=>{
                    if(!a.capitalName){
                        a.capitalName=""
                    }else if(!b.capitalName){
                        b.capitalName=""
                    }
                    return a.capitalName.localeCompare(b.capitalName)
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '操作',
                width: 310,
                render: (text, record) => {
                    const editPath = `/admin/contractTemplateManage/edit?id=${record.id}`;
                    const detailPath = `/admin/contractTemplateManage/detail?id=${record.id}`;
                    const editNode = <Button className='com-btn-opr com-small-btn-size mr5' href={editPath}>修改</Button>;
                    const detailNode = <Button className='com-btn-opr com-small-btn-size mr5' href={detailPath}>详情</Button>;
                    const browseNode = <ReviewContranct relativePath={record.file.relativePath} newFileName={record.file.newFileName} originalFileName={record.file.originalFileName} />;
                    const downloadNode = <Button className='com-btn-opr com-small-btn-size mr5' onClick={() => this.downLoadFile(record)}>下载</Button>;
                    return <div>
                        {browseNode}
                        {detailNode}
                        {editNode}
                        {downloadNode}
                    </div>
                }
            }
        ];
        const tabelHeadBtn = <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleHistoryGoAdd}>新增</Button>;
        
        return (
            <div>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="合同名称">
                                {
                                    getFieldDecorator('contractName', {})(
                                        <Input name='contractName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合同编码">
                                {
                                    getFieldDecorator('contractNum', {})(
                                        <Input name='contractNum' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="项目">
                                {
                                    getFieldDecorator('projectName', {})(
                                        <Input name='projectName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="产品">
                                {
                                    getFieldDecorator('productName', {})(
                                        <Input name='productName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="资金方">
                                {
                                    getFieldDecorator('capitalName', {})(
                                        <Input name='capitalName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <div style={{textAlign: 'center'}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Form>
                <Table 
                    columns={columns}
                    dataSource={contractTemplateManageListData.data}
                    rowKey={(record, index) => index}
                    title={() => tabelHeadBtn}
                    loading={isLoding}

                    pagination = {{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: contractTemplateManageListData.totalCount <= 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `总共 ${contractTemplateManageListData.totalCount} 条`,
                        total: contractTemplateManageListData.totalCount,
                        current: contractTemplateManageListData.pageNum,
                        pageSize: contractTemplateManageListData.pageSize,
                    }}
                    onChange = {this.handleCurrentChange}
                />
            </div>
        )
    }
}

export default ListComp;

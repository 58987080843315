import React, { Component } from 'react';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import { Form, Button, Radio, Select, Checkbox, Row, Col } from 'antd';

const { Option } = Select;

@Form.create()
@connect((store) => ({ store: store.productManage, loading: store.loading }))
class FormBtnComp extends Component {

    componentDidMount () {
        this.props.dispatch({ type: 'productManage/getLoanTermEnumerate', payload: 'debt_term'});
        this.props.dispatch({ type: 'productManage/getRepaymentDateEnumerate', payload: 'repayment_type'});
        this.props.dispatch({ type: 'productManage/getFixedDateEnumerate', payload: 'fixed_date'});
    }

    handleAddCredit = debounce(() => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                this.props.handleAddCredit(values);
            }
        })
    }, 300)

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { submitFlag, productDetails } = this.props;
        const { loanTermEnumerate, repaymentDateEnumerate, fixedDateEnumerate } = this.props.store;
        const { getFieldDecorator } = this.props.form;
        const ItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 2},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 22},
            },
            labelAlign: 'left'
        };
        return (
                <div style={{marginTop: 24}}>  
                    {
                        (() => {
                            switch( submitFlag ) {
                                case '1':
                                case '4': return <React.Fragment />;
                                case '2': 
                                case '3': return (
                                    <Form {...ItemLayout}>
                                        <Form.Item label='借款期限'>
                                            {
                                                getFieldDecorator('productLoanPeriod', {
                                                    initialValue: productDetails && productDetails.productLoanPeriod && productDetails.productRepayWay == submitFlag
                                                    ? productDetails.productLoanPeriod.split(',')
                                                    : []
                                                })(
                                                    <Checkbox.Group name='productLoanPeriod'>
                                                        <Row style={{width: 580}}>
                                                            {
                                                                loanTermEnumerate && loanTermEnumerate.length 
                                                                    ? loanTermEnumerate.map((item, index) => <Col span={4} key={index}><Checkbox value={item.dicKey}>{item.dicValue}</Checkbox></Col> )
                                                                    : null
                                                            }
                                                        </Row>
                                                    </Checkbox.Group>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item label='还款日' style={{margin: 0}}>
                                            {
                                                getFieldDecorator('productLoanType', {
                                                    initialValue: productDetails && productDetails.productLoanType && productDetails.productRepayWay == submitFlag ? productDetails.productLoanType : ''
                                                })(
                                                    <Radio.Group name='productLoanType'>
                                                        {
                                                            repaymentDateEnumerate && repaymentDateEnumerate.length 
                                                                ? repaymentDateEnumerate.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                                : null
                                                        }
                                                    </Radio.Group>
                                                )
                                            }
                                            {
                                                this.props.form.getFieldValue('productLoanType') === '2' 
                                                    ?  getFieldDecorator('productFixDue', {
                                                            initialValue: productDetails && productDetails.productFixDue && productDetails.productRepayWay == submitFlag ? productDetails.productFixDue : ''
                                                        })(
                                                            <Select name='productFixDue' style={{width: 80}}>
                                                                {
                                                                    fixedDateEnumerate && fixedDateEnumerate.length 
                                                                    ? fixedDateEnumerate.map((item, index) => <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                                    : null
                                                                }
                                                            </Select>
                                                        )
                                                    : null 
                                            }
                                        </Form.Item>
                                    </Form>
                                )
                            }
                        })()
                    }
                    {
                        submitFlag
                            ?  <div className='wraper-cd-center' style={{marginTop: 24}}>
                                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                                    <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleAddCredit}>提交</Button>
                                </div>
                            : null
                    }
                </div>
        )
    }
}

export default FormBtnComp;

import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Table} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import {withRouter} from "dva/router"
const FormItem = Form.Item;
@withRouter

class SearchOrganize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            orgName: "",
            parentOrgName: "",
        }
    }

    componentDidMount() {
        this.getOrganizeList();
    }

    //获取用户列表
    getOrganizeList = () => {
        let {pageNum, pageSize, orgName, parentOrgName} = this.state;
        let params = {orgName, parentOrgName, pageNum, pageSize};
        scfBaseApi.departListbyroleApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getOrganizeList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getOrganizeList();
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            orgName: "",
            parentOrgName: "",
        });
        this.setState({
            orgName: "",
            parentOrgName: "",
            pageNum: 1,
        }, () => {
            this.getOrganizeList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {orgName, parentOrgName} = values;
                this.setState({orgName, parentOrgName},
                    () => {
                        this.getOrganizeList();
                    })
            }
        })
    }

    //新增组织架构
    handleOperate = (type = "", orgId = 0) => {
        if (type === "add") {
            this.props.history.push(`/admin/clientManage/organize/${type}`);
        } else {
            this.props.history.push(`/admin/clientManage/organize/${type}?id=${orgId}`);
        }

    }
    handleClickHeader = (row) =>{
        row.className=row.className+" left"
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'left'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
    };
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '企业名称',
                dataIndex: 'orgName',
                key: 'orgName',
                align: 'left',
                sorter:(a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                },
                onHeaderCell: row => this.handleClickHeader(row),
            },
            {
                title: '上级机构',
                dataIndex: 'parentOrgName',
                key: 'parentOrgName',
                align: 'left',
                sorter:(a,b)=>{
                    if(!a.parentOrgName){
                        a.parentOrgName=""
                    }else if(!b.parentOrgName){
                        b.parentOrgName=""
                    }
                    return a.parentOrgName.localeCompare(b.parentOrgName)
                },
                onHeaderCell: row => this.handleClickHeader(row),

            },
            {
                title: '操作',
                render: (record) => {
                    return <div>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleOperate("edit", record.id)
                        }}>编辑</Button>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleOperate("detail", record.id)
                        }}>详情</Button>

                    </div>
                }
            },
        ];

        return (
            <div>
                <Form onSubmit={this.handleSubmit}  {...formRowItem} >
                    <Row>
                        <Col span={12}>
                            <FormItem label="企业名称">
                                {
                                    getFieldDecorator('orgName', {
                                        rules: [
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            }
                                        ],
                                        initialValue: "",
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="上级机构">
                                {
                                    getFieldDecorator('parentOrgName', {
                                        rules: [
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            },
                                        ],
                                        initialValue: "",
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>

                    </Row>
                    <div className="com-diff-two-btn">
                        <Button type="default" className="com-middle-btn-size"
                                onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <div>
                    <h2>企业组织架构列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size"
                                onClick={() => this.handleOperate("add", null)}>+新增</Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount>10?pagination:false}
                />
            </div>
        );
    }
}

export default Form.create()(SearchOrganize);
import React, { Component } from 'react';
import { connect } from 'dva';
import ReviewContranct from './reviewContract';
import { getUrlParam } from '@/utils/tools';
import { Form, Button, Row, Col, Spin, Table } from 'antd';

@Form.create()
@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class DetailComp extends Component {

    state = {
        flag: true,
        current: 1,
        pageSize: 10
    }

    componentDidMount () {
        this.props.dispatch({ type: 'contractTemplateManage/getContractDetailData', payload: getUrlParam('id')});
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    handleCurrentChange = (option) => {
        this.setState({
            current: option.current,
            pageSize: option.pageSize
        })
    }

    projectRender () {
        const { contractDetailData } = this.props.store;
        const formItemLayout = {
            labelCol: { span: 8, },
            wrapperCol: { span: 14, offset: 2 }
        };
        return (
            <Row>
                <Col span={6}>
                    <Form.Item label="请选择项目" {...formItemLayout}>
                        {contractDetailData && contractDetailData.projectName ? contractDetailData.projectName : null}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="请选择产品" {...formItemLayout}>
                        {contractDetailData && contractDetailData.productName ? contractDetailData.productName : null}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    ifAssignProductRender () {
        const { contractDetailData } = this.props.store;
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <Form.Item label="是否指定产品">
                            {contractDetailData && contractDetailData.ifAssignProductName ? contractDetailData.ifAssignProductName : null}
                        </Form.Item>
                    </Col>
                </Row>
                {
                    contractDetailData && contractDetailData.ifAssignProduct === '1'
                        ? this.projectRender()
                        :  null
                }
                <Row>
                    <Col span={12}>
                        <Form.Item label="模板上传">
                            <ReviewContranct relativePath={contractDetailData.file.relativePath} newFileName={contractDetailData.file.newFileName} originalFileName={contractDetailData.file.originalFileName}/>
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    render() {
        const { current, pageSize } = this.state;
        const { contractDetailData } = this.props.store;
        const isLoding3 = this.props.loading.effects['contractTemplateManage/getContractDetailData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
                offset: 1
            },
            labelAlign: ''
        };
        const columns = [
            {
                title: '序号',
                key: 'seqNo',
                render: (text, record, index) => `${(this.state.current-1)*(this.state.pageSize)+(index+1)}`,
            },
            {
                title: '域名',
                dataIndex: 'domainName',
                key: 'domainName',
                sorter: (a,b)=>{
                    if(!a.domainName){
                        a.domainName=""
                    }else if(!b.domainName){
                        b.domainName=""
                    }
                    return a.domainName.localeCompare(b.domainName)
                }
            },
            {
                title: '表名.系统字段名称',
                dataIndex: 'sysFieldName',
                key: 'sysFieldName',
                sorter: (a,b)=>{
                    if(!a.sysFieldName){
                        a.sysFieldName=""
                    }else if(!b.sysFieldName){
                        b.sysFieldName=""
                    }
                    return a.sysFieldName.localeCompare(b.sysFieldName)
                }
            },
            {
                title: '规则',
                dataIndex: 'ruleTypeName',
                key: 'ruleTypeName',
                sorter: (a,b)=>{
                    if(!a.ruleTypeName){
                        a.ruleTypeName=""
                    }else if(!b.ruleTypeName){
                        b.ruleTypeName=""
                    }
                    return a.ruleTypeName.localeCompare(b.ruleTypeName)
                }
            }
        ];
        return (
            <div>
                <Spin spinning={ isLoding3 }>
                    <Form {...formItemLayout}>
                        <p className='h_text_style'>合同基础信息</p>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同名称">
                                    {contractDetailData && contractDetailData.contractName ? contractDetailData.contractName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同编号">
                                    {contractDetailData && contractDetailData.contractNum ? contractDetailData.contractNum : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="签署方">
                                    {
                                        contractDetailData && contractDetailData.signatoryNames 
                                            ? contractDetailData.signatoryNames.map(item => `${item}，`)
                                            : null
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="合同类型">
                                    {contractDetailData && contractDetailData.contractTypeName ? contractDetailData.contractTypeName : null}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            contractDetailData && contractDetailData.contractType === '00'
                                ? this.ifAssignProductRender()
                                :  null
                        }
                    </Form>
                    <p className='h_text_style'>字段信息配置</p>
                    <Table 
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={contractDetailData && contractDetailData.configs ? contractDetailData.configs : []}

                        pagination = {{
                            current: current,
                            pageSize: pageSize,
                        }}
                        onChange={this.handleCurrentChange}
                    />
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default DetailComp;

import React, { Component } from "react";
import ReactSimpleVerify from 'react-simple-verify'
import loginApi from "@/request/api/login";
import 'react-simple-verify/dist/react-simple-verify.css'
import { Form, Input, Row,  Col, Checkbox, Button,  message } from "antd";
import RegisterModal from './registerModal'
import { registerApi , sendregisteredApi } from '../../request/api/login'
import { checkPhone , isEmpyt  } from '../../utils/formatCheck'
import { setStorage } from '../../utils/tools'
import CryptoJS from "crypto-js";
let temer;
const userLogin = loginApi["userLoginApi"];
const getAesApi = loginApi["getAesApi"];

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verify:false,
            codeNum: 60,
            codeText:'获取验证码',
            disabledFalg:false,
            kaptchaId:'',
        };
    }

    componentDidMount() {
        this.handleGetAesApi();
    }

    handleGetAesApi = () => {
        getAesApi()
            .then((res) => {
                this.setState({
                    stateIv: res.data.AES_IV,
                    stateKey: res.data.AES_KEY,
                    loadingFlag: false,
                });
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    handleEncrypt = (word) => {
        const {stateKey, stateIv} = this.state;
        const key = CryptoJS.enc.Utf8.parse(stateKey);
        const iv = CryptoJS.enc.Utf8.parse(stateIv);
        let encrypted = CryptoJS.AES.encrypt(word, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString();
    };

    handleSubmit = e => {
        e.preventDefault();
        const { kaptchaId } = this.state
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            if(values.agreement==undefined||values.agreement==false){
                message.info("请阅读并勾选协议！")
                return
            }
            delete values.agreement;
            let pw = this.handleEncrypt(values.password)
            let cpw =this.handleEncrypt(values.confirmPassword)
            values.password = pw
            values.confirmPassword = cpw
            values.systemId = 2
            values.kaptchaId = kaptchaId
            this.registerLogic(values)
          }
        });
    };
    LoginGetToken = (params)=>{
        userLogin(params).then(res => {
            if (res.code === '2000') {
                setStorage('tokens', res.data.token);
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    registerLogic = (params)=>{
        let { mobile ,  password } = params
        registerApi(params).then(res => {
            if(res.code=='2000'){
                let Loginparams = {
                    userMobile:mobile,
                    userPassword:password,
                    code:'qqaaa'
                }
                this.LoginGetToken(Loginparams)
                this.refs.RegisterModal.showModal()
                setStorage('userId',res.data.userId)
                this.setState({
                    mobile:mobile
                })
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getSmsCode = (params)=>{
        sendregisteredApi(params).then(res=>{
            if(res.code=='2000'){
                this.setState({
                    kaptchaId:res.data.kaptchaId
                })
                message.success(res.message)
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
            message.error(err.message)
        })
    }
    goSetDealPassword = ()=>{
        const { mobile } = this.state
        this.props.history.push(`/setDealPassword?mobile=${mobile}&route=1`);
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('两次密码不一致，请重新输入!');
        } else  if(value.indexOf(' ')!=-1){
            callback('密码不能为空格,请重新输入!');
        }else{
          callback();
        }
    };
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
          form.validateFields(['confirm'], { force: true });
        }else if(value.indexOf(' ')!=-1){
            callback('密码不能为空格,请重新输入!');
        }else if(value.length<6){
            callback('密码长度不能小于6位');
        }else if(/.*[\u4e00-\u9fa5]+.*$/.test(value)){
            callback('密码不能为中文！');
        }else{
            callback();
        }
    };
    success = ()=>{
        this.setState({
            verify:true
        })
    }
    getCode = ()=>{
        const { codeNum , verify } = this.state;
        const mobile =  this.props.form.getFieldValue('mobile');
        if(isEmpyt(mobile)){
            message.info('请输入手机号！')
            return
        }
        if(verify){
            let num = codeNum;
            let params = {
                phoneNumbers:mobile,
                usage:1
            }
            this.setState({
                disabledFalg:true
            })
            this.getSmsCode(params)
            temer = setInterval(() => {
                num--;
                if (num === 0) {
                    clearInterval(temer)
                    this.setState({
                        codeNum: 60,
                        codeText: '重新获取',
                        disabledFalg:false
                    });
                } else {
                    this.setState({
                        codeNum: num,
                        codeText: num + 's后重新获取',
                        disabledFalg:true
                    });
                }
            }, 1000);
        }else{
            message.info("请先拖动滑块进行校验")
        }
    }
    goBack = ()=>{
        this.props.history.push('/login');
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { codeText , disabledFalg , mobile} = this.state
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                span: 24,
                offset: 0,
                },
                sm: {
                span: 16,
                offset: 4,
                },
            },
        };
        return (
            <>  
                <div className='bg-img'>
                    <div className='register-title'>
                        <h1 className='register-h1'>新用户注册</h1>
                        <p className='register-p'></p>
                    </div>
                    <div className="register-box">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="手机号码">
                                {getFieldDecorator("mobile", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入手机号码",
                                        },
                                        {
                                            validator: checkPhone,
                                        },
                                    ],
                                })(<Input maxLength={11}/>)}
                            </Form.Item>
                            <Form.Item label="登录密码" hasFeedback>
                                {getFieldDecorator("password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入登录密码",
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        },
                                    ],
                                })(<Input.Password minLength={6}  maxLength={20}/>)}
                            </Form.Item>
                            <Form.Item label="确认密码" hasFeedback>
                                {getFieldDecorator("confirmPassword", {
                                    rules: [
                                        {
                                            required: true,
                                            message:"请输入确认密码",
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        },
                                    ],
                                })(
                                    <Input.Password
                                        minLength={6} 
                                        maxLength={20}
                                        onBlur={this.handleConfirmBlur}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="验证">
                                <ReactSimpleVerify ref="verify" success={ this.success }/>
                            </Form.Item>
                            <Form.Item
                                label="验证码"
                            >
                                <Row gutter={8}>
                                    <Col span={16}>
                                        {getFieldDecorator("kaptcha", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:"请输入验证码",
                                                },
                                            ],
                                        })(<Input maxLength={6}/>)}
                                    </Col>
                                    <Col span={8}>
                                        <Button onClick={this.getCode} disabled={disabledFalg}>{ codeText }</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                {getFieldDecorator("agreement", {
                                    valuePropName: "checked",
                                })(
                                    <Checkbox>
                                        我已阅读同意   <span className='deal-title'>《企汇安交易平台注册协议》</span>
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                            <Col span={12}>
                                <Button onClick={this.goBack}>
                                    返回
                                </Button>
                            </Col>
                                
                                <Button type="primary" htmlType="submit">
                                    注册
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <RegisterModal 
                    ref='RegisterModal'
                    goSetDealPassword={this.goSetDealPassword}
                    mobile = {mobile}
                    />
                </div>
            </>
        );
    }
}
const RegisterForm = Form.create()(Register);
export default RegisterForm;

import React, { Component } from 'react';
import { withRouter } from 'dva/router';
import { Button } from 'antd';
import { getStorage } from "@/utils/tools";

@withRouter

class RenderData extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userId:getStorage('userId'),
            companyID:getStorage('companyID'),
            type:0
         }
    }
    componentDidMount(){
        this.getType()
    }
    getType = ()=>{
        if(this.props.match.path=='/admin/bussinessTicketPayMoney/paymoney'){
            this.setState({
                type:2
            })
        }else{
            this.setState({
                type:0
            })
        }
    }
    godetail = (data)=>{  
        const { type } = this.state
        this.props.history.push(`/admin/businessTicketManage/issusedetail?id=${data.id}&type=${type}&drawerOrgId=${data.drawerOrgId}`)
    }
    goRepeatAdd = (data)=>{  
        this.props.history.push(`/admin/businessTicketManage/add?id=${data.id}`)
    }
    goAudit =(data)=>{  
        this.props.history.push(`/admin/businessTicketIssue/detail?id=${data.id}&wkId=${data.wkId}&nodeId=${data.nodeId}&ticketStatusCd=${data.ticketStatusCd}&drawerOrgId=${data.drawerOrgId}&sysBusinessType=${data.sysBusinessType}`)
    }
    goSigin =(data)=>{
        this.props.history.push(`/admin/businessTicketIssue/sigin?id=${data.id}&wkId=${data.wkId}&nodeId=${data.nodeId}&audit=1&drawerOrgId=${data.drawerOrgId}`)
    }
    goGetTicket = (data) =>{
        this.props.history.push(`/admin/businessTicketIssue/getTicket?id=${data.id}&wkId=${data.wkId}&nodeId=${data.nodeId}&audit=1&drawerOrgId=${data.drawerOrgId}`)
    }
    goPayMoney = (data) =>{
        this.props.history.push(`/admin/bussinessTicketPayMoney/paymoneyAudit?id=${data.id}&audit=1`)
    }
    goPayMoneyAudit = (data) =>{
        this.props.history.push(`/admin/bussinessTicketPayMoney/paymoneyAudit?id=${data.id}&wkId=${data.wkId}&nodeId=${data.nodeId}&ticketStatusCd=${data.ticketStatusCd}&audit=2`)
    }
    render() { 
        const { userId , type , companyID } = this.state
        // alert(type)
        const { data } = this.props
        return ( 
            <>
                <div>
                    <Button type="primary" style={{marginRight:'20px'}} onClick={()=>{
                        this.godetail(data)
                    }}>详情</Button>
                    {
                        (()=>{
                            if(data.ticketStatusCd==1040&&data.sysOperationType==10||data.ticketStatusCd==2040&&data.sysOperationType==10||data.ticketStatusCd==3040&&data.sysOperationType==10){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goAudit(data)
                                }}>审核</Button>
                            }else if(data.createUser==userId&&data.ticketStatusCd==1070&&companyID==data.drawerOrgId||data.createUser==userId&&data.ticketStatusCd==2070&&companyID==data.drawerOrgId||data.createUser==userId&&data.ticketStatusCd==3070&&companyID==data.drawerOrgId){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                            this.goRepeatAdd(data)
                                        }}>重新提交</Button>
                            }else if(data.ticketStatusCd==2040&&data.sysOperationType=='00'){
                                return  <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goSigin(data)
                                }}>签收</Button>
                            }else if(data.ticketStatusCd==3040&&data.sysOperationType=='00'){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goGetTicket(data)
                                }}>收票</Button>
                            }else if(data.ticketStatusCd==4090 && type=='2' && companyID == data.acceptorOrgId){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goPayMoney(data)
                                }}>付款</Button>
                            }else if(data.ticketStatusCd==5040&&data.sysOperationType==10){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goPayMoneyAudit(data)
                                }}>付款审核</Button>
                            }
                        })()
                    }
                    
                </div>
            </>
         );
    }
}
 
export default RenderData;
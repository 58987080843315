import React, {Component} from 'react';
import {Col, Form, Input, message, Modal, Select, Tree} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";

const {TreeNode} = Tree;
const FormItem = Form.Item;
const Option = Select.Option;

class addOrEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            systemCode: "",
            checkedKeys: [],
            halfCheckedKeys:[],
            roleCode: "",
            menuList: [],
        }
    }

    componentDidMount() {
        let {modalStatus, roleId, systemId} = this.props;
        scfBaseApi.menuQueryAllApi({id: systemId}).then(res => {
            let data = res.data;
            this.setState({
                menuList: data
            })
        }).catch(err => {
            message.error(err.message);
        })
        if (modalStatus === 2) {
            scfBaseApi.roleQueryPermissionApi({id: roleId}).then(res => {
                let data = res.data;
                let {roleName, roleDes, menuList, roleCode } = data;
                let {halfCheckedKeys} = this.state
                let checkedKeys = [];
                menuList.map(item => {
                    // checkedKeys.push(item.id);
                    // return true;

                    if (item.chidrenMenus.length>0) {   //如果有子节点
                            item.chidrenMenus.map(val=>{
                                checkedKeys.push(val.id);                            
                            })                        
                              halfCheckedKeys.push(item.id);
                    } else if(item.chidrenMenus.length==0){
                             checkedKeys.push(item.id);
                       
                    }
                })
                this.setState({checkedKeys,halfCheckedKeys,roleCode});
                this.props.form.setFieldsValue({roleName, roleDes , roleCode });
                setTimeout(() => {
                    menuList.map(item => {
                        let arr = []
                        item.opers.map(item => {
                            arr.push(item.id);
                            return true;
                        });
                        this.props.form.setFieldsValue({
                            [item.id]: arr
                        });
                        return true;
                    })
                }, 1000)


            }).catch(err => {
                message.error(err.message);
            })
        }
    }

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {modalStatus, roleId, systemId} = this.props;
                let {checkedKeys,halfCheckedKeys, roleCode} = this.state;
                console.log(checkedKeys)
                console.log(halfCheckedKeys)
                let apiName = "";
                let menus = [];
                checkedKeys.map(item => {
                    console.log(values[item])
                    let obj = {};
                    obj.menuId = item;
                    obj.opers = values[item];
                    delete values[item];
                    menus.push(obj);
                    return true;
                });

                let halfMenus = [];
                halfCheckedKeys.map(item=>{
                    let obj = {}
                    obj.menuId = item;
                    obj.opers = undefined;
                    halfMenus.push(obj);
                })
                values.menus = menus.concat(halfMenus);
                if (modalStatus === 2) {
                    apiName = "roleUpdateApi";
                    values.id = roleId;
                    // values.roleCode = roleCode;
                    values.systemId = systemId;

                } else {
                    values.systemId = systemId;
                    apiName = "roleAddApi";
                }
                scfBaseApi[apiName](values).then(res => {
                    message.success(res.message);
                    this.setState({
                        isShowModal: false
                    });
                    this.props.operationOk();
                }).catch(err => {
                    message.error(err.message);
                })
            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });
    }
    //点击复选框触发
    onCheck = (checkedKeys, e) => {

        console.log(checkedKeys)
        console.log(e)
        this.setState({checkedKeys: checkedKeys, halfCheckedKeys: e.halfCheckedKeys});
    };

    render() {
        let {getFieldDecorator} = this.props.form;
        let {modalStatus} = this.props;
        let {visible, menuList, checkedKeys} = this.state;
        let formItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 10}
            },
            labelAlign: 'right'
        };
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16}
            },
            labelAlign: 'right'
        };
        let formTreeItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20}
            },
            labelAlign: 'right'
        };


        let treeNode = (record) => {
            if (record.chidrenMenus.length === 0 && checkedKeys.filter(item => item == record.id).length > 0) {
                return <div className="flex-between " style={{width: "100%"}}>
                    <div style={{width: 160}}>{record.menuName}</div>
                    <FormItem label="操作权限" key={record.id} {...formTreeItem} style={{width: 418, marginBottom: 0}}>
                        {
                            getFieldDecorator(`${record.id}`, {
                                rules: [],
                                initialValue:[]
                            })(<Select mode="multiple">
                                {
                                    record.opers.length > 0 ? record.opers.map(record => {
                                        return <Option key={record.id} value={record.id}> {record.operName} </Option>
                                    }) : null
                                }
                            </Select>)
                        }
                    </FormItem>
                </div>
            } else {
                return <div style={{width: 160}}>{record.menuName}</div>
            }
        }


        let renderTreeNodes = data => data.map((record) => {
            if (record.chidrenMenus !== undefined && record.chidrenMenus.length > 0) {
                return (
                    <TreeNode title={record.menuName} key={record.id} dataRef={record}>
                        {renderTreeNodes(record.chidrenMenus)}
                    </TreeNode>
                );
            }
            return <TreeNode {...record} dataRef={record} key={record.id} title={treeNode(record)}></TreeNode>;
        })
        return (
            <Modal
                title={modalStatus === 1 ? "新增角色" : "编辑角色"}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
                width={800}
                className="role-add-edit-wrapper"
            >
                <Form onSubmit={this.handleOk} {...formItem}>
                    <FormItem label="角色名称">
                        {
                            getFieldDecorator('roleName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入角色名称'
                                    },
                                    {
                                        max: 100,
                                        message: "最大长度为100位,请重新输入"
                                    }
                                ],
                                validateTrigger: "onBlur",
                                initialValue: ""
                            })(<Input/>)
                        }
                    </FormItem>
                    <FormItem label="角色编码">
                        {
                            getFieldDecorator('roleCode', {
                                rules: [
                                    {
                                        required: false,
                                        message: '请输入角色编码'
                                    },
                                    {
                                        max: 100,
                                        message: "最大长度为100位,请重新输入"
                                    }
                                ],
                                validateTrigger: "onBlur",
                                initialValue: ""
                            })(<Input disabled={true}/>)
                        }
                    </FormItem>
                    <FormItem label="角色描述">
                        {
                            getFieldDecorator('roleDes', {
                                rules: [
                                    {
                                        required: false,
                                        message: '请输入系统名称'
                                    },
                                    {
                                        max: 100,
                                        message: "最大长度为100位,请重新输入"
                                    }
                                ],
                                initialValue: "",
                                validateTrigger: "onBlur"
                            })(<Input/>)
                        }
                    </FormItem>
                    <div>
                        <FormItem style={{width: "50%"}} className="com-inline-block" label="菜单权限"{...formRowItem}>

                            <Tree
                                checkable
                                autoExpandParent
                                onCheck={this.onCheck}
                                checkedKeys={checkedKeys}
                                defaultExpandParent
                            >
                                {renderTreeNodes(menuList)}
                            </Tree>
                        </FormItem>
                    </div>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(addOrEditModal);
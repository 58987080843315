import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Spin,Radio,Checkbox} from "antd";
import { withRouter } from 'dva/router';
import {queryEvaluationSheet , queryinitDdata , queryticketApplyAdd } from "../../../request/api/quota"
import { getStorage } from "@/utils/tools";
import {  isEmpyt } from '../../../utils/formatCheck'
const FormItem=Form.Item;
@withRouter
class ApplyEvaluate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModal: false,
            spinning: false,
            title:"",
            text:"",
            initData:{},
            dataList:[],
        }
    }
    componentDidMount(){
        this.getEvaluationSheetStatus()
        this.initDdata()
    }
    getEvaluationSheetStatus = ()=>{
        queryEvaluationSheet().then(res=>{
            if(res.code=='2000'){
                this.setState({
                    dataList : res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
             this.setState({spinning:false});
              message.error(err.message)
        })
    }
    initDdata = ()=>{
        queryinitDdata({id:getStorage('companyID') }).then(res=>{
            if(res.code=='2000'){
                this.setState({
                    initData:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
             this.setState({spinning:false});
              message.error(err.message)
        })
    }
    IsElesInfoDes = (values)=>{
        const { dataList } = this.state
        if( dataList.enterpriseNatureCd.length == values.enterpriseNature){
            if(isEmpyt(this.props.form.getFieldValue('enterpriseNatureDes'))){
                message.info("请填写其他企业性质")
                return
            }
        }
        if( dataList.enterpriseRateCd.length == values.enterpriseRate){
            if(isEmpyt(this.props.form.getFieldValue('enterpriseRateDes'))){
                message.info("请填写其他企业最新评级")
                return
            }
        }
        if( dataList.ratingAgenciesCd.length == values.ratingAgencies){
            if(isEmpyt(this.props.form.getFieldValue('ratingAgenciesDes'))){
                message.info("请填写其他评级机构")
                return
            }
        }
        if( dataList.industryCd.length == values.industry){
            if(isEmpyt(this.props.form.getFieldValue('industryDes'))){
                message.info("请填写其他所属行业")
                return
            }
        }
        if( dataList.businessScaleCd.length == values.businessScale){
            if(isEmpyt(this.props.form.getFieldValue('businessScaleDes'))){
                message.info("请填写其他年营业规模")
                return
            }
        }
        if( dataList.netProfitScaleCd.length == values.netProfitScale){
            if(isEmpyt(this.props.form.getFieldValue('profitScaleDes'))){
                message.info("请填写其他净利润规模")
                return
            }
        }
        if( dataList.debtScaleCd.length == values.debtScale){
            if(isEmpyt(this.props.form.getFieldValue('debtScaleDes'))){
                message.info("请填写其他负债规模")
                return
            }
        }
        if( dataList.accountsPayableScaleCd.length == values.accountsPayableScale){
            if(isEmpyt(this.props.form.getFieldValue('accountsPayableDes'))){
                message.info("请填写其他应付账款规模")
                return
            }
        }
        if( dataList.typePaymentCd.length == values.typePayment){
            if(isEmpyt(this.props.form.getFieldValue('typePaymentDes'))){
                message.info("请填写其他付款方式")
                return
            }
        }
        values.financingModeArr.map((item,index)=>{
            if( dataList.financingModeCd.length == item){
                if(isEmpyt(this.props.form.getFieldValue('financingModeDes'))){
                   values.IsfinancingMode=true
                }
            }
        })
        values.coreEnterpriseTypeArr.map((item,index)=>{
            if( dataList.coreEnterpriseTypeCd.length == item){
                if(isEmpyt(this.props.form.getFieldValue('coreEnterpriseDes'))){
                   values.IscoreEnterpriseType=true
                }
            }
        })
        if(values.financingModeArr.includes(dataList.financingModeCd.length.toString())){
            values.financingModeDes = values.financingModeDes
        }else{
            values.financingModeDes = ''
        }
        if(values.coreEnterpriseTypeArr.includes(dataList.coreEnterpriseTypeCd.length.toString())){
            values.coreEnterpriseDes = values.coreEnterpriseDes
        }else{
            values.coreEnterpriseDes = ''
        }
        console.log("数据："+JSON.stringify(values))
        this.AddEvaluationSheet(values)
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { initData } = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(JSON.stringify(values))
                values.enterpriseId = getStorage('companyID') 
                values.enterpriseName = getStorage('companyName') 
                values.contactMobile  = initData.contactMobile
                this.IsElesInfoDes(values)
            }
        })
    }
    AddEvaluationSheet = (values)=>{
        if(values.IsfinancingMode==true){
            message.info("请填写其他主要的融资方式")
            return
        }
        if(values.IscoreEnterpriseType==true){
            message.info("请填写其他核心供应商的企业性质")
            return
        }
        this.setState({spinning:true});
        queryticketApplyAdd(values).then(res=>{
            if(res.code=='2000'){
                this.props.history.push("/admin/homeClient")
            }else{
                message.info(res.message)
            }
            this.setState({spinning:false});
        }).catch(err=>{
             this.setState({spinning:false});
              message.error(err.message)
        })
    }
    closeModal=()=>{
        this.setState({
            isShowModal:false,
        },()=>{
            this.props.history.push("/trade/commercialTicketIssuance/businessExplain");
        })
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {spinning,isShowModal,title,text , coreEnterpriseTypeCd , initData , dataList }=this.state;
        const formRowLeft = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
            labelAlign: 'left'
        };
        const formRowRight = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5, offset: 1},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
            labelAlign: 'left'
        }
        const formRowItemLeft = {
            wrapperCol: {
                xs: {span: 24},
                sm: {offset:1,span: 23},
            },
            labelAlign: 'left'
        };

        const modalProps={
            title,
            text,
            closeModal:this.closeModal,
        }
        return (
            <div className="apply-evaluate-form-wrapper">
                    <h2 className='es-title'>企业商票发行申请评估表</h2>
                    {
                        initData.id != undefined?<div className='es-refuse'>
                        <Row>
                            <Col span={12}>您的企业名称:{initData.enterpriseName}</Col>
                            <Col span={12}>开通失败原因:{initData.des}</Col>
                        </Row>
                    </div>:null
                    }
                    
                     <Form onSubmit={this.handleSubmit} autoComplete="off" className="apply-evaluate-content">
                         <ul className="form-items">
                             <li>
                                 <p>1、请选择您的企业性质</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('enterpriseNature', {
                                                initialValue: initData.enterpriseNatureCd,
                                                rules: [
                                                    { required: true, message: '请选择您的企业性质!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.enterpriseNatureCd && dataList.enterpriseNatureCd.length 
                                                            ? dataList.enterpriseNatureCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('enterpriseNatureDes',{
                                                    initialValue:initData.enterpriseNatureDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>2、请选择您的企业最新评级</p>
                                 <Row>
                                     <Col span={7}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('enterpriseRate', {
                                                initialValue: initData.enterpriseRateCd,
                                                rules: [
                                                    { required: true, message: '请选择您的企业最新评级!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.enterpriseRateCd && dataList.enterpriseRateCd.length 
                                                            ? dataList.enterpriseRateCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={12}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('enterpriseRateDes',{
                                                    initialValue:initData.enterpriseRateDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>3、请选择您的评级机构</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('ratingAgencies', {
                                                initialValue: initData.ratingAgenciesCd,
                                                rules: [
                                                    { required: true, message: '请选择您的评级机构!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.ratingAgenciesCd && dataList.ratingAgenciesCd.length 
                                                            ? dataList.ratingAgenciesCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={12}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('ratingAgenciesDes',{
                                                    initialValue:initData.ratingAgenciesDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>4、请选择您的所属行业</p>
                                 <Row>
                                     <Col span={11}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('industry', {
                                                initialValue: initData.industryCd,
                                                rules: [
                                                    { required: true, message: '请选择您的所属行业!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.industryCd && dataList.industryCd.length 
                                                            ? dataList.industryCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('industryDes',{
                                                    initialValue:initData.industryDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>5、主营业务内容说明</p>
                                 <Row>
                                     <Col span={24}>
                                         <FormItem label="" style={{marginLeft:20}}>
                                             {
                                                 getFieldDecorator('coreBusiness',{
                                                    initialValue: initData.coreBusiness,
                                                    rules: [
                                                        { required: true, message: '主营业务内容说明!' }
                                                    ],
                                                 })(<Input.TextArea/>)
                                             }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>

                             <li>
                                 <p>6、请选择您的年营业规模</p>
                                 <Row>
                                     <Col span={13}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('businessScale', {
                                                initialValue: initData.businessScaleCd,
                                                rules: [
                                                    { required: true, message: '请选择您的年营业规模!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.businessScaleCd && dataList.businessScaleCd.length 
                                                            ? dataList.businessScaleCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('businessScaleDes',{
                                                    initialValue:initData.businessScaleDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>7、请选择您的净利润规模</p>
                                 <Row>
                                     <Col span={12}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('netProfitScale', {
                                                initialValue: initData.netProfitScaleCd,
                                                rules: [
                                                    { required: true, message: '请选择您的净利润规模!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.netProfitScaleCd && dataList.netProfitScaleCd.length 
                                                            ? dataList.netProfitScaleCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('profitScaleDes',{
                                                    initialValue:initData.profitScaleDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>8、请选择您的负债规模</p>
                                 <Row>
                                     <Col span={13}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('debtScale', {
                                                initialValue: initData.debtScaleCd,
                                                rules: [
                                                    { required: true, message: '请选择您的负债规模!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.debtScaleCd && dataList.debtScaleCd.length 
                                                            ? dataList.debtScaleCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('debtScaleDes',{
                                                    initialValue:initData.debtScaleDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>9、请选择您的应付账款规模</p>
                                 <Row>
                                     <Col span={12}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('accountsPayableScale', {
                                                initialValue: initData.accountsPayableScaleCd,
                                                rules: [
                                                    { required: true, message: '请选择您的应付账款规模!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.accountsPayableScaleCd && dataList.accountsPayableScaleCd.length 
                                                            ? dataList.accountsPayableScaleCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('accountsPayableDes',{
                                                    initialValue:initData.accountsPayableDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>10、请选择您的主要付款方式</p>
                                 <Row>
                                     <Col span={8}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('typePayment', {
                                                initialValue: initData.typePaymentCd,
                                                rules: [
                                                    { required: true, message: '请选择您的主要付款方式!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.typePaymentCd && dataList.typePaymentCd.length 
                                                            ? dataList.typePaymentCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={10}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('typePaymentDes',{
                                                    initialValue:initData.typePaymentDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>11、请选择您的应付款的账期分布</p>
                                 <Row>
                                     <Col span={9}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('accountPeriod', {
                                                initialValue: initData.accountPeriodCd,
                                                rules: [
                                                    { required: true, message: '请选择您的应付款的账期分布!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.accountPeriodCd && dataList.accountPeriodCd.length 
                                                            ? dataList.accountPeriodCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>12、请选择您目前主要的融资方式（可多选）</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('financingModeArr', {
                                                initialValue: initData.financingModeArr,
                                                rules: [
                                                    { required: true, message: '请选择您目前主要的融资方式!' }
                                                ],
                                            })(
                                                <Checkbox.Group>
                                                    {
                                                        dataList.financingModeCd && dataList.financingModeCd.length 
                                                            ? dataList.financingModeCd.map((item, index) => <Checkbox key={index} value={item.dicKey}>{item.dicValue}</Checkbox>)
                                                            : null
                                                    }
                                                </Checkbox.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={12}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('financingModeDes',{
                                                    initialValue:initData.financingModeDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>13、请选择您的目前综合融资成本</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('financeCosts', {
                                                initialValue: initData.financeCostsCd,
                                                rules: [
                                                    { required: true, message: '请选择您的目前综合融资成本!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.financeCostsCd && dataList.financeCostsCd.length 
                                                            ? dataList.financeCostsCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>14、请选择您的核心供应商的企业性质（可多选）</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('coreEnterpriseTypeArr', {
                                                initialValue: initData.coreEnterpriseTypeArr,
                                                rules: [
                                                    { required: true, message: '请选择您的核心供应商的企业性质!' }
                                                ],
                                            })(
                                                <Checkbox.Group>
                                                    {
                                                        dataList.coreEnterpriseTypeCd && dataList.coreEnterpriseTypeCd.length 
                                                            ? dataList.coreEnterpriseTypeCd.map((item, index) => <Checkbox key={index} value={item.dicKey}>{item.dicValue}</Checkbox>)
                                                            : null
                                                    }
                                                </Checkbox.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                     <Col span={12}  >
                                         <FormItem  {...formRowRight} >
                                            {
                                                getFieldDecorator('coreEnterpriseDes',{
                                                    initialValue:initData.coreEnterpriseDes
                                                })(<Input/>)
                                            }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                             <li>
                                 <p>15、您目前对商票的了解程度</p>
                                 <Row>
                                     <Col span={10}>
                                         <FormItem label="" {...formRowItemLeft} >
                                         {
                                            getFieldDecorator('underLevel', {
                                                initialValue: initData.underLevelCd,
                                                rules: [
                                                    { required: true, message: '请选择您的您目前对商票的了解程度!' }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        dataList.underLevelCd  && dataList.underLevelCd.length 
                                                            ? dataList.underLevelCd.map((item, index) => <Radio key={index} value={item.dicKey}>{item.dicValue}</Radio>)
                                                            : null
                                                    }
                                                </Radio.Group>
                                            )
                                        }
                                         </FormItem>
                                     </Col>
                                 </Row>
                             </li>
                         </ul>
                         <div className="submit com-text-center">
                             <Spin spinning={spinning}>
                                 <Button htmlType="submit" type="primary" style={{width: 150}}
                                         className="com-shadow-radius"><b>提交问卷</b></Button>
                             </Spin>
                         </div>
                 </Form>
            </div>
        );
    }
}
const ApplyEvaluateForm=Form.create()(ApplyEvaluate);
export default ApplyEvaluateForm;
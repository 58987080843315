import React, { Component } from 'react';
import { Steps, Button, message } from 'antd';
import CardInfo from './component/cardInfo'
import FacereCognition from './component/facereCognition'
import MoneyCertification from './component/moneyCertification'
import Result from './component/result' 
import logo from '../../assets/img/logo.png'
import './index.scss'
const { Step } = Steps;
const steps = [
    {
      title: '提交证件信息',
      content: <CardInfo/>,
    },
    {
      title: '人脸识别',
      content: <FacereCognition/>,
    },
    {
      title: '小额打款认证',
      content: <MoneyCertification/>,
    },
    {
        title: '结果',
        content: <Result/>,
      },
  ];
class CompanyInfoEntry extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            current: 0,
         }
    }
    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    render() { 
        const { current } = this.state;
        return ( 
            <>
                <div className='cie-box'>
                    <div className='cie-w'>
                        <div className='cie-title'>
                            <img src={logo} alt="logo"/>
                            <span>供应链金融平台</span>
                        </div>
                    </div>
                    <div className='cie-step'>
                        <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                        </Steps>
                        <div className="steps-content">{steps[current].content}</div>
                        <div className="steps-action">
                        {current!=0 && current < steps.length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                                下一步
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            Done
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                            Previous
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            </>
         );
    }
}
 
export default CompanyInfoEntry;
import React, { Component } from 'react';
import { Row, Col , message } from 'antd';
import QuotalInfo from './quotaInfo'
import AuditHistory from '../../businessTicketManage/component/aditHistory'
import GetTicketInfoSubmit from './getTicketInfoSubmit'
import TicketTable from '../../../containers/commercialTicketManage/client/component/ticketTable'
import url from 'url'
import { getStorage } from '../../../utils/tools'
class IssueGetTicket extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ticketLedgerId:url.parse(this.props.location.search,true).query.id,
            wkId:url.parse(this.props.location.search,true).query.wkId,
            nodeId:url.parse(this.props.location.search,true).query.nodeId,
            drawerOrgId:url.parse(this.props.location.search,true).query.drawerOrgId,
            companyID:getStorage('companyID'),
         }
    }
    render() { 
        const { ticketLedgerId , wkId , nodeId ,  drawerOrgId , companyID } = this.state
        return ( 
            <>
                <div>
                    {
                        drawerOrgId == companyID?<QuotalInfo/>:null
                    }
                    <Row>
                        <h1 style={{fontSize:"18px",fontWeight:'600',marginBottom:'30px'}}>发行票据信息</h1>
                        <TicketTable ticketLedgerId={ticketLedgerId}/>
                        <AuditHistory businessId={ticketLedgerId} businessType={'2010'}/>
                        <GetTicketInfoSubmit id={ticketLedgerId} wkId={wkId} nodeId={nodeId}  nodeType={'2'} />
                    </Row>
                </div>
                
            </>
         );
    }
}
 
export default IssueGetTicket;
import React, { Component } from 'react';
import Picker from '../../../components/picker/picker'
import {checkFixedPhone, checkIdNumber, checkUnifiedCreditCode, checkPhone,checkBankNumber, isEmpyt } from '../../../utils/formatCheck';
import moment from "moment";
import { withRouter } from "dva/router";
import FileUploadForm from '../../../components/fileUpload';
import { DatePicker, Select, Form, Input, Row, Col, Radio , Checkbox, Button, message } from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import {setStorage } from "@/utils/tools";
const { RangePicker } = DatePicker;
const { Option } = Select;
@withRouter
class CardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            idNoLongEffective:0,
            bsnLcsPreview:"",
            frontUrl:'',
            bankList: [],  //银行列表,
         }
    }
    componentDidMount(){
        this.getBankList()
    }
    clearPickerCity = (index)=>{
        this.props.form.resetFields([`City${index}`,`County${index}`]);
    }
    clearPickerCounty = (index)=>{
        this.props.form.resetFields([`County${index}`]);
    }
    handleSubmit = e => {
        e.preventDefault();
        const {  imgId  , imgFrontId , imgBackUId} = this.state
        if(isEmpyt(imgId)){
            message.info("请先上传营业执照！")
            return
        }
        if(isEmpyt(imgFrontId)){
            message.info("请先上传身份证头像面照片！")
            return
        }
        if(isEmpyt(imgBackUId)){
            message.info("请先上传身份证国徽面照片！")
            return
        }
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
            this.handleParams(values)
          }
        });
    }
    //处理数据
    handleParams = (value)=>{
        const { idNoValidPeriodStart , idNoValidPeriodEnd ,legalPersonIdNoValidPeriodStart , legalPersonIdNoValidPeriodEnd , imgUrl ,imgId ,imgFrontUrl , imgFrontId , imgBackUrl , imgBackUId} = this.state
        if(isEmpyt(idNoValidPeriodStart)){
            message.info("请选择营业期限开始日期！")
            return
        }else if(isEmpyt(idNoValidPeriodEnd)){
            message.info("请选择营业期限日期！")
            return
        }
        if(isEmpyt(legalPersonIdNoValidPeriodStart)){
            message.info("请选择证件有效期的开始日期！")
            return
        }else if(isEmpyt(legalPersonIdNoValidPeriodEnd)){
            message.info("请选择证件有效期的日期！")
            return
        }
        let params = {
            inviteCode:value.inviteCode,
            idNoCd:value.idNoCd,
            name:value.name,
            idNo:value.idNo,
            province:value.province0,
            city:value.city0,
            county:value.county0,
            address:value.address0,
            idNoLongEffective:value.idNoLongEffective,
            idNoValidPeriodStart:idNoValidPeriodStart,
            idNoValidPeriodEnd:idNoValidPeriodEnd,
            imgUrl:imgUrl,
            imgId:imgId,
            business:{
                province:value.province1,
                city:value.city1,
                county:value.county1,
                address:value.address1,
                landline:value.landline,
                fax:value.fax
            },
            account:{
                bankName:value.bankName,
                subBankName:value.subBankName,
                bankCard:value.bankCard
            },
            legalPerson:{
                idNoCd:value.legalPersonIdNoCd,
                name:value.legalPersonName,
                idNo:value.legalPersonIdNo,
                idNoLongEffective:value.legalPersonIdNoLongEffective,
                idNoValidPeriodStart:legalPersonIdNoValidPeriodStart,
                idNoValidPeriodEnd:legalPersonIdNoValidPeriodEnd,
                marriageCd:value.legalPersonMarriageCd,
                mobile:value.legalPersonMobile,
                mail:value.legalPersonMail,
                imgFrontUrl:imgFrontUrl,
                imgFrontId:imgFrontId,
                imgBackUrl:imgBackUrl,
                imgBackUId:imgBackUId
            },
            manager:{
                name:value.managerName,
                mobile:value.managerMobile,
                position:value.managerPosition,
                mail:value.managerMail
            }
        }
        console.log("解析之后的数据："+JSON.stringify(params))
        this.companyRegester(params)
    }
    //企业注册
    companyRegester = (params)=>{
        const { name } = params
        scfBaseApi.querycompanyRegesterApi(params).then(res => {
            if(res.code=='2000'){
                setStorage('companyName',name);
                setStorage('companyID', res.data.orgId);
                message.success(res.message)
                this.props.history.push('/admin/homeClient');
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取银行列表
    getBankList = () => {
        scfBaseApi.queryBankListApi().then(res => {
            let data = res.data;
            this.setState({
                bankList: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    onChange = (value)=>{
        this.setState({
            idNoValidPeriodStart:moment(value[0]).format('YYYY-MM-DD'),
            idNoValidPeriodEnd:'2999-12-31'
        })
    }
    onChangeIet = (value)=>{
        this.setState({
            legalPersonIdNoValidPeriodStart:moment(value[0]).format('YYYY-MM-DD'),
            legalPersonIdNoValidPeriodEnd:'2999-12-31'
        })
    }
    onChangeRangePicker = (value)=>{
        this.setState({
            idNoValidPeriodStart:moment(value[0]).format('YYYY-MM-DD'),
            idNoValidPeriodEnd:moment(value[1]).format('YYYY-MM-DD')
        })
    }
    onChangeRangePickerIet = (value)=>{
        this.setState({
            legalPersonIdNoValidPeriodStart:moment(value[0]).format('YYYY-MM-DD'),
            legalPersonIdNoValidPeriodEnd:moment(value[1]).format('YYYY-MM-DD')
        })
    }
    handleChanges = (e) => {
        this.setState({
            idNoLongEffective: e.target.value
        })
    };
    handleChangesIet = (e) => {
        this.setState({
            legalPersonIdNoLongEffective: e.target.value
        })
    };
    //删除上传文件
    handleRemoveFile = () => {
        this.props.form.resetFields([
            "code",
            "idNoCd",               //认证企业名称
            "legalPersonName",               //法人
            "orgAddress",       //注册地址
            "cardNo",    //统一社会信用代码,
            "businessScope",
            "isBusinessLicenseLongTerm",
            "businessLicenseValidityStart",
            "businessLicenseValidityEnd"
        ])
    }
    //确定上传的field 和value
    handleFileChange = (field, val) => {
        if(field=='businessLicenseResourceFileId'){
            this.setState({
                imgUrl:`${val.directory}/${val.fileName}`,
                imgId:val.resourceFileId
            })
            this.props.form.setFieldsValue({
                name: val.orgName,        
                idNo: val.cardNo,       
                idNoLongEffective: val.isBusinessLicenseLongTerm
            });
        }else if(field=='idFrontResourceFileId'){
            this.setState({
                imgFrontUrl:`${val.directory}/${val.fileName}`,
                imgFrontId:val.resourceFileId
            })
            this.props.form.setFieldsValue({
                legalPersonName: val.legalPersonName,        
            });
        }else if(field=='idBackResourceFileId'){
            this.setState({
                imgBackUrl:`${val.directory}/${val.fileName}`,
                imgBackUId:val.resourceFileId
            })
        }
    };
    render() { 
        const { getFieldDecorator } = this.props.form;
        const { idNoLongEffective , frontUrl , bankList , legalPersonIdNoLongEffective} = this.state
        const isLegalPersonMarriedArr = [
            {
                dicKey:0,
                dicValue:'未婚'
            },
            {
                dicKey:1,
                dicValue:'已婚'
            },
            {
                dicKey:2,
                dicValue:'离异'
            },
        ]
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 2 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 10 },
            },
          };
        const formItemLayoutWithOutLabel = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 10 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
              },
          };  
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                span: 24,
                offset: 0,
                },
                sm: {
                span: 16,
                offset: 4,
                },
            },
        };
        return ( 
            <>
                <div>
                    <div className='info-box'>
                        <h2>上传照片要求</h2>
                        <ul>
                            <li>1. 请上传最新证件原件的彩色扫描件或数码照，如使用复印件请加盖公章上传（推荐使用原件电子版）；</li>
                            <li>2. 图片支持格式：jpg/jpeg/png/bmp格式；</li>
                            <li>3. 请使用像素大于600万像素的相机或手机拍摄，图片大小不超过10M；</li>
                            <li>4. 经微信传输过的图片会导致图片失真，请避免使用经微信传输过的图片；</li>
                        </ul>
                    </div>
                    <div>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="邀请码">
                                {getFieldDecorator("inviteCode", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入邀请码",
                                        }
                                    ],
                                })(<Input maxLength={6}/>)}
                            </Form.Item>
                            <div className='ci-title'>营业执照</div>
                            <Row>
                                <Col span={8}>
                                    <div>
                                        <Form.Item style={{marginBottom: 0}}>
                                            <FileUploadForm uploadText="营业执照"
                                                            action="ocrBusinessLicenseApi"
                                                            handleFileChange={this.handleFileChange}
                                                            handleRemoveFile={this.handleRemoveFile}
                                                            field="businessLicenseResourceFileId"
                                                            key="businessLicenseResourceFileId"
                                                            uploadDescripte=""
                                                            preview={frontUrl}
                                            />
                                            {
                                                getFieldDecorator('businessLicenseResourceFileId', {
                                                    initialValue: ''
                                                })(<Input type="hidden"/>)
                                            }
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <Form.Item label="证件类型" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("idNoCd", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请选择证件类型",
                                                }
                                            ],
                                            initialValue:'1'
                                        })(<Radio.Group><Radio value="1">统一社会信用代码类</Radio></Radio.Group>)}
                                    </Form.Item>
                                    <Form.Item label="名称" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("name", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入名称",
                                                }
                                            ],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="统一社会信用代码证（18位）" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("idNo", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "统一社会信用代码证（18位）",
                                                },
                                                {
                                                    validator:checkUnifiedCreditCode
                                                }
                                            ]
                                        })(<Input maxLength={18}/>)}
                                    </Form.Item>
                                    <Picker 
                                        index = {0}
                                        getFieldDecorator={getFieldDecorator} 
                                        clearPickerCity={this.clearPickerCity} 
                                        clearPickerCounty={this.clearPickerCounty}
                                    />
                                    <Form.Item label="营业期限" className="register-radio"  {...formItemLayoutWithOutLabel}>
                                        {
                                            getFieldDecorator('idNoLongEffective', {
                                                rules: [{
                                                    required: true, message: '请选择是否长期'
                                                }],
                                            })(
                                                <Radio.Group onChange={this.handleChanges}>
                                                    <Radio value={0}>固定</Radio>
                                                    <Radio value={1}>长期</Radio>
                                                </Radio.Group>
                                            )
                                        }
                                        {
                                            idNoLongEffective==0?<RangePicker onChange={this.onChangeRangePicker} />:  <DatePicker placeholder='请选择开始日期' style={{width:'356px'}} onChange={this.onChange} />
                                        }
                                        
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='ci-title'>经营地址</div>
                            <Row>
                                <Col span={8}>

                                </Col>
                                <Col span={16}>
                                <Picker 
                                    index = {1}
                                    getFieldDecorator={getFieldDecorator} 
                                    clearPickerCity={this.clearPickerCity} 
                                    clearPickerCounty={this.clearPickerCounty}
                                />
                                    <Form.Item label="座机" {...formItemLayoutWithOutLabel}>
                                        {
                                            getFieldDecorator('landline', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入座机号码'
                                                    }, {
                                                        validator: checkFixedPhone,
                                                        message: '座机号码格式有误，请重填'
                                                    }],
                                                validateTrigger: "onBlur"
                                            })(<Input placeholder="区号-电话" />)
                                        }
                                    </Form.Item>
                                    <Form.Item label="传真" {...formItemLayoutWithOutLabel}>
                                        {
                                            getFieldDecorator('fax', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: '请输入传真号码'
                                                    }, 
                                                        {
                                                        validator: checkFixedPhone,
                                                        message: '传真号码格式有误，请重填'
                                                    }],
                                                validateTrigger: "onBlur"
                                            })(<Input placeholder="区号-电话"/>)
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>    
                            <div className='ci-title'>对公账户信息</div>
                            <Row>
                                <Col span={8}>

                                </Col>
                                <Col span={16}>
                                    <Form.Item label="开户银行" {...formItemLayoutWithOutLabel}>
                                    {
                                        getFieldDecorator('bankName', {
                                            rules: [
                                                {
                                                    required: true, message: '请输入开户银行名称'
                                                }],
                                            validateTrigger: "onBlur"

                                        })(<Select
                                            showSearch
                                            placeholder="请输入查询"
                                        >
                                            {
                                                bankList != null && bankList.length !== 0 ? bankList.map((record, index) => {
                                                    return <Option key={record.id} value={record.name}>{record.name}</Option>
                                                }) : null
                                            }
                                        </Select>)
                                    }
                                </Form.Item>
                                <Form.Item label="开户支行" {...formItemLayoutWithOutLabel}>
                                    {
                                        getFieldDecorator('subBankName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入开户支行名称'
                                                },
                                                {
                                                    max: 100,
                                                    message: "最大长度为100位,请重新输入"
                                                }
                                            ],
                                            validateTrigger: "onBlur"
                                        })(<Input />)
                                    }
                                </Form.Item> 
                                <Form.Item label="银行账户" {...formItemLayoutWithOutLabel}>
                                    {
                                        getFieldDecorator('bankCard', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入银行账户'
                                                },
                                                {validator:checkBankNumber}
                                            ],
                                            validateTrigger: "onBlur"
                                        })(<Input />)
                                    }
                                </Form.Item>
                                </Col>
                            </Row>    
                            <div className='ci-title'>法定代表人证件</div>
                                                                <div className="flex-end-middle">
                                        <div className="clearfix">
                                            <Form.Item className="com-fl">
                                                <FileUploadForm uploadText="身份证头像面"
                                                                action="ocrIdCardBackApi"
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={ frontUrl }
                                                                field="idFrontResourceFileId"
                                                                key="idFrontResourceFileId"/>
                                                {
                                                    getFieldDecorator('idFrontResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden"/>)
                                                }
                                            </Form.Item>

                                            <Form.Item className="com-fl">
                                                <FileUploadForm uploadText="身份证国徽面"
                                                                action="ocrIdCardFrontApi"
                                                                handleFileChange={this.handleFileChange}
                                                                handleRemoveFile={this.handleRemoveFile}
                                                                preview={ frontUrl }
                                                                field="idBackResourceFileId"
                                                                key="idBackResourceFileId"/>
                                                {
                                                    getFieldDecorator('idBackResourceFileId', {
                                                        initialValue: ''
                                                    })(<Input type="hidden"/>)
                                                }
                                            </Form.Item>
                                        </div>
                                        <div style={{width: 700}}>
                                            <Form.Item label="选择证件类型"  {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonIdNoCd', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择选择证件类型'
                                                            },
                                                        ],
                                                        initialValue: '2'
                                                    })(<Select>
                                                        <Option value="2" key="2">中华人民共和国身份证</Option>
                                                    </Select>)
                                                }
                                            </Form.Item>
                                            <Form.Item label="姓名"  {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonName', {
                                                        rules: [
                                                            {
                                                                required: true, message: '请输入法人姓名'
                                                            }, {
                                                                max: 50,
                                                                message: "最大长度为50位,请重新输入"
                                                            }],
                                                        validateTrigger: "onBlur"
                                                    })(<Input/>)
                                                }
                                            </Form.Item>
                                            <Form.Item label="证件号码"  {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonIdNo', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入法人证件号码'
                                                            },
                                                            {
                                                                validator: checkIdNumber,
                                                            }
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input placeholder="请输入身份证号码"/>)
                                                }
                                            </Form.Item>
                                            <Form.Item label="证件有效期（止）" className="register-radio"  {...formItemLayoutWithOutLabel}>
                                            {
                                                getFieldDecorator('legalPersonIdNoLongEffective', {
                                                    rules: [{
                                                        required: true, message: '请选择是否长期'
                                                    }],
                                                })(
                                                    <Radio.Group onChange={this.handleChangesIet}>
                                                        <Radio value={0}>固定</Radio>
                                                        <Radio value={1}>长期</Radio>
                                                    </Radio.Group>
                                                )
                                            }
                                            {
                                                legalPersonIdNoLongEffective==0?<RangePicker onChange={this.onChangeRangePickerIet} />:  <DatePicker style={{width:'356px'}} placeholder='请选择开始日期' onChange={this.onChangeIet} />
                                            }
                                                
                                            </Form.Item>
                                            <Form.Item label="婚姻状况" {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonMarriageCd', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择婚姻状况'
                                                            }
                                                        ]
                                                    })(
                                                        <Radio.Group>
                                                            {
                                                                isLegalPersonMarriedArr.length !== 0 ? isLegalPersonMarriedArr.map(record => {
                                                                    return <Radio key={record.dicKey}
                                                                                  value={record.dicKey}> {record.dicValue} </Radio>
                                                                }) : null
                                                            }
                                                        </Radio.Group>
                                                    )
                                                }
                                            </Form.Item>
                                            <Form.Item label="手机号"  {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonMobile', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入手机号'
                                                            },
                                                            {validator: checkPhone}
                                                        ],
                                                        validateTrigger: "onBlur"
                                                    })(<Input />)
                                                }
                                            </Form.Item>
                                            <Form.Item label="邮箱"  {...formItemLayoutWithOutLabel}>
                                                {
                                                    getFieldDecorator('legalPersonMail', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请输入邮箱'
                                                            },
                                                            {
                                                                type: 'email',
                                                                message: '请输入正确的邮箱地址'
                                                            }
                                                        ],
                                                    })(<Input />)
                                                }
                                            </Form.Item>
                                        </div>
                                    </div>
                            <div className='ci-title'>经办人信息</div>
                            <Row>
                                <Col span={8}>

                                </Col>
                                <Col span={16}>
                                    <Form.Item label="姓名" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("managerName", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入姓名",
                                                }
                                            ],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="手机号码" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("managerMobile", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入手机号码",
                                                }
                                            ],
                                        })(<Input />)}
                                    </Form.Item>
                                    <Form.Item label="职务" {...formItemLayoutWithOutLabel}>
                                        {getFieldDecorator("managerPosition", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入职务",
                                                }
                                            ],
                                        })(<Input />)}
                                    </Form.Item>
                                <Form.Item label="企业邮箱" {...formItemLayoutWithOutLabel}>
                                    {getFieldDecorator("managerMail", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入企业邮箱",
                                            }
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                                </Col>
                            </Row>    
                            <Form.Item {...tailFormItemLayout} style={{textAlign:"center",marginTop:'20px'}}>
                                <Button type="primary" htmlType="submit">
                                    立即注册
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </>
         );
    }
}
const CardInfoForm = Form.create()(CardInfo);
export default CardInfoForm;
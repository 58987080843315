import React, { Component } from "react";
import { Layout, Menu, Breadcrumb, message } from "antd";
import scfBaseApi from "../request/api/scfBaseApi";
import RouterView from "@/router";
import { setStorage, getStorage } from "@/utils/tools";
import "./App.scss";
import { withRouter, NavLink,Link } from "react-router-dom";
import logoWrite  from "../assets/img/logo-write.png";
const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;

@withRouter
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuData: [],
        };
    }
    componentDidMount() {
        window.document.documentElement.setAttribute('data-theme', "theme1");
        scfBaseApi.queryUserMenuByRolesApi().then(res => {
            let data = res.data;
            this.setState({
                menuData: data,
            })
        }).catch(err => {
            message.error(err.message);
        });
        //为后期超级管理员开的接口
        // scfBaseApi.queryUserLeftMenuApi().then(res => {
        //     let data = res.data;
        //     this.setState({
        //         menuData: data,
        //     })
        // }).catch(err => {
        //     message.error(err.message);
        // })
    }

    handleMenuSelect = (event) => {
        let openKeys = event.keyPath[1];
        let selectedKeys = event.keyPath[0];
        setStorage('openKeys', openKeys);
        setStorage('selectedKeys', selectedKeys);
    }

    handleLogout=()=>{
        localStorage.clear();
        this.props.history.push({pathname: '/login'});
    }
    render() {
        let tokens=getStorage("tokens");
        let userName=getStorage("userName");
        let userMobile=getStorage("userMobile")
        let { menuData } = this.state;
        let openKeys = getStorage('openKeys') ? getStorage('openKeys') : '1';
        let selectedKeys = getStorage('selectedKeys') ? getStorage('selectedKeys') : '1';
        return (
            <Layout style={{ minHeight: "100%" }} className="app-wrapper">
                <Sider trigger={null} collapsible className="sider-menu">
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultOpenKeys={[openKeys]}
                        defaultSelectedKeys={[selectedKeys]}
                        onClick={ this.handleMenuSelect }
                    >
                        {menuData!=null&&menuData.length > 0
                            ? menuData.map((record, index) => {
                                if (record.chidrenMenus.length > 0) {
                                    return (
                                        <SubMenu
                                            key={record.id}
                                            title={record.menuName}
                                        >
                                            {record.chidrenMenus.map(
                                                (record) => {
                                                    return (
                                                        <Menu.Item
                                                            key={record.id}
                                                            url={
                                                                record.menuUrl
                                                            }
                                                            name={
                                                                record.menuName
                                                            }
                                                        >
                                                            <NavLink
                                                                to={
                                                                    record.menuUrl!=null?record.menuUrl:"#"
                                                                }
                                                            >
                                                                {
                                                                    record.menuName
                                                                }
                                                            </NavLink>
                                                        </Menu.Item>
                                                    );
                                                }
                                            )}
                                        </SubMenu>
                                    );
                                } else {
                                    return (
                                        <Menu.Item
                                            key={record.id}
                                            url={record.menuUrl}
                                            name={record.menuName}
                                        >
                                            <NavLink to={record.menuUrl!=null?record.menuUrl:"#"}>
                                                {record.menuName}
                                            </NavLink>
                                        </Menu.Item>
                                    );
                                }
                            })
                            : null}
                    </Menu>
                </Sider>
                <Layout
                    style={{ minHeight: "100%", position: "relative" }}
                    className="main-content"
                >
                    <Header className="header flex-between" style={{zIndex:'100'}}>
                        <img src={logoWrite} alt="logo" className="logo-write"/>
                        {tokens!==undefined&&tokens!==""?<div className="user-info">
                            <span><b>您好,&nbsp;</b></span> <span><b>{userName}</b></span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link to="/businessRole/select" className="login-out" style={{color:'orange'}}><b>切换角色</b></Link>  &nbsp;&nbsp;&nbsp;&nbsp;
                            <Link onClick={this.handleLogout} to="#" className="login-out"><b>退出</b></Link>
                        </div>:null}
                    </Header>
                    <Breadcrumb className="bread-crumb-content">
                        <Breadcrumb.Item>
                            <a href="/">首页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="/">首页</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <Content
                        className="com-shadow-radius"
                        style={{
                            marginTop: 15,
                            marginLeft: 30,
                            marginRight: 30,
                            marginBottom: 80,
                            padding: 30,
                            background: "#fff",
                        }}
                    >
                        <RouterView {...this.props} />
                    </Content>
                    <Footer
                        style={{
                            textAlign: "center",
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                        }}
                    >
                        Ant Design ©2018 Created by Ant UED
                    </Footer>
                </Layout>
            </Layout>
        );
    }
}

export default App;

import React, { Component } from 'react';
import { withRouter } from 'dva/router';
import { Button , message , Popconfirm  } from 'antd';
import { getStorage } from "@/utils/tools";
import { queryDeleteApi } from '../../../request/api/quota'

@withRouter

class RenderData extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userId:getStorage('userId'),
            companyID:getStorage('companyID'),
            type:0,
         }
    }
    componentDidMount(){
        this.getType()
    }
    getType = ()=>{
        if(this.props.match.path=='/admin/bussinessTicketPayMoney/paymoney'){
            this.setState({
                type:2
            })
        }else{
            this.setState({
                type:0
            })
        }
    }
    goRepeatDelete = (data)=>{
        queryDeleteApi({id:data.id}).then(res => {
            if(res.code=='2000'){
                this.props.handleCurrentChange({current:1,pageSize:10})
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    godetail = (data)=>{  
        const { type } = this.state
        this.props.history.push(`/admin/businessTicketManage/issusedetail?id=${data.id}&type=${type}&drawerOrgId=${data.drawerOrgId}`)
    }
    goRepeatAdd = (data)=>{  
        this.props.history.push(`/admin/businessTicketManage/addShanjian?id=${data.id}`)
    }
    render() { 
        const { data } = this.props
        return ( 
            <>
                <div>
                    <Button type="primary" style={{marginRight:'20px'}} onClick={()=>{
                        this.godetail(data)
                    }}>详情</Button>
                    {
                        getStorage('companyID')==data.drawerOrgId?<span> <Button  type="primary"  style={{marginRight:'20px'}} onClick={()=>{
                            this.goRepeatAdd(data)
                        }}>编辑</Button><Popconfirm title="您确定删除当前数据吗？" onConfirm={() => this.goRepeatDelete(data)}>
                        <Button type='danger'>删除</Button>
                    </Popconfirm></span>
                    :null
                    }
                </div>
            </>
         );
    }
}
 
export default RenderData;
import React, { Component } from 'react';
import { connect } from 'dva';
import { getUrlParam } from '@/utils/tools';
import { Descriptions, Row, Col, Form, Spin, Button } from 'antd';

@connect((store) => ({ store: store.productManage, loading: store.loading }))
class DetailComp extends Component {

    componentDidMount () {
        this.getAllEnterprise();
    }

    getAllEnterprise = () => {
        let params = { id: getUrlParam('id') };
        this.props.dispatch({ type: 'productManage/getAllEnterprise', payload: params});
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    IntervalFormatter = (num1, num2) => {
        if(!(num1 || num2)) return;
        let newNum1 = num1 * 1, newNum2 = num2 * 1;
        if(typeof newNum1 !== 'number' || typeof newNum2 !== 'number') return;
        if(newNum1 > newNum2) {
            return `${newNum2} ${newNum1}`.replace(/\s/, '-');
        }else {
            return `${newNum1} ${newNum2}`.replace(/\s/, '-');
        }
    }

    render() {
        const { productDetails } = this.props.store;
        const isLoding1 = this.props.loading.effects['productManage/getAllEnterprise'];
        const ItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 19},
            },
            labelAlign: 'left'
        };
        return (
            <div>
                <Spin spinning={isLoding1}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label='项目名称' {...ItemLayout}>
                                {productDetails && productDetails.productName ? productDetails.productName : ''}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Descriptions bordered column={2} size='small'>
                        <Descriptions.Item label="资金方名称">
                            {productDetails && productDetails.capitalName ? productDetails.capitalName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="核心企业名称">
                            {productDetails && productDetails.companyName ? productDetails.companyName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="核心企业授信额度">
                            {productDetails && productDetails.comTotalAmount ? productDetails.comTotalAmount : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="核心企业剩余授信额度">
                            {productDetails && productDetails.comHaveAmount ? productDetails.comHaveAmount : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="授信开始日期">
                            {productDetails && productDetails.companyStartTimeName ? productDetails.companyStartTimeName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="授信截止日期">
                            {productDetails && productDetails.companyEndTimeName ? productDetails.companyEndTimeName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="产品名称">
                            {productDetails && productDetails.productName ? productDetails.productName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="产品利率（年化%）">
                            {productDetails && productDetails.productStartRate !== null ? productDetails.productStartRate : ''}
                            {productDetails && productDetails.productEndRate !== null ? ` ${productDetails.productEndRate}`.replace(/\s/, '-') : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="融资模式">
                            {productDetails && productDetails.productModeName ? productDetails.productModeName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="产品额度（元）">
                            {productDetails && productDetails.totalAmount !== null ? productDetails.totalAmount : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="提前还款手续费率（%）">
                            {productDetails && productDetails.productEarlyRate !== null ? productDetails.productEarlyRate : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="逾期利率（%）">
                            {productDetails && productDetails.productOverdueRate !== null ? productDetails.productOverdueRate : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="最大融资期限">
                            {productDetails && productDetails.productMaxDuetime !== null ? productDetails.productMaxDuetime : ''} {productDetails && productDetails.productMaxUnitName ? `（${productDetails.productMaxUnitName}）` : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="融资比例（%）">
                            {productDetails && productDetails.productFinanceScale !== null ? productDetails.productFinanceScale : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="还款方式" span={2}>
                            {productDetails && productDetails.productRepayWayName ? productDetails.productRepayWayName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="借款期限">
                            {productDetails && productDetails.productLoanPeriodName ? productDetails.productLoanPeriodName : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="还款日">
                            {productDetails && productDetails.productLoanTypeName ? productDetails.productLoanTypeName : ''}，{productDetails && productDetails.productFixDueName ? productDetails.productFixDueName : ''}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
                <div className='wraper-cd-center' style={{marginTop: 24}}>
                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                </div>
            </div>
        )
    }
}

export default DetailComp;

import React, { Component } from 'react'
import { Button, Form, Table, message } from "antd";
import "../../index.scss"
import scfBusinessApi from "../../../../request/api/scfBusinessApi";
import TreeGraph from "./treeGraph"
import TicketTable from './ticketTable'


class CtSignDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            pageNum: "1",
            total: "",
            ticketMessage: "",//票据信息
            dataSource: [],
            auditLt: [],//审核历史

        }
    }
    goBack = () => {
        this.props.history.go(-1)
    }
    componentDidMount() {
        let id = this.props.match.params.id
        this.getTicketMessage(id)
        this.getAuditHistory(id)

    }

    //票据信息
    getTicketMessage = (id) => {
        let params = {
            id: id
        }
        scfBusinessApi.getDetailApi(params).then(res => {
            this.setState({
                ticketMessage: res.data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //审核历史
    getAuditHistory = (id) => {
        let params = {
            businessId: id,
            businessType: "2030"
        }
        scfBusinessApi.auditHistoryApi(params).then(res => {
            this.setState({
                tableData: res.data
            })
        }).catch(err => {
            message.error(err.message)
        })
    }
    render() {
        let { tableData, ticketMessage } = this.state
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'sysBusinessTypeCdDesc',
                key: 'sysBusinessTypeCdDesc',

            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
                key: 'stateCdDesc',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];
        return (
            <div className="CtSignDetail">
                <div className="CtSignDetail-record">
                    <div>
                        <h2>票据信息</h2>
                        <ul>
                            <li>票据号码：{ticketMessage.ticketNo}</li>
                            <li>出票日期：{ticketMessage.ticketDate}</li>
                            <li>汇票到期日期：{ticketMessage.ticketExpireDate}</li>
                            <li>转入方：{ticketMessage.ticketFromOrgName}</li>
                            <li>票据额度(元)：{ticketMessage.ticketAmount}</li>
                            <li>转入日期：{ticketMessage.createTime}</li>
                        </ul>
                    </div>


                </div>
                <div className="CtSignDetail-ticketContent">
                    <div className="CtSignDetail-ticketContent-front">
                        <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                    </div>
                    <div className="CtSignDetail-ticketContent-behind marginTopOrBottom">
                        <TreeGraph id={this.props.match.params.id}></TreeGraph>
                    </div>
                </div>
                <div>
                    <h2>审核历史</h2>
                    <Table dataSource={tableData} columns={columns} pagination={false} className="table" />
                </div>
                <div className="marginTopOrBottom textAlignCenter">
                    <Button onClick={this.goBack} className="com-middle-btn-size">返回</Button>
                </div>
            </div>
        )
    }
}
export default Form.create()(CtSignDetail);
import React, { Component } from 'react'
import { Button,Form,Table, message } from "antd";
import TreeGraph from './treeGraph';
import TicketTable from './ticketTable';
import "../../index.scss"
import scfBusinessApi from "../../../../request/api/scfBusinessApi";


class CtSignDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            tableData:[],
            pageNum:"1",
            total:"",
            router:"",
            dataSource:[],
            ticketMessage:"",//票据信息
            
           
        }
    }
    goBack = () =>{
        this.props.history.go(-1)
    }
    componentDidMount(){

        let id = this.props.match.params.id
        let router = this.props.match.params.item
        this.setState({
            router: router
        })

        this.getTicketMessage(id)
        this.getAuditHistory(id)
    }

        //票据信息
        getTicketMessage = (id) => {
            let params = {
                id: id
            }
            scfBusinessApi.getDetailApi(params).then(res => {
                this.setState({
                    ticketMessage: res.data
                })
            }).catch(err => {
                message.error(err.message);
            })
        }
    
        //审核历史
        getAuditHistory = (id) =>{
            let params ={
                businessId:id,
                businessType:"2030"
            }
            scfBusinessApi.auditHistoryApi(params).then(res=>{
               this.setState({
                dataSource:res.data
               })
            }).catch(err=>{
                message.error(err.message)
            })
        }
    //签收驳回
    reject = ()=>{
        let {id,wkId,nodeId} = this.props.match.params
        let params = {
            id,
            wkId,
            nodeId,
            operationStatus: "40"
        }
        scfBusinessApi.signTransferApi(params).then(res=>{
            message.success(res.message)
            this.props.history.push("/admin/commercialTicketManage/CtTradeClient") 
        }).catch(err=>{
            message.error(err.message)
        })
    }
//确认签收
    signPass = () =>{
        let {id,wkId,nodeId} = this.props.match.params
        let params = {
            id:id,
            wkId,
            nodeId,
            operationStatus:"20"
        }
        scfBusinessApi.signTransferApi(params).then(res=>{
            message.success(res.message)
            this.props.history.push("/admin/commercialTicketManage/CtTradeClient")
        }).catch(err=>{
            message.error(err.message)
        })
    }
    render() {
        let {ticketMessage,router,dataSource} = this.state
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'sysBusinessTypeCdDesc',
                key: 'sysBusinessTypeCdDesc',
               
            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
                key: 'stateCdDesc',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];
        return (
            <div>
               {
                   localStorage.getItem("treeLevel")!=="1"?(
                    <div>
                    <div>
                         <h2>票据信息</h2>
                         <ul>
                             <li>票据号码：{ticketMessage.ticketNo}</li>
                             <li>出票日期：{ticketMessage.ticketDate}</li>
                             <li>汇票到期日期：{ticketMessage.ticketExpireDate}</li>
                             <li>转入方：{ticketMessage.ticketFromOrgName}</li>
                             <li>票据金额：{ticketMessage.ticketAmount}</li>
                             <li>转入日期：{ticketMessage.createTime}</li>
                         </ul>
                    </div>
                </div>):null
               }
                <div className="CtSignDetail-ticketContent">
                     <div className="CtSignDetail-ticketContent-front">
                     <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>          
                     </div>
                     <div className="CtSignDetail-ticketContent-behind marginTopOrBottom">
                     <TreeGraph id={this.props.match.params.id}></TreeGraph>
                     </div>
                </div>
                { localStorage.getItem("treeLevel")!=="1"?( <div className="marginTopOrBottom">
                     <h2>审核历史</h2>
                     <Table dataSource={dataSource} columns={columns} pagination={false} className="table"/>
                 </div>):null}
               
                <div className="marginTopOrBottom textAlignCenter">
                    <Button onClick={this.goBack} className="com-middle-btn-size" >返回</Button>
                    {router==="Sign"?(<>
                        <Button type="primary" className="com-middle-btn-size" style={{marginLeft: '20px'}} onClick={this.reject}>驳回</Button>
                        <Button type="primary" className="com-middle-btn-size" style={{marginLeft: '20px'}} onClick={this.signPass}>确认签收</Button>
                    </>):null
                    }
                    
                   </div>
            </div>
        )
    }
}
export default Form.create()(CtSignDetail);
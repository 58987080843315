import React, { Component } from "react";
import { Form , Input, DatePicker, Button, Row, Col,message } from "antd";
import moment from "moment";
import { withRouter } from "dva/router";
import { queryAuditInfoSubmitApi  } from '../../../request/api/quota'
import { isEmpyt  } from '../../../utils/formatCheck'
const { RangePicker } = DatePicker;
const { TextArea } = Input;

@withRouter
class AuditInfoSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { id, wkId , nodeId } = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(values.date && values.date.length) {
                    values.startTime = moment(values.date[0]).format('YYYY-MM-DD');
                    values.endTime = moment(values.date[1]).format('YYYY-MM-DD');
                }
                values.id = id
                values.wkId = wkId
                values.nodeId = nodeId
                values.auditStatus = '20'
                delete values.date
                this.getAuditAgreeInfo(values)
            }
        });
    };
    refuceAudit = ()=>{
        const { id, wkId , nodeId } = this.props
        let values = {}
        if(isEmpyt(this.props.form.getFieldValue('des'))){
            message.info("请输入审核原因！")
            return
        }
        values.id = id
        values.wkId = wkId
        values.nodeId = nodeId
        values.auditStatus = '40'
        values.des = this.props.form.getFieldValue('des')
        this.getAuditAgreeInfo(values)
    }

    getAuditAgreeInfo = (params)=>{
        queryAuditInfoSubmitApi(params).then(res => {
            if(res.code=='2000'){
                this.props.history.push(`/admin/businessTicketManage/quota`);
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }

    goBack = () => {
        this.props.history.push(`/admin/businessTicketManage/quota`);
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, audit } = this.props;
        return (
            <>
                <div className="ai-box">
                    {
                        audit==0 && data.auditStatusCd==1? null:<h1>审核信息</h1>
                    }
                    {audit == 1 ? (
                        <Form
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 15 }}
                            onSubmit={this.handleSubmit}
                        >
                            <Form.Item label="额度">
                                {getFieldDecorator("totalAmount", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入额度!",
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="额度有效期">
                                {getFieldDecorator("date", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请选择额度有效期!",
                                        },
                                    ],
                                })(<RangePicker />)}
                            </Form.Item>
                            <Form.Item label="审核意见">
                                {getFieldDecorator("des", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入审核意见",
                                        },
                                    ],
                                })(<TextArea rows={4} />)}
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                                <Button type="primary" onClick={this.goBack}>
                                    返回
                                </Button>
                                <Button
                                    type="primary"
                                    style={{ margin: "0 10px" }}
                                    onClick={this.refuceAudit}
                                >
                                    审核拒绝
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    审核通过
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            {
                                data.auditStatusCd!=1?<Row className="ais-info">
                                <Col>
                                    <span>额度：</span>
                                    <span>{data.totalAmount}</span>
                                </Col>
                                <Col>
                                    <span>额度有效期：</span>
                                    <span>
                                        {moment(data.startTime).format(
                                            "YYYY-MM-DD"
                                        )}
                                        ~{moment(data.endTime).format("YYYY-MM-DD")}
                                    </span>
                                </Col>
                                <Col>
                                    <span>审核意见：</span>
                                    <span>{data.des}</span>
                                </Col>
                                <Col>
                                    <span>状态：</span>
                                    <span>{data.auditStatusCdDesc}</span>
                                </Col>
                                <div className="ais-box">
                                    <Button type="primary" onClick={this.goBack}>
                                        返回
                                    </Button>
                                </div>
                            </Row>:null
                            }
                        </div>
                        
                    )}
                </div>
            </>
        );
    }
}
const AuditInfoSubmitForm = Form.create()(AuditInfoSubmit);
export default AuditInfoSubmitForm;

import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import scfBusinessApi from '@/request/api/scfBusinessApi';

const { confirm } = Modal;
const signConfigDeleteApi = scfBusinessApi['signConfigDeleteApi'];

class RemoveComp extends Component {

    forbidden = (resolve, reject) => {
        const { id } = this.props;
        const params = { signConfigId: id };
        signConfigDeleteApi(params).then(res => {
            if(res.code === '0') {
                message.success(res.message);
            }
            setTimeout(() => {
                this.props.updata();
            },0);
            return resolve();
        }).catch(err => {
            message.error(err.message);
            return reject();
        })
    }

    showConfirm = () => {
        confirm({
            title: '是否确认要删除此产品协议签约配置？',
            content: '',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.forbidden(resolve, reject);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    render() {
        return (
            <Button className='com-btn-opr com-small-btn-size mr5' onClick={this.showConfirm}>删除</Button>
        )
    }
}

export default RemoveComp;

import App from '@/containers/App';
import Home from '@/containers/home';
import HomeClient from '@/containers/homeClient/index';
import Login from '@/containers/login';
import ClientInfoManage from '../containers/clientInfoManage/index';
import BusinessRoleSelect from '../containers/businessRoleSelect';
import ClientRelateManage from '../containers/clientRelateManage/index';
import UserManage from '../containers/userManage/index';
import SystemManage from '../containers/systemManage/index';
import RoleManage from '../containers/roleManage/index';
import MenuManage from '../containers/menuManage/index';
import OrganizeManage from '../containers/organizeManage/index';
import OperationManageFm from '../containers/operationManage/interfaceQueryFm';
import OperationManageFmJst from '../containers/operationManage/interfaceQueryFmJst';
import OperationManageTicket from '../containers/operationManage/interfaceQueryticketPay';
import ProductManage from '@/containers/productManage';
import BorrowerCredit from '@/containers/borrowerCredit';
import CoreBusinessCredit from '@/containers/coreBusinessCredit';
import ContractTemplateManage from '@/containers/contractTemplateManage';
import SigningConfiguration from '@/containers/signingConfiguration';

// 商票管理
import QuotaManage from '../containers/businessTicketManage'
import AuditInfo from '../containers/businessTicketManage/component/auditInfo'
import Publish from '../containers/businessTicketManage/component/businessPublish'
import Register from '../containers/login/register'
import SetDealPassword from '../containers/login/setDealPassword'
import ForgotPassword from '../containers/login/forgotPassword'
import SmsCode from '../containers/login/smsCode'
import CompanyInfoEntry from '../containers/companyInfoEntry'
import IssueManage from '../containers/businessTicketIssue'
import IssueManageShanjian from '../containers/businessTicketIssueShanxi'
import AddIssuseShanjian from '../containers/businessTicketIssueShanxi/component/addIssue'
import AddbatchIssueShanjian from '../containers/businessTicketIssueShanxi/component/addbatchIssue'
import IssueManageDetail from '../containers/businessTicketIssue/component/issueAudit'
import Evaluation from '../containers/homeClient/component/evaluationSheet'
import AddIssuse from '../containers/businessTicketIssue/component/addIssue'
import IssueSigin from '../containers/businessTicketIssue/component/issuseSigin'
import IssueGetTicket from '../containers/businessTicketIssue/component/issueGetTicket'
import PaymoneyAudit from '../containers/bussinessTicketPayMoney/index'
import IssueDetail from '../containers/businessTicketIssue/component/issueDetail'

import CommercialTicketTrade from '@/containers/commercialTicketManage/operation/commercialTicketTrade';
import CtTransforDetail from '@/containers/commercialTicketManage/operation/component/CtTransforDetail';
import CtTransforDetailDetail from '@/containers/commercialTicketManage/operation/component/CtTransforDetailDetail';
import CtSignDetail from '@/containers/commercialTicketManage/operation/component/CtSignDetail';


import CommercialTicketAuthorityManage from '@/containers/commercialTicketManage/client/commercialTicketAuthorityManage';
import AddRoleAuthority from '@/containers/commercialTicketManage/client/component/AddRoleAuthority';
import ConfirmRoleAuthority from '@/containers/commercialTicketManage/client/component/ConfirmRoleAuthority';
import CommercialTicketTradeClient from '@/containers/commercialTicketManage/client/commercialTicketTradeClient';
import CtTransforClient from '@/containers/commercialTicketManage/client/component/CtTransforClient';
import CtSignClient from '@/containers/commercialTicketManage/client/component/CtSignClient';
import AddTransforClient from '@/containers/commercialTicketManage/client/component/AddTransforClient';
import CtTransforDetailClient from '@/containers/commercialTicketManage/client/component/CtTransforDetailClient';
import WaitDealtClient from '@/containers/homeClient/component/WaitDealtClient';
import WaitDealt from '@/containers/home/component/WaitDealt';
import InvitateCompanyManage from '@/containers/companyInformationManage/invitateCompanyManage';
import StatisticsEcharts from '@/containers/statisticsEcharts/index'
import ErrorPage from '../../src/components/errorPage/index'
import ChangePassword from '@/containers/SysManage/changePassword'

export default [
    {
        path: '/admin',
        name: 'App',
        component: App,
        children: [
            {
                path: '/admin/home',
                name: 'home',
                component: Home
            },
            //借款方-待办
            {
                path: '/admin/WaitDealtClient',
                name: ' 待办',
                component:  WaitDealtClient
            },
            //运营方-待办
            {
                path: '/admin/WaitDealt',
                name: ' 待办',
                component:  WaitDealt
            },
            {
                path: '/admin/homeClient',
                name: ' 首页',
                component:  HomeClient
            },
            {
                path: '/admin/coreBusinessCredit/:item',
                name: '核心企业授权',
                component: CoreBusinessCredit
            },
            {
                path: '/admin/productManage/:item',
                name: '产品管理',
                component: ProductManage
            },
            {
                path: '/admin/borrowerCredit/:item',
                name: '借款方授信',
                component: BorrowerCredit
            },
            {
                path: '/admin/contractTemplateManage/:item',
                name: '合同模板管理',
                component: ContractTemplateManage
            },

            {
                path: '/admin/clientManage/clientInfo/:item',
                name: '客户信息管理',
                component: ClientInfoManage
            },
            {
                path: '/admin/signingConfiguration/:item',
                name: '产品协议签约配置',
                component: SigningConfiguration
            },
            //用户管理
            {
                path: '/admin/clientManage/user',
                name: '用户管理',
                component: UserManage
            },
            //平台系统管理
            {
                path: '/admin/clientManage/system',
                name: '平台系统管理',
                component: SystemManage
            },
            //菜单管理
            {
                path: '/admin/clientManage/menu',
                name: '菜单管理',
                component: MenuManage
            },
            //角色管理
            {
                path: '/admin/clientManage/role',
                name: '角色管理',
                component: RoleManage
            },
           //组织架构管理
            {
                path: '/admin/clientManage/organize/:item',
                name: '组织架构管理',
                component: OrganizeManage
            },
            //客户关系管理
            {
                path: '/admin/clientRelateManage/relateMaintain/:item',
                name: '客户关系维护',
                component:ClientRelateManage
            },
            //运营管理
            {
                path: '/admin/operationManage/fmInterfaceQuery',
                name: '接口调用查询（富民）',
                component: OperationManageFm
            },
            {
                path: '/admin/operationManage/ticketInterfaceQuery',
                name: '接口调用查询（票付通）',
                component: OperationManageTicket
            },
            {
                path: '/admin/operationManage/fmJstInterfaceQuery',
                name: '接口调用查询（富民急速贴）',
                component: OperationManageFmJst
            },
            //商票管理
            //运营-商票交易列表
            {
                path: '/admin/commercialTicketManage/CommercialTicketTrade',
                name: '商票交易',
                component: CommercialTicketTrade
            },
            //运营-商票交易列表-转让详情
            {
                path: '/admin/commercialTicketManage/CtTransforDetail/:id/:ticketLedgerId',
                name: '商票转让详情',
                component: CtTransforDetail
            },
             //运营-票据交易列表-签收详情
             {
                path: '/admin/commercialTicketManage/CtSignDetail/:id/:ticketLedgerId',
                name: '商票签收详情',
                component: CtSignDetail
            },
             //运营-票据交易列表-转让详情-转让详情
            {
                path: '/admin/commercialTicketManage/CtTransforDetailDetail/:id/:ticketLedgerId',
                name: '商票转让详情-详情',
                component: CtTransforDetailDetail
            },

            //借款方：商票发行
            {
                path: '/admin/businessTicketIssue/issue',
                name: '商票发行',
                component: IssueManage
            },
            //借款方：商票发行(陕建)
            {
                path: '/admin/businessTicketIssue/issueShanjian',
                name: '商票发行',
                component: IssueManageShanjian
            },
            //借款方：商票发行新增(陕建)
            {
                path: '/admin/businessTicketManage/addShanjian',
                name: '商票发行新增',
                component: AddIssuseShanjian
            },
             //借款方：批量发行(陕建)
             {
                path: '/admin/businessTicketManage/addbatchIssue',
                name: '批量发行',
                component: AddbatchIssueShanjian
            },
            //借款方：商票发行审核页面
            {
                path: '/admin/businessTicketIssue/detail',
                name: '商票发行审核',
                component: IssueManageDetail
            },
            //借款方：商票发行签收页面
            {
                path: '/admin/businessTicketIssue/sigin',
                name: '商票发行签收',
                component: IssueSigin
            },
            //借款方：商票发行收票页面
            {
                path: '/admin/businessTicketIssue/getTicket',
                name: '商票发行收票',
                component: IssueGetTicket
            },
            //借款方：商票付款管理
            {
                path: '/admin/bussinessTicketPayMoney/paymoney',
                name: '商票付款',
                component: IssueManage
            },
            //借款方：商票付款审核页面
            {
                path: '/admin/bussinessTicketPayMoney/paymoneyAudit',
                name: '商票付款',
                component: PaymoneyAudit
            },
             //借款方：商票发行新增
             {
                path: '/admin/businessTicketManage/add',
                name: '商票发行新增',
                component: AddIssuse
            },
            //借款方：商票发行详情页面
            {
                path: '/admin/businessTicketManage/issusedetail',
                name: '商票发行详情',
                component: IssueDetail
            },
            //借款方：企业商票发行申请评估表
            {
                path: '/admin/businessTicketIssue/evaluation',
                name: '企业商票发行申请评估表',
                component: Evaluation
            },

            //借款方-商票交易列表
            {
                path: '/admin/commercialTicketManage/CtTradeClient',
                name: '商票交易',
                component: CommercialTicketTradeClient
            },
            
            //借款方-票据交易列表-转让详情
            {
                path: '/admin/commercialTicketManage/CtTransfor/:id/:ticketLedgerId',
                name: '商票转让',
                component:  CtTransforClient
            },
             //借款方-票据交易列表-签收详情
            {
                path: '/admin/commercialTicketManage/CtSign/:item/:id/:ticketLedgerId/:wkId/:nodeId',
                name: '商票签收',
                component: CtSignClient
            },
             //借款方-票据交易列表-详情
            {
                path: '/admin/commercialTicketManage/CtTransforDetailClient/:item/:id/:ticketLedgerId/:wkId/:nodeId',
                name: '商票转让详情',
                component:  CtTransforDetailClient
            },
            // 借款方-票据交易列表-新增转让/重新提交
            {
                path: '/admin/commercialTicketManage/AddTransforClient/:item/:id/:ticketLedgerId',
                name: '新增转让',
                component: AddTransforClient
            },
            // 商票权限管理-借款方
            {
                path: '/admin/commercialTicketManage/CtAuthorityManage',
                name: '商票权限管理',
                component: CommercialTicketAuthorityManage
            },
            {
                path: '/admin/commercialTicketManage/AddRoleAuthority/:item',
                name: '新增他方角色权限',
                component: AddRoleAuthority
            },
            {
                path: '/admin/commercialTicketManage/ConfirmRoleAuthority/:id',
                name: '我方角色权限确认',
                component: ConfirmRoleAuthority
            },
            //企业信息管理
            {
                path: '/admin/companyInformationManage/invitateCompanyManage',
                name: '邀请企业管理',
                component: InvitateCompanyManage
            },
            
            //业务角色选择
            {
                redirect: '/admin',
                name: 'defaultRoute',
                component: '/admin/home'
            },
            //商票管理:额度管理
            {
                path: '/admin/businessTicketManage/quota',
                name: '额度管理',
                component: QuotaManage
            },
            //商票管理:额度管理详情
            {
                path: '/admin/businessTicketManage/detail',
                name: '额度管理详情',
                component: AuditInfo
            },
            //商票管理:商票发行管理
            {
                path: '/admin/businessTicketManage/publish',
                name: '商票发行管理',
                component: Publish
            },
             //商票管理:商票付款管理
             {
                path: '/admin/businessTicketManage/payMoney',
                name: '商票发行管理',
                component: Publish
            },
             //系统管理
             {
                path: '/admin/SysManage/changePassword',
                name: '修改密码',
                component: ChangePassword
            },
            {
                component:ErrorPage
            },    
            
        ]
    },
    //统计页
{
    path: '/StatisticsEcharts',
    name: '统计页',
    component: StatisticsEcharts
},
    //登录页
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword
    },
    {
        path: '/smsCode',
        name: 'smsCode',
        component: SmsCode
    },
    {
        path: '/setDealPassword',
        name: 'setDealPassword',
        component: SetDealPassword
    },
    {
        path:'/companyInfoEntry',
        name: 'CompanyInfoEntry',
        component: CompanyInfoEntry
    },
    {
        path: '/businessRole/select',
        name: '业务角色选择',
        component: BusinessRoleSelect
    },
    {
        component:Login
    },
    {
        redirect: '/',
        name: 'defaultRoute',
        component: '/login'
    },
    
]
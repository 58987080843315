import React from 'react';
import echarts from 'echarts/lib/echarts';
import tree from 'echarts/lib/chart/tree';
import {Button, message} from "antd";
import {withRouter} from "dva/router";
import AddModal from "./addModal";
import scfBaseApi from "../../../request/api/scfBaseApi";
@withRouter
class TreeGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            checkedId: 0,
            isShowAdd: false,
            orgId:this.props.orgId,
            departLevel: 1  //树状图层级  默认显示层级
        };
    }

    componentDidMount() {
        let treeDom = document.getElementById('main');
        let myChart = echarts.init(treeDom);
        this.myChart = myChart;
        this.handelUpdateEcharts({});
        this.myChart.on('contextmenu', this.handleMouseover);
        this.myChart.on('click', this.handleClickCircle);
        this.getDepartDetail();
        window.onresize = this.myChart.resize;
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps)
        let {renderStatus, orgName, parentOrgName, orgId, parentOrgId} = nextProps;
        //由于添加和编辑中客户和上级机构改变，更新数据
        if (renderStatus!=this.props.renderStatus){
            let treeData = this.myChart.getOption().series[0].data;
        
            if (renderStatus === 1) {
                this.setState({orgId});
                treeData[0].children[0].id = orgId;
                treeData[0].children[0].departName = orgName;
                treeData[0].children[0].children = [];
                console.log(treeData[0])
                this.handelUpdateEcharts(treeData[0]);
            } else if (renderStatus === 2) {
                treeData[0].id = parentOrgId;
                treeData[0].departName = parentOrgName;
                this.handelUpdateEcharts(treeData[0]);
            } else if (renderStatus === 3) {
                this.setState({orgId});
                treeData[0] = {
                    id: parentOrgId,
                    onlyId: new Date().getTime() - 10,
                    departName: parentOrgName,
                    departLevel:-1,
                    children: [{
                        id: orgId,
                        departName: orgName,
                        onlyId: new Date().getTime() - 20,
                        departLevel:0,
                        children: []
                    }]
                }
                this.handelUpdateEcharts(treeData[0]);
            }

        }
    }
   //重绘chartTree
    getChartTree=(data)=>{
        let option={
            calculable: false,
            series: [
                {
                    name: '树图',
                    type: 'tree',
                    orient: 'TB', // 垂直布局
                    data: [data],
                    rootLocation: {x: 'center', y: '15%'}, // 根节点位置  {x: 'center',y: 10}
                    nodePadding: 20,
                    layerPadding: 40,
                    // borderColor: 'black',
                    // symbol: 'emptyCircle',
                    symbolSize: [300, 50],
                    symbol: 'rect',  //标记图形
                    symbolKeepAspect: true,  //图形缩放保持长宽比
                    edgeShape: 'polyline',
                    // roam:"move",
                    initialTreeDepth: -1, //节点展开的层级
                    //容器位置
                    left: 'center',
                    bottom: 50,
                    top: 50,
                    width: "100%",
                    expandAndCollapse: true,  //子树折叠和展开
                    //节点样式
                    itemStyle: {
                        color: "#333333",
                        normal: {
                            color: '#ffffff', // 节点背景色
                            borderColor: '#1890ff',
                            label: {
                                show: true,
                                position: ['50%', '50%'],
                                fontWeight: 'normal',
                                lineHeight: 24,
                                align: 'center',  //文本水平对齐方式
                                verticalAlign: "middle",//文本垂直对齐方式
                                color: '#696969',
                                fontSize: 14,
                                formatter: function (params) {
                                      console.log(params)
                                    if (params.data.departLevel === -1&&params.data.departName!==""&&params.data.departName!=null){
                                        // let departName="";
                                        // if(params.data.departName.length>15){
                                        //     departName=params.data.departName.substring(0,15)+"..."
                                        // }else{
                                        //     departName=params.data.departName;
                                        // }
                                        // return "上级机构:" + " " + `${departName}`
                                        return "上级机构:" + " " + `${params.data.departName}`
                                    }else if(params.data.departLevel === -1&&(params.data.departName===""||params.data.departName==null)){
                                        return "上级机构:" + " " + "无"
                                    } else if (params.data.departLevel === 0) {
                                        // let departName="";
                                        // if(params.data.departName.length>17){
                                        //     departName=params.data.departName.substring(0,17)+"..."
                                        // }else{
                                        //     departName=params.data.departName;
                                        // }
                                        // return "企业:" + " " + `${departName}`
                                        return "企业:" + " " + `${params.data.departName}`
                                    }
                                    //  else {
                                    //     return `${params.data.departLevel}级部门:` + " " + `${params.data.departName}`
                                    // }
                                },
                            },
                            //树图边样式
                            lineStyle: {
                                color: '#1890ff',
                                width: 3
                                // type: 'broken' // 'curve'|'broken'|'solid'|'dotted'|'dashed'
                            }
                        },
                        emphasis: {
                            label: {
                                show: false
                            }
                        }
                    },
                }

            ],
            expandAndCollapse: true,    //子树折叠和展开的交互，默认打开
            animationDuration: 550,     //初始动画的时长，支持回调函数,默认1000
            animationDurationUpdate: 750//数据更新动画的时长，默认300
        }
        this.myChart.clear();
        this.myChart.setOption(option,true);

    }
    //获取初始组织架构
    //获取组织机构编辑页详情和详情
    getDepartDetail = () => {
        let {id,item, parentOrgId, parentOrgName, orgId, orgName} = this.props;
        if(item==="edit"||item==="detail"){
            scfBaseApi.departDetailApi({id}).then(res => {
                let data = res.data;
                data.departLevel=-1;
                data.onlyId=new Date().getTime() -10;
                data.children[0].departLevel=0;
                data.children[0].onlyId=new Date().getTime() - 20;
                this.handelUpdateEcharts(data);
            }).catch(err => {
                message.error(err.message);
            })
        }else{
            let data = {
                id: parentOrgId,
                onlyId: new Date().getTime() - 10,
                departName: parentOrgName,
                departLevel:-1,
                children: [{
                    id: orgId,
                    departName: orgName,
                    onlyId: new Date().getTime() - 20,
                    departLevel:0,
                    children: [],
                }]
            }
            this.handelUpdateEcharts(data);
        }
    }
    //更新charts数据
    handelUpdateEcharts=(data)=>{
        this.getChartTree(data);
        this.handleEchartsSize();
    }
    //递归删除节点
    recursiverDelete = (onlyId, data) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].onlyId === onlyId) {
                data.splice(i, 1);
                break;
            } else {
                if (data[i].children !== undefined && data[i].children.length > 0) {
                    this.recursiverDelete(onlyId, data[i].children);
                }
            }
        }
        return data;
    }
    //递归增加节点
    recursiverAdd = (onlyId, data, val, level) => {
        level++;
        for (var i = 0; i < data.length; i++) {
            if (data[i].onlyId === onlyId) {
                data[i].children.push({
                    departName: val,
                    onlyId: new Date().getTime(),
                    children: [],
                    departLevel: level,
                    orgId: this.state.orgId
                });
                break;
            } else {
                if (data[i].children !== undefined && data[i].children.length > 0) {
                    this.recursiverAdd(onlyId, data[i].children, val, level);
                }
            }
        }
        return data;
    }
    //添加节点
    addNode = (val) => {
        let {checkedId} = this.state;
        let treeData = this.myChart.getOption().series[0].data;
        let data = this.recursiverAdd(checkedId, treeData, val, -1);
        this.handelUpdateEcharts(data[0]);
        this.operationCancel();
    }
    //删除节点
    deleteNode = () => {
        let {checkedId} = this.state;
        let treeData = this.myChart.getOption().series[0].data;
        let data = this.recursiverDelete(checkedId, treeData);
        this.handelUpdateEcharts(data[0]);
        this.hideMenu();
    }
    // handleNodeTelescopic=(params)=>{
    //     console.log(params);
    //     const onlyId = params.data.onlyId;
    //     console.log(this.myChart);
    //     const curNode = this.myChart._chartsViews[0]._data.tree._nodes.find(item => {
    //         return item.onlyId === onlyId;
    //     });
    //     console.log(curNode);
    //     const depth = curNode.depth;
    //     const curIsExpand = curNode.isExpand;
    //     this.myChart._chartsViews[0]._data.tree._nodes.forEach((item, index) => {
    //         if (item.depth === depth && item.onlyId !== onlyId && !curIsExpand) {
    //             item.isExpand = false;
    //         }
    //     });
    // }
    //调整容器高度和宽度
    handleEchartsSize = () => {
        let nodes = this.myChart._chartsViews[0]._data._graphicEls;
                let allNode = 0;
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i] === undefined) {
                        continue;
                    }
                    allNode++;
                }

                let currentHeight = 70 * allNode;
                let currentWidth=70*allNode;
                let newHeight = Math.max(currentHeight,300);
                let newWidth=Math.max(currentWidth,window.innerWidth);
                let treeDom = document.getElementById('main');
                treeDom.style.height = newHeight + 'px';
                // treeDom.style.width = newWidth + 'px';
                this.myChart.resize();
    }
    handleMouseover = (params) => {
        params.event.event.preventDefault()  //屏蔽浏览器默认行为
        let {organizeStatus} = this.props;
        if (organizeStatus !== 2 && params.data.departLevel >= 0) {
            this.setState({
                checkedId: params.data.onlyId,
                departLevel: params.data.departLevel
            })
            let rightMenu = document.getElementById('rightMenu');
            rightMenu.style.display = "block";
            rightMenu.style.position = "absolute";
            rightMenu.style.left = `${params.event.event.pageX-170}px`;
            rightMenu.style.top = `${params.event.event.pageY-30}px`;
        }
        return false;
    }
    hideMenu = () => {
        let {organizeStatus} = this.props;
        if (organizeStatus !== 2) {
            let rightMenu = document.getElementById('rightMenu');
            rightMenu.style.display = "none";
        }
    }
    handleClickCircle = (param) => {
        this.hideMenu();
        this.handleEchartsSize();
        // let index = param.data.index  // 获取当钱节点的深度param.data是自己传的数据
        // console.log(param);
        // this.findId(this.myChart.getOption().series[0].data, param.data.onlyId, 'getObj')   // 获取当前节点
        // // 判断此节点是展开还是收缩状态
        //
        // this.map=new Map(this.myChart.getOption().series[0].data);
        // console.log(this.myChart.getOption().series[0]);
        // if (this.map.get(this.currentClickObj.value)) {
        //     // 收缩状态，执行下面代码
        //     this.map.set(this.currentClickObj.value, false)
        //     // 将同级的全部收缩   this.option.series[0].data是本树的data数据（数组）
        //     this.handleCollapsed(this.myChart.getOption().series[0].data, index);
        //     // 当前节点展开
        //     this.findId(this.myChart.getOption().series[0].data, param.data.onlyId, 'setCollapsed')
        //     this.map.set(this.currentClickObj.value, true)  // 将当前节点设置为收缩状态
        //     // 重新渲染  echart中的data更改的时候，需要重新渲染
        //     this.myChart.clear();
        //     this.myChart.setOption(this.option)
        // }
        // // 计算高度
        // //计算高度这个点，我是查找此子树的每个级别的节点，并找出最多的节点数，根据这个子节点数来计算高度
        // this.listNumber = []
        // this.findMostLeaves(param.data.index + 1)
        // // Math.max.apply(null, this.listNumber) 取出listNumber中的最大值并且计算高度
        // let treeDom=document.getElementById('main');
        //  treeDom.style.height = Math.max.apply(null, this.listNumber) * 80 + 500 + 'px'
        // // 重要的一步，小编当时就是漏掉此步，导致没办法更新高度，敲黑板
        // this.myChart.resize();
    }

    // 将同级的全部收缩
    handleCollapsed(array, index) {
        array.forEach((value, i) => {
            if (value.index == index) {
                value.collapsed = true  // collapsed属性为true，设置此节点收缩状态
                this.map.set(value.value, false)  // 并且将此节点设置为可收缩状态（map）
            } else {
                if (value.children.length > 0) {
                    // 递归
                    this.handleCollapsed(value.children, index)
                } else {
                    return
                }
            }
        })
    }

    //// 查找当前节点  当前节点展开
    findId(array, id, flag) {
        array.forEach((value, i) => {
            if (value.onlyId === id) {
                if (flag === 'setCollapsed') {
                    value.collapsed = false  //collapsed属性为true，设置此节点展开状态
                    this.map.set(value.value, true)  // 并且将此节点设置为可展开状态（map）
                } else {
                    this.currentClickObj = value
                }
            } else {
                if (value.children.length > 0) {
                    this.findId(value.children, id, flag)
                } else {
                    return
                }
            }
        })
    }

    // 找出最多的节点
    findMostLeaves(index) {
        // this.myChart._chartsViews[0]._data.tree._nodes 此值是tree数据将结构中的数据，左击的时候就可以获取到此数值，并且里面的isExpand就是判断当前节点的展开和收缩状态
        this.myChart._chartsViews[0]._data.tree._nodes.forEach(value => {
            if (value.depth <= index) {
                if (value.isExpand) {
                    // 将每一个等级的叶子数量存入listNumber中
                    this.listNumber.push(value.children.length)
                }
            }
        })
    }

    //添加部门弹框取消
    operationCancel = () => {
        this.setState({
            isShowAdd: false
        });
    }
    addNodeModal = () => {
        this.setState({
            isShowAdd: true,
        });
        this.hideMenu();
    }
    handleMaintain = () => {
        let departs=this.myChart.getOption().series[0].data[0].children[0].children;
        scfBaseApi.departAddApi(departs).then(res => {
            message.success(res.message);
            setTimeout(()=>{
                this.props.history.push("/admin/clientManage/organize/search");
            },2000);

        }).catch(err => {
            message.error(err.message);
        })
    }

    render() {
        let {isShowAdd, departLevel} = this.state;
        let {organizeStatus} = this.props;
        let addOrEditModalProps = {
            operationOk: this.addNode,
            operationCancel: this.operationCancel,
        };
        return (
            <div className="tree-graph-wrapper">
                {
                    organizeStatus !== 2 ? <div>
                        <h2>2.企业组织架构关系</h2>
                        <div className="maintain-finish">
                            {/* <Button type="primary" className="com-middle-btn-size"
                                    onClick={this.handleMaintain}>确认维护完毕</Button> */}
                        </div>
                    </div> : null
                }
                <div id="main"  className="tree-img"></div>

                <div id="rightMenu" style={{display: "none"}} className="mask-info">
                    <div className="flex-direction-center">
                        <span className="com-hand-blue" onClick={this.addNodeModal}>添加+</span>
                        {departLevel > 0 ? <span className="com-hand-blue" onClick={this.deleteNode}>删除-</span> : null}
                    </div>
                </div>
                {isShowAdd === true ? <AddModal {...addOrEditModalProps}/> : null}
            </div>

        )
    }
}

export default TreeGraph;




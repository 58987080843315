import React, { Component } from 'react';
import { Button, Col, Form,  message, Row,  Table, } from "antd";
import '../../index.scss'
import scfBusinessApi from "../../../../request/api/scfBusinessApi";
import scfBaseApi from "../../../../request/api/scfBaseApi";

const FormItem = Form.Item;

class ConfirmRoleAuthority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource:[],
            detail:"",
        }
    }
 
componentDidMount(){
    this.getDetail()
    this.getAuditHistory()
}
    goBack = ()=>{
        this.props.history.push("/admin/commercialTicketManage/CtAuthorityManage")
    }

    getDetail = () =>{
        let params = {
            id:this.props.match.params.id
        }
        scfBaseApi.infoDetailApi(params).then(res=>{
             this.setState({
                 detail:res.data
             })
        }).catch(err=>{
            message.error(err.message)
        })
    }
    //审核
    action = (action)=>{
        let params = {
           action,
           id: this.props.match.params.id,
           role:this.state.detail.inviteRoleCd
        }
        scfBaseApi.auditApi(params).then(res=>{
            message.success(res.message)
            this.props.history.push("/admin/commercialTicketManage/CtAuthorityManage")
        }).catch(err=>{
            message.error(err.message)
        })
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }
     //审核历史
     getAuditHistory = () =>{
        let {id} = this.props.match.params
        let params ={
            businessId:id,
            businessType:"2005"
        }
        scfBusinessApi.auditHistoryApi(params).then(res=>{
           this.setState({
            dataSource:res.data
           })
        }).catch(err=>{
            message.error(err.message)
        })
    }
render() {
        let {detail} = this.state
        let {  dataSource } = this.state;
        let formRowItem = {
            labelCol: {
                xs: { span: 24 },
                sm: { offset: 3, span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { offset: 1, span: 12 }
            },
            labelAlign: 'right'
        };
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'sysBusinessTypeCdDesc',
                key: 'sysBusinessTypeCdDesc',
               
            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
                key: 'stateCdDesc',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];

       return (
            <div>
                <Form className="" {...formRowItem}>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="邀请方企业">
                                        {
                                        <span>{detail.otherOrgName}</span>
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="邀请方角色">
                                        {
                                            
                                            <span>{detail.otherOrgRoleCdDesc}</span>
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="邀请日期">
                                        {
                                            
                                            <span>{detail.createTime}</span>
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <FormItem label="邀请我方角色">
                                        {
                                            
                                            <span>{detail.inviteRoleCdDesc}</span>
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <div style={{ width: '100%' }}>
                                            <Button type="default" onClick={this.goBack} className="com-middle-btn-size" style={{ marginRight: '20px' }}>返回</Button>
                                            <Button type="primary" onClick={()=>this.action('40')} style={{ marginRight: '20px' }} className="com-middle-btn-size">驳回</Button>
                                            <Button type="primary" onClick={()=>this.action('20')}  className="com-middle-btn-size">审核通过</Button>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                    </Form>
                    <div>
                    <h2 className="history">审核历史</h2>
                    <Table dataSource={dataSource} columns={columns} className="history-table" pagination={false}/>
                </div>
            </div>
              
        );
    }
}

export default Form.create()(ConfirmRoleAuthority);




import React, { Component } from 'react';
import AddComp from './component/addComp';
import EditComp from './component/editComp';
import ListComp from './component/listComp';
import DetailComp from './component/detailComp';
import './index.scss';

class ProductManage extends Component {
    render() {
        let item = this.props.match.params.item;
        let reactNode = <React.Fragment></React.Fragment>;
        switch(item) {
            case 'list': reactNode = <ListComp {...this.props}></ListComp>; break;
            case 'add': reactNode = <AddComp {...this.props}></AddComp>; break;
            case 'edit': reactNode = <EditComp {...this.props}></EditComp>; break;
            case 'detail': reactNode = <DetailComp {...this.props}></DetailComp>; break;
            default: break;
        }
        return (
            <div className='productManage_style'>
                {reactNode}
            </div>
        )
    }
}

export default ProductManage;

import React, { Component } from 'react';
import { Row, Col , message } from 'antd';
import AuditHistory from '../businessTicketManage/component/aditHistory'
import PaymoneyDetail from './compoent/paymoneyDetail'
import PaymoneyInfoSubmit from './compoent/paymoneyInfoSubmit'
import PayMoneyAuditSubmit from './compoent/payMoneyAuditSubmit'
import TicketTable from '../../containers/commercialTicketManage/client/component/ticketTable'
import url from 'url'
import './index.scss';
class PaymoneyAudit extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ticketLedgerId:url.parse(this.props.location.search,true).query.id,
            wkId:url.parse(this.props.location.search,true).query.wkId,
            nodeId:url.parse(this.props.location.search,true).query.nodeId,
            ticketStatusCd:url.parse(this.props.location.search,true).query.ticketStatusCd,
            audit:url.parse(this.props.location.search,true).query.audit,
         }
    }
    render() { 
        const { ticketLedgerId , wkId , nodeId , ticketStatusCd , audit } = this.state
        return ( 
            <>
                <div>
                    <Row>
                        <h1 style={{fontSize:'18px',fontWeight:'600',paddingTop:'20px',margin:'4px 0 36px 0'}}>发行票据信息</h1>
                        <TicketTable ticketLedgerId={ticketLedgerId}/>
                        <AuditHistory businessId={ticketLedgerId} businessType={'2040'}/>
                        <PaymoneyDetail ticketLedgerId = {ticketLedgerId}/>
                        <PaymoneyInfoSubmit ticketLedgerId = {ticketLedgerId} audit={audit}/>
                        {
                            audit==2?<Col span={10}><PayMoneyAuditSubmit id={ticketLedgerId}  wkId={wkId} nodeId={nodeId} ticketStatusCd={ticketStatusCd}/></Col>:null
                        }
                    </Row>
                </div>
                
            </>
         );
    }
}
 
export default PaymoneyAudit;
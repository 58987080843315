import React, {Component} from 'react';
import {withRouter} from "dva/router";
import SearchOrganize from "./component/searchOrganize";
import ShowOrganize from "./component/showOrganize";
import queryString from "query-string";
import "./index.scss";
class Index extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }
    render() {
        let {match,location}=this.props;
        let item =match.params.item;
        let id = item==="add"?"":queryString.parse(location.search).id;
        return (
            <div className="organize-manage-wrapper">
                {(() => {
                    switch (item) {
                        case  "search":
                            return <SearchOrganize/>  //查询组织架构
                        case  "add":
                            return <ShowOrganize key={1} item={item} id={id}/>     //添加组织架构
                        case  "edit":
                            return <ShowOrganize key={2}  item={item} id={id}/>   //编辑组织架构
                        case  "detail":
                            return <ShowOrganize key={3}  item={item} id={id}/>   //编辑组织架构
                        default:
                            break;
                    }
                })()
                }
            </div>
        );
    }
}

export default withRouter(Index);
import React, {Component} from 'react';
import {Button, Form, Input, message, Modal, Table} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";

const FormItem = Form.Item;

class OperatePurview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // pageNum: 1,
            // pageSize: 10,
            // totalCount: 0,
            tableData: [],
            visible: true,
            editId: 0,
        }
    }

    componentDidMount() {
        this.getButtonList();
    }

    //获取按钮列表
    getButtonList = () => {
        let {menuId} = this.props;
        // let {pageNum, pageSize} = this.state;
        let params = {
            id: menuId,
            // pageNum,
            // pageSize,
        };
        scfBaseApi.operateQueryApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    // changePage = (current) => {
    //     this.setState({
    //         pageNum: current
    //     }, () => {
    //         this.getButtonList();
    //     })
    // }

    // changeShowSize = (current, pageSize) => {
    //     this.setState({
    //         pageSize
    //     }, () => {
    //         this.getButtonList();
    //     })
    // }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {menuId} = this.props;
                values.menuId = menuId;
                 scfBaseApi.operateAddApi(values).then(res => {
                    message.success(res.message);
                     this.getButtonList();
                }).catch(err => {
                    message.error(err.message);
                });

            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });


    }
    //取消编辑
    handleCancelEdit = () => {
        this.setState({
            editId: 0
        })
    }
    //编辑
    handleEdit = (operateId = 0) => {
        this.setState({
            editId: operateId
        })
    }
    //保存
    handleSave = (operateId = 0) => {
        let {menuId} = this.props;
        let values = this.props.form.getFieldsValue([`operName${operateId}`, `operCode${operateId}`]);
        if (values[`operName${operateId}`] === "") {
            message.warn("请输入按钮名称");
        } else if (values[`operCode${operateId}`] === "") {
            message.warn("请输入按钮编码");
        } else if (values[`operCode${operateId}`] === "" && values[`operName${operateId}`] === "") {
            message.warn("请输入按钮名称和按钮编码");
        } else {
            let params = {
                menuId,
                id: operateId,
                operName: values[`operName${operateId}`],
                operCode: values[`operCode${operateId}`],
            }
            scfBaseApi.operateUpdateApi(params).then(res => {
                message.success(res.message);
                this.setState({
                    editId: 0
                });
                this.getButtonList();
            }).catch(err => {
                message.error(err.message);
            })
        }
    }
    //删除
    handleDelete = (operateId = 0) => {
        scfBaseApi.operateDeleteApi({id: operateId}).then(res => {
            message.success(res.message);
            this.getButtonList();
        }).catch(err => {
            message.error(err.message);
        })
    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount, visible, editId} = this.state;
        // let pagination = {
        //     position: "bottom",
        //     total: totalCount,
        //     pageSize: pageSize,
        //     defaultCurrent: 1,
        //     current: pageNum,
        //     onChange: this.changePage,
        //     onShowSizeChange: this.changeShowSize,
        //     pageSizeOptions: ['5', '10', '20', '50', '100'],
        //     showSizeChanger: true,
        //     showQuickJumper: true,
        //     hideOnSinglePage: true
        // };
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '按钮名称',
                dataIndex: 'operName',
                key: 'operName',
                sorter: (a,b)=>{
                    if(!a.operName){
                        a.operName=""
                    }else if(!b.operName){
                        b.operName=""
                    }
                    return a.operName.localeCompare(b.operName)
                },
                render: (text, record) => {
                    if (editId !== record.id) {
                        return <span>{text}</span>
                    } else {
                        return <FormItem style={{marginRight: 0, marginBottom: 0}}>
                            {
                                getFieldDecorator(`operName${record.id}`, {
                                    rules: [],
                                    initialValue: "",
                                })(<Input style={{width: 250}}/>)
                            }
                        </FormItem>
                    }
                }
            },
            {
                title: '按钮编码',
                dataIndex: 'operCode',
                key: 'operCode',
                sorter: (a,b)=>{
                    if(!a.operCode){
                        a.operCode=""
                    }else if(!b.operCode){
                        b.operCode=""
                    }
                    return a.operCode-b.operCode
                },
                render: (text, record) => {
                    if (editId !== record.id) {
                        return <span>{text}</span>
                    } else {
                        return <FormItem style={{marginRight: 0, marginBottom: 0}}>
                            {
                                getFieldDecorator(`operCode${record.id}`, {
                                    rules: [],
                                    initialValue: "",
                                })(<Input style={{width: 250}}/>)
                            }
                        </FormItem>
                    }
                }
            },
            {
                title: '操作',
                render: (record) => {
                    if (editId !== record.id) {
                        return <div>
                            <span className="com-hand-blue" onClick={() => {
                                this.handleDelete(record.id)
                            }}>删除</span>
                            <span className="com-hand-blue" onClick={() => {
                                this.handleEdit(record.id)
                            }}>编辑</span>
                        </div>
                    } else {
                        return <div>
                            <span className="com-hand-blue" onClick={() => {
                                this.handleCancelEdit(record.id)
                            }}>取消</span>
                            <span className="com-hand-blue" onClick={() => {
                                this.handleSave(record.id)
                            }}>保存</span>
                        </div>
                    }
                }
            },
        ];

        return (
            <Modal
                title="操作权限"
                visible={visible}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
                width={1000}
                footer={null}
            >
                <Form layout="inline" onSubmit={this.handleSubmit} className="flex-between" style={{marginBottom: 20}}>
                    <FormItem label="按钮名称" style={{marginRight: 0}}>
                        {
                            getFieldDecorator('operName', {
                                rules: []
                            })(<Input style={{width: 250}}/>)
                        }
                    </FormItem>
                    <FormItem label="按钮编码" style={{marginRight: 0}}>
                        {
                            getFieldDecorator('operCode', {
                                rules: []
                            })(<Input placeholder='请输入按钮编码' style={{width: 250}}/>)
                        }
                    </FormItem>
                    <FormItem style={{marginRight: 0}}>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">+新增</Button>
                    </FormItem>
                </Form>
                <Table
                    className="freezing-deposit-table"
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={false}
                    // pagination={pagination}
                />
            </Modal>
        );
    }
}

export default Form.create()(OperatePurview);
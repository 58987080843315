import React, { Component } from "react";
import ReactSimpleVerify from "react-simple-verify";
import { connect } from "dva";
import "react-simple-verify/dist/react-simple-verify.css";
import { Form, Input, Row, Col, Button, message } from "antd";
import scfBaseApi from "../../request/api/scfBaseApi";
import { setStorage } from "@/utils/tools";
import { sendsmsApi, smsCodeLoginApi } from "../../request/api/login";
import { checkPhone, isEmpyt } from "../../utils/formatCheck";
let temer;

@connect((store) => ({
    store: store.login,
    loading: store.loading,
}))
class SmsCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verify: false,
            codeNum: 60,
            codeText: "获取验证码",
            disabledFalg: false,
        };
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                const { kaptchaId } = this.state;
                values.kaptchaId = kaptchaId;
                this.smsCodeLogin(values);
            }
        });
    };
    success = () => {
        this.setState({
            verify: true,
        });
    };
    smsCodeLogin = (values) => {
        smsCodeLoginApi(values)
            .then((res) => {
                if (res.code == "2000") {
                    localStorage.clear();
                    setStorage("tokens", res.data.token);
                    setStorage("userName", res.data.userName);
                    setStorage("userMobile", res.data.userMobile);
                    this.props.dispatch({
                        type: "login/setToken",
                        payload: res.data.token,
                    });
                    this.getSearchUserInfo(res.data.userMobile);
                } else {
                    message.info(res.message);
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    //获取用户下的机构列表和机构下的业务角色
    getUserOrgAndRole = (params) => {
        scfBaseApi
            .getUserOrgsApi()
            .then((res) => {
                let data = res.data;
                if (data.length === 0) {
                    setTimeout(() => {
                        //5是借款方角色
                        if(params==5){
                            this.props.history.push('/companyInfoEntry');
                        }else{
                            if(data[0].businessRoles[0].businessRole=='operate'){
                                this.props.history.push('/admin/home');
                            }else{
                                this.props.history.push('/admin/homeClient');
                            }
                        }
                    }, 1000);
                } else if (
                    data.length === 1 &&
                    data[0].businessRoles.length === 1
                ) {
                    setStorage('companyID', res.data[0].id);
                    setStorage('companyName', res.data[0].orgName);
                    this.setUser(
                        data[0].id,
                        data[0].businessRoles[0].businessRole
                    );
                } else {
                    setTimeout(() => {
                        this.props.history.push("/businessRole/select");
                    }, 1000);
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    setUser = (companyId,role) => {
        let params = {
            userCurrentRole:role,
            currentOrgId: companyId
        }
        scfBaseApi.setCurrentUserApi(params).then(res => {
            message.success(res.message);
            setTimeout(() => {
                if(role=='operate'){
                    this.props.history.push('/admin/home');
                }else{
                    this.props.history.push('/admin/homeClient');
                }
            }, 1000);
        }).catch(err => {
            message.error(err.message);
        })
    }
    //判断用户是否设置建议密码：接口是查询用户信息
    getSearchUserInfo = (params) => {
        scfBaseApi
            .querySearchUserInfoApi()
            .then((res) => {
                if (res.code == "2000") {
                    setStorage("userId", res.data.id);
                    if (res.data.userStatus == 0) {
                        this.getUserOrgAndRole(res.data.systemId);
                    } else if (res.data.userStatus == 1) {
                        message.error(
                            "您的账号被禁止登录，请联系管理员重新开通！"
                        );
                    } else if (res.data.userStatus == 2) {
                        this.props.history.push(
                            `/setDealPassword?mobile=${params}&route=0`
                        );
                    }
                } else {
                    message.info(res.message);
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    getSmsCode = (params) => {
        sendsmsApi(params)
            .then((res) => {
                if (res.code == "2000") {
                    this.setState({
                        kaptchaId: res.data.kaptchaId,
                    });
                    message.success(res.message);
                } else {
                    message.info(res.message);
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    getCode = () => {
        const { codeNum, verify } = this.state;
        const mobile = this.props.form.getFieldValue("mobile");
        if (isEmpyt(mobile)) {
            message.info("请输入手机号！");
            return;
        }
        if (verify) {
            let num = codeNum;
            let params = {
                phoneNumbers: mobile,
                usage: 4,
            };
            this.setState({
                disabledFalg: true,
            });
            this.getSmsCode(params);
            temer = setInterval(() => {
                num--;
                if (num === 0) {
                    clearInterval(temer);
                    this.setState({
                        codeNum: 60,
                        codeText: "重新获取",
                        disabledFalg: false,
                    });
                } else {
                    this.setState({
                        codeNum: num,
                        codeText: num + "s后重新获取",
                        disabledFalg: true,
                    });
                }
            }, 1000);
        } else {
            message.info("请先拖动滑块进行校验");
        }
    };
    registerRoute = () => {
        this.props.history.push("/register");
    };
    forgotPassworldRoute = () => {
        this.props.history.push("/forgotPassword");
    };
    loginRoute = ()=>{
        this.props.history.push("/login");
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { codeText, disabledFalg } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 4,
                },
            },
        };
        return (
            <>
                <div className="bg-img">
                    <div className="register-title">
                        <h1 className="register-h1">验证码登录</h1>
                        <p className="register-p"></p>
                    </div>
                    <div className="register-box sms-box">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="手机号码">
                                {getFieldDecorator("mobile", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入手机号码",
                                        },
                                        {
                                            validator: checkPhone,
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="验证">
                                <ReactSimpleVerify
                                    ref="verify"
                                    success={this.success}
                                />
                            </Form.Item>
                            <Form.Item label="验证码">
                                <Row gutter={8}>
                                    <Col span={16}>
                                        {getFieldDecorator("kaptcha", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入验证码",
                                                },
                                            ],
                                        })(<Input />)}
                                    </Col>
                                    <Col span={8}>
                                        <Button
                                            onClick={this.getCode}
                                            disabled={disabledFalg}
                                        >
                                            {codeText}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="sms-btn"
                                >
                                    登录
                                </Button>
                            </Form.Item>
                            <Row className="foot-box">
                                <Col span={4}></Col>
                                <Col span={16} className="sms-back">
                                    {/* <span onClick={this.registerRoute}>
                                        立即注册
                                    </span> */}
                                    <span onClick={this.loginRoute}>
                                        密码登录
                                    </span>
                                    <span onClick={this.forgotPassworldRoute}>
                                        忘记密码
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const SmsCodeForm = Form.create()(SmsCode);
export default SmsCodeForm;

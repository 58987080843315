import React, { Component } from "react";
import { Table } from "antd";
import TicketStatusEnum from './ticketStatusEnum'
import RenderData from "./renderData";
import { money100 } from '../../../utils/formatCheck'
class IssueTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            pageNum: 1,
            pageSize: 10,
        };
    }
    render() {
        const columns = [
            {
                title: "票据号码",
                dataIndex: "ticketNo",
                width: 240,
                fixed: 'left',
                sorter:(a,b)=>{
                    if(!a.ticketNo){
                        a.ticketNo=""
                    }else if(!b.ticketNo){
                        b.ticketNo=""
                    }
                    return a.ticketNo-b.ticketNo
                },
            },
            {
                title: "票据金额（元）",
                render: (record) => {
                    return <span>{money100(record.ticketAmount)}</span>
                },
                sorter:(a,b)=>{
                    if(!a.ticketAmount){
                        a.ticketAmount=""
                    }else if(!b.ticketAmount){
                        b.ticketAmount=""
                    }
                    return a.ticketAmount-b.ticketAmount
                },
            },
            {
                title: "出票日期",
                dataIndex: "ticketDate",
                sorter: (a, b) => {
                    let aTime = new Date(a.ticketDate).getTime();
                    let bTime = new Date(b.ticketDate).getTime();
                    return aTime - bTime;
                },
            },
            {
                title: "汇票到期日期",
                dataIndex: "ticketExpireDate",
                sorter: (a, b) => {
                    let aTime = new Date(a.ticketExpireDate).getTime();
                    let bTime = new Date(b.ticketExpireDate).getTime();
                    return aTime - bTime;
                },
            },

            {
                title: "票据状态",
                dataIndex: "ticketStatusCd",
                sorter:(a,b)=>{
                    if(!a.ticketStatusCd){
                        a.ticketStatusCd=""
                    }else if(!b.ticketStatusCd){
                        b.ticketStatusCd=""
                    }
                    return a.ticketStatusCd.localeCompare(b.ticketStatusCd)
                },
                render: (record) => {
                    return <TicketStatusEnum data={record} creditStateList={this.props.creditStateList}/>
                },
            },
            {
                title: "出票人名称",
                dataIndex: "drawerName",
                sorter:(a,b)=>{
                    if(!a.drawerName){
                        a.drawerName=""
                    }else if(!b.drawerName){
                        b.drawerName=""
                    }
                    return a.drawerName.localeCompare(b.drawerName)
                },
            },
            
            {
                title: "出票账号",
                dataIndex: "drawerAccountNo",
                sorter:(a,b)=>{
                    if(!a.drawerAccountNo){
                        a.drawerAccountNo=""
                    }else if(!b.drawerAccountNo){
                        b.drawerAccountNo=""
                    }
                    return a.drawerAccountNo-b.drawerAccountNo
                },
                
            },
            {
                title: "出票开户行",
                dataIndex: "drawerBank",
                sorter:(a,b)=>{
                    if(!a.drawerBank){
                        a.drawerBank=""
                    }else if(!b.drawerBank){
                        b.drawerBank=""
                    }
                    return a.drawerBank.localeCompare(b.drawerBank)
                },
            },

            {
                title: "收款人名称",
                dataIndex: "payeeName",
                sorter:(a,b)=>{
                    if(!a.payeeName){
                        a.payeeName=""
                    }else if(!b.payeeName){
                        b.payeeName=""
                    }
                    return a.payeeName.localeCompare(b.payeeName)
                },
            },
            {
                title: "收款账号",
                dataIndex: "payeeAccountNo",
                sorter:(a,b)=>{
                    if(!a.payeeAccountNo){
                        a.payeeAccountNo=""
                    }else if(!b.payeeAccountNo){
                        b.payeeAccountNo=""
                    }
                    return a.payeeAccountNo-b.payeeAccountNo
                },
                
            },
            {
                title: "收款开户行",
                dataIndex: "payeeBank",
                sorter:(a,b)=>{
                    if(!a.payeeBank){
                        a.payeeBank=""
                    }else if(!b.payeeBank){
                        b.payeeBank=""
                    }
                    return a.payeeBank.localeCompare(b.payeeBank)
                },
            },
            {
                title: "承兑人名称",
                dataIndex: "acceptorName",
                sorter:(a,b)=>{
                    if(!a.acceptorName){
                        a.acceptorName=""
                    }else if(!b.acceptorName){
                        b.acceptorName=""
                    }
                    return a.acceptorName.localeCompare(b.acceptorName)
                },
            },
            {
                title: "承兑人账号",
                dataIndex: "acceptorAccountNo",
                sorter:(a,b)=>{
                    if(!a.acceptorAccountNo){
                        a.acceptorAccountNo=""
                    }else if(!b.acceptorAccountNo){
                        b.acceptorAccountNo=""
                    }
                    return a.acceptorAccountNo-b.acceptorAccountNo
                },
            },
            {
                title: "承兑人开户行",
                dataIndex: "acceptorBank",
                sorter:(a,b)=>{
                    if(!a.acceptorBank){
                        a.acceptorBank=""
                    }else if(!b.acceptorBank){
                        b.acceptorBank=""
                    }
                    return a.acceptorBank.localeCompare(b.acceptorBank)
                },
            },
            {
                title: "操作",
                width: "280px",
                fixed: 'right',
                render: (record) => {
                    return <RenderData data={record} handleCurrentChange={this.props.handleCurrentChange} handleFormReset={this.props.handleFormReset}/>;
                },
            },
        ];
        const { data } = this.props;
        return (
            <>
                <Table
                    bordered
                    columns={columns}
                    rowKey={(record, index) => index}
                    dataSource={data.list}
                    scroll={{ x: 2000 }}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: data.total <= 10,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total) => `总共 ${data.total} 条`,
                        total: data.total,
                        current: data.pageNum,
                        pageSize: data.pageSize,
                    }}
                    onChange={this.props.handleCurrentChange}
                />
            </>
        );
    }
}

export default IssueTable;

import scfBusinessApi from '@/request/api/scfBusinessApi';
const findDicLtApi = scfBusinessApi['findDicLtApi'];
const getproductListApi = scfBusinessApi['getproductListApi'];
const getAllEnterpriseApi = scfBusinessApi['getAllEnterpriseApi'];
const getAllProjectByBusRoleApi = scfBusinessApi['getAllProjectByBusRoleApi'];
const getProductEnterpriseCreditDetailApi = scfBusinessApi['getProductEnterpriseCreditDetailApi'];

export default {
    namespace: 'productManage',
    state: {
        productList: [],
        productListData: [],
        productModeList: [],
        productStateList: [],
        priseCreditDetail: {},
        productDetails: {},
        dateEnumerate: [],
        repaymentEnumerate: [],
        loanTermEnumerate: [],
        repaymentDateEnumerate: [],
        fixedDateEnumerate: []
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        *getProductModeList(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'productModeList', val: result.data } })
        },
        *getProductStateList(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'productStateList', val: result.data } })
        },
        *getproductList(action, { call, put }) {
            const result = yield call(getproductListApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'productListData', val: result.data } })
        },
        *modifyEnterpriseCredit(action, { call, put }) {
            const result = yield call(getAllProjectByBusRoleApi)
            yield put({ type: 'updateReducers', payload: { key: 'productList', val: result.data } })
        },
        *getEnterpriseCreditDetail(action, { call, put }) {
            const result = yield call(getProductEnterpriseCreditDetailApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'priseCreditDetail', val: result.data } })
        },
        *getAllEnterprise(action, { call, put }) {
            const result = yield call(getAllEnterpriseApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'productDetails', val: result.data } })
        },
        *getDateEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'dateEnumerate', val: result.data } })
        },
        *getRepaymentEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'repaymentEnumerate', val: result.data } })
        },
        *getLoanTermEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'loanTermEnumerate', val: result.data } })
        },
        *getRepaymentDateEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'repaymentDateEnumerate', val: result.data } })
        },
        *getFixedDateEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'fixedDateEnumerate', val: result.data } })
        }
    }
}
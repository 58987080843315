import React, { Component } from "react";
import { withRouter } from "dva/router";
import { Form , Input, Button, Row, Col,message } from "antd";
import { queryIssuseAuditInfoSubmitApi  } from '../../../request/api/quota'

@withRouter

class AuditInfoSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { id, wkId , nodeId , ticketStatusCd , sysBusinessType } = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.but = '20'
                values.id = id
                if(ticketStatusCd=='1040'){
                    values.optType = '1'
                }else if(ticketStatusCd=='2040'){
                    values.optType = '2'
                }else if(ticketStatusCd=='3040'){
                    values.optType = '3'
                }
                values.wkId = wkId
                values.nodeId = nodeId
                values.sysBusinessType = sysBusinessType
                this.getAuditAgreeInfo(values)
            }
        });
    };
    refuceAudit = ()=>{
        const { id, wkId , nodeId  , ticketStatusCd , sysBusinessType } = this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.but = '40'
                values.id = id
                if(ticketStatusCd=='1040'){
                    values.optType = '1'
                }else if(ticketStatusCd=='2040'){
                    values.optType = '2'
                }else if(ticketStatusCd=='3040'){
                    values.optType = '3'
                }
                values.wkId = wkId
                values.nodeId = nodeId
                values.sysBusinessType = sysBusinessType
                this.getAuditAgreeInfo(values)
            }
        });
    }

    getAuditAgreeInfo = (params)=>{
        queryIssuseAuditInfoSubmitApi(params).then(res => {
            if(res.code=='2000'){
                this.props.history.push(`/admin/businessTicketIssue/issue`);
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    goBack = ()=>{
        this.props.history.push(`/admin/businessTicketIssue/issue`);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className="ai-box">
                    <h1>审核信息</h1>
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                        onSubmit={this.handleSubmit}
                    >
                        <Form.Item label="审核意见">
                            {getFieldDecorator("des", {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入审核意见!",
                                    },
                                ],
                            })(<textarea style={{border:'1px solid #ccc',width:'100%'}} />)}
                        </Form.Item>
                        <Form.Item wrapperCol={{ span:19, offset: 5 }}>
                            <Button type="primary" onClick={this.goBack}>
                                返回
                            </Button>
                            <Button
                                type="primary"
                                style={{ margin: "0 10px" }}
                                onClick={this.refuceAudit}
                            >
                                审核驳回
                            </Button>
                            <Button type="primary" htmlType="submit">
                                审核通过
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

const AuditInfoSubmitForm = Form.create()(AuditInfoSubmit);
export default AuditInfoSubmitForm;

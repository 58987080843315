import React from "react";
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import { Table, Form, Select, Button } from "antd";

const { Option } = Select;
const EditableContext = React.createContext();

let formEvent = [];
const EditableRow = ({ form, index, ...props }) => {
    formEvent[props['data-row-key']] = form;
    return <EditableContext.Provider value={form}>
                <tr {...props} />
           </EditableContext.Provider>
}

const EditableFormRow = Form.create()(EditableRow);

@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class EditableCell extends React.Component {

    getInput = () => {
        const { SysField, RuleType } = this.props.store;
        if (this.props.inputType === 'ruleType') {
          return <Select 
            style={{width: '100%'}} 
            showSearch 
            ref={(node) => (this.input = node)} 
            onBlur={this.save}
            optionFilterProp='children'
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
                    {
                        RuleType && RuleType.length 
                        ? RuleType.map((item, index)=> <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                        : null
                    }
                 </Select>;
        }
        return <Select 
            style={{width: '100%'}} 
            showSearch 
            ref={(node) => (this.input = node)} 
            onBlur={this.save}
            optionFilterProp='hildren'
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
                    {
                        SysField && SysField.length 
                        ? SysField.map((item, index)=> <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                        : null
                    }
                </Select>;
    };

    save = (e) => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if(!e) return;
            if (error && error[e.currentTarget.id]) {
                return;
            }
            handleSave({ ...record, ...values });
        });
    };

    renderCell = (form) => {
        this.form = form;
        const { dataIndex, record, title } = this.props;
        return <Form.Item style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true,
                                message: `请选择${title}！`,
                            },
                        ],
                        initialValue: record[dataIndex],
                    })(
                        this.getInput()
                    )}
                </Form.Item>
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            children,
            inputType,
            dispatch,
            handleSave,
            handleParamsSubmitFn,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {this.renderCell}
                    </EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        );
    }
}

@Form.create()
@connect((store) => ({ store: store.contractTemplateManage, loading: store.loading }))
class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            current: 1,
            pageSize: 10
        };
        this.columns = [
            {
                title: '序号',
                key: 'index',
                render: (text, record, index) => {
                    record.key = (this.state.current-1)*(this.state.pageSize)+(index+1);
                    return `${(this.state.current-1)*(this.state.pageSize)+(index+1)}`
                },
            },
            {
                title: '域名',
                dataIndex: 'domainName',
                key: 'domainName',
                sorter: (a,b)=>{
                    if(!a.domainName){
                        a.domainName=""
                    }else if(!b.domainName){
                        b.domainName=""
                    }
                    return a.domainName.localeCompare(b.domainName)
                }
            },
            {
                title: '表名.系统字段名称',
                dataIndex: 'sysField',
                key: 'sysField',
                editable: true,
                width: '35%',
                sorter: (a,b)=>{
                    if(!a.sysField){
                        a.sysField=""
                    }else if(!b.sysField){
                        b.sysField=""
                    }
                    return a.sysField.localeCompare(b.sysField)
                }
            },
            {
                title: '规则',
                dataIndex: 'ruleType',
                key: 'ruleType',
                editable: true,
                width: '35%',
                sorter: (a,b)=>{
                    if(!a.ruleType){
                        a.ruleType=""
                    }else if(!b.ruleType){
                        b.ruleType=""
                    }
                    return a.ruleType.localeCompare(b.ruleType)
                }
            }
        ];
    }

    componentDidMount () {
        this.props.dispatch({ type: 'contractTemplateManage/getSysField', payload: 'sys_field'});
        this.props.dispatch({ type: 'contractTemplateManage/getRuleType', payload: 'rule_type'});
    }

    UNSAFE_componentWillReceiveProps (newProps) {
        if(newProps.data !== this.props.data) {
            this.setState({ dataSource: newProps.data });
            formEvent.length = 0;
        }
    }

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    handleAddCredit = debounce(() => {
        const data = this.state.dataSource;
        this.props.subMitFn(data);
    }, 300)

    // handleCurrentChange = (option) => {
    //     this.setState({
    //         current: option.current,
    //         pageSize: option.pageSize
    //     })
    // }

    handleParamsSubmitFn = () => {
        let submitFlag = [];
        let newSubmitArr = [];
        formEvent.forEach(item => {
            item.validateFields((err, values) => {
                submitFlag = [...submitFlag, err];
            })
        });
        newSubmitArr = submitFlag.filter(item => item !== null);
        if(newSubmitArr.length) return;
        this.handleAddCredit();
    }

    render() {
        const { dataSource, current, pageSize } = this.state;
        const isLoding1 = this.props.loading.effects['contractTemplateManage/getAddContractData'];
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    title: col.title,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    handleSave: this.handleSave,
                    handleParamsSubmitFn: this.handleParamsSubmitFn,
                    inputType: col.dataIndex === 'sysField' ? 'sysField' : 'ruleType'
                }),
            };
        });
        return (
            <div>
                <Table
                    rowKey={(record, index) => index}
                    components={components}
                    rowClassName={() => "editable-row"}
                    dataSource={dataSource}
                    columns={columns}   
                    loading={isLoding1}
                    pagination = {false}
                    // pagination = {{
                    //     current: current,
                    //     pageSize: pageSize,
                    // }}
                    // onChange={this.handleCurrentChange}
                />
                <div className='wraper-cd-center' style={{marginTop: 24}}>
                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                    <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleParamsSubmitFn}>提交</Button>
                </div>
            </div>
        );
    }
}

export default EditableTable;

import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce, getUrlParam } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Row, Col, Spin, Descriptions, DatePicker, Button, message, Checkbox, InputNumber } from 'antd';

const modifyBorrowerCreditApi = scfBusinessApi['modifyBorrowerCreditApi'];

@Form.create()
@connect((store) => ({ store: store.borrowerCredit, loading: store.loading }))
class EditComp extends Component {

    state = {
        endDate: '',
        startDate: '',
        productId: '',
        projectName: '',
        loanTermEnumerateArr: []
    }

    componentDidMount () {
        this.props.dispatch({ type: 'borrowerCredit/getLoanTermEnumerate', payload: 'debt_term'});
        this.props.dispatch({ type: 'borrowerCredit/getBorrowerDetailData', payload: getUrlParam('id')}).then(res => {
            if(!(this.props.store.borrowerDetailData && this.props.store.borrowerDetailData.productLoanPeriod)) return;
            this.setState({
                loanTermEnumerateArr: this.handleFilterArrFn(this.props.store.borrowerDetailData.productLoanPeriod.split(','), this.props.store.loanTermEnumerate)
            })
        })
    }
    handleAddCredit = debounce(() => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                values.id = getUrlParam('id');
                values.borrowerEndRate = moment(values.borrowerEndRate).format('YYYY-MM-DD HH:mm:ss');
                values.borrowerStartRate = undefined;
                values.borrowerLoanPeriod = values.borrowerLoanPeriod.join(',');
                modifyBorrowerCreditApi(values).then(res => {
                    message.success(res.message);
                    this.props.history.push('/admin/borrowerCredit/list')
                }).catch(err => message.error(err.message))
            }
        })
    }, 300)

    disabledStartDate = startValue => {
        const endDate = this.props.form.getFieldValue('borrowerStartRate');
        if (!startValue || !endDate) {
          return startValue < moment().subtract(1, "days");
        } 
        return startValue.valueOf() > endDate.valueOf() || startValue < moment().subtract(1, "days");
    };
    
    disabledEndDate = endValue => {
        const startDate = this.props.form.getFieldValue('borrowerEndRate');
        if (!endValue || !startDate) {
          return endValue < moment().subtract(1, "days");
        }
        return endValue.valueOf() <= startDate.valueOf();
    };

    handleBorrowerNameChange = () => {
        if(!this.props.form.getFieldValue('projectName')) {
            this.props.form.setFields({
                projectName: {
                    errors: [new Error('请选择项目')]
                }
            })
        }
        if(!this.props.form.getFieldValue('productName')) {
            this.props.form.setFields({
                productName: {
                    errors: [new Error('请选择产品')]
                }
            })
        }
    }

    handleFilterArrFn = (conditionArr, filterArr) => {
        let newArr = [];
        conditionArr.forEach(item => {
            let index = 0;
            index = filterArr.findIndex(items => item === items.dicKey);
            newArr.push(filterArr[index]);
        })
        return newArr;
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { loanTermEnumerateArr } = this.state;
        const { borrowerDetailData } = this.props.store;
        const isLoding1 = this.props.loading.effects['borrowerCredit/getBorrowerDetailData'];
        const isLoding2 = this.props.loading.effects['borrowerCredit/getLoanTermEnumerate'];
        const formItemLayout = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            }
        };
        return (
            <div className='addComp_style'>
                <Spin spinning={ isLoding1 || isLoding2 }>
                    <Form {...formItemLayout} className='enble_form_style'>
                        <Descriptions title="项目-产品信息" bordered column={2} size="small">
                            <Descriptions.Item label="项目名称">{ borrowerDetailData && borrowerDetailData.projectName ? borrowerDetailData.projectName : null }</Descriptions.Item>
                            <Descriptions.Item label="产品名称">{ borrowerDetailData && borrowerDetailData.productName ? borrowerDetailData.productName : null }</Descriptions.Item>
                            <Descriptions.Item label="核心企业名称">{ borrowerDetailData && borrowerDetailData.companyName ? borrowerDetailData.companyName : null }</Descriptions.Item>
                            <Descriptions.Item label="资金方名称">{ borrowerDetailData && borrowerDetailData.capitalName ? borrowerDetailData.capitalName : null }</Descriptions.Item>
                            <Descriptions.Item label="融资模式">{ borrowerDetailData && borrowerDetailData.productModeName ? borrowerDetailData.productModeName : null }</Descriptions.Item>
                            <Descriptions.Item label="产品利率（%）">{ borrowerDetailData && borrowerDetailData.productStartRate ? borrowerDetailData.productStartRate : ''} {borrowerDetailData && borrowerDetailData.productEndRate ? `-${borrowerDetailData.productEndRate}` : null }</Descriptions.Item>
                            <Descriptions.Item label="提前还款手续费率（%）">{ borrowerDetailData && borrowerDetailData.productEarlyRate ? borrowerDetailData.productEarlyRate : null }</Descriptions.Item>
                            <Descriptions.Item label="逾期利率（%）">{ borrowerDetailData && borrowerDetailData.productOverdueRate ? borrowerDetailData.productOverdueRate : null }</Descriptions.Item>
                            <Descriptions.Item label="融资比例（%）">{ borrowerDetailData && borrowerDetailData.productFinanceScale ? borrowerDetailData.productFinanceScale : null }</Descriptions.Item>
                            <Descriptions.Item label="产品可用授信额度（元）">{ borrowerDetailData && borrowerDetailData.totalAmount ? borrowerDetailData.totalAmount : null }</Descriptions.Item>
                            <Descriptions.Item label="还款方式">{ borrowerDetailData && borrowerDetailData.productRepayWayName ? borrowerDetailData.productRepayWayName : null }</Descriptions.Item>
                            <Descriptions.Item label="借款期限">{ borrowerDetailData && borrowerDetailData.productLoanPeriodName ? borrowerDetailData.productLoanPeriodName : null }</Descriptions.Item>
                        </Descriptions>
                        <p style={{height: 24}}></p>
                        <Descriptions title="借款方授信信息" bordered column={2} size="small">
                            <Descriptions.Item label="借款方名称">
                                <Form.Item style={{margin: 0}}>
                                    { borrowerDetailData && borrowerDetailData.borrowerName ? borrowerDetailData.borrowerName : '' }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="借款方授信额度（元）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('totalAmount', {
                                            initialValue: borrowerDetailData && borrowerDetailData.totalAmount !==null ? borrowerDetailData.totalAmount : null,
                                            rules: [
                                                { required: true, message: '请输入借款方授信额度' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                min={1}
                                                name='totalAmount'
                                                style={{width: '100%'}}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="产品利率（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerRate', {
                                            initialValue: borrowerDetailData && borrowerDetailData.borrowerRate !==null ? borrowerDetailData.borrowerRate : null,
                                            rules: [
                                                { required: true, message: '请输入产品利率' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                name='borrowerRate'
                                                max={borrowerDetailData && borrowerDetailData.productEndRate ? borrowerDetailData.productEndRate : null}
                                                min={borrowerDetailData && borrowerDetailData.productStartRate ? borrowerDetailData.productStartRate : 0} 
                                                style={{width: '100%'}} 
                                                placeholder={`不能超过该产品的利率范围${
                                                    borrowerDetailData && borrowerDetailData.productStartRate ? `${borrowerDetailData.productStartRate}%` : ''
                                                }${
                                                    borrowerDetailData && borrowerDetailData.productEndRate ? `-${borrowerDetailData.productEndRate}%` : ''
                                                }`}
                                            ></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="融资比例（%）">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerFinanceScale', {
                                            initialValue: borrowerDetailData && borrowerDetailData.borrowerFinanceScale !==null ? borrowerDetailData.borrowerFinanceScale : null,
                                            rules: [
                                                { required: true, message: '请输入融资比例' },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber 
                                                min={0} 
                                                max={borrowerDetailData && borrowerDetailData.productFinanceScale ? borrowerDetailData.productFinanceScale : null}
                                                name='borrowerFinanceScale' 
                                                style={{width: '100%'}} 
                                                placeholder={`不能超过该产品的融资比例${
                                                    borrowerDetailData && borrowerDetailData.productFinanceScale ? `${borrowerDetailData.productFinanceScale}%` : ''
                                                }`}
                                            ></InputNumber>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信起始日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerStartRate', {
                                            // initialValue: null,
                                            initialValue: borrowerDetailData && borrowerDetailData.borrowerStartRateName ? moment(borrowerDetailData.borrowerStartRateName, 'YYYY-MM-DD') : null,
                                            rules: [{ required: true, message: '请选择授信起始日' }]
                                        })(
                                            <DatePicker 
                                                disabled
                                                name='borrowerStartRate' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledStartDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信到期日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('borrowerEndRate', {
                                            // initialValue: null,
                                            initialValue: borrowerDetailData && borrowerDetailData.borrowerEndRateName ? moment(borrowerDetailData.borrowerEndRateName, 'YYYY-MM-DD') : null,
                                            rules: [{ required: true, message: '请选择授信到期日' }]
                                        })(
                                            <DatePicker 
                                                name='borrowerEndRate' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledEndDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            {
                                borrowerDetailData && borrowerDetailData.productRepayWay !== '1' && borrowerDetailData.productRepayWay
                                    ? <Descriptions.Item label="借款期限">
                                        <Form.Item style={{margin: 0}}>
                                            {
                                                getFieldDecorator('borrowerLoanPeriod', {
                                                    initialValue: borrowerDetailData && borrowerDetailData.borrowerLoanPeriod 
                                                    ? borrowerDetailData.borrowerLoanPeriod.split(',')
                                                    : [],
                                                    rules: [{ required: true, message: '请选择借款期限' }]
                                                })(
                                                    <Checkbox.Group name='borrowerLoanPeriod'>
                                                        <Row style={{width: 580}}>
                                                            {
                                                                loanTermEnumerateArr && loanTermEnumerateArr.length 
                                                                    ? loanTermEnumerateArr.map((item, index) => <Col span={4} key={index}><Checkbox value={item.dicKey}>{item.dicValue}</Checkbox></Col> )
                                                                    : null
                                                            }
                                                        </Row>
                                                    </Checkbox.Group>
                                                )
                                            }
                                        </Form.Item>
                                    </Descriptions.Item>
                                    : null 
                            }
                        </Descriptions>
                    </Form>
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleAddCredit}>确定</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default EditComp;

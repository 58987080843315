import React, { Component } from 'react';
import { withRouter } from 'dva/router';
import { Button } from 'antd';
import AuditModal from './auditModal'
@withRouter
class RenderData extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    goDetail = (id, wkId, nodeId)=>{
        this.props.history.push(`/admin/businessTicketManage/detail?id=${id}&audit=0`)
    }
    goAudit =(id, wkId, nodeId)=>{
        this.props.history.push(`/admin/businessTicketManage/detail?id=${id}&audit=1&wkId=${wkId}&nodeId=${nodeId}`)
    }
    render() { 
        const { data } = this.props
        return ( 
            <>
                <div>
                    <Button type="primary" style={{marginRight:'12px'}} onClick={()=>{
                        this.goDetail(data.id)
                    }}>详情</Button>
                    {
                        (()=>{
                            if(data.auditStatusCd==1 && data.sysOperationType==10){
                                return <Button type="primary" style={{border:'#0fd59d',background:'#0fd59d'}} onClick={()=>{
                                    this.goAudit(data.id, data.wkId , data.nodeId)
                                }}>审核</Button>
                            }else if(data.auditStatusCd==2||data.auditStatusCd==5){
                                return <AuditModal id={data.id} auditStatusCd={data.auditStatusCd}/>
                            }
                        })()
                    }
                    
                </div>
            </>
         );
    }
}
 
export default RenderData;
import React, { Component } from "react";
import { Form, Icon, Input, Button, InputNumber  } from "antd";
class MoneyCertifiCation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className="MCf-box">
                    <p>
                        材料已经提交成功，我平台会随后为您企业账户打款，收到打款后，请将金额输入下方验证框进行验证。
                    </p>
                    <h2>您的企业账户信息如下：</h2>
                    <div className='MCF-account'>
                        <span>账号:</span>
                        <span>2345346478569870989089</span>
                    </div>
                    <div className='MCF-account'>
                        <span>开户行:</span>
                        <span>中国银行</span>
                    </div>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator("username", {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入检验金额",
                                    },
                                ],
                            })(
                                <InputNumber 
                                    min={0.00001}
                                    max={1}
                                    placeholder="请输入检验金额"
                                    style={{marginRight:'20px'}}
                                />
                            )}
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                核验金额
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}
const MoneyCertifiCationFrom = Form.create()(MoneyCertifiCation);
export default MoneyCertifiCationFrom;

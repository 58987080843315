import React, {Component} from 'react';
import {Radio, Form, Input, message, Modal, Row, Col, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";

const FormItem = Form.Item;
const Option = Select.Option;

class addOrEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            menuType: "0",
            isRedirect: "",  //0不跳转 1-跳转
            fatherMenu: []
        }
    }

    componentDidMount() {
        let {modalStatus, menuId, systemId} = this.props;
        if (modalStatus === 1) {
            this.getParentMenu(systemId);
        } else if (modalStatus === 2) {
            this.getParentMenu(systemId);
            scfBaseApi.menuQueryApi({id: menuId}).then(res => {
                let data = res.data;
                let {menuName, menuSort, menuType, isRedirect, menuUrl, parentId} = data;
                this.setState({menuType, isRedirect});
                this.props.form.setFieldsValue({menuName, menuSort, menuType, isRedirect});
                setTimeout(() => {
                    if (parentId !== 0) {
                        this.props.form.setFieldsValue({parentId});
                    }
                    if (isRedirect === 1) {
                        this.props.form.setFieldsValue({menuUrl});
                    }
                }, 1000)
            }).catch(err => {
                message.error(err.message);
            })
        } else {
            this.setState({menuType: "1", isRedirect: 1});
            this.props.form.setFieldsValue({isRedirect: 1});
        }

    }

    //获取父及菜单
    getParentMenu = (id) => {
        scfBaseApi.queryParentMenuApi({id}).then(res => {
            let data = res.data;
            this.setState({fatherMenu: data});
        }).catch(err => {
            message.error(err.message);
        })
    }

    handleOk = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {modalStatus, menuId, systemId} = this.props;
                let {menuType} = this.state;
                let apiName = "";
                if (modalStatus === 2) {
                    values.id = menuId;
                    values.menuType = menuType;
                    apiName = "menuUpdateApi";
                } else if (modalStatus === 1) {
                    values.systemId = systemId;
                    apiName = "menuAddApi";
                } else {
                    values.parentId = menuId;
                    values.systemId = systemId;
                    apiName = "menuAddChildrenApi";
                    values.menuType = "1";
                }
                scfBaseApi[apiName](values).then(res => {
                    message.success(res.message);
                    this.setState({
                        isShowModal: false
                    });
                    this.props.operationOk();
                }).catch(err => {
                    message.error(err.message);
                })

            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        }, () => {
            this.props.operationCancel();
        });
    }
    changeJump = (e) => {
        this.setState({
            isRedirect: e.target.value
        })
    }
    changeMenuType = (e) => {
        this.setState({
            menuType: e.target.value
        })
        this.props.form.setFieldsValue({isRedirect: ""});
    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {modalStatus, parentMenu, title} = this.props;
        let {visible, isRedirect, menuType, fatherMenu} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 2, span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 15}
            },
            labelAlign: 'right'
        };
        let getMenuTypeDom = () => {
            if (modalStatus === 1) {
                return <Row>
                    <Col span={12}>
                        <FormItem label="菜单类型">
                            {
                                getFieldDecorator('menuType', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择菜单类型'
                                        },
                                    ],
                                    initialValue: "",
                                    validateTrigger: "onBlur"
                                })(<Radio.Group onChange={this.changeMenuType}>
                                    <Radio value="0">父级菜单</Radio>
                                    <Radio value="1">叶子菜单</Radio>
                                </Radio.Group>)
                            }
                        </FormItem>
                    </Col>
                    {menuType === "1" ? <Col span={12}>
                        <FormItem label="父级菜单">
                            {
                                getFieldDecorator('parentId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择父级菜单'
                                        },
                                    ],
                                    initialValue: "",
                                    validateTrigger: "onBlur"
                                })(<Select>
                                    {
                                        fatherMenu.length !== 0 ? fatherMenu.map(record => {
                                            return <Option key={record.id} value={record.id}>{record.menuName} </Option>
                                        }) : null
                                    }
                                </Select>)
                            }
                        </FormItem>
                    </Col> : null
                    }

                </Row>
            } else if (modalStatus === 2) {
                return <Row>
                    <Col span={12}>
                        <FormItem label="菜单类型">
                            <span>{menuType === "0" ? "父级菜单" : "叶子菜单"}</span>
                        </FormItem>
                    </Col>
                    {menuType === "1" ? <Col span={12}>
                        <FormItem label="父级菜单">
                            {
                                getFieldDecorator('parentId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择父级菜单'
                                        },
                                    ],
                                    initialValue: "",
                                    validateTrigger: "onBlur"
                                })(<Select>
                                    <Option key={0} value="">请选择</Option>
                                    {
                                        fatherMenu.length !== 0 ? fatherMenu.map(record => {
                                            return <Option key={record.id}
                                                           value={record.id}> {record.menuName} </Option>
                                        }) : null
                                    }
                                </Select>)
                            }
                        </FormItem>
                    </Col> : null
                    }
                </Row>
            } else if (modalStatus === 3) {
                return <Row>
                    <Col span={12}>
                        <FormItem label="菜单类型">
                            <span>叶子菜单</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="父级菜单">
                            <span>{parentMenu}</span>
                        </FormItem>
                    </Col>
                </Row>
            }

        }
        return (
            <Modal
                title={title}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                maskClosable={false}
                width={800}
            >
                <Form onSubmit={this.handleOk} {...formRowItem}>
                    <Row>
                        <Col span={12}>
                            <FormItem label="菜单名称">
                                {
                                    getFieldDecorator('menuName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入菜单名称'
                                            },
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            }
                                        ],
                                        validateTrigger: "onBlur",
                                        initialValue: ""
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="菜单索引">
                                {
                                    getFieldDecorator('menuSort', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入菜单索引'
                                            },
                                        ],
                                        initialValue: "",
                                        validateTrigger: "onBlur"
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    {
                        getMenuTypeDom()
                    }
                    <Row>
                        <Col span={12}>
                            {
                                menuType === "0" ? <FormItem label="是否跳转">
                                    {
                                        getFieldDecorator('isRedirect', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择是否跳转'
                                                },
                                            ],
                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Radio.Group onChange={this.changeJump}>
                                            <Radio value={0}>否</Radio>
                                            <Radio value={1}>是</Radio>
                                        </Radio.Group>)
                                    }
                                </FormItem> : <FormItem label="是否跳转">
                                    {
                                        getFieldDecorator('isRedirect', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择是否跳转'
                                                },
                                            ],
                                            validateTrigger: "onBlur",
                                            initialValue: ""
                                        })(<Radio.Group onChange={this.changeJump}>
                                            <Radio value={1}>是</Radio>
                                        </Radio.Group>)
                                    }
                                </FormItem>
                            }

                        </Col>
                        {isRedirect === 1 ? <Col span={12}>
                            <FormItem label="跳转路径">
                                {
                                    getFieldDecorator('menuUrl', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入跳转路径'
                                            },
                                        ],
                                        initialValue: "",
                                        validateTrigger: "onBlur"
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col> : null
                        }
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(addOrEditModal);
import React, { Component } from "react";
import { Row, Col } from "antd";
class AuditBaseInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getDes = (params,Des)=>{
        if(params!=undefined && params.indexOf('其他')!=-1){
            return params.replace('其他','') + Des
        }else{
            return params
        }
    }
    render() {
        const { data } = this.props
        return (
            <>
                <div className="abi-Table">
                    <h1>申请人基本信息</h1>
                    <div className='abi-head'>
                        <span>企业名称:{data.enterpriseName}</span>
                        <span style={{float:'right'}}>联系人电话:{data.contactMobile}</span>
                    </div>
                    <div  className='abi-head'>
                        <span>申请日期:{data.auditTime}</span>
                    </div>
                    <table border="1">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>企业性质</td>
                                <td>{data.enterpriseNatureCdDesc=='其他'?data.enterpriseNatureDes:data.enterpriseNatureCdDesc}</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>企业最新评级</td>
                                <td>{data.enterpriseRateCdDesc=='其他'?data.enterpriseRateDes:data.enterpriseRateCdDesc}</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>评级机构</td>
                                <td>{data.ratingAgenciesCdDesc=='其他'?data.ratingAgenciesDes:data.ratingAgenciesCdDesc}</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>所属行业</td>
                                <td>{data.industryCdDesc=='其他'?data.industryDes:data.industryCdDesc}</td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <td>主营业务说明</td>
                                <td>{data.coreBusiness}</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>年营业额</td>
                                <td>{data.businessScaleCdDesc=='具体规模'?data.businessScaleDes:data.businessScaleCdDesc}</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>净利润规模</td>
                                <td>{data.netProfitScaleCdDesc=='具体规模'?data.profitScaleDes:data.netProfitScaleCdDesc}</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>负债规模</td>
                                <td>{data.debtScaleCdDesc=='具体规模'?data.debtScaleDes:data.debtScaleCdDesc}</td>
                            </tr>

                            <tr>
                                <td>9</td>
                                <td>应付账款规模</td>
                                <td>{data.accountsPayableScaleCdDesc=='具体规模'?data.accountsPayableDes:data.accountsPayableScaleCdDesc}</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>主要付款方式</td>
                                <td>{data.typePaymentCdDesc=='其他'?data.typePaymentDes:data.typePaymentCdDesc}</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>应付账款账期分布</td>
                                <td>{data.accountPeriodCdDesc}</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>主要融资方式</td>
                                <td>{this.getDes(data.financingModeCdDesc,data.financingModeDes)}</td>
                            </tr>

                            <tr>
                                <td>13</td>
                                <td>总和融资成本</td>
                                <td>{data.financeCostsCdDesc}</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>核心供应商企业性质</td>
                                <td>{this.getDes(data.coreEnterpriseTypeCdDesc,data.coreEnterpriseDes)}</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>对商票了解程度</td>
                                <td>{data.underLevelCdDesc}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default AuditBaseInfo;

import React, { Component } from 'react';
import { Button } from 'antd';
import Img from '../../assets/img/notfond.png';
import './index.scss'

let timer

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            count:3
        }
    }
    componentDidMount(){
        this.getCunt()
    }
    getCunt = ()=>{
        timer = setInterval(()=>{
            if(this.state.count==0){
                clearInterval(timer)
                this.props.history.push('/admin/homeClient');
            }else{
                this.setState({
                    count:this.state.count-1
                })
            }
        },1000)
    }
    render() { 
        const { count } = this.state
        return ( 
            <>
                <div className='notfond-box'>
                    <img src={Img} />
                    <div className='notfond-sorry'> 抱歉，您找的页面不存在 </div>
                    <div className='notfond-box-bold'><span> { count } 秒 </span>后跳转到首页</div>
                </div>
            </>
         );
    }
}
 
export default ErrorPage;
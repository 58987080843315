import React, {Component} from 'react';
import {Form, Input, message,Modal,Select,TreeSelect,Button,Table} from "antd";
import {checkPhone} from "../../../utils/formatCheck";
import scfBaseApi from "../../../request/api/scfBaseApi";
import AddCompanyModal from "./addCompanyModal"
const FormItem = Form.Item;
const Option = Select.Option;
const {SHOW_CHILD} = TreeSelect;
class DetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            companyList:[],  //企业列表
            departList:[],  //部门列表
            roleList:[],
            systemArr:[],
            systemType:"1",  //1-客户端  2-运营端,
            userMobile:"",
            userEmail:"",
            userName:"",
            orgName:"",
            tableData:[],
            orgDepartIds:[],
            roles:[],
            addCompanyModal:false,//添加企业弹框
            systemId:"",
            id:"",
        }
    }

    componentDidMount() {
    let {tableData,orgDepartIds,roles} = this.state
      let{ userId, systemId }=this.props;
          scfBaseApi.userQueryApi({id:userId,systemId:systemId}).then(res => {
              let data = res.data;
              let {userMobile, systemId, id, userName, userEmail}=data;;
             
              data.orgList.map(item=>{
                let departNameArr = item.departs.map(val=> val.departName);
                let departName = departNameArr.join();

                let roleNameArr = item.roleOrgList.map(val => val.roleName)
                let roleOrgList = roleNameArr.join()
                    tableData.push({
                        systemName: data.systemName,
                        orgName: item.orgName,
                        departs: departName,
                        roleOrgList:roleOrgList
                    })
            })
            this.setState({
                tableData,
                userMobile,
                userEmail,
                userName
            })
          
          }).catch(err => {
              message.error(err.message);
          })

    }


    handleCancel = () => {
       this.props.detailOk()
    }



    render() {
        let {tableData} = this.state;
        let {visible,userEmail,userName,departList,userMobile} = this.state;
        let newData=JSON.parse(JSON.stringify(departList).replace(/id/g, 'value').
            replace(/departName/g, 'title')
        )
        let formItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16}
            },
            labelAlign: 'right'
        };
        let columns = [
            
            {
                title: '所属企业名称',
                dataIndex: 'orgName',
                key: 'orgName',
            
            },
            {
                title: '所属企业部门',
                dataIndex: 'departs',
                key: 'departs',
             
            },
            {
                title: '所属系统',
                dataIndex: 'systemName',
                key: 'systemName',
              
            },
            {
                title: '角色',
                dataIndex: 'roleOrgList',
                key: 'roleOrgList',
               
            },
        ];
        let tProps={
            treeData:newData,
            onChange:this.onDepartChange,
            treeCheckable:true,
            listHeight:80,
            showCheckedStrategy:SHOW_CHILD,
            style: {width: '100%',},
        }
        return (
            <div>
           
            <Modal
                title="详情"
                visible={visible}
                width = "800px"
                footer={false}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={this.handleCancel}
               
            >
                <Form  {...formItem}>
                  <FormItem label="登录账号(手机号)">{userMobile}</FormItem>
                  <FormItem label="用户姓名">{userName}</FormItem>
                  <FormItem label="邮箱">{userEmail}</FormItem>
                  
                </Form>
                 <div>
               
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => index}
                    pagination={false}
                />
                 </div>
                
                
                   
                   <div style={{marginTop: 30, marginBottom: 30,marginLeft: 300}}>
                       <Button  className="com-middle-btn-size" onClick={this.handleCancel}>返回</Button>
                   </div>
            
            </Modal>
         
               
            
        </div>
        );
    }
}

export default Form.create()(DetailModal);
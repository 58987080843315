import React, { Component } from 'react';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table, message } from "antd";
import { withRouter } from 'dva/router';
import { debounce } from '@/utils/tools';
import IssueTable from '../../businessTicketIssue/component/issueTableList'
import { getQuotaStatusApi , getCreditListApi , queryAllExportCurrentExcelApi } from '../../../request/api/quota'
import moment from 'moment';
import ExportJsonExcel from "js-export-excel";
const { Option } = Select;
const { RangePicker } = DatePicker;

@withRouter

class BusinessPublish extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalCount: 0,
            current: 1,
            pageSize: 10,
            data:{},
            creditStateList:[],
            ExcelData:[]
         }
    }
    componentDidMount () {
        this.getQuotaStatus()
        this.getExportCurrentExcel()
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
    }
    getQuotaStatus = ()=>{
        getQuotaStatusApi({dicType:'ticketStatusCd'}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    creditStateList:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getExportCurrentExcel = ()=>{
        queryAllExportCurrentExcelApi({type:1}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    ExcelData:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
      //导出excel
      handleExportCurrentExcel = (ExcelData) => {
            let sheetFilter = ["ticketNo", "ticketAmount", "ticketDate", "ticketExpireDate", "ticketStatusCd","drawerName","drawerAccountNo", "drawerBank", "payeeName", "payeeAccountNo", "payeeBank","acceptorName", "acceptorAccountNo","acceptorBank"];
            let option = {};
            option.fileName = '商票发行管理';
            option.datas = [
                {
                    sheetData: ExcelData,
                    sheetName: '商票发行管理',
                    sheetFilter: sheetFilter,
                    sheetHeader: ['票据号码', '票据金额（元）', '出票日期', '汇票到期日期', '票据状态','出票人名称','出票账号', '出票开户行', '收款人名称', '收款账号', '收款开户行','承兑人名称', '承兑人账号','承兑人开户行'],
                    columnWidths: [10,10,10,10,10,10,10,10,10,10,10,10,10,10]
                },
            ];
    
            var toExcel = new ExportJsonExcel(option); //new
            toExcel.saveExcel(); //保存
        }
    getCreditListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if(values.dates && values.dates.length) {
                values.ticketStartTime = moment(values.dates[0]).format('YYYY-MM-DD');
                values.ticketEndTime = moment(values.dates[1]).format('YYYY-MM-DD');
                delete values.dates;
            }
            if(values.Tickdates && values.Tickdates.length) {
                values.ticketExpireStartTime = moment(values.Tickdates[0]).format('YYYY-MM-DD');
                values.ticketExpireEndTime = moment(values.Tickdates[1]).format('YYYY-MM-DD');
                delete values.Tickdates
            }
            values.pageNum = pagination.current
            values.pageSize = pagination.pageSize
            // if(this.props.match.url=='/admin/businessTicketManage/payMoney'){
            //     values.type = 2
            // }else{
            //     values.type = 1
            // }
            
            getCreditListApi(values).then(res => {
                if(res.code=='2000'){
                    this.setState({
                        data:res.data
                    })
                }else{
                    message.info(res.message)
                }
            }).catch(err => {
                message.error(err.message);
            })
        })
    }
    handleCurrentChange = (pagination) => {
        this.getCreditListData(pagination)
    }
    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    handleFormSearch = debounce(() => {
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    goAddRouter = ()=>{
        this.props.history.push(`/admin/businessTicketManage/add`)
    }
    render() { 
        const { getFieldDecorator } = this.props.form;
        const { creditStateList , data , ExcelData } = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        return ( 
            <>
            <Form {...formItemLayout} style={{margin:"20px 0 20px 0"}}>
                <Row>
                    <Col span={8}>
                        <Form.Item label="票据号码">
                            {
                                getFieldDecorator('ticketNo', {})(
                                    <Input name='ticketNo' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="出票日期">
                            {
                                getFieldDecorator('dates', {})(
                                    <RangePicker name='dates' style={{width: '100%'}} suffixIcon=' ' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="汇票到期日期">
                            {
                                getFieldDecorator('Tickdates', {})(
                                    <RangePicker name='Tickdates' style={{width: '100%'}} suffixIcon=' ' />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label="出票人">
                            {
                                getFieldDecorator('drawerName', {})(
                                    <Input name='drawerName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="收款人">
                            {
                                getFieldDecorator('payeeName', {})(
                                    <Input name='payeeName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="承兑人">
                            {
                                getFieldDecorator('acceptorName', {})(
                                    <Input name='acceptorName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label="票据状态">
                            {
                                getFieldDecorator('ticketStatusCd', {})(
                                    <Select name='ticketStatusCd'>
                                        <Option value=''>全部</Option>
                                        {
                                            creditStateList && creditStateList.length 
                                            ? creditStateList.map((item, index)=> <Option key={item.id} value={item.dicKey}>{item.dicValue}</Option>)
                                            : null
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <div className='wraper-cd-right'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Row>
            </Form>
            <div className='excel-box'>   
                <Button type='primary' onClick={()=>{
                    this.handleExportCurrentExcel(ExcelData)
                }}>导出全部</Button>
                <Button type='primary' onClick={()=>{
                    this.handleExportCurrentExcel(data.list)
                }}>导出当前页面</Button>
            </div>
            <IssueTable data={data} handleCurrentChange={this.handleCurrentChange} creditStateList = {creditStateList}/>
            </>
         );
    }
}
const BusinessPublishForm = Form.create()(BusinessPublish);
export default BusinessPublishForm;
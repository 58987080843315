import React, {Component} from 'react';
import {Upload, Icon, message} from 'antd';
import RcViewer from "@hanyk/rc-viewer";
import {unCrudeHttp} from '../../request/http';
import scfBaseApi from "../../request/api/scfBaseApi";
import scfBusinessApi from "../../request/api/scfBusinessApi";
import './index.scss';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewImage: '',
            fileSuccess: false,  //是否已经上传一个,
            preview: '',
            hidePreview: 'none',
            loading: false,      //是否正在上传
        }
    }

    componentDidMount() {
        const {preview = null} = this.props;
        this.getPreviewImg(preview);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {preview = null} = nextProps;
        if(this.props.preview!==preview){
            this.getPreviewImg(preview);
        }
    }

    //判断是否初始化及图片预览
    getPreviewImg = (preview) => {
        if (preview != null && preview !== ""){
            scfBusinessApi.fileDownloadApi(preview).then(res => {
                let preview = `data:image/jpg/png/jpeg/pdf;base64,${res.data}`;
                this.setState({
                    preview: preview,
                    hidePreview: 'block',
                    url: preview
                })
            }).catch(err => {
                if(err.code=='0001'){
                    console.log('图片未上传');
                }else{
                    message.error(err.message);
                }
            })
        } else {
            this.setState({
                preview: '',
                hidePreview: 'none',
                url: preview
            })
        }

    };


    handlePreview = async file => {
        const {preview} = this.state;
        if (file.originFileObj !== undefined) {
            file.preview = await getBase64(file.originFileObj);
            this.setState({
                previewImage: file.url || file.preview,
            }, () => {
                this.viewerImg.click();
            });
        } else {
            this.setState({
                previewImage: preview,
            }, () => {
                this.viewerImg.click();
            });
        }
    };
    //获取ocr识别信息
    getFileOcrInfo = (data) => {
        let {action,ocr} = this.props;
        if(ocr && ocr == 'false'){
            const {field, index = null} = this.props;
            this.props.handleFileChange(field, data, index);
        }else{
            let {relativePath, newFileName, originalFileName, resourceFileId} = data;
            let params = {relativePath, newFileName, originalFileName, resourceFileId};
            console.log(params)
            scfBaseApi[action](params).then(res => {
                const {field, index = null} = this.props;
                this.props.handleFileChange(field, res.data, index);
            }).catch(err => {
                message.error(err.message);
            })

        }
    }
    handleChange = ({fileList, file}) => {
        console.log(fileList)
        this.setState({loading: true}, () => {
            if (fileList.length > 0 && fileList[0].response !== undefined && fileList[0].response.data !== undefined) {
                let response = fileList[0].response;
                    if (response.code === "2000") {
                        message.success(response.message);
                    this.setState({fileSuccess: true, loading:true});
                    this.preview['src'] = '';
                    this.getFileOcrInfo(response.data);
                } else if (fileList[0].response.code === "3001020114"){
                    localStorage.setItem('tokens', fileList[0].response.data.token);
                    let url = `${window.API_HOST}/business/resourcefile/upload`;
                    let {file} = this.state;
                    let formData = new FormData();
                    formData.append('file', file);
                    unCrudeHttp.request({
                        url: url,  //上传地址
                        method: 'post',
                        processData: false,  //必须false才会避开 formdata 的默认处理
                        contentType: false, //必须false才会自动加上正确的Content-Type
                        data: {formData,fileType:this.props.fileType},
                    }).then(res => {
                        if (res.data.code === "2000") {
                            message.success(res.data.message);
                            this.setState({fileSuccess: true, loading:true});
                            this.preview['src'] = '';
                            this.getFileOcrInfo(res.data.data);
                        } else {
                            message.error(res.data.message)
                        }
                    }).catch(err => {
                        message.error(err.message)
                    })
                } else if (fileList[0].response.code === "3001020111" || fileList[0].response.code ==="3001020112" || fileList[0].response.code ==="3001020113") {
                    localStorage.clear();
                    message.error(fileList[0].response.message);
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 1000);
                } else {
                    //上传失败
                    message.error(fileList[0].response.message);
                    fileList.length = 0;
                    this.handleRemove();
                    this.setState({fileSuccess: false, loading: false});
                }
            } else if(fileList.length > 0 && fileList[0].response !== undefined && fileList[0].response.data == undefined){
                message.error("文件上传失败！")
                this.setState({fileSuccess: false, loading: false});
            } if (fileList.length === 0) {
                this.setState({fileSuccess: false, loading: false});
            }
        });
    };

    //隐藏 初始化预览
    hidePreview = () => {
        const {url} = this.state;
        let obj = {
            response: {
                data: {
                    url: url
                }
            }
        };
        this.handleRemove(obj);
    };
    //移除
    handleRemove = (file) => {
        const {field, index = null} = this.props;
        if (file !== undefined && file.response !== undefined) {
            let data = file.response.data;
            scfBusinessApi.fileDeleteApi({resourceFileId: data.resourceFileId}).then(res => {
                message.success(res.message);
            }).catch(err => {
                message.error(err.message);
            })
            this.props.handleRemoveFile(field, data, index);
            setTimeout(() => {
                this.setState({
                    hidePreview: 'none',
                })
            }, 100)
        } else {
            return true;
        }
    };

    
    beforeUpload = (file, list) => {
        //每次重新上传 则清空掉 上一次上传的数据
        this.setState({file: ""}, () => {
            this.setState({file: file});
        });
    };
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return null;
        };
    }

    render() {
        const {uploadText = '', accept = ".jpg, .jpeg, .png, .gif",uploadDescripte} = this.props;
        const {previewImage, fileSuccess, preview, hidePreview, loading} = this.state;
        //isFront 发布票据 正面票据 则需要传递 isFront = true  其他上传 则不需要
        let options = {
            navbar: false,       //关闭缩略图
            fullscreen: false,   //播放全屏
            loop: false,         //是否循环 上一个 下一个
            toolbar: {
                zoomIn: {size: 'large'},  //放大
                zoomOut: {size: 'large'}, //缩小
                reset: {size: 'large'},   //重置
                prev: {show: false, size: 'large',}, //上一张
                play: {show: false, size: 'large',},  //播放
                next: {show: false, size: 'large',},  //下一张
                rotateLeft: {size: 'large'},  //左旋转
                rotateRight: {size: 'large'}, //右旋转
                flipHorizontal: {size: 'large'},  //左右翻转
                flipVertical: {size: 'large'},    //上下翻转
            }
        };
        return (
            <span
                className={uploadDescripte != undefined ? "com-fl com-hand com-pr big-upload-box" : "com-fl com-hand com-pr norm-upload-box"}
                style={{marginRight: 15}}
                id="uploadFile">
                {/*初始化是否有图像显示*/}
                <div style={{display: hidePreview}} className="diy-img-preview com-pa com-text-center">
                    <div className="img-preview-bg com-pa">
                        <Icon onClick={this.handlePreview} type="eye" className="see com-fl"/>
                        {/*<Icon onClick={this.hidePreview} type="delete" className="see com-fl"/>*/}
                    </div>
                    <img ref={ref => this.preview = ref} className="preview-img" src={preview} alt=""/>
                </div>
                {/*上传成功后显示的文件名称*/}
                {uploadDescripte === undefined && fileSuccess ? <div className="upload-success-text com-pa">{uploadText}</div> : null}
                <Upload
                    name="file"
                    className="com-pr avatar-uploader"
                    action={`${window.API_HOST}/business/resourcefile/upload`}
                    // action={`/ap2/business/resourcefile/upload`}
                    data={{fileType:this.props.fileType}}
                    headers={{Authorization:localStorage.getItem('tokens')}}
                    listType="picture-card"
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    accept={accept}
                    onRemove={this.handleRemove}
                    beforeUpload={this.beforeUpload}
                >
                    {
                        loading ? ''
                            : <div>
                                <Icon type="cloud-upload"
                                      style={{color: "#1890ff", fontSize: uploadDescripte === undefined ? 26 : 76}}/>
                                <div className="com-text-center"
                                     style={{fontSize: uploadDescripte === undefined ? 12 : 16}}><p>请点击上传图片</p></div>
                                {uploadDescripte === undefined ?
                                    <div className="ant-upload-text upload-text com-pa">{uploadText}</div> : null}
                            </div>
                    }
                </Upload>
                <RcViewer style={{display: 'none'}} options={options}>
                    <img ref={(ref) => this.viewerImg = ref} alt="example" style={{width: '100%'}} src={previewImage}/>
                </RcViewer>
            </span>
        )
    }
}

export default FileUpload;
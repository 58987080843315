import React, { Component } from 'react'
import echarts from 'echarts';
import 'echarts/map/js/china';
import AMapJS from "amap-js";
import "../index.scss"
// import { AMapLoader, AMapUILoader, LocaLoader } from 'amap-js';
// import geoJson from 'echarts/map/json/china.json';
// import Script from 'react-load-script';


import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message } from 'antd';



export default class MapMain extends Component {
   
    constructor(props) {
        super(props)

        this.state = {
            geoJson: {
                features: []
            },

            parentInfo: [{
                cityName: '全国',
                level: 'china',
                code: 100000
            }],
            intervalId:"",
            saveJson:''
         


        }
    }
    back = () => {
       
    let {parentInfo} = this.state
    // if (parentInfo.length === 1) {
    //     return;
    // }
    if (parentInfo.length === 1) {
           var intervalId = setInterval(() => {
          
            this.getGeoJson('全国','china',100000)
        }, 7200000);
        this.setState({
            intervalId,
        })
    }else{
        parentInfo.pop()
        this.getGeoJson(parentInfo[parentInfo.length - 1].cityName,parentInfo[parentInfo.length - 1].level,parentInfo[parentInfo.length - 1].code)
    }
    // this.getGeoJson(parentInfo[parentInfo.length - 1].cityName,parentInfo[parentInfo.length - 1].level,parentInfo[parentInfo.length - 1].code)
   

    }
    componentDidMount() {
        this.getGeoJson('全国','china',100000)
        // const myChart = echarts.init(document.getElementById('MapMain'));
        // this.getMapData()
        // myChart.showLoading();
        // let index = -1;
        // echarts.registerMap('zhongguo', geoJson);
        // echarts.registerMap('china', geoJson);
        // myChart.hideLoading();   

        var intervalId = setInterval(() => {
           
            this.getGeoJson('全国','china',100000)
        }, 3600000);
        this.setState({
            intervalId,
        })


    }


    getGeoJson = (name,level,adcode) => {
     
        let _this = this
        const loader = new AMapJS.AMapLoader({
            key: 'ec3c3c23479f970ca193fac6bb7ce089f',
            version: '1.4.15',
            plugins: []
        });

        const aMapUILoader = new AMapJS.AMapUILoader({
            v: "1.0" // UI组件库版本号
        });

        loader.load().then(AMap => {
           
            aMapUILoader.load().then(initAMapUI => {
             
                initAMapUI.loadUI(['geo/DistrictExplorer'])
                    .then(([DistrictExplorer]) => {
                      
                        var districtExplorer = new DistrictExplorer()
                        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
                            if (error) {
                                return;
                            }
                            let Json = areaNode.getSubFeatures()
                           
                          
                            if (Json.length > 0) {
                                _this.setState({
                                    saveJson:Json
                                })
                                setTimeout(() => {
                                    _this.getMapData(Json,name,level,adcode)
                                }, 500)
                            }
                            else if (Json.length === 0) {
                                Json = _this.state.saveJson.filter(item => item.properties.adcode == adcode)
                                if (Json.length === 0) {
                                    return
                                }
                            //    console.log(_this.state.parentInfo)
                            //     return

                                setTimeout(() => {
                                    _this.getMapData(Json,name,level,adcode)
                                }, 500)
                            }

                        });
                    }).catch(e => { });
            });
        })
    }

    //获取数据    拼接，获取近五年的数据
    getMapData = (Json,name,level,adcode) => {
      
        let mapData = []
        let pointData = []
        let arr = []


        // if(adcode===100000){
            let params = {
                level: level,
                name:name,
                code:adcode
            }
            scfBusinessApi.companyCountsApi(params).then(res=>{
                arr = res.data
                // if(arr.length===0){return}
                arr.map(item=>{
                    mapData.push({
                            name: item.name,
                            value: item.count,
                            level: item.level,
                            cityCode: item.cityCode
                    })
                 })
                 Json.map(item=>{
                    mapData.map(val=>{
                     if(item.properties.adcode==val.cityCode){
                      
                        pointData.push({
                            name: val.name,
                            value: [item.properties.center[0], item.properties.center[1], val.value],
                            cityCode: val.cityCode
                        })
                     }
                    })
                 })
                 this.initEchartMap(Json, mapData, pointData)
            }).catch(err=>{
                message.error(err.message)
            })
        // }
        // else{
        //     let params = {
        //         level: level,
        //         name:name,
        //         code:adcode
        //     }
        //     scfBusinessApi.companyCountsApi(params).then(res=>{
        //         arr = res.data

        //         arr.map(item=>{
        //             mapData.push({
        //                     name: item.name,
        //                     value: item.count,
        //                     level: item.level,
        //                     cityCode: item.cityCode
        //             })
        //          })
        //          Json.map(item=>{
        //             mapData.map(val=>{
        //              if(item.properties.adcode==val.cityCode){
        //                 pointData.push({
        //                     name: val.name,
        //                     value: [item.properties.center[0], item.properties.center[1], val.value],
        //                     cityCode: val.cityCode
        //                 })
        //              }
        //             })
        //          })
        //          this.initEchartMap(Json, mapData, pointData)
        //     }).catch(err=>{
        //         message.error(err.message)
        //     })
        // }
    }


    //渲染echarts
    initEchartMap = (Json, mapData, pointData) => {
      
       
        let _this = this
        let obj = {}
        obj.features = Json
        
     
        const myChart = echarts.init(document.getElementById('MapMain'));
        //这里做个切换，全国的时候才显示南海诸岛  只有当注册的名字为china的时候才会显示南海诸岛
        if (this.state.parentInfo.length === 1) {
            echarts.registerMap('china', obj); //注册
        } else {
            echarts.registerMap('map', obj); //注册
        }
        var option = {
            timeline: {
                show: false,
            },
            baseOption: {
                animation: false,
                tooltip: {
                    trigger: 'item',
                },
                grid: {
                    right: '2%',
                    top: '12%',
                    bottom: '8%',
                    width: '20%'
                },
                geo: {//地理坐标系组件。 地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
                    map: this.state.parentInfo.length === 1 ? 'china' : 'map',//地图类型。
                    zoom: 1.1, //当前视角的缩放比例。
                    roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
                    center: this.state.parentInfo.length === 1 ? ['118.83531246', '32.0267395887'] : false,//当前视角的中心点，用经纬度表示
                    tooltip: {
                        trigger: 'item',
                        formatter: (p) => {
                            let val = p.value[2];
                            if (window.isNaN(val)) {
                                val = 0;
                            }
                            let txtCon =
                                "<div style='text-align:left'>" + p.name + ":<br />用户量：" + val + '个</div>';
                            return txtCon;
                        }
                    },
                    label: {  //图形上的文本标签，可用于说明图形的一些数据信息
                        normal: {
                            show: true,
                            color: "rgb(249, 249, 249)", //省份标签字体颜色
                            formatter: p => {
                                switch (p.name) {
                                    case '内蒙古自治区':
                                        p.name = "内蒙古"
                                        break;
                                    case '西藏自治区':
                                        p.name = "西藏"
                                        break;
                                    case '新疆维吾尔自治区':
                                        p.name = "新疆"
                                        break;
                                    case '宁夏回族自治区':
                                        p.name = "宁夏"
                                        break;
                                    case '广西壮族自治区':
                                        p.name = "广西"
                                        break;
                                    case '香港特别行政区':
                                        p.name = "香港"
                                        break;
                                    case '澳门特别行政区':
                                        p.name = "澳门"
                                        break;
                                    default:
                                        break;
                                }
                                return p.name;
                            }
                        },
                        emphasis: {  //高亮状态下的多边形和标签样式。
                            show: true,
                            color: '#f75a00',
                        }
                    },
                    itemStyle: { //地图区域的多边形 图形样式。
                        normal: {
                            areaColor: '#24CFF4',
                            borderColor: '#53D9FF',
                            borderWidth: 1.3,
                            shadowBlur: 15,
                            shadowColor: 'rgb(58,115,192)',
                            shadowOffsetX: 7,
                            shadowOffsetY: 6,
                        },
                        emphasis: {  //高亮状态下的多边形和标签样式。
                            areaColor: '#8dd7fc',
                            borderWidth: 1.6,
                            shadowBlur: 25,
                        }

                    },
                },

            },
            options: []
        }

       
            var xData = [], yData = []
            var min = mapData[mapData.length - 1].value
            var max = mapData[0].value
            if (mapData.length === 1) {
                min = 0
            }
            if(min==max){
                min = 0
            }
            mapData.forEach(c => {
                xData.unshift(c.name)
                yData.unshift(c.value)
           
            option.options.push({
                // backgroundColor: '#012248',
                // backgroundColor: '#1447b5',
                title: [
                    {
                    left: 'center',
                    top: 10,
                    text: '用户分布图',
                    textStyle: {
                        // color: 'rgb(179, 239, 255)',
                        color:'#fff',
                        fontSize: 16
                    },
                },
                // {
                //     text: "销售总额：" + sum[item].toFixed(2) + '万',
                //     left: 'center',
                //     top: '6.5%',
                //     textStyle: {
                //         color: '#FFAC50',
                //         fontSize: 26
                //     }
                // }
                ],
                visualMap: {
                    min: min,
                    max: max,
                    left: '3%',
                    bottom: '5%',
                    calculable: true,
                    seriesIndex: [0],
                    inRange: {
                        color: ['#24CFF4', '#2E98CA', '#1E62AC']
                       
                    },
                    textStyle: {
                        color: '#24CFF4'
                    }
                },
                xAxis: {
                    type: 'value',
                    scale: true,
                    position: 'top',
                    boundaryGap: false,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#455B77'
                        }
                    },
                    axisTick: { 
                        show: false  //是否显示坐标轴刻度。
                    },
                    axisLabel: {
                        margin: 2,
                        textStyle: {
                            color: '#c0e6f9'
                        }
                    },
                },
                yAxis: {
                    type: 'category',
                    nameGap: 16,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#455B77'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#c0e6f9'
                        }
                    },
                    data: xData
                },
                series: [
                    {
                    // name: item + '销售额度',
                    type: 'map',
                    geoIndex: 0,
                    map: this.state.parentInfo.length === 1 ? 'china' : 'map',
                    roam: true,
                    zoom: 1.3,
                    tooltip: {
                        trigger: "item",
                        formatter: p => {
                         
                            let val = p.value;
                            if (p.name == '南海诸岛') return
                            if (window.isNaN(val)) {
                                val = 0;
                            }
                            let txtCon =
                                "<div style='text-align:left'>" + p.name + ":<br />用户量：" + val + '个</div>';
                            return txtCon;
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    data: mapData,

                },
                 {
                    name: '散点',
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    rippleEffect: {
                        brushType: 'fill'
                    },
                    itemStyle: {
                        normal: {
                            color: '#F4E925',
                            shadowBlur: 10,
                            shadowColor: '#333'
                        }
                    },
                    data: pointData,
                    // symbolSize: function (val) {
                    //     let value = val[2]
                    //     // if (value == max) {
                    //     //     return 27
                    //     // }
                    //     return 10
                    // },
                    showEffectOn: 'render', //加载完毕显示特效
                },
                {
                    type: 'bar',
                    barGap: '-100%',
                    barCategoryGap: '60%',
                    itemStyle: {
                        normal: {
                            color: '#11AAFE'
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    data: yData
                }

                ]
            })
        })

        myChart.setOption(option, true)
        //点击前解绑，防止点击事件触发多次
        myChart.off('click');
        myChart.on('click', _this.echartsMapClick);
        window.onresize = myChart.resize;
    }

    //echarts点击事件
    echartsMapClick = (params) => {
        let {intervalId} = this.state
        clearInterval(intervalId)
       
        if (!params.data) {
            return
        } else {
            //如果当前是最后一级，那就直接return
            if (this.state.parentInfo[this.state.parentInfo.length - 1].code == params.data.cityCode) {
               
                return
            }
                let data = params.data
                this.state.parentInfo.push({
                    cityName: data.name,
                    level: data.level,
                    code: data.cityCode
                })
                this.getGeoJson(data.name,data.level,data.cityCode)
            
         
           
        }
    }


    render() {

        return (
                <div className="echarts">
                    <div style={{ width: '100%', height: '100%' }} id="MapMain" ref="Map"></div>
                    <span className="back" onClick={this.back}>返回</span>
                </div>

        )
    }
}
import React, { Component } from 'react'
import { Button, Form, Table, message } from "antd";
import "../../index.scss"
import TreeGraph from './treeGraph'
import TicketTable from './ticketTable'
import scfBusinessApi from "../../../../request/api/scfBusinessApi";



class CtTransforDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            pageNum: "1",
            total: "",
            ticketMessage: "",//票据信息
            transferList: [],//转让列表
            treeMessage: "",//树信息
           


        }
    }
    goBack = () => {
        this.props.history.push("/admin/commercialTicketManage/CommercialTicketTrade")
    }
    toDetail = (id,ticketLedgerId) => {
        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailDetail/${id}/${ticketLedgerId}`)
    }
    componentDidMount() {
        //接收传入的id
        let id = this.props.match.params.id
        this.getTicketMessage(id)
        this.getTransferList(id)
        


    }

    //票据信息
    getTicketMessage = (id) => {
        let params = {
            id: id
        }
        scfBusinessApi.getDetailApi(params).then(res => {
            this.setState({
                ticketMessage: res.data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    //转让列表transferList
    getTransferList = (id) => {
        let params = {
            id: id
        }
        scfBusinessApi.getTransferLtApi(params).then(res => {
            this.setState({
                transferList: res.data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }




    render() {
        let { treeMessage, transferList, ticketMessage } = this.state

        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '转让持票方票号',
                dataIndex: 'ticketNo',

                key: 'ticketNo',
                sorter: (a, b) => {
                    if (!a.ticketNo) {
                        a.ticketNo = ""
                    } else if (!b.ticketNo) {
                        b.ticketNo = ""
                    }
                    return a.ticketNo - b.ticketNo
                },

            },
            {
                title: '转让额度(元)',
                dataIndex: 'transferAmount',
                key: 'transferAmount',
                sorter: (a, b) => {
                    if (!a.transferAmount) {
                        a.transferAmount = ""
                    } else if (!b.transferAmount) {
                        b.transferAmount = ""
                    }
                    return a.transferAmount.replace(/[^\d.]/g, "") - b.transferAmount.replace(/[^\d.]/g, "")
                },


            },
            {
                title: '转让到',
                dataIndex: 'ticketToOrgName',
                key: 'ticketToOrgName',
                sorter: (a, b) => {
                    if (!a.ticketToOrgName) {
                        a.ticketToOrgName = ""
                    } else if (!b.ticketToOrgName) {
                        b.ticketToOrgName = ""
                    }
                    return a.ticketToOrgName.localeCompare(b.ticketToOrgName)
                },
            },

            {
                title: '转让日期',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }

            },

            {
                title: '转让状态',
                dataIndex: 'ticketTransStatusCdDesc',
                key: 'ticketTransStatusCdDesc',
                sorter: (a, b) => {
                    if (!a.ticketTransStatusCdDesc) {
                        a.ticketTransStatusCdDesc = ""
                    } else if (!b.ticketTransStatusCdDesc) {
                        b.ticketTransStatusCdDesc = ""
                    }
                    return a.ticketTransStatusCdDesc.localeCompare(b.ticketTransStatusCdDesc)
                }
            },
            {
                title: '操作',
                render: (record) => {
                    return <div className="flex-around-center">
                        <Button type="primary" onClick={() => this.toDetail(record.id,record.ticketLedgerId)}>详情</Button>
                    </div>

                }
            },
        ];
        return (
            <div className="CtTransforDetail">
                <ul>
                    <li><span>票据号码:</span>{ticketMessage.ticketNo}</li>
                    <li><span>出票日期:</span>{ticketMessage.ticketDate}</li>
                    <li><span>汇票到期日期:</span>{ticketMessage.ticketExpireDate}</li>
                    <li><span>背书转让方:</span>{ticketMessage.ticketFromOrgName}</li>
                </ul>
                <ul className="ul">
                    <li><span>{ticketMessage.ticketAmount}元</span><span>票据额度(元)</span></li>
                    <li><span>{ticketMessage.remainAmount}元</span><span>票据剩余额度(元)</span></li>
                    <li><span>{ticketMessage.transferredAmount}元</span><span>票据已转让额度(元)</span></li>
                    <li><span>{ticketMessage.amountInTransfer}元</span><span>票据转让中额度(元)</span></li>
                </ul>
               
                <div>
                    <Table
                        columns={columns}
                        dataSource={transferList}
                        rowKey={(record, index) => record.id}
                        pagination={false}

                    />
                </div>
                <div>
                    <div className="CtTransforDetail-ticketContent-front marginTopOrBottom">
                     <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                    </div>
                    <div className="CtTransforDetail-ticketContent-behind">
                        <TreeGraph id={this.props.match.params.id}></TreeGraph>
                    </div>
                </div>
                <div className="textAlignCenter marginTopOrBottom">
                    <Button className="com-middle-btn-size" onClick={this.goBack}>返回</Button>
                </div>
            </div>
        )
    }
}
export default Form.create()(CtTransforDetail);
import React, { Component } from 'react';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table, message } from "antd";
import { debounce } from '@/utils/tools';
import QuotaTable from './component/quotaTableList'
import { getQuotaStatusApi , getQuotaListApi } from '../../request/api/quota'
import moment from 'moment';
import './index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;

class QuotaManage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalCount: 0,
            current: 1,
            pageSize: 10,
            data:{},
            quotaStateList:[]
         }
    }
    componentDidMount () {
        this.getQuotaStatus()
        this.getQuotaListData({current:this.state.current,pageSize:this.state.pageSize});
    }
    getQuotaStatus = ()=>{
        getQuotaStatusApi({dicType:'auditStatusCd'}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    quotaStateList:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getQuotaListData = (pagination) => {
        console.log(JSON.stringify(pagination))
        this.props.form.validateFields((err, values) => {
            if(values.dates && values.dates.length) {
                values.startTime = moment(values.dates[0]).format('YYYY-MM-DD');
                values.endTime = moment(values.dates[1]).format('YYYY-MM-DD');
            }
            values.pageNum = pagination.current
            values.pageSize = pagination.pageSize
            delete values.dates;
            getQuotaListApi(values).then(res => {
                if(res.code=='2000'){
                    this.setState({
                        data:res.data
                    })
                }else{
                    message.info(res.message)
                }
            }).catch(err => {
                message.error(err.message);
            })
        })
    }
    handleCurrentChange = (pagination) => {
        this.getQuotaListData(pagination)
    }
    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getQuotaListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    handleFormSearch = debounce(() => {
        this.getQuotaListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    render() { 
        const { getFieldDecorator } = this.props.form;
        const { quotaStateList , data } = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        return ( 
            <>
            <Form {...formItemLayout} style={{margin:"20px 0 60px 0"}}>
                <Row>
                    <Col span={8}>
                        <Form.Item label="申请人">
                            {
                                getFieldDecorator('enterpriseName', {})(
                                    <Input name='enterpriseName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="申请日期">
                            {
                                getFieldDecorator('dates', {})(
                                    <RangePicker name='dates' style={{width: '100%'}} suffixIcon=' ' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="状态">
                            {
                                getFieldDecorator('auditStatus', {})(
                                    <Select name='companyState'>
                                        <Option value=''>全部</Option>
                                        {
                                            quotaStateList && quotaStateList.length 
                                            ? quotaStateList.map((item, index)=> <Option key={item.id} value={item.dicKey}>{item.dicValue}</Option>)
                                            : null
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <div className='wraper-cd-right'>
                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                    <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                </div>
            </Form>
            <QuotaTable data={data} handleCurrentChange={this.handleCurrentChange}/>
            
            </>
         );
    }
}
const QuotaManageForm = Form.create()(QuotaManage);
export default QuotaManageForm;
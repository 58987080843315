import React from "react";
import { connect } from 'dva';
import Sortable from 'sortablejs';
import { debounce } from '@/utils/tools';
import { Table, Button, Popconfirm, Form, Select } from "antd";

const { Option } = Select;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} className='signing-wrap-mover' />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = (e) => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = (form) => {
        this.form = form;
        let { children, dataIndex, record, title, thatData, data } = this.props;
        let { editing } = this.state;
        let viewData = [];
        let nodeData = [];
        viewData = [thatData[thatData.findIndex(item => item.contractTemplateId === record[dataIndex] * 1)]];
        data = [...new Set(data)];
        nodeData = [...data];
        nodeData.push(...viewData);
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex] * 1,
                })(
                    <Select 
                        ref={(node) => (this.input = node)} 
                        onBlur={() => this.save()}
                        onChange={(e) => {
                            this.props.handleSelectChange(e, form.getFieldValue(dataIndex));
                            setTimeout(() => this.save(), 0);
                        }}
                        style={{width: '100%'}}
                    >
                        {
                            nodeData && nodeData.length 
                                ? nodeData.map((item, index)=> <Option key={index} value={item.contractTemplateId}>{item.contractName}</Option>)
                                : null 
                        }
                    </Select>
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                onClick={this.toggleEdit}
            >
                {
                    thatData && thatData.length 
                        ? thatData[thatData.findIndex(item => item.contractTemplateId === children[2])].contractName
                        : null
                }
            </div>
        );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            signOrder,
            data,
            thatData,
            dataSource,
            handleSelectChange,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {
                    editable ? (
                        dataSource[dataSource.length-1].contractTemplateId === record.contractTemplateId
                            ? (
                                <EditableContext.Consumer>
                                    {this.renderCell}
                                </EditableContext.Consumer>
                            )
                            : (
                                thatData && thatData.length 
                                ? thatData[thatData.findIndex(item => item.contractTemplateId === children[2])].contractName
                                : null
                            )
                    ) : (
                        children
                    )
                }
            </td>
        );
    }
}

@connect((store) => ({ store: store.signingConfiguration, loading: store.loading }))
class DragTableComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataSource: [],
            thatData: [],
            count: 1,
            textFlag: false
        };
        this.columns = [
            {
                title: "签约顺序",
                dataIndex: "signOrder",
                key: 'signOrder',
                sorter:(a,b)=>{
                    if(!a.signOrder){
                        a.signOrder=""
                    }else if(!b.signOrder){
                        b.signOrder=""
                    }
                    return a.signOrder.localeCompare(b.signOrder)
                },
                render: (text, record, index) => {
                    record.signOrder = index+1;
                    return `${index+1}`
                }
            },
            {
                title: "合同名称",
                dataIndex: "contractTemplateId",
                key: 'contractTemplateId',
                editable: true,
                sorter:(a,b)=>{
                    if(!a.contractTemplateId){
                        a.contractTemplateId=""
                    }else if(!b.contractTemplateId){
                        b.contractTemplateId=""
                    }
                    return a.contractTemplateId.localeCompare(b.contractTemplateId)
                },
            },
            {
                title: "操作",
                dataIndex: "operation",
                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="你确定删除吗?"
                            onConfirm={() => this.handleDelete(record)}
                        >
                            <Button className='com-btn-opr com-middle-btn-size'>删除</Button>
                        </Popconfirm>
                    ) : null,
            },
        ];
    }

    componentWillReceiveProps (newProps) {
        this.setState({ dataSource: [],data: [], thatData: [] }, () => {
            this.setState({ data: [...newProps.data], thatData: [...newProps.data] });
        });
    }

    sortableGoods = (componentBackingInstance) => {
        let tab = document.getElementsByClassName('goodsTable');
        let el = tab[0].getElementsByClassName('ant-table-tbody')[0];
        let self = this;
        let sortable = Sortable.create(el, {
            // animation: 150,
            ghostClass: 'blue-background-class',
            onEnd: function (evt) {
                let menuArr = self.state.dataSource; 
                const oldEl = menuArr.splice(evt.oldIndex, 1);
                menuArr.splice(evt.newIndex, 0, oldEl[0]);
                self.setState({ dataSource: menuArr });
            }
        });
    }

    handleDelete = (record) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            data: [...this.state.data, record],
            dataSource: dataSource.filter((item) => item.key !== record.key),
        });
    };

    handleAdd = () => {
        let { count, dataSource, data } = this.state;
        if(data.length <= 0) return;
        let newData = {...data.splice(data.length-1, 1)[0], key: count};
        this.setState({
            data,
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    handleSelectChange = (newVal, oldVal) => {
        let newIndex = this.state.data.findIndex(item => item.contractTemplateId === newVal);
        let oldIndex = this.state.thatData.findIndex(item => item.contractTemplateId === oldVal);
        let menuArr = this.state.data;
        menuArr.splice(newIndex,1);
        menuArr.splice(newIndex, 0, this.state.thatData[oldIndex]);
        this.setState({ data: menuArr });
    }

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    handleAddCredit = debounce(() => {
        let { dataSource, data } = this.state;
        if(data.length <= 0 && dataSource.length > 0) {
            this.props.update(dataSource);
            return;
        }
        this.setState({ textFlag: true });
    }, 300)

    render() {
        const { dataSource, data, textFlag } = this.state;
        const isLoding = this.props.loading.effects['signingConfiguration/getEnableData'];
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    data: this.state.data,
                    thatData: this.state.thatData,
                    dataSource: this.state.dataSource,
                    handleSelectChange: this.handleSelectChange
                }),
            };
        });
        const tabelHeadBtn = <div>
            <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleAdd} icon='plus'>导入模板</Button>
            {
                data.length <= 0 && dataSource.length > 0 ? <span style={{marginLeft: 10}}>该产品项下合同已全部导入完毕！</span> : null
            }
            {
                data.length > 0  && textFlag ? <span style={{color: '#f5222d', marginLeft: 10}}>该产品项下还有合同未导入，请导入！</span> : null
            }
        </div>
        return (
            <div className="goodsTable" ref={this.sortableGoods}>
                <Table
                    components={components}
                    rowClassName={() => "editable-row tableItem"}
                    dataSource={dataSource}
                    columns={columns}
                    title={() => tabelHeadBtn}
                    loading={isLoding}
                    pagination = {false}
                />
                <div className='wraper-cd-center' style={{marginTop: 24}}>
                    <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                    <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleAddCredit}>确定</Button>
                </div>
            </div>
        );
    }
}

export default DragTableComp;

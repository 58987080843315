import React, { Component } from 'react';
import { Row, Col , message } from 'antd';
import AuditorBaseInfo from './auditorBaseInfo'
import AuditInfoSubmit from './auditInfoSubmit'
import AuditHistory from './aditHistory'
import url from 'url'
import { queryAuditInfoApi  } from '../../../request/api/quota'

class AuditInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{},
            tableData:[],
            audit:url.parse(this.props.location.search,true).query.audit,
            id:url.parse(this.props.location.search,true).query.id,
            wkId:url.parse(this.props.location.search,true).query.wkId,
            nodeId:url.parse(this.props.location.search,true).query.nodeId,
         }
    }
    componentDidMount(){
        this.getAuditInfo()
    }
    getAuditInfo = ()=>{
        const { id } = this.state
        queryAuditInfoApi({id:id}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    data:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    
    render() { 
        const { data , audit , tableData , id , wkId ,nodeId } = this.state
        return ( 
            <>
            <Row>
                <AuditorBaseInfo data={data}/>
                <AuditHistory businessId={id} businessType={'2010'}/>
                <AuditInfoSubmit data={data}  audit={audit} id={id} wkId={wkId} nodeId={nodeId}/>
            </Row>
            </>
         );
    }
}
 
export default AuditInfo;
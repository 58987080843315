import React, { Component } from 'react';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import ModalMsgComp from './modalMsgComp';
import { Form, Input, Select, Button, Table, Row, Col } from 'antd';

const { Option } = Select;

@Form.create()
@connect((store) => ({ store: store.productManage, loading: store.loading }))
class ListComp extends Component {

    componentDidMount () {
        this.getSelectListData();
        this.getproductListData();
    }
    
    getproductListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                if(pagination) {
                    values.pageNum = pagination.current;
                    values.pageSize = pagination.pageSize;
                }
                this.props.dispatch({ type: 'productManage/getproductList', payload: values});
            }
        })
    }

    getSelectListData = () => {
        this.props.dispatch({ type: 'productManage/getProductModeList', payload: 'financing_mode'});
        this.props.dispatch({ type: 'productManage/getProductStateList', payload: 'credit_state'});
    }

    handleFormSearch = debounce(() => {
        this.getproductListData();
    }, 300)

    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getproductListData();
    }, 300)

    handleCurrentChange = (pagination) => {
        this.getproductListData(pagination)
    }
    
    handleHistoryGoAdd = () => {
        this.props.history.push('/admin/productManage/add');
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { productModeList, productStateList, productListData } = this.props.store;
        const isLoding3 = this.props.loading.effects['productManage/getproductList'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '序号',
                key: 'index',
                render: (text, record, index) => `${index+1}`,
            },
            {
                title: '产品名称',
                dataIndex: 'productName',
                key: 'productName',
                sorter:(a,b)=>{
                    if(!a.productName){
                        a.productName=""
                    }else if(!b.productName){
                        b.productName=""
                    }
                    return a.productName.localeCompare(b.productName)
                }
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                sorter:(a,b)=>{
                    if(!a.projectName){
                        a.projectName=""
                    }else if(!b.projectName){
                        b.projectName=""
                    }
                    return a.projectName.localeCompare(b.projectName)
                }
            },
            {
                title: '核心企业名称',
                dataIndex: 'companyName',
                key: 'companyName',
                sorter:(a,b)=>{
                    if(!a.companyName){
                        a.companyName=""
                    }else if(!b.companyName){
                        b.companyName=""
                    }
                    return a.companyName.localeCompare(b.companyName)
                }
            },
            {
                title: '资金方名称',
                dataIndex: 'capitalName',
                key: 'capitalName',
                sorter:(a,b)=>{
                    if(!a.capitalName){
                        a.capitalName=""
                    }else if(!b.capitalName){
                        b.capitalName=""
                    }
                    return a.capitalName.localeCompare(b.capitalName)
                }
            },
            {
                title: '融资模式',
                dataIndex: 'productModeName',
                key: 'productModeName',
                sorter:(a,b)=>{
                    if(!a.productModeName){
                        a.productModeName=""
                    }else if(!b.productModeName){
                        b.productModeName=""
                    }
                    return a.productModeName.localeCompare(b.productModeName)
                }
            },
            {
                title: '产品额度（元）',
                dataIndex: 'totalAmountName',
                key: 'totalAmountName',
                sorter:(a,b)=>{
                    if(!a.totalAmountName){
                        a.totalAmountName=""
                    }else if(!b.totalAmountName){
                        b.totalAmountName=""
                    }
                    return a.totalAmountName.replace(/[^\d.]/g,"")-b.totalAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '剩余额度（元）',
                dataIndex: 'haveAmountName',
                key: 'haveAmountName',
                sorter:(a,b)=>{
                    if(!a.haveAmountName){
                        a.haveAmountName=""
                    }else if(!b.haveAmountName){
                        b.haveAmountName=""
                    }
                    return a.haveAmountName.replace(/[^\d.]/g,"")-b.haveAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '产品状态',
                dataIndex: 'productStateName',
                key: 'productStateName',
                sorter:(a,b)=>{
                    if(!a.productStateName){
                        a.productStateName=""
                    }else if(!b.productStateName){
                        b.productStateName=""
                    }
                    return a.productStateName.localeCompare(b.productStateName)
                }
            },
            {
                title: '上级状态',
                dataIndex: 'companyStateName',
                key: 'companyStateName',
                sorter:(a,b)=>{
                    if(!a.companyStateName){
                        a.companyStateName=""
                    }else if(!b.companyStateName){
                        b.companyStateName=""
                    }
                    return a.companyStateName.localeCompare(b.companyStateName)
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record) => {
                    const editPath = `/admin/productManage/edit?id=${record.id}`;
                    const detailPath = `/admin/productManage/detail?id=${record.id}`;
                    const editNode = <Button className='com-btn-opr com-small-btn-size mr5' href={editPath}>修改</Button>;
                    const detailNode = <Button className='com-btn-opr com-small-btn-size mr5' href={detailPath}>详情</Button>;
                    return <div>
                        {
                            record.productState === '1' 
                                ? <React.Fragment>
                                    {detailNode}
                                    {editNode}
                                    <ModalMsgComp type={0} id={record.id} status='0' updata={this.getproductListData} />
                                </React.Fragment> 
                                : null
                        }
                        {
                            record.productState === '0'
                                ? <React.Fragment>
                                    {detailNode}
                                    {
                                        record.companyState !== '0' && record.comHaveAmount >= record.totalAmount
                                        ?   <ModalMsgComp 
                                                type={1} 
                                                id={record.id} 
                                                status='1' 
                                                updata={this.getproductListData}
                                                totalAmount={record.totalAmount}
                                                companyCreditId={record.companyCreditId} 
                                            />
                                        : null
                                    }
                                </React.Fragment> 
                                : null
                        }
                        {
                            record.productState === '2' 
                            ? <React.Fragment>
                                {editNode}
                            </React.Fragment> 
                            : null
                        }
                    </div>
                }
            }
        ];
        const tabelHeadBtn = <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleHistoryGoAdd}>添加</Button>;

        return (
            <div>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label='产品名称'>
                                {
                                    getFieldDecorator('productName', {})(
                                        <Input name='productName'></Input>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='核心企业名称'>
                                {
                                    getFieldDecorator('companyName', {})(
                                        <Input name='companyName'></Input>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='资金方名称'>
                                {
                                    getFieldDecorator('capitalName', {})(
                                        <Input name='capitalName'></Input>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label='项目名称'>
                                {
                                    getFieldDecorator('projectName', {})(
                                        <Input name='projectName'></Input>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='融资模式'>
                                {
                                    getFieldDecorator('productMode', {
                                        initialValue: ''
                                    })(
                                        <Select name='productMode'>
                                            <Option value=''>全部</Option>
                                            {
                                                productModeList && productModeList.length 
                                                ? productModeList.map(item => <Option key={item.dicType} value={item.dicKey}>{item.dicValue}</Option>)
                                                : null
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='产品状态'>
                                {
                                    getFieldDecorator('productState', {
                                        initialValue: ''
                                    })(
                                        <Select name='productState'>
                                            <Option value=''>全部</Option>
                                            {
                                                productStateList && productStateList.length 
                                                ? productStateList.map(item => <Option key={item.dicType} value={item.dicKey}>{item.dicValue}</Option>)
                                                : null
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='wraper-cd-center'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>搜索</Button>
                    </div>
                </Form>
                <Table
                    columns={columns}
                    dataSource={productListData.data}
                    rowKey={(record, index) => index}
                    title={() => tabelHeadBtn}
                    loading={isLoding3}

                    pagination = {{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: productListData.totalCount <= 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `总共 ${productListData.totalCount} 条`,
                        total: productListData.totalCount,
                        current: productListData.pageNum,
                        pageSize: productListData.pageSize,
                    }}
                    onChange = {this.handleCurrentChange}
                />
            </div>
        )
    }
}

export default ListComp;

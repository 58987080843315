import React, { Component } from 'react';
import { Modal, Button } from 'antd';
class RegisterModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible: false
        }
    }
    showModal = ()=>{
        this.setState({
            visible: true
        });
    }
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
        this.props.goSetDealPassword()
    };
    render() { 
        const { mobile } = this.props
        return ( 
            <>
                <div className='register-modal-box'>
                    <Modal
                    title="恭喜您用户注册成功！"
                    visible={this.state.visible}
                    closable={false}
                    footer={null}
                    maskClosable={false}
                    style={{textAlign:'center'}}
                    >
                    <p className='register-modal-p'>登录账号：{ mobile }</p>
                    <Button  className="register-modal-btn" type='primary' onClick={this.handleOk}>确定</Button >
                    </Modal>
                </div>
            </>
         );
    }
}
 
export default RegisterModal;
import React, {Component} from 'react';
import {Steps} from 'antd';
import ChooseBusinessType from "./chooseBusinessType";
import AddBusinessInfo from "./addBusinessInfo";

const {Step} = Steps;

class AddClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: JSON.parse(localStorage.getItem("clientInfoManage")).addCurrent,
        }
    }

    componentDidMount() {

    }

    getBusinessType = () => {
        let clientInfoManage = JSON.parse(localStorage.getItem("clientInfoManage"));
        let {addCurrent} = clientInfoManage;
        this.setState({
            current: addCurrent,
        });
    }

    render() {
        let {current} = this.state;
        return (
            <div className="add-client-info">
                <Steps current={current} className="com-shadow-radius">
                    {/* <Step title="" description="选择企业类型"/> */}
                    <Step title="" description="提交证件信息"/>
                    <Step title="" description="提交成功"/>
                </Steps>
                <div>
                    {(() => {
                        switch (current) {
                            case 0 :
                                // return <ChooseBusinessType getBusinessType={this.getBusinessType}/>;
                                return <AddBusinessInfo/>;
                            // case 1:
                            //     return <AddBusinessInfo/>;
                            default :
                                break;
                        }
                    })()}
                </div>
            </div>
        );
    }
}

export default AddClient;
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import scfBusinessApi from '@/request/api/scfBusinessApi';
import { Form, Button, Input, InputNumber, Select, DatePicker, message, Spin, Descriptions } from 'antd';

const { Option } = Select;
const addCreditApi = scfBusinessApi['addCreditApi'];

@Form.create()
@connect((store) => ({ store: store.coreBusinessCredit, loading: store.loading }))
class AddComp extends Component {

    state = {
        capitalName: '',
        companyName: ''
    }

    componentDidMount () {
        this.props.dispatch({ type: 'coreBusinessCredit/updateReducers', payload: { key: 'capitalList', val: [] }});
        this.props.dispatch({ type: 'coreBusinessCredit/updateReducers', payload: { key: 'EnterpriseList', val: [] }});
        this.getAllcapitalData();
    }

    handleAddCredit = debounce(() => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                values.companyStartTime = moment(values.companyStartTime).format('YYYY-MM-DD HH:mm:ss');
                values.companyEndTime = moment(values.companyEndTime).format('YYYY-MM-DD HH:mm:ss');
                values.capitalName = this.state.capitalName;
                values.companyName = this.state.companyName;
                addCreditApi(values).then(res => {
                    message.success(res.message);
                    this.props.history.push('/admin/coreBusinessCredit/list')
                }).catch(err => message.error(err.message))
            }
        })
    }, 300)

    getAllcapitalData = () => {
        this.props.dispatch({ type: 'coreBusinessCredit/getAllcapitalData'});
    }

    handleCapitalSelect = (val, eve) => {
        if(!val) {
            this.props.dispatch({ type: 'coreBusinessCredit/updataAllEnterpriseData', payload: [] });
            return;
        }
        this.props.form.resetFields('companyId');
        this.setState({ capitalName : eve.props.children });
        this.getAllEnterpriseData(val);
    }

    handleCompanySelect = (val, eve) => {
        this.setState({ companyName : eve.props.children });
    }

    getAllEnterpriseData = (valId) => {
        let params = { id: valId };
        this.props.dispatch({ type: 'coreBusinessCredit/getAllEnterpriseData', payload: params});
    }
    
    disabledStartDate = startValue => {
        const endDate = this.props.form.getFieldValue('companyEndTime');
        if (!startValue || !endDate) {
          return startValue < moment().subtract(1, "days");
        } 
        return startValue.valueOf() > endDate.valueOf() || startValue < moment().subtract(1, "days");
    };
    
    disabledEndDate = endValue => {
        const startDate = this.props.form.getFieldValue('companyStartTime');
        if (!endValue || !startDate) {
          return endValue < moment().subtract(1, "days");
        }
        return endValue.valueOf() <= startDate.valueOf();
    };

    handleHistoryGoBack = () => {
        this.props.history.go(-1)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { capitalList, EnterpriseList } = this.props.store;
        const isLoding1 = this.props.loading.effects['coreBusinessCredit/getAllcapitalData'];
        const isLoding2 = this.props.loading.effects['coreBusinessCredit/getAllEnterpriseData'];
        const formItemLayout = {
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            }
        };
        
        return (
            <div>
                <Spin spinning={isLoding1}>
                    <Form {...formItemLayout} className='wraper-cd-center'>
                        <Descriptions bordered column={2} size='small' className='descriptions-h-width'>
                            <Descriptions.Item label="项目名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('projectName', {
                                            initialValue: '',
                                            rules: [
                                                { 
                                                    required: true,
                                                    whitespace: true,
                                                    message: '请输入项目名称!' 
                                                }
                                            ]
                                        })(
                                            <Input name='projectName' />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="项目编号">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('projectNo', {
                                            initialValue: '',
                                            rules: [
                                                { 
                                                    required: true,
                                                    whitespace: true,
                                                    message: '请输入项目编号!' 
                                                }
                                            ]
                                        })(
                                            <Input name='projectNo' />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="资金方名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('capitalId', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择资金方名称!' }]
                                        })(
                                            <Select name='capitalId' onChange={this.handleCapitalSelect}>
                                                {
                                                    capitalList && capitalList.length 
                                                    ? capitalList.map((item, index) => <Option key={index} value={item.id}>{item.orgName}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="核心企业名称">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyId', {
                                            initialValue: '',
                                            rules: [{ required: true, message: '请选择核心企业名称!' }]
                                        })(
                                            <Select name='companyId' onChange={this.handleCompanySelect} loading={isLoding2}>
                                                <Option value=''>请选择</Option>
                                                {
                                                    EnterpriseList && EnterpriseList.length 
                                                    ? EnterpriseList.map((item, index) => <Option key={index} value={item.id}>{item.orgName}</Option>)
                                                    : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信额度(元)" span={2}>
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('totalAmount', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择授信额度(元)!'
                                                },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: '请输入合法数字！'
                                                }
                                            ]
                                        })(
                                            <InputNumber
                                                min={1}
                                                name='totalAmount'
                                                style={{width: '100%'}}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信起始日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyStartTime', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择授信起始日!' }]
                                        })(
                                            <DatePicker 
                                                name='companyStartTime' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledStartDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="授信到期日">
                                <Form.Item style={{margin: 0}}>
                                    {
                                        getFieldDecorator('companyEndTime', {
                                            initialValue: null,
                                            rules: [{ required: true, message: '请选择授信到期日!' }]
                                        })(
                                            <DatePicker 
                                                name='companyEndTime' 
                                                style={{width: '100%'}} 
                                                disabledDate={this.disabledEndDate}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <div className='wraper-cd-center' style={{marginTop: 24}}>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleHistoryGoBack}>返回</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleAddCredit}>提交</Button>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default AddComp;

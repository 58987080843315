import React, { Component } from 'react';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table, message } from "antd";
import { withRouter } from 'dva/router';
import { debounce } from '@/utils/tools';
import IssueTable from './component/issueTableList'
import { connect } from 'dva';
import { getQuotaStatusApi , getCreditListApi } from '../../request/api/quota'
import moment from 'moment';
import { getStorage } from "@/utils/tools";
import './index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;

@withRouter

@connect((store) => ({ 
    store: store.bussimessTicket,
    loading: store.loading 
}))

class IssueManage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalCount: 0,
            current: 1,
            pageSize: 10,
            data:{},
            flag:false,
            creditStateList:[]
         }
    }
    componentDidMount () {
        if(this.props.match.url=='/admin/bussinessTicketPayMoney/paymoney'){
            this.setState({
                flag:true
            })
        }else{
            this.setState({
                flag:false
            })
        }
        this.getQuotaStatus()
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
        this.props.dispatch({ type: 'bussimessTicket/getOpenStatustListData', payload: {id:getStorage("companyID")}});
    }
    getQuotaStatus = ()=>{
        getQuotaStatusApi({dicType:'ticketStatusCd'}).then(res => {
            if(res.code=='2000'){
                this.setState({
                    creditStateList:res.data
                })
            }else{
                message.info(res.message)
            }
        }).catch(err => {
            message.error(err.message);
        })
    }
    getCreditListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if(values.dates && values.dates.length) {
                values.ticketStartTime = moment(values.dates[0]).format('YYYY-MM-DD');
                values.ticketEndTime = moment(values.dates[1]).format('YYYY-MM-DD');
                delete values.dates;
            }
            if(values.Tickdates && values.Tickdates.length) {
                values.ticketExpireStartTime = moment(values.Tickdates[0]).format('YYYY-MM-DD');
                values.ticketExpireEndTime = moment(values.Tickdates[1]).format('YYYY-MM-DD');
                delete values.Tickdates
            }
            values.pageNum = pagination.current
            values.pageSize = pagination.pageSize
            if(this.props.match.url=='/admin/bussinessTicketPayMoney/paymoney'){
                values.type = 2
            }else{
                values.type = 1
            }
            
            getCreditListApi(values).then(res => {
                if(res.code=='2000'){
                    this.setState({
                        data:res.data
                    })
                }else{
                    message.info(res.message)
                }
            }).catch(err => {
                message.error(err.message);
            })
        })
    }
    handleCurrentChange = (pagination) => {
        this.getCreditListData(pagination)
    }
    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    handleFormSearch = debounce(() => {
        this.getCreditListData({current:this.state.current,pageSize:this.state.pageSize});
    }, 300)
    goAddRouter = ()=>{
        this.props.history.push(`/admin/businessTicketManage/add`)
    }
    render() { 
        const { getFieldDecorator } = this.props.form;
        const { OpenStatustList } = this.props.store;
        const { creditStateList , data , flag } = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        return ( 
            <>
            <Form {...formItemLayout} style={{margin:"20px 0 20px 0"}}>
                <Row>
                    <Col span={8}>
                        <Form.Item label="票据号码">
                            {
                                getFieldDecorator('ticketNo', {})(
                                    <Input name='ticketNo' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="出票日期">
                            {
                                getFieldDecorator('dates', {})(
                                    <RangePicker name='dates' style={{width: '100%'}} suffixIcon=' ' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="汇票到期日期">
                            {
                                getFieldDecorator('Tickdates', {})(
                                    <RangePicker name='Tickdates' style={{width: '100%'}} suffixIcon=' ' />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label="出票人">
                            {
                                getFieldDecorator('drawerName', {})(
                                    <Input name='drawerName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="收款人">
                            {
                                getFieldDecorator('payeeName', {})(
                                    <Input name='payeeName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="承兑人">
                            {
                                getFieldDecorator('acceptorName', {})(
                                    <Input name='acceptorName' />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label="票据状态">
                            {
                                getFieldDecorator('ticketStatusCd', {})(
                                    <Select name='ticketStatusCd'>
                                        <Option value=''>全部</Option>
                                        {
                                            creditStateList && creditStateList.length 
                                            ? creditStateList.map((item, index)=> <Option key={item.id} value={item.dicKey}>{item.dicValue}</Option>)
                                            : null
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <div className='wraper-cd-right'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Row>
            </Form>
            {
                OpenStatustList.auditStatusCd==2&&flag==false?<Button type="danger" onClick={this.goAddRouter} style={{marginBottom:'40px'}}>新增发行</Button>:null
            }
            
            <IssueTable data={data} handleCurrentChange={this.handleCurrentChange} creditStateList = {creditStateList}/>
            
            </>
         );
    }
}
const IssueManageForm = Form.create()(IssueManage);
export default IssueManageForm;
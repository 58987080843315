import React, { Component } from 'react'
import { Button,Form,Table,message } from "antd";
import "../../index.scss"
import TicketTable from './ticketTable'
import TreeGraph from './treeGraph'
import TicketDetailTable from './ticketDetailTable'
import scfBusinessApi from "../../../../request/api/scfBusinessApi";


class CtTransforDetailDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            tableData:[],
            pageNum:"1",
            total:"",
            ticketMessage:"",
            transferDetail:""
        }
    }

     componentDidMount(){

       let id = this.props.match.params.id
    //    this.getTicketMessage(id)
       this.getAuditHistory(id)
    
     }
        // //票据信息
        // getTicketMessage = (id) =>{
        //     let params = {
        //         id:id
        //     }
        //     scfBusinessApi.getTransferDetailApi(params).then(res=>{
        //        this.setState({
        //         ticketMessage: res.data
        //        })
        //     }).catch(err=>{
        //         message.error(err.message);
        //     })
        // }

    goBack = () =>{
        this.props.history.go(-1)
    }
      //审核历史
      getAuditHistory = (id) =>{
        let params ={
            businessId:id,
            businessType:"2030"
        }
        scfBusinessApi.auditHistoryApi(params).then(res=>{
           this.setState({
            tableData:res.data
           })
        }).catch(err=>{
            message.error(err.message)
        })
    }
    render() {
        let {tableData,ticketMessage} = this.state
        let columns = [
            {
                title: '操作流程',
                dataIndex: 'sysBusinessTypeCdDesc',
                key: 'sysBusinessTypeCdDesc',
               
            },
            {
                title: '操作人',
                dataIndex: 'createUserName',
                key: 'createUserName',
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
                key: 'stateCdDesc',
            },
            {
                title: '审核意见',
                dataIndex: 'des',
                key: 'des',
            },
        ];
        return (
            <div className="CtTransforDetailDetail">
               <div className="CtTransforDetailDetail-record">
               <TicketDetailTable id={this.props.match.params.id}></TicketDetailTable>
                   {/* <div className="wrap">
                   <div>
                        <h2>票据信息</h2>
                        <ul>
                            <li>票据号码：{ticketMessage.ticketNo}</li>
                            <li>出票日期：{ticketMessage.ticketDate}</li>
                            <li>汇票到期日期：{ticketMessage.ticketExpireDate}</li>
                            <li>转入方：{ticketMessage.ticketFromOrgName}</li>
                        </ul>
                   </div>
                   <div>
                       <h2>票据额度信息</h2>
                        <ul>
                            <li>票据额度(元)：{ticketMessage.ticketAmount}</li>
                            <li>票据剩余额度(元)：{ticketMessage.remainAmount}</li>
                       </ul>
                   </div>
                   </div>
                   
                   <div>
                       <h2>转让信息</h2>
                        <ul>
                            <li>转让额度(元)：{ticketMessage.transferAmount}</li>
                            <li>转让方：{ticketMessage.ticketFromOrgName}</li>
                            <li>转让方账号：{ticketMessage.toBankCnap}</li>
                            <li>开户行：{ticketMessage.toBankName}</li>
                            <li>开户行行号：{ticketMessage.toBankNumber}</li>
                       </ul>
                   </div> */}
                 
                <div className="marginTopOrBottom">
                <h2>审核历史</h2>
                <Table dataSource={tableData} columns={columns} pagination={false} className="table"/>
                </div>
                <div style={{textAlign:'center',marginTop:"30px"}}>
                    <Button className="com-middle-btn-size" onClick={this.goBack}>返回</Button>
                   </div>
               </div>
                {/* <div className="CtTransforDetailDetail-ticketContent">
                     <div className="CtTransforDetailDetail-ticketContent-front">原票详情
                     <TicketTable ticketLedgerId={this.props.match.params.ticketLedgerId}></TicketTable>
                     </div>
                     <div className="CtTransforDetailDetail-ticketContent-behind">
                     <TreeGraph id={this.props.match.params.id}></TreeGraph>
                     </div>
                </div> */}
                 
            </div>
        )
    }
}
export default Form.create()(CtTransforDetailDetail);
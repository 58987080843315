import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { debounce } from '@/utils/tools';
import ModalMsgComp from './modalMsgComp';
import { Form, Input, Row, Col, Select, Button, DatePicker, Table } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

@connect((store) => ({ store: store.coreBusinessCredit, loading: store.loading }))
@Form.create()
class ListComp extends Component {

    componentDidMount () {
        this.getenterpriseCreditListData();
        this.getAllCompanyStateData();
    }

    getenterpriseCreditListData = (pagination) => {
        this.props.form.validateFields((err, values) => {
            if( !err ) {
                if(values.dates && values.dates.length) {
                    values.beginCompanyEndTime = moment(values.dates[0]).format('YYYY-MM-DD');
                    values.endCompanyEndTime = moment(values.dates[1]).format('YYYY-MM-DD');
                    values.dates = undefined;
                }
                if(pagination) {
                    values.pageNum = pagination.current;
                    values.pageSize = pagination.pageSize;
                }
                this.props.dispatch({ type: 'coreBusinessCredit/getenterpriseCreditListData', payload: values});
            }
        })
    }

    getAllCompanyStateData = () => {
        this.props.dispatch({ type: 'coreBusinessCredit/getAllCompanyStateData', payload: 'credit_state'});
    }

    handleFormSearch = debounce(() => {
        this.getenterpriseCreditListData();
    }, 300)

    handleFormReset = debounce(() => {
        this.props.form.resetFields();
        this.getenterpriseCreditListData();
    }, 300)

    handleCurrentChange = (pagination) => {
        this.getenterpriseCreditListData(pagination)
    }

    handleHistoryGoAdd = () => {
        this.props.history.push('/admin/coreBusinessCredit/add')
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { CoreBusinessCreditList, companyStateList } = this.props.store;
        const isLoding = this.props.loading.effects['coreBusinessCredit/getenterpriseCreditListData'];
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                offset: 1
            },
            labelAlign: 'right'
        };
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => `${(CoreBusinessCreditList.pageNum-1)*(CoreBusinessCreditList.pageSize)+(index+1)}`,
            },
            {
                title: '项目编号',
                dataIndex: 'projectNo',
                key: 'projectNo',
                sorter: (a,b)=>{
                    if(!a.projectNo){
                        a.projectNo=""
                    }else if(!b.projectNo){
                        b.projectNo=""
                    }
                    return a.projectNo-b.projectNo
                }
            },
            {
                title: '项目名称',
                dataIndex: 'projectName',
                key: 'projectName',
                sorter: (a,b)=>{
                    if(!a.projectName){
                        a.projectName=""
                    }else if(!b.projectName){
                        b.projectName=""
                    }
                    return a.projectName.localeCompare(b.projectName)
                }
            },
            {
                title: '核心企业名称',
                dataIndex: 'companyName',
                key: 'companyName',
                sorter: (a,b)=>{
                    if(!a.companyName){
                        a.companyName=""
                    }else if(!b.companyName){
                        b.companyName=""
                    }
                    return a.companyName.localeCompare(b.companyName)
                }
            },
            {
                title: '资金方名称',
                dataIndex: 'capitalName',
                key: 'capitalName',
                sorter: (a,b)=>{
                    if(!a.capitalName){
                        a.capitalName=""
                    }else if(!b.capitalName){
                        b.capitalName=""
                    }
                    return a.capitalName.localeCompare(b.capitalName)
                }
            },
            {
                title: '授信额度（元）',
                dataIndex: 'totalAmountName',
                key: 'totalAmountName',
                sorter: (a,b)=>{
                    if(!a.totalAmountName){
                        a.totalAmountName=""
                    }else if(!b.totalAmountName){
                        b.totalAmountName=""
                    }
                    return a.totalAmountName.replace(/[^\d.]/g,"")-b.totalAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '剩余授信额度（元）',
                dataIndex: 'haveAmountName',
                key: 'haveAmountName',
                sorter: (a,b)=>{
                    if(!a.haveAmountName){
                        a.haveAmountName=""
                    }else if(!b.haveAmountName){
                        b.haveAmountName=""
                    }
                    return a.haveAmountName.replace(/[^\d.]/g,"")-b.haveAmountName.replace(/[^\d.]/g,"")
                }
            },
            {
                title: '授信起始日',
                dataIndex: 'companyStartTimeName',
                key: 'companyStartTimeName',
                sorter: (a, b) => {
                    let aTime = new Date(a.companyStartTimeName).getTime()
                    let bTime = new Date(b.companyStartTimeName).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '授信到期日',
                dataIndex: 'companyEndTimeName',
                key: 'companyEndTimeName',
                sorter: (a, b) => {
                    let aTime = new Date(a.companyEndTimeName).getTime()
                    let bTime = new Date(b.companyEndTimeName).getTime()
                    return aTime-bTime
                }
            },
            {
                title: '授信状态',
                dataIndex: 'companyStateName',
                key: 'companyStateName',
                sorter: (a,b)=>{
                    if(!a.companyStateName){
                        a.companyStateName=""
                    }else if(!b.companyStateName){
                        b.companyStateName=""
                    }
                    return a.companyStateName.localeCompare(b.companyStateName)
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record) => {
                    const editPath = `/admin/coreBusinessCredit/edit?id=${record.id}`
                    const formNode = <Button className='com-btn-opr com-small-btn-size mr5' href={editPath}>修改</Button>;
                    return <div>
                        {
                            record.companyState === '1' 
                                ? <React.Fragment>
                                    {formNode}
                                    <ModalMsgComp type={0} id={record.id} status={0} updata={this.getenterpriseCreditListData} />
                                  </React.Fragment> 
                                : null
                        }
                        {
                            record.companyState === '0' 
                                ? <React.Fragment>
                                    {formNode}
                                    <ModalMsgComp type={1} id={record.id} status={1} updata={this.getenterpriseCreditListData} />
                                  </React.Fragment> 
                                : null
                        }
                    </div>
                }
            }
        ];
        const tabelHeadBtn = <Button className='com-btn-opr com-middle-btn-size' onClick={this.handleHistoryGoAdd}>新增</Button>;

        return (
            <div className='coreBusinessCredit_style'>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="项目名称">
                                {
                                    getFieldDecorator('projectName', {})(
                                        <Input name='projectName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="项目编号">
                                {
                                    getFieldDecorator('projectNo', {})(
                                        <Input name='projectNo' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="资金方名称">
                                {
                                    getFieldDecorator('capitalName', {})(
                                        <Input name='capitalName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="核心企业名称">
                                {
                                    getFieldDecorator('companyName', {})(
                                        <Input name='companyName' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="授信结束日期">
                                {
                                    getFieldDecorator('dates', {})(
                                        <RangePicker name='dates' style={{width: '100%'}} suffixIcon=' ' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="授信状态">
                                {
                                    getFieldDecorator('companyState', {
                                        initialValue: ''
                                    })(
                                        <Select name='companyState'>
                                            <Option value=''>全部</Option>
                                            {
                                                companyStateList && companyStateList.length 
                                                ? companyStateList.map((item, index)=> <Option key={index} value={item.dicKey}>{item.dicValue}</Option>)
                                                : null
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='wraper-cd-center'>
                        <Button className='com-btn-no com-middle-btn-size mr20' onClick={this.handleFormReset}>重置</Button>
                        <Button className='com-btn-opr com-middle-btn-size mr20' onClick={this.handleFormSearch}>查询</Button>
                    </div>
                </Form>
                <Table 
                    columns={columns}
                    dataSource={CoreBusinessCreditList.data}
                    rowKey={(record, index) => index}
                    title={() => tabelHeadBtn}
                    loading={isLoding}

                    pagination = {{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: CoreBusinessCreditList.totalCount <= 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: total => `总共 ${CoreBusinessCreditList.totalCount} 条`,
                        total: CoreBusinessCreditList.totalCount,
                        current: CoreBusinessCreditList.pageNum,
                        pageSize: CoreBusinessCreditList.pageSize,
                    }}
                    onChange = {this.handleCurrentChange}
                />
            </div>
        )
    }
}

export default ListComp;

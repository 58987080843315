import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Spin, Radio, Modal, Table, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import AddOrEditModal from "./addOrEditModal";
const FormItem = Form.Item;
class SearchSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            systemName:"",
            isShowAddOrEdit: false,//true-新增 
            systemId: 0,//系统id
            modalStatus: 0,//1-新增  2-编辑 
        }
    }

    componentDidMount() {
        this.getSystemList();
    }

    //获取用户列表
    getSystemList = () => {
        let {pageNum, pageSize,systemName} = this.state;
        let params = {systemName,pageNum, pageSize};
        scfBaseApi.systemListApi(params).then(res => {
            let data = res.data;
            this.setState({
                tableData: data.data,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getSystemList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getSystemList();
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            systemName:"",
        });
        this.setState({
            systemName:"",
            pageNum: 1,
        }, () => {
            this.getSystemList();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {systemName} = values;
                this.setState({systemName},
                    () => {
                        this.getSystemList();
                    })
            }
        })
    }
    
    //新增系统
    handleAddSystem = () => {
        this.setState({
            isShowAddOrEdit: true,
            modalStatus:1,
        })
    }
    //编辑系统
    handleEdit = (systemId = 0) => {
        this.setState({
            isShowAddOrEdit: true,
            modalStatus:2,
            systemId
        })
    }
    //编辑/新增弹框确定后
    operationOk = () => {
        this.setState({
            isShowAddOrEdit: false
        }, () => {
            this.getSystemList();
        });
    }
    //编辑/新增弹框取消后
    operationCancel = () => {
        this.setState({
            isShowAddOrEdit: false
        });
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return null;
        };
    }
    render() {
        let {getFieldDecorator} = this.props.form;
        let {pageNum, pageSize, tableData, totalCount, isShowAddOrEdit, modalStatus, systemId} = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset:2, span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {offset: 1, span: 12}
            },
            labelAlign: 'right'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal:total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        };
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text,record,index) => {
                    return <span>{index+1}</span>
                }
            },
            {
                title: '系统ID',
                dataIndex: 'systemCode',
                key: 'systemCode',
                sorter:(a,b)=>{
                    if(!a.systemCode){
                        a.systemCode=""
                    }else if(!b.systemCode){
                        b.systemCode=""
                    }
                    return a.systemCode.localeCompare(b.systemCode)
                },
            },
            {
                title: '系统名称',
                dataIndex: 'systemName',
                key: 'systemName',
                sorter:(a,b)=>{
                    if(!a.systemName){
                        a.systemName=""
                    }else if(!b.systemName){
                        b.systemName=""
                    }
                    return a.systemName.localeCompare(b.systemName)
                },
            },
            {
                title: '系统类型',
                dataIndex: 'systemTypeName',
                key: 'systemTypeName',
                sorter:(a,b)=>{
                    if(!a.systemTypeName){
                        a.systemTypeName=""
                    }else if(!b.systemTypeName){
                        b.systemTypeName=""
                    }
                    return a.systemTypeName.localeCompare(b.systemTypeName)
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split(' ')[0] : "";
                    let time = text != null ? text.split(' ')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '创建人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                sorter:(a,b)=>{
                    if(!a.createUserName){
                        a.createUserName=""
                    }else if(!b.createUserName){
                        b.createUserName=""
                    }
                    return a.createUserName.localeCompare(b.createUserName)
                },
            },
            {
                title: '操作',
                render: (record) => {
                    return <div className="flex-around-center">
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleEdit(record.id)
                        }}>编辑</Button>
                    </div>

                }
            },
        ];
        let addOrEditModalProps = {
            modalStatus,
            systemId,
            operationOk: this.operationOk,
            operationCancel: this.operationCancel,
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit}  {...formRowItem} >
                    <Row>
                        <Col span={10} offset={2}>
                            <FormItem label="系统名称">
                                {
                                    getFieldDecorator('systemName', {
                                        rules:[
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            },
                                        ],
                                        initialValue: "",
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <div className="com-diff-two-btn">
                                <Button type="default" className="com-middle-btn-size"
                                        onClick={this.handleReset}>重置</Button>
                                <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <div>
                    <h2>系统信息列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size" onClick={this.handleAddSystem}>+新增</Button>
                    </div>
                </div>
                <Table
                    className=""
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount>10?pagination:false}
                />
                {isShowAddOrEdit === true ? <AddOrEditModal {...addOrEditModalProps}/> : null}
            </div>
        );
}
}

export default Form.create()(SearchSystem);
import React, {Component} from 'react';
import {Button, Col, Form, Input, message, Row, Spin, Table, Select} from "antd";
import scfBaseApi from "../../../request/api/scfBaseApi";
import ModalTipTwoBtn from "../../../components/modalTipTwoBtn";
import AddOrEditModal from "./addOrEditModal";
import OperatePurview from "./operatePurview";

const FormItem = Form.Item;
const Option = Select.Option;

class SearchMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            totalCount: 0,
            tableData: [],
            systemId: 0,//系统id
            menuName: "",
            menuId: 0,
            isShowModal: false,  //删除弹框
            isShowAddOrEdit: false,//true-新增
            isShowOperatePurview: false,//
            modalStatus: 0,//1-新增  2-编辑
            title: "",
            text: "",
            parentMenu: "",
            systemArr: [],

        }
    }

    componentDidMount() {
        this.getSystemQueryAll();
    }

    getSystemQueryAll = () => {
        scfBaseApi.systemQueryAllApi().then(res => {
            let data = res.data;
            this.setState({
                systemArr: data
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //获取菜单列表
    getMenuList = () => {
        let {pageNum, pageSize, systemId, menuName} = this.state;
        let params = {systemId, menuName, pageNum, pageSize};
        scfBaseApi.menuListApi(params).then(res => {
            let data = res.data;
            let tableData = this.deleteNullChildrenMenus(data.data);
            this.setState({
                tableData: tableData,
                totalCount: data.totalCount,
            })
        }).catch(err => {
            message.error(err.message);
        })
    }
    //遍历树结构删除空的chidrenMenus
    deleteNullChildrenMenus = (data) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].chidrenMenus !== undefined && data[i].chidrenMenus.length > 0) {
                this.deleteNullChildrenMenus(data[i].chidrenMenus);
            } else if (data[i].chidrenMenus !== undefined && data[i].chidrenMenus.length === 0) {
                delete data[i].chidrenMenus;
            }
        }
        return data;
    }
    changePage = (current) => {
        this.setState({
            pageNum: current
        }, () => {
            this.getMenuList();
        })
    }

    changeShowSize = (current, pageSize) => {
        this.setState({
            pageSize
        }, () => {
            this.getMenuList();
        })
    }
    handleReset = () => {
        this.props.form.setFieldsValue({
            systemId: "",
            menuName: "",
        });
        this.setState({
            systemId: "",
            menuName: "",
            pageNum: 1,
            tableData: []
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {systemId, menuName} = values;
                this.setState({systemId, menuName, pageNum: 1},
                    () => {
                        this.getMenuList();
                    })
            }
        })
    }

    //新增菜单
    handleAddSystem = () => {
        let systemId = this.props.form.getFieldValue('systemId');
        if (systemId === "") {
            message.warn("请选择所属系统");
        } else {
            this.setState({
                isShowAddOrEdit: true,
                modalStatus: 1,
                systemId: systemId,
                title: "新增菜单"
            })
        }

    }
    //新增子菜单
    handleAddChildMenu = (menuId = 0, menuName = "") => {
        this.setState({
            isShowAddOrEdit: true,
            modalStatus: 3,
            menuId,
            parentMenu: menuName,
            title: "新增子菜单"
        })
    }
    //编辑菜单
    handleEdit = (menuId = 0) => {
        this.setState({
            isShowAddOrEdit: true,
            modalStatus: 2,
            menuId,
            title: "编辑菜单"
        })
    }
    //编辑/新增弹框确定后
    operationOk = () => {
        this.setState({
            isShowAddOrEdit: false
        }, () => {
            this.getMenuList();
        });
    }
    //编辑/新增弹框取消后
    operationCancel = () => {
        this.setState({
            isShowAddOrEdit: false
        });
    }
    //删除角色
    handleDelete = (menuId = 0) => {
        this.setState({
            menuId,
            isShowModal: true,
            title: "删除",
            text: "此操作将删除菜单数据，您确认操作？"
        });
    }
    //确定删除
    handleOk = () => {
        let {menuId} = this.state;
        scfBaseApi.menuDeleteApi({id: menuId}).then(res => {
            message.success(res.message);
            this.setState({
                isShowModal: false
            });
            this.getMenuList();
        }).catch(err => {
            message.error(err.message);
        })

    }

    //取消删除
    handleCancel = () => {
        this.setState({
            isShowModal: false
        });
    };

    //操作权限
    handleOperatePurview = (menuId = 0) => {
        this.setState({
            isShowOperatePurview: true,
            menuId
        })
    }
    operationPurviewCancel = () => {
        this.setState({
            isShowOperatePurview: false
        }, () => {
            this.getMenuList();
        });

    }

    render() {
        let {getFieldDecorator} = this.props.form;
        let {
            pageNum, pageSize, tableData, totalCount, isShowAddOrEdit, modalStatus, menuId, systemId,
            title, text, isShowModal, isShowOperatePurview, systemArr, parentMenu
        } = this.state;
        let formRowItem = {
            labelCol: {
                xs: {span: 24},
                sm: {offset: 6, span: 3}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 9}
            },
            labelAlign: 'left'
        };
        let pagination = {
            position: "bottom",
            total: totalCount,
            showTotal: total => `总共${total}条`,
            pageSize: pageSize,
            defaultCurrent: 1,
            current: pageNum,
            onChange: this.changePage,
            onShowSizeChange: this.changeShowSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
        };
        let columns = [
            {
                title: '菜单名称',
                dataIndex: 'menuName',
                key: 'menuName',
                className: "menuName",
                sorter: (a,b)=>{
                    if(!a.menuName){
                        a.menuName=""
                    }else if(!b.menuName){
                        b.menuName=""
                    }
                    return a.menuName.localeCompare(b.menuName)
                },
            },
            {
                title: '菜单索引',
                dataIndex: 'menuSort',
                key: 'menuSort',
                sorter: (a,b)=>{
                    if(!a.menuSort){
                        a.menuSort=""
                    }else if(!b.menuSort){
                        b.menuSort=""
                    }
                    return a.menuSort-b.menuSort
                },
            },
            {
                title: '是否跳转',
                dataIndex: 'isRedirectCdDesc',
                key: 'isRedirectCdDesc',
                sorter: (a,b)=>{
                    if(!a.isRedirect){
                        a.isRedirect=""
                    }else if(!b.isRedirect){
                        b.isRedirect=""
                    }
                    return a.isRedirect.localeCompare(b.isRedirect)
                },
            },
            {
                title: '菜单类型',
                dataIndex: 'menuTypeCdDesc',
                key: 'menuTypeCdDesc',
                sorter: (a,b)=>{
                    if(!a.menuTypeName){
                        a.menuTypeName=""
                    }else if(!b.menuTypeName){
                        b.menuTypeName=""
                    }
                    return a.menuTypeName.localeCompare(b.menuTypeName)
                },
            },
            {
                title: '菜单路径',
                dataIndex: 'menuUrl',
                key: 'menuUrl',
                sorter: (a,b)=>{
                    if(!a.menuUrl){
                        a.menuUrl=""
                    }else if(!b.menuUrl){
                        b.menuUrl=""
                    }
                    return a.menuUrl.localeCompare(b.menuUrl)
                },
            },
            {
                title: '操作权限',
                dataIndex: 'operNames',
                key: 'operNames',
                sorter: (a,b)=>{
                    if(!a.operNames){
                        a.operNames=""
                    }else if(!b.operNames){
                        b.operNames=""
                    }
                    return a.operNames.localeCompare(b.operNames)
                },
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.modifyTime).getTime()
                    let bTime = new Date(b.modifyTime).getTime()
                    return aTime-bTime
                },
                render: (text) => {
                    let date = text != null ? text.split(' ')[0] : "";
                    let time = text != null ? text.split(' ')[1] : "";
                    return <div>
                        <div>{date}</div>
                        <div>{time}</div>
                    </div>
                }
            },
            {
                title: '修改人',
                dataIndex: 'modifyUserName',
                key: 'modifyUserName',
                sorter: (a,b)=>{
                    if(!a.modifyUserName){
                        a.modifyUserName=""
                    }else if(!b.modifyUserName){
                        b.modifyUserName=""
                    }
                    return a.modifyUserName.localeCompare(b.modifyUserName)
                },
            },
            {
                title: '操作',
                render: (record) => {
                    return <div>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleEdit(record.id)
                        }}>编辑</Button>
                        <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleDelete(record.id)
                        }}>删除</Button>
                        {record.menuType === "0" ? <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleAddChildMenu(record.id, record.menuName)
                        }}>新增子菜单</Button> : null}
                        {record.isRedirect === 1 ? <Button className="com-table-btn" type="primary" onClick={() => {
                            this.handleOperatePurview(record.id)
                        }}>操作权限</Button> : null}
                    </div>
                }
            },
        ];
        let modalProps = {
            title,
            text,
            operationOk: this.handleOk,
            operationCancel: this.handleCancel,

        };
        let addOrEditModalProps = {
            modalStatus,
            menuId,
            systemId,
            parentMenu,
            operationOk: this.operationOk,
            operationCancel: this.operationCancel,
            title
        };
        let operatePurviewProps = {
            menuId,
            operationCancel: this.operationPurviewCancel,
        }

        return (
            <div>
                <Form onSubmit={this.handleSubmit}  {...formRowItem} >
                    <Row>
                        <Col span={12}>
                            <FormItem label="所属系统">
                                {
                                    getFieldDecorator('systemId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属系统'
                                            },
                                        ],
                                        validateTrigger: "onBlur",
                                        initialValue: "",
                                    })(<Select>
                                        <Option key={0} value="">请选择</Option>
                                        {
                                            systemArr.length !== 0 ? systemArr.map(record => {
                                                return <Option key={record.id}
                                                               value={record.id}> {record.systemName} </Option>
                                            }) : null
                                        }
                                    </Select>)
                                }
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="菜单名称">
                                {
                                    getFieldDecorator('menuName', {
                                        rules: [
                                            {
                                                max: 100,
                                                message: "最大长度为100位,请重新输入"
                                            },
                                        ],
                                        initialValue: "",
                                    })(<Input/>)
                                }
                            </FormItem>
                        </Col>

                    </Row>
                    <div className="com-diff-two-btn">
                        <Button type="default" className="com-middle-btn-size"
                                onClick={this.handleReset}>重置</Button>
                        <Button type="primary" className="com-middle-btn-size" htmlType="submit">查询</Button>
                    </div>
                </Form>
                <div>
                    <h2>菜单信息列表</h2>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <Button type="primary" className="com-middle-btn-size"
                                onClick={this.handleAddSystem}>+新增</Button>
                    </div>
                </div>
                <Table
                    className="menu-table"
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => record.id}
                    pagination={totalCount > 10 ? pagination : false}
                    childrenColumnName="chidrenMenus"
                />
                {isShowModal === true ? <ModalTipTwoBtn {...modalProps} /> : null}
                {isShowAddOrEdit === true ? <AddOrEditModal {...addOrEditModalProps}/> : null}
                {isShowOperatePurview === true ? <OperatePurview {...operatePurviewProps}/> : null}
            </div>
        );
    }
}

export default Form.create()(SearchMenu);
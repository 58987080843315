import React, { Component } from 'react';
import { Table , Button , message } from 'antd';
import { withRouter } from "dva/router";
import { queryAuditHistroyInfoApi } from '../../../request/api/quota'
@withRouter
class AuditHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          tableData:[]
        }
    }
    componentDidMount(){
      this.getAuditHistroyInfo()
    }
    getAuditHistroyInfo = ()=>{
      const { businessId , businessType  } = this.props
      queryAuditHistroyInfoApi({businessId,businessType}).then(res => {
          if(res.code=='2000'){
              this.setState({
                  tableData:res.data
              })
          }else{
              message.info(res.message)
          }
      }).catch(err => {
          message.error(err.message);
      })
  }
    goBack = ()=>{
      this.props.history.push("/admin/businessTicketManage/quota")
    }
    render() { 
        const columns = [
            {
              title: '操作流程',
              dataIndex: 'sysBusinessTypeCdDesc',
            },
            {
              title: '操作人',
              dataIndex: 'createUserName',
            },
            {
              title: '操作时间',
              dataIndex: 'createTime',
            },
            {
                title: '审核结果',
                dataIndex: 'stateCdDesc',
              },
              {
                title: '审核意见',
                dataIndex: 'des',
              }
          ];
        const { tableData } = this.state  
        return ( 
            <>
                <div className='ah-box'>
                    <h1>审核历史</h1>
                    <Table 
                      bordered 
                      columns={columns} 
                      dataSource={tableData}  
                      pagination={false}
                      />
                      {
                        /***
                         *  <div style={{textAlign:'center',marginTop:'20px'}}>
                              <Button type="primary" onClick={this.goBack}>返回</Button>
                            </div>
                         * 
                         */
                      }
                </div>
            </>
         );
    }
}
 
export default AuditHistory;
import React, { Component } from 'react';
import { Modal, Button, Icon, message } from 'antd';
import scfBusinessApi from '@/request/api/scfBusinessApi';

const { confirm } = Modal;
const modifyBorrowerStateApi = scfBusinessApi['modifyBorrowerStateApi'];

class ModalMsgComp extends Component {

    forbidden = (resolve, reject) => {
        const { status, id } = this.props;
        const params = { id: id, borrowerState: status+'' };
        modifyBorrowerStateApi(params).then(res => {
            if(res.code === '0') {
                message.success(res.message);
            }
            setTimeout(() => {
                this.props.updata();
            },0);
            return resolve();
        }).catch(err => {
            message.error(err.message);
            return reject();
        })
    }

    showConfirm = () => {
        confirm({
            title: '是否确认要禁用该借款方授信？',
            content: '',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.forbidden(resolve, reject);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    info = () => {
        confirm({
            title: '是否确认要启用该借款方授信？',
            content: '',
            icon: <Icon type="info-circle" theme="twoTone" />,
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.forbidden(resolve, reject);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.type === 0 
                        ? <Button className='com-btn-opr com-small-btn-size mr5' onClick={this.showConfirm}>禁用</Button>
                        : null
                }
                {
                    this.props.type === 1 
                        ? <Button className='com-btn-opr com-small-btn-size mr5' onClick={this.info}>启用</Button>
                        : null
                }
            </React.Fragment>
        )
    }
}

export default ModalMsgComp;

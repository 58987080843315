import React, { Component } from 'react'
import {Table,Button,message} from 'antd'
import scfBusinessApi from "../../../request/api/scfBusinessApi";

export default class WaitDealt extends Component {
    constructor(props){
        super(props)
        this.state={
            tableData:[],
            

        }
    }
    componentDidMount(){
        this.getDate()
    }
    goBack = () =>{
        this.props.history.go(-1)
    }

    //待办列表数据
    getDate = () =>{

        scfBusinessApi.todoQueryApi().then(res=>{
             this.setState({
               tableData: res.data
             })
        }).catch(err=>{
            message.error(err.message)
        })
    }

    //待办跳详情
    toDetail = (relationId,sysBusinessType,operationType,wkId,nodeId) => {
             if(sysBusinessType==="203010"){  //商票交易-转让-审核
                scfBusinessApi.getAuditNeedIdsApi({id:relationId}).then(res=>{
                  let ticketLedgerId = res.data.ticketLedgerId;//原票详情接口参数id
                  if(operationType=="10"||operationType=="00"){  
                      this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/examine/${relationId}/${ticketLedgerId}/${wkId}/${nodeId}`)
                  }
                }).catch(err=>{
                    message.error(err.message)
                })
             }else if(sysBusinessType==="203090"){  //商票交易-转让-撤销审核
                scfBusinessApi.getAuditNeedIdsApi({id:relationId}).then(res=>{
                    let ticketLedgerId = res.data.ticketLedgerId;//原票详情接口参数id
                    if(operationType=="10"||operationType=="00"){  
                        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/revokeexamine/${relationId}/${ticketLedgerId}/${wkId}/${nodeId}`)
                    }
                  }).catch(err=>{
                      message.error(err.message)
                  })
             }else if(sysBusinessType==="203020"){ // 商票交易
                scfBusinessApi.getAuditNeedIdsApi({id:relationId}).then(res=>{
                    let ticketLedgerId = res.data.ticketLedgerId;//原票详情接口参数id
                    if(operationType=="10"){     //-签收审核
                        this.props.history.push(`/admin/commercialTicketManage/CtTransforDetailClient/signExamine/${relationId}/${ticketLedgerId}/${wkId}/${nodeId}`)
                    }else if(operationType=="00"){  //-签收
                        this.props.history.push(`/admin/commercialTicketManage/CtSign/Sign/${relationId}/${ticketLedgerId}/${wkId}/${nodeId}`)
                    }
                  }).catch(err=>{
                      message.error(err.message)
                  })
             }else if(sysBusinessType==="200520"){  //商票权限管理-审核
                 if(operationType=="10"||operationType=="00"){
                    this.props.history.push(`/admin/commercialTicketManage/ConfirmRoleAuthority/${relationId}`)
                 }
             }else if(sysBusinessType==="202010"){  // 商票发行审核
                scfBusinessApi.getTicketDetailApi({id:relationId}).then(res=>{
                    let data = res.data
                    if(operationType=="10"||operationType=="00"){  
                    this.props.history.push(`/admin/businessTicketIssue/detail?id=${relationId}&wkId=${wkId}&nodeId=${nodeId}&ticketStatusCd=${data.ticketStatusCd}`)
                    }
               })
               
            }else if(sysBusinessType==="202020"){  // 签收审核
                scfBusinessApi.getTicketDetailApi({id:relationId}).then(res=>{
                    let data = res.data
                    if(operationType=="10"||operationType=="00"){  
                    this.props.history.push(`/admin/businessTicketIssue/detail?id=${relationId}&wkId=${wkId}&nodeId=${nodeId}&ticketStatusCd=${data.ticketStatusCd}`)
                    }
               })
            }else if(sysBusinessType==="202030"){  // 收票审核
                scfBusinessApi.getTicketDetailApi({id:relationId}).then(res=>{
                    let data = res.data
                    if(operationType=="10"||operationType=="00"){  
                    this.props.history.push(`/admin/businessTicketIssue/detail?id=${relationId}&wkId=${wkId}&nodeId=${nodeId}&ticketStatusCd=${data.ticketStatusCd}`)
                    }
               })
            }else if(sysBusinessType==="204010"){  // 付款审核
                scfBusinessApi.getTicketDetailApi({id:relationId}).then(res=>{
                    let data = res.data
                    if(operationType=="10"||operationType=="00"){  
                    this.props.history.push(`/admin/bussinessTicketPayMoney/paymoneyAudit?id=${relationId}&wkId=${wkId}&nodeId=${nodeId}&ticketStatusCd=${data.ticketStatusCd}&audit=2`)
                    }
               })
            }
                
    }
    render() {
        let {tableData}=this.state;
        let columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                render: (text, record, index) => {
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                sorter:(a,b)=>{
                    if(!a.title){
                        a.title=""
                    }else if(!b.title){
                        b.title=""
                    }
                    return a.title.localeCompare(b.title)
                },
               
            },
            {
                title: '申请时间',
                dataIndex: 'createTime',
                key: 'createTime',
                sorter: (a, b) => {
                    let aTime = new Date(a.createTime).getTime()
                    let bTime = new Date(b.createTime).getTime()
                    return aTime - bTime
                }
            },
            {
                title: '申请方',
                dataIndex: 'orgName',
                key: 'orgName',
                sorter:(a,b)=>{
                    if(!a.orgName){
                        a.orgName=""
                    }else if(!b.orgName){
                        b.orgName=""
                    }
                    return a.orgName.localeCompare(b.orgName)
                }
            },
            {
                title: '申请人',
                dataIndex: 'createUserName',
                key: 'createUserName',
                sorter:(a,b)=>{
                    if(!a.createUserName){
                        a.createUserName=""
                    }else if(!b.createUserName){
                        b.createUserName=""
                    }
                    return a.createUserName.localeCompare(b.createUserName)
                }
            },
           
            {
                title: '操作',
                render: (record) => {
                    return <div className="flex-around-center">
                         <Button type="primary" onClick={()=>this.toDetail(record.relationId,record.sysBusinessType,record.operationType,record.wkId,record.nodeId)}>去操作</Button>
                    </div>

                }
            },
        ];
        return (
            <div>
                <h2>待办事项</h2>
                <Button style={{marginBottom: '20px'}} onClick={this.goBack}>返回</Button>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey={(record, index) => index}
                    pagination={false}
                />
            </div>
        )
    }
}

import React, { Component } from 'react'
import echarts from 'echarts';
import scfBusinessApi from '../../../request/api/scfBusinessApi'
import { message } from 'antd';

export default class FuminAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allDate: "",
            withdraw: "",  //提现
            ledger: "",//担保
            transfer: "",//转账
            consume: "",//交易
            intervalId:""

        }
    }
    componentDidMount() {
        this.getData()
    var intervalId = setInterval(() => {
          this.getData();
        }, 60000);
        this.setState({
            intervalId,
        })
}
componentWillUnmount = () => {
    let {intervalId} = this.state
    clearInterval(intervalId)
}
        

    getData = () => {
        let params = {
            // startTime: '2020-01-01 01:01:00',
            // endTime: '2020-09-27 23:00:00',
            timeUnitCd: '5'
        }
        scfBusinessApi.getFmStatisticsApi(params).then(res => {
            let data = res.data
            this.setState({
                allDate: data.allDate,
                withdraw: data.withdraw,
                ledger: data.ledger,
                transfer: data.transfer,
                consume: data.consume
            }, () => {
                this.initalECharts();
            })
        }).catch(err => {
            message.error(err.message)
        })
    }
    initalECharts = () => {
        let { allDate, withdraw, ledger, transfer, consume } = this.state
        // console.log(allDate)
        const myChart = echarts.init(document.getElementById('FuminAccount'))
        let option = {
            // title: {
            //     text: '富民账户交易',
            //     textStyle: {
            //         // color: 'rgb(179, 239, 255)',
            //         color:'#fff',
            //         fontSize: 16
            //     },
            // },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['提现', '担保', '转账', '交易'],
                textStyle: {
                    // color: 'rgb(179, 239, 255)',
                    color:'#fff',
                    fontSize: 16
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '1%',
                height: '90%',
                containLabel: true,
            },
            // toolbox: {
            //     feature: {
            //         saveAsImage: {}
            //     }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: allDate,
                axisLabel: {
                    show: true,
                     textStyle: {
                       color: '#fff',  //更改坐标轴文字颜色
                       fontSize : 14      //更改坐标轴文字大小
                     }
                  },
                  axisLine:{
                    lineStyle:{
                       color:'#fff' //更改坐标轴颜色
                    }
              }
            },
            yAxis: {
                type: 'value',
                splitLine: { show: false },
                axisLabel: {
                    show: true,
                     textStyle: {
                       color: '#fff',  //更改坐标轴文字颜色
                       fontSize : 14      //更改坐标轴文字大小
                     }
                  },
                  axisLine:{
                    lineStyle:{
                       color:'#fff' //更改坐标轴颜色
                    }
              }
            },
            series: [
                {
                    name: '提现',
                    type: 'line',
                    // stack: '总量',
                    data: withdraw
                },
                {
                    name: '担保',
                    type: 'line',
                    // stack: '总量',
                    data: ledger
                },
                {
                    name: '转账',
                    type: 'line',
                    // stack: '总量',
                    data: transfer
                },
                {
                    name: '交易',
                    type: 'line',
                    // stack: '总量',
                    data: consume
                },
            ]
        };


        myChart.setOption(option);
        window.addEventListener('resize', function() {
            myChart.resize(); 
              })
    }
    render() {
        return (
          
            <div id="FuminAccount" style={{ width: '100%', height: '100%',padding:'5px' }}></div>
         
                
            
        )
    }
}

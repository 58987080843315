import React, { Component } from "react";
import loginApi from "@/request/api/login";
import ReactSimpleVerify from 'react-simple-verify'
import 'react-simple-verify/dist/react-simple-verify.css'
import {
    Form,
    Input,
    Row,
    Col,
    Checkbox,
    Button,
    message
} from "antd";
import CryptoJS from "crypto-js";
import { sendsmsApi , forgotPasswordApi } from '../../request/api/login'
import { checkPhone , isEmpyt } from '../../utils/formatCheck'
import { outputJson } from "fs-extra";
let temer;
const getAesApi = loginApi["getAesApi"];
class forgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateKey: "",
            stateIv: "",
            verify:false,
            codeNum: 60,
            codeText:'获取验证码',
            disabledFalg:false,
        };
    }
    componentDidMount(){
        this.handleGetAesApi()
    }
    handleGetAesApi = () => {
        getAesApi()
            .then((res) => {
                this.setState({
                    stateIv: res.data.AES_IV,
                    stateKey: res.data.AES_KEY,
                    loadingFlag: false,
                });
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    handleEncrypt = (word) => {
        const {stateKey, stateIv} = this.state;
        const key = CryptoJS.enc.Utf8.parse(stateKey);
        const iv = CryptoJS.enc.Utf8.parse(stateIv);
        console.log("key:"+key)
        console.log("iv:"+iv)
        let encrypted = CryptoJS.AES.encrypt(word, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString();
    };
    handleSubmit = e => {
        e.preventDefault();
        const { kaptchaId } = this.state
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            let pw = this.handleEncrypt(values.password);
            let cpw = this.handleEncrypt(values.confirmPassword);
            values.password = pw
            values.confirmPassword = cpw
            values.kaptchaId = kaptchaId
            this.forgotPassword(values)
          }
        });
      };
    forgotPassword = (params)=>{
        forgotPasswordApi(params).then(res=>{
            if(res.code=='2000'){
                message.success(res.message)
                this.props.history.push('/login');
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
            message.error(err.message)
        })
    }  
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('两次密码不一致，请重新输入!');
        } else {
          callback();
        }
    };
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    success = ()=>{
        this.setState({
            verify:true
        })
    }
    getSmsCode = (params)=>{
        sendsmsApi(params).then(res=>{
            if(res.code=='2000'){
                this.setState({
                    kaptchaId:res.data.kaptchaId
                })
                message.success(res.message)
            }else{
                message.info(res.message)
            }
        }).catch(err=>{
            message.error(err.message)
        })
    }
    getCode = ()=>{
        const { codeNum , verify } = this.state;
        const mobile =  this.props.form.getFieldValue('mobile');
        if(isEmpyt(mobile)){
            message.info('请输入手机号！')
            return
        }
        if(verify){
            let num = codeNum;
            this.setState({
                disabledFalg:true
            })
            let params = {
                phoneNumbers:mobile,
                usage:3
            }
            this.getSmsCode(params)
            temer = setInterval(() => {
                num--;
                if (num === 0) {
                    clearInterval(temer)
                    this.setState({
                        codeNum: 60,
                        codeText: '重新获取',
                        disabledFalg:false
                    });
                } else {
                    this.setState({
                        codeNum: num,
                        codeText: num + 's后重新获取',
                        disabledFalg:true
                    });
                }
            }, 1000);
        }else{
            message.info("请先拖动滑块进行校验")
        }
    }
    goBack = ()=>{
        this.props.history.push('/login');
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { codeText , disabledFalg } = this.state
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                span: 24,
                offset: 0,
                },
                sm: {
                span: 16,
                offset: 4,
                },
            },
        };
        return (
            <>  
                <div className='bg-img'>
                    <div className='register-title'>
                        <h1 className='register-h1'>登录密码重置</h1>
                        <p className='register-p'></p>
                    </div>
                    <div className="register-box">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="手机号码">
                                {getFieldDecorator("mobile", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入手机号码",
                                        },
                                        {
                                            validator: checkPhone,
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="登录密码" hasFeedback>
                                {getFieldDecorator("password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入登录密码",
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        },
                                    ],
                                })(<Input.Password />)}
                            </Form.Item>
                            <Form.Item label="确认密码" hasFeedback>
                                {getFieldDecorator("confirmPassword", {
                                    rules: [
                                        {
                                            required: true,
                                            message:"请输入确认密码",
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        },
                                    ],
                                })(
                                    <Input.Password
                                        onBlur={this.handleConfirmBlur}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="验证">
                                <ReactSimpleVerify ref="verify" success={ this.success }/>
                            </Form.Item>
                            <Form.Item
                                label="验证码"
                            >
                                <Row gutter={8}>
                                    <Col span={16}>
                                        {getFieldDecorator("kaptcha", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:"请输入验证码",
                                                },
                                            ],
                                        })(<Input />)}
                                    </Col>
                                    <Col span={8}>
                                        <Button onClick={this.getCode} disabled={disabledFalg}>{ codeText }</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Col span={12}>
                                    <Button onClick={this.goBack}>
                                        返回
                                    </Button>
                                </Col>
                                <Button type="primary" htmlType="submit">
                                    确定修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const ForgotPasswordForm = Form.create()(forgotPassword);
export default ForgotPasswordForm;
